import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { createRef } from "react";
import * as Yup from "yup";
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

toast.configure()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  labelEmail: string;
  labelPassword: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  reCaptchaVarified: boolean;
  showPassword: boolean;
  siteKeyOfCaptcha: string;
  loginLoader: boolean;
  openDialoug:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  checkBtn:number;
  resendApiCallId:any;
  ChatAuthTokenApiCallId:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      reCaptchaVarified: false,
      showPassword: false,
      labelEmail: configJSON.emailInputLabel,
      labelPassword: configJSON.passwordInputLabel,
      siteKeyOfCaptcha: configJSON.siteKeyOfCaptcha,
      loginLoader: false,
      openDialoug: false,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.checkBtn=0;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  //Customizable area start
  async componentDidMount(){
    //@ts-ignore
    const b = document.querySelector("#loginBTN");
    //@ts-ignore
    b.setAttribute("style", 'background-color: rgb(24, 130, 173); color: #ffffff52; height: 50px; margin-top: 25px; padding: 10px 30px; text-transform: none; font-size: 16px; font-family: Poppins, sans-serif;') 
    try {
      const Token = Cookies.get("Login_Token")
      const verifiedMail=Cookies.get('Account_Verified')
      const currentMail=Cookies.get('Login_Email')
      if (Token && (verifiedMail===currentMail)) {
        toast.info("You are already Log In",{position: toast.POSITION.BOTTOM_RIGHT})
        //@ts-ignore
             this.props.history.push({
              pathname: '/'
            })
      }
    } catch (error) {
      Cookies.remove("Login_Token")
      Cookies.remove('Chat_Token')
      console.log("error", error)
      toast.info("Due to technical error your session closed Please login Again",{position: toast.POSITION.BOTTOM_RIGHT})
       //@ts-ignore
             this.props.history.push({
              pathname: '/'
            })
    }
    this.setState({ loginLoader: false })
  }

  _reCaptchaRef = createRef();

  LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required(configJSON.emailRequire)
      .matches(configJSON.emailRegex, configJSON.errorEmailNotValid),
    password: Yup.string()
      .required(configJSON.passwordRequire)
      .min(8, configJSON.passwordMinlength)
      .matches(configJSON.passwordRegex, configJSON.passwordInValid) ,
    remember: Yup.string(),
  });

  onRecaptchaChange = (value: any) => {
    try {
      if (value) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  checkDisabled = (errors: any, captcha: any) => {
    if (!Object.keys(errors).length && captcha) {
      //@ts-ignore
    const b = document.querySelector("#loginBTN");
    //@ts-ignore
    b.setAttribute("style", 'background-color: rgb(24, 130, 173); color: white; height: 50px; margin-top: 25px; padding: 10px 30px; text-transform: none; font-size: 16px; font-family: Poppins, sans-serif;') 
    this.checkBtn=1; 
    return false;
    }else if(this.checkBtn===1){
        //@ts-ignore
    const b = document.querySelector("#loginBTN");
    //@ts-ignore
    b.setAttribute("style", 'background-color: rgb(24, 130, 173); color: #ffffff52; height: 50px; margin-top: 25px; padding: 10px 30px; text-transform: none; font-size: 16px; font-family: Poppins, sans-serif;') 
      return true;
    } 
    else {
      return true;
    }
  };
  gotoSignup(props:any){
    
    //@ts-ignore
     this.props.history.push('/signup')
  }
  gotoForgetpassword(props:any){
    
    //@ts-ignore
     this.props.history.push('/forgotPass')
  }
  

  doLogin(values: any) {
    this.setState({ loginLoader: true })
    this.doEmailLogIn(values)
  }

  doEmailLogIn(values:any): boolean {

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: values.email,
      password: values.password
    };

    const data = {
      type: "email_account",
      token : Cookies.get('Push_NotificationToken'),
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  resendCode=(): boolean=>{
    
    
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const data = {
      attributes: {
      email: Cookies.get('Login_Email')
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
       'POST'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

   runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  
  generateChatAuthToken() : boolean {
    
    
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ChatAuthTokenApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_chat/tokens'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.apiEmailLoginCallId &&
            responseJson !== undefined
          ) {
             if(responseJson?.data?.attributes?.account_status==="account_created"){
              this.setState({ loginLoader: false })
              toast.info("Please create profile to continue.",{position: toast.POSITION.BOTTOM_RIGHT}) 
                    //@ts-ignore
               this.props.history.push({
                pathname: '/profile',
                state: { detail: responseJson }
              })
             }else if(responseJson?.data?.attributes?.account_status==="profile_created" || responseJson?.data?.attributes?.account_status === "deactivated"){
              this.setState({ loginLoader: false })
              Cookies.set('Login_Token', responseJson.meta.token)
              Cookies.set('Login_Email', responseJson.data.attributes.email)
              Cookies.set('Login_Id', responseJson.data.id)
              this.resendCode()
              toast.info("Please verify your account, You may have received otp on your email account.",{position: toast.POSITION.BOTTOM_RIGHT}) 
             //@ts-ignore
             this.props.history.push({
              pathname: '/verification',
              state: { detail: responseJson }
            })
            }else{
              Cookies.set('Login_Token', responseJson.meta.token)
              Cookies.set('Login_Email', responseJson.data.attributes.email)
              Cookies.set('Login_Id', responseJson.data.id)
              Cookies.set('Account_Verified',responseJson.data.attributes.email)
              this.generateChatAuthToken()
               
            }
          }
          if (
            apiRequestCallId === this.resendApiCallId &&
            responseJson !== undefined
          ) {
          }
          if (
            apiRequestCallId === this.ChatAuthTokenApiCallId &&
            responseJson !== undefined
          ) {
            this.setState({ loginLoader: false })
            toast.success('Login Successful',{position: toast.POSITION.BOTTOM_RIGHT})
            Cookies.set("Chat_Token", responseJson.token)
               //@ts-ignore
               this.props.history.push({
                pathname: '/',
                state: { detail: responseJson }
              })
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;

        if (errors[0]?.failed_login) {
          this.setState({ loginLoader: false })
          Cookies.remove('Login_Token')
          toast.error(errors[0]?.failed_login, { position: toast.POSITION.BOTTOM_RIGHT })
          return
        }

        if ((apiRequestCallId === this.ChatAuthTokenApiCallId
          || apiRequestCallId === this.resendApiCallId
          || apiRequestCallId === this.apiEmailLoginCallId) &&
          (errors[0]?.token === 'Invalid token' || errors[0]?.token === "Token has Expired")) {
          Cookies.remove('Login_Token')
          Cookies.remove('Login_Email')
          Cookies.remove('Login_Id')
          Cookies.remove('Account_Verified')
          this.setState({ loginLoader: false })
          toast.error(errors[0].token, { position: toast.POSITION.BOTTOM_RIGHT })
        } else {
          toast.error(errors, { position: toast.POSITION.BOTTOM_RIGHT })
        }
      }
    }
  }

  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  //Customizable area Ends
}
