import React from "react";

// Customizable Area Start
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import VerificationCodeController, {
  Props,
} from "./VerificationCodeController.web";
import { classNames } from "react-select/src/utils";

class VerificationCode extends VerificationCodeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.verificationWrapper}>
          <Formik
            initialValues={{
              verification: "",
            }}
            validationSchema={this.SubmitSchema}
            onSubmit={(values): any => this.doContinue(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              handleBlur,
            }) => (
              //@ts-ignore
              <Form className={classes.verificationForm}>
                <h1>deegee</h1>
                <h2>Verification</h2>
                <p>Please enter the verification code sent to your email.</p>
                <h3>Verification code</h3>
                <div className={classes.verificationTextArea}>
                  <TextField
                    id="verification"
                    variant="outlined"
                    name="verification"
                    onChange={handleChange}
                    onFocus={()=>this.setState({doSub:false})}
                    onBlur={handleBlur}
                    value={values.verification}
                    helperText={errors.verification}
                    error={
                      touched.verification && errors.verification ? true : false
                    }
                    inputProps={{ maxLength: 4, minLength: 4 }}
                  />
                </div>
                <div className={classes.notReceived}>
                  <span>Have not received?</span>
                  <Button className={classes.resendCode} variant="contained" onClick={this.resendCode}>Resend the code</Button>
                </div>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                  disabled={this.state.doSub}
                  className={classes.submitBtn}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </>
    );
  }
}
const useStyles = {
  verificationWrapper: {
    padding: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    minWidth: "100%",
    minHeight: "100vh",
    boxSizing: "border-box",
    "& *": {
      boxSizing: "border-box",
    }
  },
  verificationForm: {
    backgroundColor: "#fffffe",
    maxWidth: "546px",
    borderRadius: "10px",
    margin: "0 auto",
    padding: "40px",
    "& h1": {
      color: "#1f7fb6",
      margin: "0",
      fontSize: "38px",
      lineHeight: "1.5",
      textAlign: "center",
      paddingBottom: "40px",
      fontFamily: "'Poppins', sans-serif",
      fontWeight: "700",
    },
    "& h2": {
      fontWeight: "600",
      paddingBottom: "35px",
      color: "#4d4e4c",
      margin: "0",
      fontSize: "39px",
      lineHeight: "1",
      fontFamily: "'Poppins', sans-serif",
    },
    "& p": {
      color: "#4d4e4c",
      margin: "0",
      paddingBottom: "20px",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "1",
      fontFamily: "'Poppins', sans-serif",
    },
    "& h3": {
      color: "#4d4e4c",
      margin: "0",
      paddingBottom: "10px",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "1",
      fontFamily: "'Poppins', sans-serif",
    },
  },
  verificationTextArea: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiOutlinedInput-input": {
      padding:"5px 12px",
      height: "48px",
      width: "100%",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontSize: "10px"
    }
  },
  notReceived: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0 0",
    "& span": {
      color: "#777776",
      margin: "0",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "1",
      fontFamily: "'Roboto', sans-serif",
      marginRight: "5px",
    }
  },
  resendCode: {
    margin: "0",
    padding: "0",
    backgroundColor: "transparent",
    boxShadow: "none",
    "& span": {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "1",
      fontFamily: "'Roboto', sans-serif",
      color: "#1f7fb6",
      textTransform: "none",
    }
  },
  submitBtn: {
    textTransform: "none",
    display: "block",
    backgroundColor: "#1f7fb6",
    border: "2px solid #1f7fb6",
    borderRadius: "55px",
    textAlign: "center",
    color: "#ffffff",
    fontWeight: 700,
    width: "100%",
    marginTop: "30px",
    "&:hover": {
      backgroundColor: "#1f7fb6",
    }
  }
} as any;
//@ts-ignore
const verificationCode = withRouter(VerificationCode);
export default withStyles(useStyles)(verificationCode);