import React, { useState, useEffect } from "react";
import { getToken } from "../../web/src/firebaseInit";
import Cookies from 'js-cookie';
const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);

  console.log("Token found", isTokenFound);

  // To load once
  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        console.log("Given Token is", data);
        Cookies.set('Push_NotificationToken', data)
      }
      return data;
    }

    tokenFunc();
  }, [setTokenFound]);

  return <></>;
};

Notifications.propTypes = {};

export default Notifications;
