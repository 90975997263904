import React from "react";

// Customizable Area Start
import "date-fns";
import chatOneToOneController, { Props } from "./chatOneToOneController.web";
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined'
import SendIcon from "@material-ui/icons/Send"
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import "@reach/menu-button/styles.css";
import NavbarWeb from "../../TargetedFeed/src/Navbar.web";
import Sidebar from '../../TargetedFeed/src/Sidebar.web';
import { connect } from "react-redux";
//@ts-ignore
import { GET_CLIENT, GET_ACTIVEUSERS, DELETE_ACTIVEUSERS, GET_USERSID, GET_INVITED_CHANNELS, DELETE_USERID, GET_LASTMESG, GET_CHAT_USERLIST, DELETE_CHAT_USERLIST, DELETE_LASTMESG, UPDATED_USR_LAST_MSG, UPDATE_LAST_MSG, REMOVE_BADGE } from "../../../web/src/allActions.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  AppBar,
  Backdrop,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  List,
  Toolbar,
  ListItem
} from "@material-ui/core";
import Cookies from "js-cookie";
import CloseIcon from "@material-ui/icons/Close";
import { Scrollbar } from "react-scrollbars-custom";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
// Customizable Area End

class chatOneToOne extends chatOneToOneController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    //@ts-ignore
    const { classes } = this.props
    const { loading, text, messages, channel } = this.state;
    let messageOwner = Cookies.get("Login_Email") || "";
    let isOwnMessage = false;
    if (messageOwner) {
      isOwnMessage = true
    }
    let count = -1;
    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        this.sendMessage(event)
        //@ts-ignore
        document.getElementById('BottomViewID').scrollIntoView();
      }
    }

    const handleRedirect = (id: string) => {
      if (!!id) {
        //@ts-ignore
        this.props.history.push({
          pathname: '/user/' + id,
          state: { detail: id }
        })
      }
    }

    return (
      <>
        <NavbarWeb />
        <div className={classes.container}>
          <div style={{ display: 'flex', justifyContent: "space-between", maxHeight: "calc(100vh - 80px)" }}>
            <Sidebar
              //@ts-ignore
              callcomponentDidMountFromChat={this.callcomponentDidMountFromChat}
              chatOneToOne={'chatOneToOne'} />
            <div className={classes.rightContent}>
              <div className={classes.rightContentInner}>
                <div className={classes.chatUserHeader}>
                  <Avatar
                    alt="Steave Rogers"
                    src={this.state.userProfileImage}
                    onClick={() => handleRedirect(this.state?.useProfileId)}
                    className={classes.pointer} />
                  <Typography onClick={() => handleRedirect(this.state?.useProfileId)} className={classes.pointer}>
                    {this.state.userFullName}
                    {
                      //@ts-ignore
                      this.props?.activeUser?.length !== 0 && (
                        //@ts-ignore
                        this.props?.activeUser.map((user: any, indexUser: number) => {
                          //@ts-ignore
                          if (user?.identity == this.state.userEmail && user?.is_online == true) {

                            return (
                              <span key={indexUser}>Active
                                <FiberManualRecordIcon key={indexUser + user} style={{ color: 'green', fontSize: '10px' }} />
                              </span>
                            )

                            //@ts-ignore
                          }
                        })
                      )
                    }
                  </Typography>
                </div>
                <div className={classes.chatUserContent}>

                  <div className={classes.chatUser}>
                    {/* <Avatar alt="Steave Rogers" src={this.state.userProfileImage} />
                    <Typography>
                      {this.state.userFullName}
                    </Typography> */}
                  </div>
                  <div style={{ padding: "0 15px" }}>
                    <Backdrop style={{ zIndex: 99999 }} open={loading}>
                      {/* <CircularProgress style={{ color: "white" }} /> */}
                    </Backdrop>
                    <CssBaseline />
                    <Scrollbar className={classes.customScroll + " " + classes.sidebarCustomScroll}>
                      <div style={styles.mainGrid}>
                        <div style={styles.gridItemChatList} >
                          <List dense={true}>
                            {messages.length !== 0 ? (
                              messages.map((message: any, index: any) => {
                                if (message.index <= this.state.lastConsumedMessageIndex) {
                                  if (message.state.type === "media") {

                                    return (
                                      <ListItem
                                        key={index + message.author + 1}
                                        //@ts-ignore
                                        style={styles.listItem(message.state.author)}>
                                        <div>
                                          <img
                                            //@ts-ignore
                                            // src={this.state.mediaMesg[count]}
                                            alt="&nbsp;&nbsp;Loading..." key={index + index} style={{ height: '150px', width: '200px' }} id={"msgImG" + index} />

                                        </div>
                                        <div key={index + message.author + message + index}
                                          //@ts-ignore
                                          style={styles.timestamp}>
                                          {new Date(message.dateCreated.toISOString())?.toLocaleString()}
                                          <CheckCircleRoundedIcon style={styles.timestampSvgUnread(message.state.author)} />

                                        </div>
                                      </ListItem>)
                                  } else {
                                    return (
                                      <ListItem
                                        key={index + message.author + 1}
                                        //@ts-ignore
                                        style={styles.listItem(message.state.author)}>
                                        <div key={index + message.author + 2 + index}
                                          style={styles.container(message.state.author)}>
                                          {message.body}
                                        </div>
                                        <div key={index + message.author + message + index}
                                          //@ts-ignore
                                          style={styles.timestamp}>
                                          {new Date(message.dateCreated.toISOString())?.toLocaleString()}
                                          <CheckCircleRoundedIcon style={styles.timestampSvgUnread(message.state.author)} />

                                        </div>
                                      </ListItem>)

                                  }
                                } else {
                                  if (message.state.type === "media") {

                                    return (
                                      <ListItem
                                        key={index + message.author + 1}
                                        //@ts-ignore
                                        style={styles.listItem(message.state.author)}>
                                        <div>
                                          <img
                                            //@ts-ignore
                                            // src={this.state.mediaMesg[count]}
                                            alt="&nbsp;&nbsp;Loading..." key={index + index} style={{ height: '150px', width: '200px' }} id={"msgImG" + index} />

                                        </div>
                                        <div key={index + message.author + message + index}
                                          //@ts-ignore
                                          style={styles.timestamp}>
                                          {new Date(message.dateCreated.toISOString())?.toLocaleString()}
                                          <CheckCircleRoundedIcon style={styles.timestampSvg(message.state.author)} />

                                        </div>
                                      </ListItem>)
                                  } else {
                                    return (
                                      <ListItem
                                        key={index + message.author + 1}
                                        //@ts-ignore
                                        style={styles.listItem(message.state.author)}>
                                        <div key={index + message.author + 2 + index}
                                          style={styles.container(message.state.author)}>
                                          {message.body}
                                        </div>
                                        <div key={index + message.author + message + index}
                                          //@ts-ignore
                                          style={styles.timestamp}>
                                          {new Date(message.dateCreated.toISOString())?.toLocaleString()}
                                          <CheckCircleRoundedIcon style={styles.timestampSvg(message.state.author)} />

                                        </div>
                                      </ListItem>)

                                  }
                                }


                              })) : (
                              this.state.loadMessages && this.props.chatUserList?.length > 0 ?
                                <div style={{ textAlign: 'center' }}> <CircularProgress style={{ color: '#0388c9' }} /></div> : <h4 style={{ color: 'gray', textAlign: 'center' }}>You don't have any messages yet!</h4>)

                            }
                          </List>
                          <div id="BottomViewID"></div>
                        </div>
                      </div>
                    </Scrollbar>
                    <Grid item style={styles.gridItemMessage}>

                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item
                          //@ts-ignore
                          style={styles.textFieldContainer}>

                          <div className={classes.textArea}>
                            <input
                              accept="image/*"
                              id={"icon-button-file"}
                              type="file"
                              name="profilePic"
                              style={{ display: "none" }}
                              multiple
                              onChange={(event) => this.fileUploadHandler(event)}
                            />
                            <label htmlFor="icon-button-file">
                              <IconButton
                                aria-label="upload picture"
                                component="span" onClick={() => {

                                  this.setState({ imageFile: '', setDeletingImg: [], tempImgUrl: '' })
                                  //@ts-ignore
                                  document.getElementById('icon-button-file').value = ''

                                }}>
                                <AddPhotoAlternateOutlinedIcon style={{ color: "#1f7fb6" }} />
                              </IconButton>
                            </label>
                            <div className={classes.imgUploadWrap}>
                              <div className={classes.imgUploadWrapInner}>
                                {this.state.tempImgUrl !== '' && this.state.tempImgUrl.urlArr.map((element: any, imgIndex: number) => {
                                  return (
                                    <>
                                      <div className={classes.imgUpload} id={this.state.tempImgUrl.fileName[imgIndex] + 'div'}>
                                        <IconButton key={imgIndex} id={this.state.tempImgUrl.fileName[imgIndex]} onClick={() => { this.setImages(this.state.tempImgUrl.fileName[imgIndex]); }}>
                                          <CloseIcon key={imgIndex + element} />
                                        </IconButton>
                                        <img src={element} alt="" key={imgIndex + element + imgIndex} id={this.state.tempImgUrl.fileName[imgIndex] + 'img'} />
                                      </div>
                                    </>)
                                })}
                                <h4 id={this.state?.userEmail + 'chat'} style={{ display: 'none' }} >Typing...</h4>
                              </div>
                              <TextField
                                id="sendOne2OneMessageBtn"
                                className={classes.searchBar}
                                variant="outlined"
                                placeholder="Message"
                                autoFocus
                                onBlur={({ target }) => {
                                  this.setState({ keepFocus: target })

                                }}
                                onChange={(event) => {
                                  this.setState({ text: event.target.value })

                                  if (this.state?.channel !== '' && this.state?.channel !== null && this.state?.channel !== undefined) {
                                    this.state?.channel?.typing()
                                  }

                                }}
                                onKeyDown={handleKeyDown}
                                autoComplete="off"
                              />
                            </div>
                            <IconButton
                              className={classes.sendButtonOuter}
                              onClick={this.sendMessage} >
                              <SendIcon className={classes.sendIcon} />
                            </IconButton>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

// Customizable Area Start
const useStyles = {
  container: {
    padding: "0 20px",
    overflowX: "hidden",
    height: "100%",
    backgroundColor: "#f1f2f6",
    "& *": {
      boxSizing: "border-box",
    },
    "& .MuiGrid-item": {
      maxHeight: "35vh",
    }
  },
  rightContent: {
    flex: "0 0 75%",
    maxWidth: "75%",
    padding: "15px 20px 0",
    maxHeight: "100vh",
    overflow: "hidden",
  },
  rightContentInner: {
    backgroundColor: "#fffffe",
    paddingBottom: "60px",
    boxShadow: "1px 1px 3px rgb(0 0 0 / 20%)",
    boxSizing: "border-box",
    position: "relative",
    borderRadius: "10px",
    height: "100%",
    overflow: "hidden",
    border: '1px solid #dcdcd7'
  },
  chatUserHeader: {
    width: "100%",
    padding: "20px",
    borderBottom: "1px solid #dcdcd7",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
      border: "1px solid #dcdcd7",
      borderRaduis: "50%",
      width: "57px",
      height: "57px",
      marginRight: "15px"
    },
    "& p": {
      color: "#000307",
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      margin: "0",
      lineHeight: "1",
      "& span": {
        paddingTop: "10px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        color: "#505452",
        fontSize: "10px",
        fontWeight: "700",
        fontFamily: "'Roboto', sans-serif",
      }
    }
  },
  chatUser: {
    maxWidth: "400px",
    margin: "20px auto",
    boxSizing: "border-box",
    textAlign: "center",
    "& .MuiAvatar-root": {
      border: "1px solid #dcdcd7",
      margin: "0 auto 20px",
      borderRaduis: "50%",
      width: "76px",
      height: "76px",
    },
    "& p": {
      color: "#000307",
      fontSize: "16px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      margin: "0",
      lineHeight: "1",
      "& span": {
        paddingTop: "10px",
        display: "block",
        width: "100%",
        color: "#505452",
        fontSize: "12px",
        fontWeight: "700",
        fontFamily: "'Roboto', sans-serif",
      }
    }
  },
  textArea: {
    position: "absolute",
    left: "0",
    bottom: "0",
    right: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 10px",
  },
  imgUploadWrap: {
    width: "100%",
    position: "relative",
    marginRight: "10px",
  },
  imgUploadWrapInner: {
    position: "absolute",
    bottom: "100%",
    left: "10px",
    display: "flex",
    flexWrap: "wrap",
  },
  imgUpload: {
    width: "auto",
    height: "100px",
    textAlign: "center",
    background: "#cccccc",
    borderRadius: "5px",
    padding: "10px",
    boxShadow: "1px 1px 3px rgb(0 0 0 / 20%)",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain"
    },
    "& .MuiIconButton-root": {
      position: "absolute",
      right: "-10px",
      top: "-10px",
      backgroundColor: "#000000",
      color: "#ffffff",
      padding: "7px",
      height: "30px",
      width: "30px",
      "& svg": {
        width: "15px"
      }
    }
  },
  searchBar: {
    margin: "10px",
    width: "100%",
    position: "relative",
    "& .MuiInputBase-root": {
      backgroundColor: "#ebecec",
      color: "#6e7370",
      fontSize: "14px",
      borderRadius: "30px",
      margin: "0",
      width: "100%",
      cursor: "pointer",
    },
    "& .MuiInputBase-input": {
      padding: "10px 15px",
      height: "40px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    }
  },
  sidebarCustomScroll: {
    height: "calc(100vh - 300px) !important",
  },
  customScroll: {
    "& .ScrollbarsCustom-Track": {
      width: "4px !important",
      background: "transparent !important"
    }
  },
  sendButtonOuter: {
    backgroundColor: "#1f7fb6",
    width: "40px",
    height: "40px",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#1f7fb6",
    }
  },
  sendIcon: {
    color: "#ffffff"
  },
  pointer: {
    cursor: "pointer"
  }

} as any;

const styles = {
  listItem: (isOwnMessage: any) => ({
    flexDirection: "column",
    alignItems: isOwnMessage === Cookies.get("Login_Email") ? "flex-end" : "flex-start",
    padding: "0",
    paddingTop: "10px",
  }),
  container: (isOwnMessage: any) => ({
    maxWidth: "90%",
    borderRadius: "12px",
    padding: "10px 15px",
    lineHeight: "1.3",
    fontSize: "15px",
    backgroundColor: isOwnMessage === Cookies.get("Login_Email") ? "#1f7fb6" : "#d8dddd",
    color: isOwnMessage === Cookies.get("Login_Email") ? "#ffffff" : "#000105",
    fontFamily: "'Roboto', sans-serif",
    overflowWrap: "break-word" as "break-word",
    flexRedirection: "column-reverse"
  }),
  author: { fontSize: 10, color: "gray" },
  timestamp: {
    fontSize: 8,
    color: "#888b87",
    textAlign: "right",
    paddingTop: 4,
    display: "flex",
    alignItems: "center",
  },
  timestampSvg: (isOwnMessage: any) => ({
    width: "15px",
    height: "15px",
    marginLeft: "5px",
    display: isOwnMessage === Cookies.get("Login_Email") ? "inline-block" : "none",
  }),
  timestampSvgUnread: (isOwnMessage: any) => ({
    width: "15px",
    height: "15px",
    marginLeft: "5px",
    display: isOwnMessage === Cookies.get("Login_Email") ? "inline-block" : "none",
    color: '#0388c9'
  }),
  textField: {
    backgroundColor: "#ebecec",
    color: "#6e7370",
    fontSize: "14px",
    borderRadius: "30px",
    margin: "0",
    width: "100%",
    cursor: "pointer",
  },
  textFieldContainer: {
    left: "0",
    bottom: "0",
    right: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 10px",
    position: "absolute"
  },
  gridItem: { paddingTop: 12, paddingBottom: 12 },
  gridItemChatList: { overflow: "auto" },
  sendButton: { backgroundColor: "#3f51b5" },
  sendIcon: { color: "white" },
  mainGrid: { borderWidth: 1, padding: "0", height: "100%", overflow: "auto" },
  gridItemMessage: { padding: "0 2px" }
};
// Customizable Area End
const mapDispatchToProps = (dispatch: any) => {
  return {
    addClient: (client: any) => {
      dispatch(GET_CLIENT(client))
    },
    addActiveUser: (user: any) => {
      dispatch(GET_ACTIVEUSERS(user))
    },
    deleteActiveUser: () => {
      dispatch(DELETE_ACTIVEUSERS())
    },
    addUserEmail: (email: any) => {
      dispatch(GET_USERSID(email))
    },
    deleteUserEmail: () => {
      dispatch(DELETE_USERID())
    },
    addLastMsg: (msg: any) => {
      dispatch(GET_LASTMESG(msg))
    },
    addChatUsers: (chatUser: any) => {
      dispatch(GET_CHAT_USERLIST(chatUser))
    },
    deleteLastMsg: () => {
      dispatch(DELETE_LASTMESG())
    },
    deleteChatUsers: () => {
      dispatch(DELETE_CHAT_USERLIST())
    },
    addInvitedChannels: (channel: any) => {
      dispatch(GET_INVITED_CHANNELS(channel))
    },
    addNewUser: (msg: any) => {
      dispatch(UPDATED_USR_LAST_MSG(msg))
    },
    updateLastMsg: (data: any, func?: any) => {
      dispatch(UPDATE_LAST_MSG(data, func))
    },
    removeBadge: (receipent: any) => {
      dispatch(REMOVE_BADGE(receipent))
    }
  }
}

const mapStateToProps = (state: any) => {
  //replace Reducer name with state.'Your Reducer name' and .property

  return {
    list: state.takeAction.clientData[0],
    activeUser: state.takeAction.userData,
    usersMails: state.takeAction.userEmails,
    UserInfo: state.takeAction.UserInfo,
    chatUserList: state.takeAction.chatUserList,
    lastMegs: state.takeAction.lastMegs[0]
  };
};
//@ts-ignore
const ChatOneToOne = withRouter(connect(mapStateToProps, mapDispatchToProps)(chatOneToOne))
export default withStyles(useStyles)(ChatOneToOne)
