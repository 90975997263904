import React from "react";

// Customizable Area Start
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Image from "material-ui-image";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from "@material-ui/core/InputAdornment";
import FavoriteIcon from '@material-ui/icons/Favorite';
import SearchIcon from '@material-ui/icons/Search';
import "date-fns";
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CakeIcon from '@material-ui/icons/CakeOutlined';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ChatIcon from '@material-ui/icons/Chat';
import Switch from '@material-ui/core/Switch';
import {
  Menu,
  MenuList,
  MenuButton,
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import NavbarWeb from "../../TargetedFeed/src/Navbar.web";
import Sidebar from '../../TargetedFeed/src/Sidebar.web'
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddFriendsProfileController, { Props } from "./AddFriendsProfileController.web";
import Backdrop from '@material-ui/core/Backdrop';
import InputLabel from '@material-ui/core/InputLabel';
import { Send } from "@material-ui/icons";
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ReportIcon from '@material-ui/icons/Report';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import Cookies from "js-cookie";
import ConfirmDeleteDialog from '../../TargetedFeed/src/ConfirmDeleteDialog.web'
import { connect } from "react-redux";
//@ts-ignore
import {UPDATE_CHAT_LIST,UPDATE_CHAT_LAST_MEG_LIST, USER_LOGOUT} from "../../../web/src/allActions.js";
// Customizable Area End

class AddFriendsProfile extends AddFriendsProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    //@ts-ignore
    const { classes } = this.props
    const openAnchor = Boolean(this.state.anchorEl);
    function TabPanel(props: any) {
      const { children, value, index, ...other } = props;

      return (
        <div {...other}>{value === index && <Box p={3}>{children}</Box>}
        </div>
      );
    }

    const {
      reportDialog,
      confirmReportDialog,
      reportDialogMessage,
      reportReasons,
      reportSubReasons,
      postReportData,
      userNFTs
    } = this.state

    const postRedirect = (postId: number) => {
      this.props.history.push({
        pathname: '/post/' + postId,
        state: { detail: postId }
      })
    }

    const handleConfirmReport = (reason: string, subReasons?: any, reportReasonId = 0) => {
      if (subReasons?.length > 0) {
        this.setState({
          ...this.state,
          reportSubReasons: subReasons,
        })
      } else {
        this.setState({
          ...this.state,
          confirmReportDialog: true,
          reportDialog: false,
          reportDialogMessage: reason,
          postReportData: {
            ...postReportData,
            report_reason_id: reportReasonId
          }
        })
      }
    }
    return (
      <>
        <NavbarWeb
          //@ts-ignore
          showNotification={'showNotification'} />
        <div className={classes.container}>
          <div style={{ display: 'flex', justifyContent: "space-between", }}>
            <Sidebar
              //@ts-ignore
              FromHome={"FromHome"} />

            {/* Right */}
            {this.state.showBackDrop ? (<Backdrop
              //@ts-ignore
              style={{ zIndex: '0', left: '400px', top: '90px', backgroundColor: '#f1f2f6' }}
              open={true}
            >
              <CircularProgress style={{ color: '#0388c9' }} />
            </Backdrop>) : (
              <div className={classes.mainContent}>
                <div className={classes.rightContent}>
                  {/* Upper */}
                  <div className={classes.upperBoxBg}>
                    {/* ImageContent          */}
                    <div style={{ display: 'flex', alignItems: "center", padding: "0 0 20px 0", borderBottom: "1px solid #d8dddd", marginBottom: "10px" }}>
                      <div style={{ width: "138px", position: "relative", justifyContent: 'left', display: "left" }}>
                        <div style={{ borderRadius: '50%', overflow: 'hidden' }}>
                          <Image id="profilePicture" src={this.state.profileImage ? this.state.profileImage : "https://www.studyheights.com/assets/img/Universitylogo/default.png"} />
                        </div>
                      </div>
                      <div className={classes.heading}>
                        <h2>{this.state.fullName}</h2>
                      </div>
                    </div>
                    {/* Tab Div */}
                    <div className={classes.tabsWrap}>
                      <div className={classes.leftTab}>
                        <Tabs value={this.state.value} onChange={(event: any, newValue: number) => {
                          this.setState({
                            value: newValue,
                          });
                        }} aria-label="simple tabs example">
                          <Tab label="Home" />
                          <Tab label="Friends" />
                        </Tabs>
                      </div>
                      <div className={classes.rightTab}>
                        {this.state.requestSendLoader ?
                          <Button className={classes.btnAddFriend}>
                            <CircularProgress size={25} style={{ color: "#0388c9" }} />
                          </Button> :
                          !this.state.isBlocked && (
                            !this.state.addFriendBtn ?
                              (<Button onClick={this.sendRequest} className={classes.btnAddFriend} >
                                Add friend
                              </Button>) :
                              this.state.addFriendBtn === 'pending' ?
                                (this.state.accountId === this.state.requestCreatedBy ?
                                <Button className={classes.btnAddFriend} onClick={this.cancelFriendRequest} >
                                  Cancel friend request
                                </Button>
                                :
                                <Button className={classes.btnAddFriend} onClick={this.acceptRequest} >
                                  Accept friend request
                                </Button>) :
                                (<Button className={classes.btnAddFriend} disabled >
                                  Friend
                                </Button>)
                          )}
                        <Button className={classes.btnChat}
                          onClick={() => { //@ts-ignore
                            this.props.history.push({
                              pathname: '/chat',
                              state: {
                                receiveId: this.state.receipentId,
                                accountId: this.state.accountId
                              }
                            })
                          }}
                        >
                          <ChatIcon /> Chat
                        </Button>
                        <Menu>
                          <MenuButton className={classes.btnMoreInfo}>
                            <MoreHorizIcon />
                          </MenuButton>
                          {this.state.isBlocked === false ? (
                              <MenuList className={classes.menuListData} >
                                <span className={classes.triangle}></span>
                                {this.state.addFriendBtn === 'approved' && (<>
                                  <Button> <h4 ><img src="https://img.icons8.com/ios/50/000000/dislike.png" /> Unfollow </h4>
                                    <Switch checked={this.state.followStatus} onChange={(e: any) => this.unfollowRequest(e.target.checked)} /></Button>
                                  <Button onClick={() =>
                                    this.setState({ openDialoug: true })
                                  }><h4 ><img src="https://img.icons8.com/ios/50/000000/delete-user-male.png" />Unfriend</h4></Button></>)}

                                <Button onClick={() =>
                                  this.setState({ openBlockDialogue: true })
                                }><h4 ><img src="https://img.icons8.com/ios/50/000000/unfriend-male.png" />Block</h4></Button>
                              </MenuList>
                          ) : (
                            <MenuList className={classes.menuListData} >
                              <span className={classes.triangle}></span>
                              <Button onClick={() => this.unblockUser(
                                this.state.receipentId)}> <h4><img src="https://img.icons8.com/ios/50/000000/ok--v4.png" />Unblock</h4></Button>
                            </MenuList>
                          )}

                        </Menu>

                      </div>
                      <Dialog
                        open={this.state.openDialoug}
                        onClose={() => this.setState({ openDialoug: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className={classes.unfriendModal}
                      >
                        <DialogTitle id="alert-dialog-title" >
                          <IconButton className={classes.closeBtn} onClick={() => this.setState({ openDialoug: false })}>
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent className={classes.removeFriend}>
                          <DialogContentText id="alert-dialog-description" >
                            <AnnouncementIcon />
                            <span>Are you sure want to remove <b>{this.state.fullName}</b> from your friends list?</span>
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions >
                          <Button className={classes.agreebtn} onClick={() => this.setState({ openDialoug: false })}>Cancel</Button>
                          <Button className={classes.disagreebtn} onClick={() => this.unfriendFunc("")} autoFocus>
                            Confirm
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Dialog
                        open={this.state.openBlockDialogue}
                        onClose={() => this.setState({ openBlockDialogue: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className={classes.blockModal}
                      >
                        <DialogTitle id="alert-dialog-title" >
                          <span>Are you sure to block <b>{this.state.fullName}</b> ? </span>  <IconButton className={classes.closeBtn} onClick={() => this.setState({ openBlockDialogue: false })}>
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent className={classes.removeFriend} >
                          <span>
                            {this.state.fullName} will no longer able to: </span>
                          <ul>
                            <li>See your posts in your bio</li>
                            <li>See you messages</li>
                            <li>Add you to their friends list</li>
                          </ul>
                          <p>If you are friends and block <b>{this.state.fullName}</b> will also remove him/her from your friends list.</p>
                        </DialogContent>
                        <DialogActions >
                          <Button className={classes.agreebtn} onClick={() => this.setState({ openBlockDialogue: false })}>Cancel</Button>
                          <Button className={classes.disagreebtn} onClick={() => this.blockfriendFunc(this.state.receipentId)}
                            autoFocus>
                            Confirm
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </div>
                  <TabPanel value={this.state.value} index={0}>
                    <div className={classes.tabContentMain}>
                      <div className={classes.tabContentLeft}>
                        <Card>
                          <CardContent className={classes.infoCard}>
                            <h2>Bio<hr style={{ margin: '0' }} /></h2>
                            {!!this.state.responseCountry?.trim() || !!this.state.responseInformation || !!this.state.responseBirthDate ?
                              <div className={classes.infoText}>
                                {!!this.state.responseCountry?.trim() && <div className={classes.location}>
                                  <LocationOnOutlinedIcon />
                                  <span>{this.state.responseCountry}</span>
                                </div>}
                                {!!this.state.responseInformation && <div className={classes.commomInfo}>
                                  <InfoOutlinedIcon /><span>{this.state.responseInformation}</span>
                                </div>}
                                {!!this.state.responseBirthDate && <div className={classes.info}>
                                  <CakeIcon />
                                  <span>{this.state.responseBirthDate}</span>
                                </div>}
                              </div>
                              :
                              <h5 style={{ fontFamily: "'Poppins', sans-serif", margin: '0' }}>
                                No bio available
                              </h5>}
                          </CardContent>
                        </Card>
                        <Card
                        //@ts-ignore
                        >
                          <CardContent className={classes.NFTCard}>
                            <div>
                              <h2>NFT collection<hr style={{ margin: '0' }} /></h2>
                              <div style={{ display: 'flex', flexWrap: "wrap" }}>
                                {userNFTs?.length > 0 ? (
                                  userNFTs?.map((nft: any) => {
                                    const { attributes } = nft
                                    return (
                                      <Card style={useStyles.colThree} key={attributes?.id} >
                                        <CardContent>
                                          <Typography variant="body2" >
                                            <h3 style={useStyles.nftText}>{attributes.name}</h3>
                                            <img
                                              className={`${classes.nftImage} ${!!attributes.post && classes.pointer}`}
                                              onClick={() => this.nftToPostRedirect(attributes.post)}
                                              src={attributes?.image_url} alt="NFT-image" />
                                            <h3 style={useStyles.paidText} className={classes.paidWrapper}>
                                              <img src="https://img.icons8.com/ios/24/000000/price-tag--v1.png" />
                                              {`Paid $ ${attributes?.usd_amount || 0}(${attributes?.eth_amount || 0} ETH)`}
                                            </h3>
                                          </Typography>
                                        </CardContent>
                                      </Card>
                                    )
                                  })
                                ) : (
                                  <h5 style={{ fontFamily: "'Poppins', sans-serif", margin: '0' }}>
                                    Nothing to see here, yet
                                  </h5>
                                )}
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                      <div className={classes.tabContentRight}>
                        {this.state.postLoader && this.state.myAllPost?.length === 0 ? <div className={classes.postLoader}><CircularProgress style={{ color: "#0388c9" }} /></div> :
                          this.state.myAllPost?.length > 0 ? (this.state.myAllPost?.map((element: any, index) => {
                            const { attributes: { animation_url, image_url, is_current_owner } } = element
                            return (
                              <Card key={index} className={classes.postCard}>
                                <CardHeader
                                  avatar={
                                    <Avatar src={this.state.profileImage ? this.state.profileImage : "https://www.studyheights.com/assets/img/Universitylogo/default.png"} aria-label="recipe">
                                      R
                                    </Avatar>

                                  }
                                  action={
                                    <Menu className={classes.pointer}>
                                      <MenuButton>
                                        <MoreHorizIcon />
                                      </MenuButton>
                                      <MenuList className={classes.menuListData} style={{ width: "200px" }}>
                                        <span className={classes.triangle}></span>
                                        <Button onClick={() => this.setState({ ...this.state, reportDialog: true, postReportData: { ...postReportData, post_id: element.attributes?.id } })}><h4><ReportIcon />Report Post</h4></Button>
                                      </MenuList>
                                    </Menu>
                                  }
                                  title={element.attributes.account.full_name}
                                  subheader={element.attributes.time}
                                />
                                <CardContent>
                                  <div>
                                    <p className={classes.pointer} onClick={() => postRedirect(element?.attributes?.id)}>
                                      {element.attributes.description}
                                    </p>
                                    {element.attributes.nft_name && <span className={classes.pointer} onClick={() => postRedirect(element?.attributes?.id)}>{element.attributes.nft_name}</span>}
                                    {animation_url ? <>
                                      {this.postTypeCheck(animation_url) === "audio" &&
                                        <audio
                                          className={classes.pointer}
                                          onClick={() => postRedirect(element?.attributes?.id)}
                                          style={{ width: '100%', objectFit: 'inherit' }} controls>
                                          <source src={animation_url} type="audio/mp3" />
                                          <source src={animation_url} type="audio/wav" />
                                          Your browser does not support the audio element.
                                        </audio>}
                                      {this.postTypeCheck(animation_url) === "video" &&
                                        <video
                                          className={classes.pointer}
                                          onClick={() => postRedirect(element?.attributes?.id)}
                                          style={{ width: '100%', height: '530px', objectFit: 'inherit' }} controls>
                                          <source src={animation_url} type="video/mp4" />
                                          <source src={animation_url} type="video/ogg" />
                                          <source src={animation_url} type="video/webm" />
                                          Your browser does not support the video element.
                                        </video>}
                                      {this.postTypeCheck(animation_url) === "iframe" &&
                                        <iframe
                                          className={classes.pointer}
                                          onClick={() => postRedirect(element?.attributes?.id)} style={{ width: '100%', height: '530px', objectFit: 'inherit' }} src={animation_url} />}
                                      {this.postTypeCheck(animation_url) === "3dObject" &&
                                        <img
                                          className={classes.pointer}
                                          onClick={() => postRedirect(element?.attributes?.id)}
                                          style={{ width: '100%', height: '530px', objectFit: 'inherit' }}
                                          src={image_url} />}
                                    </> :
                                      image_url &&
                                      <img
                                        className={classes.pointer}
                                        onClick={() => postRedirect(element?.attributes?.id)}
                                        style={{ width: '100%', height: '530px', objectFit: 'inherit' }}
                                        src={image_url} />}
                                    <div className={classes.postCardInfo}>
                                      <div>
                                        <FavoriteOutlinedIcon />
                                        <h3>
                                          {//@ts-ignore
                                            element.attributes.likes_count}
                                        </h3>
                                      </div>
                                      <p>
                                        {//@ts-ignore
                                          element.attributes.comments_count} comments
                                      </p>
                                      {image_url && <h3 className={`${classes.paidCardText} ${!is_current_owner && classes.soldNFT}`}><img src="https://img.icons8.com/ios/24/000000/price-tag--v1.png" />
                                        {`Paid $ ${element?.attributes?.usd_amount || 0}(${element?.attributes?.eth_amount || 0} ETH)`}
                                      </h3>}
                                    </div>
                                  </div>
                                </CardContent>
                                <div className={classes.likeCommentShare}>
                                  <IconButton className={classes.txtHover} aria-label="add to favorites" onClick={() => this.likePost(//@ts-ignore
                                    element.attributes.id, element.attributes.is_liked)}>
                                    <FavoriteBorderOutlinedIcon style={
                                      //@ts-ignore
                                      element.attributes.is_liked ?
                                        { color: '#0388c9' } : { color: 'grey' }} id="likePost" />
                                    <p> {
                                      element.attributes.is_liked ?
                                        'Unlike' : 'Like'}</p>
                                  </IconButton>
                                  <IconButton className={classes.txtHover} aria-label="comment" onClick={() => {
                                    if (this.state.commentBoxId !== index) {
                                      this.setState({ commentBoxId: index, allComments: [], commentCount: 1 })
                                      this.getCommentData(element.attributes.id)
                                    } else {
                                      this.setState({ commentBoxId: '' })
                                    }
                                  }}>
                                    <ModeCommentOutlinedIcon /><p>Comment</p>
                                  </IconButton>
                                </div>
                                {this.state.commentBoxId === index ?
                                  (
                                    <div className={classes.commentBlockWrapper}>
                                      <FormControl className={classes.selectComment}>
                                        <InputLabel></InputLabel>
                                        <Select
                                          labelId="demo-simple-select-autowidth-label"
                                          id="demo-simple-select-autowidth"
                                          autoWidth
                                          defaultValue={10}
                                        >
                                          <MenuItem disabled value={30}>
                                            <em>Comment filter</em>
                                          </MenuItem>
                                          <MenuItem selected value={10} onClick={() => { this.getCommentData(element.attributes.id, 'most_relevant') }}>Most relevant comments</MenuItem>
                                          <MenuItem value={21} onClick={() => { this.getCommentData(element.attributes.id, 'oldest') }}>Old comments</MenuItem>
                                          <MenuItem value={22} onClick={() => { this.getCommentData(element.attributes.id, 'newest') }}>New comments</MenuItem>
                                        </Select>
                                      </FormControl>
                                      <div className={classes.commentBlock}>
                                        <Avatar aria-label="recipe" src={this.props.profileData.profileImage || "https://www.studyheights.com/assets/img/Universitylogo/default.png"}>
                                        </Avatar>
                                        <TextField
                                          className={classes.commentBar}
                                          placeholder="Write a comment"
                                          id={"comentBox" + index}
                                        />
                                        <IconButton
                                          style={useStyles.sendButton} onClick={() => this.createComment(element.attributes.id, index)}>
                                          <Send style={useStyles.sendIcon} />
                                        </IconButton>
                                      </div>
                                      {this.state.allComments.length !== 0 && (
                                        this.state.allComments.map((comment: any, commentIndex: any) => {
                                          if (commentIndex < this.state.commentCount) {

                                            return (
                                              <div className={classes.commentBlock} key={commentIndex}>
                                                <Avatar aria-label="recipe" src={comment.attributes.account.image}>
                                                </Avatar>
                                                <div className={classes.commentWrap}>
                                                  <p>
                                                    <span>{comment.attributes.account.full_name}</span>
                                                    {this.state.editCommentId === comment.attributes.id ?
                                                      <TextField
                                                        id="editCommentBox"
                                                        fullWidth
                                                        variant="standard"
                                                        autoComplete="off"
                                                        placeholder="Write a comment"
                                                        autoFocus={this.state.editCommentId === comment.attributes.id}
                                                        defaultValue={comment.attributes.comment}
                                                        onClick={(e) => e.stopPropagation()}
                                                      />
                                                      : comment.attributes.deleted ? 'This comment was deleted' : comment.attributes.comment}
                                                  </p>
                                                  <ul className={classes.listComment}>
                                                    <li>
                                                      <Button onClick={() => this.likeOrDislikeComment(comment.attributes.id, comment.attributes.is_liked)}>
                                                        {comment.attributes.is_liked ? (<div style={{ color: '#1f7fb6' }}>Dislike</div>) : 'Like'}
                                                      </Button>
                                                    </li>
                                                    <li>
                                                      <Button onClick={() => {
                                                        if (this.state.showReplyInbox !== comment.attributes.id) {
                                                          this.setState({ showReplyInbox: comment.attributes.id, allRepliedComments: [] })

                                                          this.getRepliedCommentData(comment.attributes.id)
                                                        }
                                                      }}>
                                                        Reply
                                                      </Button>
                                                    </li>
                                                    <li style={{ color: '#1f7fb6' }}><FavoriteIcon style={{ fontSize: 'larger' }} /> ({comment.attributes.likes_count})&nbsp;</li>
                                                    <li>{comment.attributes.time}</li>
                                                    {Cookies.get('Login_Id') == comment.attributes.account_id && !comment.attributes.deleted && (
                                                      <>
                                                        <li>
                                                          {this.state.editCommentId === comment.attributes.id ?
                                                            <Button onClick={() => this.editComment(element.attributes.id)}>
                                                              Save
                                                            </Button>
                                                            : <Button onClick={() => this.setState({ editCommentId: comment.attributes.id })}>
                                                              Edit
                                                            </Button>}
                                                        </li>
                                                        <li>
                                                          <Button onClick={() => this.handleDeleteCommentToogle(comment.attributes.id)}>
                                                            Delete
                                                          </Button>
                                                        </li></>)}
                                                  </ul>
                                                  {this.state.showReplyInbox === comment.attributes.id ? (
                                                    <div className={classes.replyCommentBlock}>

                                                      <div className={classes.commentBlock}>
                                                        {this.state.allRepliedComments.length !== 0 && (
                                                          this.state.allRepliedComments.map((replyComment: any, replyIndex: any) => {
                                                            return (
                                                              <>
                                                                <Avatar aria-label="recipe" key={replyIndex + replyComment} src={replyComment.attributes.account.image}>
                                                                </Avatar>
                                                                <div className={classes.commentWrap} key={replyIndex + replyComment + replyIndex}>
                                                                  <p key={replyIndex + replyComment + replyComment}>
                                                                    <span key={replyIndex + replyComment + replyIndex + replyIndex}>{replyComment.attributes.account.full_name}</span>
                                                                    {replyComment.attributes.comment}
                                                                  </p>
                                                                  <ul className={classes.listComment} key={replyIndex + replyComment + replyIndex + replyComment}>
                                                                    <li key={replyComment}>
                                                                      <Button key={replyIndex + replyComment + replyComment + replyIndex} onClick={() => this.likeOrDislikeComment(replyComment.attributes.id, replyComment.attributes.is_liked)}>
                                                                        {replyComment.attributes.is_liked ? (<div style={{ color: '#1f7fb6' }}>Dislike</div>) : 'Like'}
                                                                      </Button>
                                                                    </li>
                                                                    <li key={replyIndex + replyComment + index}>
                                                                      <Button key={replyIndex + replyComment + index + replyIndex}>
                                                                        Reply
                                                                      </Button>
                                                                    </li>
                                                                    <li style={{ color: '#1f7fb6' }} key={replyIndex + replyComment + index + replyComment + index + index}>{replyComment.attributes.likes_count} &nbsp;<FavoriteIcon style={{ fontSize: 'larger' }} /></li>
                                                                    <li key={replyIndex + replyComment + index + replyComment}>{replyComment.attributes.time}</li>
                                                                  </ul>
                                                                </div></>
                                                            )
                                                          })
                                                        )}
                                                        <Avatar aria-label="recipe" src={this.state.profileImage ? this.state.profileImage : "https://www.studyheights.com/assets/img/Universitylogo/default.png"}>
                                                        </Avatar>
                                                        <TextField
                                                          className={classes.commentBar}
                                                          placeholder="Write a comment"
                                                          id={"comentReplyBox" + index + commentIndex}
                                                        />
                                                        <IconButton
                                                          style={useStyles.sendButton} onClick={() => this.createReplyComment(element.attributes.id, index, commentIndex, comment.attributes.id)}>
                                                          <Send style={useStyles.sendIcon} />
                                                        </IconButton>
                                                      </div>
                                                    </div>
                                                  ) : ('')}
                                                </div>
                                              </div>
                                            )
                                          }
                                        })
                                      )}
                                      {this.state.allComments.length !== 0 && (
                                        <div className={classes.paginationBlock}>
                                          <Button onClick={() => {
                                            if (this.state.commentCount !== this.state.allComments.length) {
                                              this.setState({ commentCount: this.state.allComments.length })
                                            } else {
                                              this.setState({ commentCount: 1 })
                                            }
                                          }}>{this.state.commentCount !== this.state.allComments.length ? "See more comments" : "See less comments"}</Button>
                                          <div className={classes.paginationBlockWrapper}>
                                            <span>{this.state.commentCount}</span> of <span>{this.state.allComments.length}</span>
                                          </div>
                                        </div>)
                                      }
                                    </div>
                                  ) : ('')
                                }
                              </Card>
                            )
                          })) : (<div className={classes.noPost}><h4>Nothing to see here, yet</h4></div>)}
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={this.state.value} index={1}>
                    <Card className={classes.friendCard}>
                      <CardContent>
                        <div>
                          <div className={classes.friendCardTitle}>
                            <h2>Friends</h2>
                            <TextField
                              variant="outlined"
                              InputProps={{
                                startAdornment: (
                                  //@ts-ignore
                                  <InputAdornment>
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              id='searchallFriends'
                              placeholder="Search friends"
                              onChange={(e: any) => {

                                this.handleChangeDebounceAddFriend(e.target.value)
                              }}

                            />
                          </div>
                          <div className={classes.accordionUserWrapper}>
                            {this.state.getFriendOfFriend?.length !== 0 ? (
                              this.state.getFriendOfFriend.map((element: any, index) => {
                                if (index <= this.state.frdCount) {
                                  return (
                                    <div className={classes.accordionUserItem} key={index + index}>
                                      <div className={classes.accordionUser}>
                                        <div className={classes.userInfo}>
                                          <Avatar src={element.attributes.image ? element.attributes.image : "https://www.studyheights.com/assets/img/Universitylogo/default.png"} />
                                          <Button style={{ textTransform: 'initial' }} onClick={() => {
                                            if (element.attributes.receipient_name) {
                                              this.RedirectToUser(element.attributes.receipient_name, true)
                                            } else if (element.attributes.receipient_id) {
                                              this.RedirectToUser(element.attributes.receipient_id, false)
                                            }
                                          }}>
                                            <Typography>{element.attributes.first_name + ' ' + element.attributes.last_name}
                                              <span>{`${element.attributes.common_friends} friend(s) in common`}</span>
                                            </Typography>
                                          </Button>
                                        </div>
                                        <Menu>
                                        </Menu>
                                      </div>
                                    </div>
                                  )
                                }
                              })
                            ) : (<h4 style={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", }}>No result found.</h4>)}
                          </div>

                          {this.state.getFriendOfFriend?.length > 10 && (this.state.frdCount === 9 ? (<Button
                            type="submit"
                            className={classes.btnViewNFT} style={useStyles.buttonUpload}
                            onClick={() => this.setState({ frdCount: this.state.getFriendOfFriend.length })}>
                            View all</Button>) : (<Button
                              type="submit"
                              className={classes.btnViewNFT} style={useStyles.buttonUpload}
                              onClick={() => this.setState({ frdCount: 9 })}>
                              View less</Button>))}
                        </div>
                      </CardContent>
                    </Card>
                  </TabPanel>
                </div>
              </div>)}
          </div>
        </div>
        <ConfirmDeleteDialog
          //@ts-ignore
          title={"comment"}
          isOpen={this.state.deleteCommentDialog}
          handleCancelDelete={this.handleDeleteCommentToogle}
          handleConfirmDelete={this.deleteComment}
        />
        <Dialog
          open={reportDialog}
          onClose={() => this.setState({
            ...this.state,
            reportDialog: false,
            reportSubReasons: [],
            postReportData: {
              post_id: 0,
              report_reason_id: 0
            }
          })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.deactivateDialog}
        >
          <DialogTitle className={classes.titleDialog} id="alert-dialog-title">
            <span className={classes.subreportTitle}>
              {reportSubReasons?.length > 0 &&
                <IconButton onClick={() => this.setState({
                  ...this.state,
                  reportSubReasons: [],
                  postReportData: {
                    post_id: 0,
                    report_reason_id: 0
                  }
                })}>
                  <ArrowBackRoundedIcon />
                </IconButton>}
              Report
            </span>
            <IconButton onClick={() => this.setState({
              ...this.state,
              reportDialog: false,
              reportSubReasons: [],
              postReportData: {
                post_id: 0,
                report_reason_id: 0
              }
            })}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.txtDialog}>
            <h2>Report post</h2>
            <p>
              Help us understand what is wrong with this post. How would you describe it?
            </p>
            <div className={classes.btnList}>
              {reportSubReasons?.length > 0 ? reportSubReasons?.map((subReason: any) => {
                const { data: { attributes } } = subReason
                return (
                  <Button key={attributes?.id} onClick={() => handleConfirmReport(attributes?.content, attributes?.sub_reasons, attributes?.id)}>
                    {attributes?.content}
                    <IconButton>
                      <ChevronRightIcon />
                    </IconButton>
                  </Button>
                )
              }) :
                reportReasons?.map((reason: any) => {
                  const { attributes } = reason
                  return (
                    <Button key={attributes.id} onClick={() => handleConfirmReport(attributes.content, attributes?.sub_reasons, attributes?.id)}>
                      {attributes.content}
                      <IconButton>
                        <ChevronRightIcon />
                      </IconButton>
                    </Button>
                  )
                })
              }
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={confirmReportDialog}
          onClose={() => this.setState({
            ...this.state, confirmReportDialog: false, reportDialog: true, postReportData: {
              post_id: 0,
              report_reason_id: 0
            }
          })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.deactivateDialog}
        >
          <DialogTitle className={classes.titleDialog} id="alert-dialog-title">
            Please confirm your submission
            <IconButton onClick={() => this.setState({
              ...this.state, confirmReportDialog: false, reportDialog: true, postReportData: {
                post_id: 0,
                report_reason_id: 0
              }
            })}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ textAlign: "center", paddingBottom: "20px" }}>
            <Button className={classes.btnAddFriend}><ReportIcon />{reportDialogMessage}</Button>
          </DialogContent>
          <DialogActions>
            <Button className={classes.btnChat} onClick={() => this.handlePostReport()}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
// Customizable Area Start
const useStyles = {
  txtHover: {
    "&:hover": {
      background: "transparent",
      color: "#1f7fb6"
    }
  },
  closeBtn: {
    position: "absolute",
    top: "15px",
    background: " #e5e6eb",
    right: "15px",
    padding: "5px",
  },
  agreebtn: {
    border: "1px solid #1f7fb6",
    backgroundColor: "#e4e5e4",
    height: "40px",
    borderRadius: "50px",
    boxShadow: "none !important",
    display: "inline-flex",
    alignItems: "center",
    minWidth: "110px",
    "& .MuiButton-label": {
      fontSize: "16px !important",
      fontWeight: "700 !important",
      color: "#1f7fb6 !important",
      margin: "0 !important",
      textTransform: "none",
    },
    "& svg": {
      marginLeft: "5px",
      height: "23px"
    }
  },
  disagreebtn: {
    border: "1px solid #1f7fb6",
    backgroundColor: "#1f7fb6 !important",
    height: "40px",
    borderRadius: "50px",
    boxShadow: "none !important",
    display: "inline-flex",
    alignItems: "center",
    minWidth: "110px",
    "& .MuiButton-label": {
      fontSize: "16px !important",
      fontWeight: "700 !important",
      color: "#ffffff !important",
      margin: "0 !important",
      textTransform: "none",
    },
    "& svg": {
      marginLeft: "5px",
      height: "23px"
    }
  },
  unfriendModal: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "350px",
    },
    "& .MuiDialogActions-root": {
      justifyContent: "space-between",
      padding: "15px"
    }
  },
  blockModal: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "550px",
    },
    "& .MuiDialogActions-root": {
      justifyContent: "space-between",
      padding: "15px"
    },
    "& p": {
      textAlign: "left !important"
    },
    "& .MuiDialogTitle-root": {
      paddingRight: "60px"
    },
  },
  removeFriend: {
    "& span": {
      fontSize: "16px",
      color: "#000000",
      fontFamily: "'Poppins', sans-serif",
    },
    "& ul": {
      fontSize: "16px",
      color: "#000000",
      fontFamily: "'Poppins', sans-serif",
      margin: "0",
      paddingLeft: "20px",
      paddingBottom: "20px"
    },
    "& p": {
      fontFamily: "'Poppins', sans-serif",
      margin: "0",
      textAlign: "center",
      "& svg": {
        fontSize: "40px",
        color: "#1f7fb6",
        display: "block",
        margin: "0 auto",
      }
    },
  },
  container: {
    padding: "0 20px",
    overflowX: "hidden",
    height: "100%",
    backgroundColor: "#f1f2f6",
  },
  "& .MuiIconButton-root": {
    padding: "8px"
  },
  colThree: {
    flex: "0 0 50%",
    maxWidth: "50%",
    padding: "0 2px",
    boxSizing: "border-box",
    margin: '8px 0'
  },
  paidWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "& img": {
      maxWidth: "12px",
      marginRight: "5px"
    }
  },
  nftText: {
    fontSize: "12px",
    color: "#565a57",
    fontWeight: "700",
    lineHeight: "1.1",
    minHeight: "25px",
    margin: "0"
  },
  paidText: {
    fontSize: "12px",
    color: "#1f7fb6",
    fontWeight: "600",
    margin: "0",
    fontFamily: "'Poppins', sans-serif",
  },
  paidCardText: {
    lineHeight: "1.3",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& img": {
      width: "18px",
      marginRight: "10px",
    }
  },
  upperBoxBg: {
    backgroundColor: "#fff",
    fontFamily: "'Poppins', sans-serif",
    margin: "0 0 20px 0",
    padding: "10px 15px",
    borderRadius: "0 0 10px 10px",
    boxShadow: "1px 1px 3px rgb(0 0 0 / 20%)",
  },
  heading: {
    margin: "0 0 0 20px",
    "& h2": {
      fontSize: "28px",
      fontWeight: "600",
      padding: "0",
      margin: "0",
      fontFamily: "'Poppins', sans-serif",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      margin: "0",
      color: "#565a57",
      fontFamily: "'Roboto', sans-serif",
    }
  },
  modelPopup: {
    "& fieldset": {
      display: "none",
    },
  },
  sendButton: { backgroundColor: "#1f7fb6", width: "40px", padding: "10px", height: "40px", marginLeft: "10px" },
  sendIcon: { color: "#ffffff", width: "auto", height: "auto", },
  selectComment: {
    width: "100%",
    "& .MuiInputBase-root": {
      maxWidth: "200px",
      width: "100%",
      marginLeft: "auto",
      margin: 0,
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      }
    }
  },
  paginationBlock: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 0 15px",
    alignItems: "center",
    "& button": {
      padding: "0",
      margin: "0",
      color: "#6e7370",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "transparent"
      }
    }
  },
  paginationBlockWrapper: {
    color: "#6e7370",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "'Roboto', sans-serif",
  },
  listComment: {
    margin: "0",
    marginTop: "5px",
    padding: "0",
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    color: "#404341",
    "& li": {
      fontSize: "11px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      padding: "0 5px",
      display: "inline-flex",
      alignItems: "center",
    },
    "& button": {
      margin: "0",
      padding: "0",
      textAlign: "left",
      minWidth: "auto",
      "& span": {
        fontSize: "11px",
        fontWeight: "700",
        fontFamily: "'Roboto', sans-serif",
        textTransform: "none",
      },
      "&:hover": {
        backgroundColor: "none"
      }
    }
  },
  commentWrap: {
    maxWidth: "calc(100% - 47px)",
    flex: "0 0 calc(100% - 47px)",
    "& p": {
      backgroundColor: "#ebecec",
      borderRadius: "30px",
      padding: "5px 15px",
      color: "#6e7370",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      width: "100%",
      maxWidth: "100%",
      boxSizing: "border-box",
      margin: "0",
      "& span": {
        paddingBottom: "3px",
      }
    },
    "& span": {
      color: "#000307",
      fontSize: "11px",
      display: "block",
      paddingBottom: "0",
      margin: "0",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
    },
  },
  commentBar: {
    margin: "2px 0 2px 10px",
    position: "relative",
    maxWidth: "calc(100% - 95px)",
    flex: "0 0 calc(100% - 95px)",
    "&::before": {
      position: "absolute",
      content: "",
      height: "10px",
      width: "2px",
      backgroundColor: "#ffffff",
      left: "0px"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ebecec",
      color: "#6e7370",
      borderRadius: "30px",
      height: "38px",
      fontWeight: "500",
      padding: "0 15px",
      cursor: "pointer",
      fontSize: "14px",
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    }
  },
  commentBlockWrapper: {
    borderTop: "1px solid #dcdcd7",
    margin: "0 15px 0",
    paddingTop: "10px",
    "& .MuiSelect-selectMenu": {
      textAlign: "right",
      fontSize: "11px",
      color: "#404341",
      fontWeight: 700,
      "&:focus": {
        background: "transparent",
      },
      "& em": {
        opacity: "0.7"
      }
    },
    "& .MuiSelect-icon": {
      color: "#404341"
    }
  },
  commentBlock: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0 0 15px",
    justifyContent: "space-between",
    "& .MuiAvatar-root": {
      width: "35px",
      height: "35px",
      marginTop: "5px",
    }
  },
  profileImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& div": {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      padding: "0 !important",
      "& img": {
        maxHeight: "50px",
      }
    },
    "& p": {
      fontSize: "16px",
      fontWeight: "600",
      marginLeft: "10px",
    }
  },
  createPost: {
    color: "#fff",
    backgroundColor: "#35a0d4",
    fontSize: "16px",
    fontWeight: "600",
    padding: "10px 20px",
    margin: "0 20px",
    borderRadius: "5px",
  },
  myNftImgWrap: {
    "& div": {
      paddingTop: "0 !important",
    },
  },
  myNftImg: {
    width: "100% !important",
    height: "auto !important",
    position: "relative !important",
  },
  textField: {
    padding: "0",
  },
  tabsWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      color: "#1f7fb6",
      backgroundColor: "#deedf4",
      borderRadius: "5px"
    },
    "& .MuiTabs-scroller": {
      alignSelf: "center",
    }
  },
  mainContent: {
    maxWidth: "75%",
    flex: "0 0 75%"
  },
  formWrap: {
    backgroundColor: "#fff",
    fontFamily: "'Poppins', sans-serif",
    borderRadius: "10px",
    boxShadow: "1px 1px 3px rgb(0 0 0 / 20%)",
    "& h2": {
      margin: "0 0 30px 0",
    },
    "& .MuiTextField-root": {
      width: "100%",
      margin: "10px 0",

    },
    "& .MuiButton-root": {
      backgroundColor: "#e5e6eb",
      border: "2px solid #50a0c6",
      color: "#50a0c6",
      boxShadow: "none",

    }
  },
  leftTab: {
    "& button": {
      border: "0",
      textTransform: "none",
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      minWidth: "initial",
      minHeight: "40px",
      padding: "14px 20px",
      opacity: "1",
      color: "#6e7370",
      lineHeight: "1",
      borderRadius: "5px"
    },
  },
  rightTab: {
    display: "flex",
  },
  rightContent: {
    width: "100%",
    padding: "0 40px",
    boxSizing: "border-box",
    "& .MuiBox-root": {
      padding: "0"
    },
  },
  btnAddFriend: {
    minWidth: "127px",
    backgroundColor: "#d8dddd",
    height: "41px",
    fontSize: "16px",
    color: "#1f7fb6",
    fontWeight: "700",
    letterSpacing: "0",
    border: "1px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    textTransform: "none",
    margin: "0 4px",
  },
  btnChat: {
    margin: "0 4px",
    minWidth: "91px",
    backgroundColor: "#1f7fb6",
    height: "41px",
    fontSize: "16px",
    color: "#ffffff",
    fontWeight: "700",
    letterSpacing: "0",
    border: "1px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1f7fb6",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "5px",
    },
  },
  btnMoreInfo: {
    minWidth: "49px",
    backgroundColor: "#d8dddd",
    height: "41px",
    fontSize: "16px",
    color: "#1f7fb6",
    fontWeight: "700",
    letterSpacing: "0",
    border: "1px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    textTransform: "none",
    margin: "0 0 0 4px",
    cursor: "pointer"
  },
  tabContentMain: {
    display: "flex",
    alignItems: "flex-start",
    boxSizing: "border-box",
    margin: "0 -10px",
  },
  tabContentLeft: {
    flex: "0 0 365px",
    maxWidth: "365px",
    padding: "0 10px",
    boxSizing: "border-box",
    "& .MuiCardContent-root": {
      padding: "20px",
    },
    "& .MuiPaper-rounded": {
      borderRadius: "8px",
      backgroundColor: "#fffffe",
      marginBottom: "15px",
      boxShadow: "none"
    }
  },
  tabContentRight: {
    flex: "0 0 calc(100% - 365px)",
    maxWidth: "calc(100% - 365px)",
    padding: "0 10px",
    boxSizing: "border-box",
    minHeight: '16.5rem',
  },
  postLoader: {
    width: '100%',
    minHeight: '16.5rem',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    display: 'grid',
    placeItems: 'center'
  },
  noPost: {
    display: 'grid',
    placeItems: 'center',
    backgroundColor: '#ffffff',
    minHeight: '16.5rem',
    height: '100%',
    borderRadius: '5px',
    fontFamily: "'Poppins', sans-serif",
  },
  infoCard: {
    "& h2": {
      fontSize: "18px",
      color: "#000105",
      fontFamily: "'Poppins', sans-serif",
      margin: "0",
      paddingBottom: "25px"
    }
  },
  NFTCard: {
    "& h2": {
      fontSize: "18px",
      color: "#000105",
      fontFamily: "'Poppins', sans-serif",
      margin: "0",
      paddingBottom: "25px"
    },
    "& .MuiCardContent-root": {
      padding: "0"
    },
    "& .MuiPaper-rounded": {
      backgroundColor: "#ffffff",
      marginBottom: "0",
      boxShadow: "none"
    }
  },
  infoText: {
    "& div": {
      //position: "relative",
      //padding: "2px 0 25px 30px",
      marginBottom: '10px',
      color: "#565a57",
      fontSize: "14px",
      lineHeight: "1.3",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      "& svg": {
        left: "0",
        top: "0",
        color: "#565a57",
        paddingRight: "9px"
      }
    }
  },
  location: {
    display: 'flex',
    alignItems: 'center',
    color: "#50a0c6 !important ",
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    "& > span": {
      marginTop: '3px'
    }
  },
  commomInfo: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  bod: {
    padding: "2px 0 15px 30px !important ",
  },
  postCard: {
    boxShadow: "none",
    borderRadius: "10px",
    maxWidth: "80%",
    margin: "0 auto 25px",
    "& .MuiCardHeader-root": {
      padding: "10px",
      justifyContent: "space-beetween",
      "& .MuiCardHeader-avatar": {
        marginRight: "15px",
        "& .MuiAvatar-root": {
          width: "45px",
          height: "45px",
        }
      },
      "& .MuiCardHeader-content": {
        "& .MuiCardHeader-title": {
          fontSize: "16px",
          color: "#000307",
          fontWeight: "700",
          lineHeight: "1",
          paddingBottom: "5px",
        },
        "& .MuiCardHeader-subheader": {
          fontSize: "13px",
          color: "#888b87",
          fontWeight: "500",
          lineHeight: "1",
          paddingBottom: "0",
        }
      },
      "& .MuiCardHeader-action": {
        margin: "0",
        "& button": {
          color: "#1f7fb6",
          padding: "7px",
          height: "40px",
          width: "40px",
          backgroundColor: "transparent",
          border: "none"
        }
      }
    },
    "& .MuiCardContent-root": {
      padding: "0",
      "& p": {
        margin: "0 15px 0",
        color: "#6e7370",
        fontSize: "16px",
        fontWeight: "500",
        fontFamily: "'Roboto', sans-serif",
        paddingBottom: "5px",
        lineHeight: "1.4",
      },
      "& span": {
        borderTop: "1px solid #dcdcd7",
        color: "#565a57",
        fontSize: "16px",
        fontWeight: "700",
        display: "block",
        fontFamily: "'Roboto', sans-serif",
        margin: "0 15px 15px",
        paddingTop: "10px",
      }
    }
  },
  likeCommentShare: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 0",
    margin: "0 15px",
    "& button": {
      padding: "0",
      margin: "0",
      color: "#6e7370",
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      height: "20px",
      borderRaduis: "0",
      "& p": {
        margin: "0 0 0 5px",
      }
    }
  },
  postCardInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 0",
    margin: "0 15px",
    borderBottom: "1px solid #dcdcd7",
    "& div": {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#6e7370",
      maxWidth: "33.33%",
      flex: "0 0 33.33%",
      "& h3": {
        margin: "0 0 0 10px",
      },
      "& svg": {
        color: "#1f7fb6"
      }
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#6e7370 !important",
      margin: "0 !important",
      padding: "0 !important",
      border: "none !important"
    },
    "& h3": {
      margin: "0",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#1f7fb6",
      maxWidth: "45%",
      flex: "0 0 45%",
      justifyContent: "flex-end",
    },
  },
  soldNFT: {
    color: "#9a9a9a!important"
  },
  friendCard: {
    width: "650px",
    margin: "0 auto",
    boxShadow: "none",
    borderRadius: "10px"
  },
  buttonUpload: {
    display: "block",
    background: "#d8dddd",
    border: "2px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    color: "#1f7fb6",
    fontWeight: 700,
    width: "100%"
  },
  friendCardTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "2px solid #d8dddd",
    paddingBottom: "15px",
    "& h2": {
      color: "#000105",
      fontSize: "21px",
      fontWeight: "600",
      margin: "0",
      fontFamily: "'Poppins', sans-serif",
    },
    "& .MuiFormControl-root": {
      backgroundColor: "#d8dddd",
      height: "41px",
      fontSize: "16px",
      color: "#1f7fb6",
      fontWeight: "700",
      letterSpacing: "0",
      border: "1px solid #1f7fb6",
      borderRadius: "5px",
      textAlign: "center",
      maxWidth: "170px",
      "& .MuiInputBase-input": {
        "&::placeholder": {
          color: "#1f7fb6",
          fontWeight: "700",
          opacity: "1",
        }
      },
      "& .MuiOutlinedInput-root": {
        paddingLeft: "10px",
        "& .MuiSvgIcon-root": {
          color: "#1f7fb6"
        }
      },
      "& .MuiOutlinedInput-input": {
        padding: "11px 12px 11px 0px"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        top: "0"
      }
    }
  },
  accordionUserWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  accordionUserItem: {
    flex: "0 0 50%",
    maxWidth: "50%",
    padding: "25px 20px",
    boxSizing: "border-box",
  },
  accordionUser: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    boxSizing: "border-box",
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "& .MuiButton-text": {
      width: "100%",
    },
    "& .MuiButton-label": {
      textAlign: "left",
      display: "block",
    },
    "& .MuiAvatar-root": {
      width: "72px",
      height: "72px",
      backgroundColor: "#bcbeba",
      borderRadius: "50%",
      marginRight: "10px",
    },
    "& .MuiTypography-root": {
      textTransform: "none",
      fontSize: "16px",
      color: "#000307",
      fontWeight: "700",
      lineHeight: "1",
      "& span": {
        display: "block",
        fontSize: "13px",
        color: "#505452",
        fontWeight: "500",
        fontFamily: "'Roboto', sans-serif",
        marginTop: "10px",
      }
    },
  },
  buttonMore: {
    width: "49px",
    height: "40px",
    backgroundColor: "#ebecec",
    color: "#1f7fb6",
    border: "1px solid #1f7fb6",
    borderRadius: "5px",
    "@media(max-width: 1199px)": {
      width: "30px",
      minWidth: "30px",
      height: "30px",
      "& .MuiSvgIcon-root":{
        width: "13px",
        height: "13px",
      }
    },
  },
  menuListData: {
    whiteSpace: "normal",
    width: "346px",
    backgroundColor: "#fffffe",
    borderRadius: "10px",
    position: "relative",
    marginTop: "20px",
    "& button": {
      width: "100%",
      padding: "5px 15px",
      "& h4": {
        display: "flex",
        alignItems: "center",
        fontSize: "16px",
        color: "#565a57",
        fontWeight: "700",
        fontFamily: "'Roboto', sans-serif",
        textTransform: "none",
        flexWrap: "wrap",
        margin: "0",
        width: "100%",
        "& img": {
          width: "24px",
          marginRight: "12px",
        }
      },
    },
  },
  triangle: {
    position: "absolute",
    width: "16px",
    height: "16px",
    borderBottom: "1px solid transparent",
    borderRight: "1px solid transparent",
    borderTop: "1px solid rgba(0,0,0,0.25)",
    borderLeft: "1px solid rgba(0,0,0,0.25)",
    transform: "rotate(45deg)",
    backgroundColor: "#fffffe",
    top: "-9px",
    right: "20px",
  },
  deactivateDialog: {
    "& .MuiPaper-root": {
      width: "100%",
    },
    "& *": {
      boxSizing: "border-box"
    },
    "&.MuiDialogContent-root": {
      padding: "8px 34px",
    }
  },
  txtDialog: {
    "& h2": {
      fontSize: "21px",
      color: "#000105",
      fontWeight: "600",
      fontFamily: "'Poppins', sans-serif",
      paddingBottom: "15px",
      margin: "0"
    },
    "& p": {
      fontSize: "15px",
      color: "#6e7370",
      fontWeight: "400",
      fontFamily: "'Roboto', sans-serif",
      margin: "0",
      paddingBottom: "20px",
      lineHeight: "1.3",
      "& span": {
        width: "100%",
        display: "block"
      },
    }
  },
  titleDialog: {
    "& h2": {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "21px",
      color: "#000105",
      fontWeight: "600",
      fontFamily: "'Poppins', sans-serif",
      padding: "0 0 10px",
      width: "100%",
      borderBottom: "2px solid #d8dddd",
      boxSizing: "border-box",
    },
    "& .MuiIconButton-root": {
      padding: "0",
      width: "35px",
      height: "35px",
    },
  },
  subreportTitle: {
    width: '120px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnList: {
    "& .MuiButton-text": {
      width: "100%",
      padding: "10px 6px",
      "& .MuiButton-label": {
        textAlign: "left",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "16px",
        color: "#000105",
        fontWeight: "600",
        fontFamily: "'Roboto', sans-serif",
        textTransform: "none",
        "& .MuiIconButton-root": {
          padding: "0",
          color: "#000105"
        }
      }
    },
  },
  nftImage: {
    width: '150px',
    height: '150px',
    objectFit: 'inherit',
    borderRadius: '5px'
  },
  pointer: {
    cursor: "pointer"
  }
} as any;
// Customizable Area End


const mapDispatchToProps = (dispatch: any) => {
  return {
    userLogout: () => {
      dispatch(USER_LOGOUT())
    },
    deleteUserchat: (user: any, func: any) => {
      dispatch(UPDATE_CHAT_LIST(user, func))
    },
    deleteLastMesg: (user: any, func: any) => {
      dispatch(UPDATE_CHAT_LAST_MEG_LIST(user, func))
    }
  }
}
const mapStateToProps = (state: any) => {

  return {
    profileData: state.takeAction.profileData,
    list: state.takeAction.clientData[0]
  }
}

//@ts-ignore
const myProfile = withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFriendsProfile))
//@ts-ignore
export default withStyles(useStyles)(myProfile)