import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import Cookies from 'js-cookie';
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface S {
  // Customizable Area Start
  doSub:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class VerificationCodeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  labelVerification: string;
  verifyAccountApiCallId:any
  resendApiCallId:any;
  ChatAuthTokenApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      doSub:false
      // Customizable Area End
    };

    // Customizable Area Start
    
    this.labelVerification = configJSON.labelVerification;
  
    // Customizable Area End
  }
  //Customizable area start
  SubmitSchema = Yup.object().shape({
    verification: Yup.string()
      .min(4, "Too Short!")
      .required(configJSON.verificationCodeRequire)
      .matches(/^[0-9]+$/,'Only digits are allowed.'),
  });

  async componentDidMount() {
    // Customizable Area Start
    //@ts-ignore
    const response=this.props?.location?.state?.detail;
  
    if(response?.data?.attributes?.first_name && response?.data?.attributes?.last_name && response?.data?.attributes?.email && response?.meta?.token){
        
        
        Cookies.set('first_name', response?.data?.attributes?.first_name)
        
        Cookies.set('last_name',response?.data?.attributes?.last_name)
        
        Cookies.set('Login_Email', response?.data?.attributes?.email)
        
        Cookies.set('Login_Token', response?.meta?.token)
        Cookies.set('Login_Id', response.data.id)
        
    }
     //@ts-ignore
     const verifiedMail=Cookies.get('Account_Verified')
     const currentMail=Cookies.get('Login_Email')
     //@ts-ignore
     if (Cookies.get("Login_Token")&& (currentMail !==verifiedMail)) {
         
     } else {
    //@ts-ignore
    this.props.history.push({
      pathname: "/login",
      state: { detail: "Something went wrong!! Please login to continue!!" },
    });
     }
    // Customizable Area End
  }

  doContinue(values: any) {
    this.verifyOtp(values.verification)
  }

  resendCode=(): boolean=>{
    
    this.setState({doSub:false})
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": Cookies.get("Login_Token"),
    };

    const data = {
      attributes: {
      email: Cookies.get('Login_Email')
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
       'POST'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

   runEngine.sendMessage(requestMessage.id, requestMessage);
   
    return true;
  }

  verifyOtp=(values:any): boolean=> {
     
    this.setState({doSub:true})
    
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": Cookies.get("Login_Token"),
    };

    const data = {
      otp: values,
      email: Cookies.get('Login_Email')
    }

    const httpBody = {
      data: data,
      token : Cookies.get('Push_NotificationToken'),
    };

    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verificationEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
       'POST'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

   runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  //verificationCodeOTPEndPoint


  generateChatAuthToken=() : boolean =>{
    
    
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ChatAuthTokenApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_chat/tokens'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.verifyAccountApiCallId &&
            responseJson !== undefined
          ) {
             const emailId=Cookies.get('Login_Email')
             //@ts-ignore
              Cookies.set('Account_Verified',emailId)
              toast.success("Account verified successfully.",{position: toast.POSITION.BOTTOM_RIGHT})
              this.generateChatAuthToken()
             
          }
          if (
            apiRequestCallId === this.resendApiCallId &&
            responseJson !== undefined
          ) { 
          } 
          if (
            apiRequestCallId === this.ChatAuthTokenApiCallId &&
            responseJson !== undefined
          ) {
            
            Cookies.set("Chat_Token", responseJson.token)
            
            
              //  //@ts-ignore
              //  this.props.history.push({
              //   pathname: '/',
              //   state: { detail: responseJson }
              // })
                 //@ts-ignore
                 this.props.history.push({
                  pathname: '/'
                  // state: { detail: responseJson }
                })
          }
        }
      } else {
       // this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        if (errors) {
          if(errors[0].pin==="Invalid OTP for Email"){
            toast.error(errors[0].pin,{position: toast.POSITION.BOTTOM_RIGHT})
          }else if(errors[0].pin==="This OTP has expired, please request a new OTP code."){
            toast.error(errors[0].pin,{position: toast.POSITION.BOTTOM_RIGHT})
          }else if(errors[0].phone==="Email Not Found"){
            toast.error(errors[0].phone,{position: toast.POSITION.BOTTOM_RIGHT})
          }
        }

      }
    }
  }
  // Customizable Area End
}
