export const GET_FRIENDSLIST = friendsList => ({
  type: 'GET_FRIENDSLIST',
  payload: friendsList
});

export const GET_INDEXPOST = posts => ({
  type: 'GET_INDEXPOST',
  payload: posts
});

export const EDIT_COMMENT = commentDetails => ({
  type: 'EDIT_COMMENT',
  payload: commentDetails
})

export const DELETE_COMMENT = commentDetails => ({
  type: 'DELETE_COMMENT',
  payload: commentDetails
})

export const USER_LOGOUT = () => ({
  type: 'USER_LOGOUT'
});

export const USER_CHANGE = () => ({
  type: 'USER_CHANGE'
});

export const GET_USER = detail => ({
  type: 'GET_USER',
  payload: detail
});

export const GET_INDEXTEMPPOST = posts => ({
  type: 'GET_INDEXTEMPPOST',
  payload: posts
});

export const UPDATE_POST = () => ({
  type: 'UPDATE_POST'
});

export const DELETE_tEMPPOST = () => ({
  type: 'DELETE_tEMPPOST'
});

export const DELETE_FRIENDLIST = () => ({
  type: 'DELETE_FRIENDLIST'
});

export const DELETE_POST = (id) => ({
  type: 'DELETE_POST',
  payload: id
})

export const UPDATE_POST_DESC = (postDetails) => ({
  type: 'UPDATE_POST_DESC',
  payload: postDetails
})

export const LIKE_POST = post => ({
  type: 'LIKE_POST',
  payload: post
});

export const LIKE_POSTCOMMENT = (postId,commentId) => ({
  type: 'LIKE_POSTCOMMENT',
  payload: {postId,commentId}
});

export const CREATE_COMMENT = post => ({
  type: 'CREATE_COMMENT',
  payload: post
});

export const GET_TEMPCOMMENT = comment => ({
  type: 'GET_TEMPCOMMENT',
  payload: comment
});

export const GET_IMAGES = images => ({
  type: 'GET_IMAGES',
  payload: images
});

export const GET_CLIENT = client => ({
  type: 'GET_CLIENT',
  payload: client
});

export const GET_ACTIVEUSERS = user => ({
  type: 'GET_ACTIVEUSERS',
  payload: user
});

export const DELETE_ACTIVEUSERS = () => ({
  type: 'DELETE_ACTIVEUSERS'
});

export const DELETE_USERID = () => ({
  type: 'DELETE_USERID'
});

export const GET_USERSID = user => ({
  type: 'GET_USERSID',
  payload: user
});

export const GET_LASTMESG = msg => ({
  type: 'GET_LASTMESG',
  payload: msg
});

export const GET_CHAT_USERLIST = user => ({
  type: 'GET_CHAT_USERLIST',
  payload: user
});

export const DELETE_LASTMESG = () => ({
  type: 'DELETE_LASTMESG'
});

export const DELETE_CHAT_USERLIST = () => ({
  type: 'DELETE_CHAT_USERLIST'
});

export const GET_INVITED_CHANNELS = user => ({
  type: 'GET_INVITED_CHANNELS',
  payload: user
});

export const UPDATE_LAST_MSG = (msg,func) => ({
  type: 'UPDATE_LAST_MSG',
  payload: {msg,func}
});


export const UPDATED_USR_LAST_MSG = msg => ({
  type: 'UPDATED_USR_LAST_MSG',
  payload: msg
});

export const USELESS_CALL = msg => ({
  type: 'USELESS_CALL',
  payload: msg
});

export const REMOVE_BADGE = msg => ({
  type: 'REMOVE_BADGE',
  payload: msg
});

export const UPDATE_CHAT_LIST = (user,func) => ({
  type: 'UPDATE_CHAT_LIST',
  payload: {user,func}
});

export const UPDATE_CHAT_LAST_MEG_LIST = (user,func) => ({
  type: 'UPDATE_CHAT_LAST_MEG_LIST',
  payload: {user,func}
});

export const DELETE_CHATS = () => ({
  type: 'DELETE_CHATS'
});

export const SET_OWN_POSTS = (posts) => ({
  type: 'SET_OWN_POSTS',
  payload: posts
})

export const EDIT_OWN_POST = (postDetails) => ({
  type: 'EDIT_OWN_POST',
  payload: postDetails
})

export const DELETE_OWN_POST = (id) => ({
  type: 'DELETE_OWN_POST',
  payload: id
})

export const SET_PREVIOUS_NFTS = (allNFTs) => ({
  type: 'SET_PREVIOUS_NFTS',
  payload: allNFTs
})

export const SET_OWN_NFTS = (allNFTs) => ({
  type: 'SET_OWN_NFTS',
  payload: allNFTs
})

export const SET_PROFILE = (profileData) => ({
  type: 'SET_PROFILE',
  payload: profileData
})

export const SET_FRIENDS = (friendsList) => ({
  type: 'SET_FRIENDS',
  payload: friendsList
});

export const UPDATE_FRIENDS = (updateDetails) => ({
  type: 'UPDATE_FRIENDS',
  payload: updateDetails
});

export const SET_BLOCKED_USERS = (usersList) => ({
  type: 'SET_BLOCKED_USERS',
  payload: usersList
});

export const UNBLOCK_USER = (id) => ({
  type: 'UNBLOCK_USER',
  payload: id
});

export const SET_NOTIFICATIONS = (notifications) => ({
  type: 'SET_NOTIFICATIONS',
  payload: notifications
})

export const SET_NOTIFICATIONS_CONFIGURATIONS = (configurationsSetting) => ({
  type: 'SET_NOTIFICATIONS_CONFIGURATIONS',
  payload: configurationsSetting
})

export const EMPTY_INDEX_POST = () => ({
  type: 'EMPTY_INDEX_POST'
})