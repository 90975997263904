export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const bgImage = require("../assets/background-image.png");
export const tutorialSliderFriend = require("../assets/tutorial-friends.jpg");
export const tutorialSliderLink = require("../assets/tutorial-link.jpg");
export const tutorialSliderPost = require("../assets/tutorial-post.jpg");
export const tutorialSliderSelection = require("../assets/tutorial-selection.jpg");
export const tutorialSliderUpload = require("../assets/tutorial-upload.jpg");
export const leftArrow = require("../assets/left-arrow.png");
export const rightArrow = require("../assets/right-arrow.png");

