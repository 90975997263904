import React from "react";

// Customizable Area Start
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Image from "material-ui-image";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import InfoIcon from "@material-ui/icons/Info";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import CakeIcon from "@material-ui/icons/Cake";
import AddIcon from '@material-ui/icons/Add';
import { Web3ReactProvider } from "@web3-react/core";
import Modal from "@material-ui/core/Modal";
import { Formik, Form, FormikProps } from "formik";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import MyProfileController, { Props } from "./MyProfileController.web";
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import { getWeb3ReactContext } from "@web3-react/core";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import {
  Menu,
  MenuList,
  MenuButton,
} from "@reach/menu-button";
import { connect } from "react-redux";

import "@reach/menu-button/styles.css";
import NavbarWeb from "../../TargetedFeed/src/Navbar.web";
import { postCamera } from "../../TargetedFeed/src/assets";
import SidebarWeb from "../../TargetedFeed/src/Sidebar.web";
import { toast } from "react-toastify";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
// Customizable Area End

class postSeparate extends MyProfileController {
  constructor (props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    //@ts-ignore
    const { classes, allUserPosts, allUserNFTs, blockedUsers, friendsList } = this.props
    function TabPanel(props: any) {
      const { children, value, index, ...other } = props;

      return (
        <div {...other}>{value === index && <Box p={3}>{children}</Box>}
        </div>
      );
    }

    return (
      <>
      Post separate</>
    );
  }
}

// Customizable Area Start
const useStyles = {
  txtHover: {
    "&:hover": {
      background: "transparent",
      color: "#1f7fb6"
    }
  },
  agreebtn: {
    border: "1px solid #1f7fb6",
    backgroundColor: "#e4e5e4",
    height: "40px",
    borderRadius: "50px",
    boxShadow: "none !important",
    display: "inline-flex",
    alignItems: "center",
    minWidth: "110px",
    "& .MuiButton-label": {
      fontSize: "16px !important",
      fontWeight: "700 !important",
      color: "#1f7fb6 !important",
      margin: "0 !important",
      textTransform: "none",
    },
    "& svg": {
      marginLeft: "5px",
      height: "23px"
    }
    },
    disagreebtn: {
    border: "1px solid #1f7fb6",
    backgroundColor: "#1f7fb6 !important",
    height: "40px",
    borderRadius: "50px",
    boxShadow: "none !important",
    display: "inline-flex",
    alignItems: "center",
    minWidth: "110px",
    "& .MuiButton-label": {
      fontSize: "16px !important",
      fontWeight: "700 !important",
      color: "#ffffff !important",
      margin: "0 !important",
      textTransform: "none",
    },
    "& svg": {
      marginLeft: "5px",
      height: "23px"
    }
    },
    unfriendModal: {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "350px",
      },
      "& .MuiDialogActions-root": {
        justifyContent: "space-between",
        padding: "15px"
      }
    },
    blockModal: {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "550px",
      },
      "& .MuiDialogActions-root": {
        justifyContent: "space-between",
        padding: "15px"
      },
      "& p": {
        textAlign: "left !important"
      },
      "& .MuiDialogTitle-root": {
        paddingRight: "60px"
      },
    },
    removeFriend: {
      "& span": {
        fontSize: "16px",
        color: "#000000",
        fontFamily: "'Poppins', sans-serif",
      },
      "& ul": {
        fontSize: "16px",
        color: "#000000",
        fontFamily: "'Poppins', sans-serif",
        margin: "0",
        paddingLeft: "20px",
        paddingBottom: "20px"
      },
      "& p": {
        fontFamily: "'Poppins', sans-serif",
        margin: "0",
        textAlign: "center",
        "& svg": {
          fontSize: "40px",
          color: "#1f7fb6",
          display: "block",
          margin: "0 auto",
        }
      },
    },
  infoTxt: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    color: "#565a57",
    justifyContent: "center",
    lineHeight: "1",
    "& svg": {
      height: "17px",
      widht: "17px",
      paddingRight: "6px"
    }
  },
  sideBar: {
    display: 'flex',
    background: '#ffffff',
    borderRight: '1px solid rgba(255, 255, 255, 0.07)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1em 0.3em',
    zIndex: 1,
    contentJustify: "center",
    width: "15%"
  },
  profileNFTCard: {
    "& .MuiCardContent-root": {
      padding: "0 !important",
    }
  },
  colThree: {
    flex: "0 0 33.333%",
    maxWidth: "33.333%",
    padding: "20px",
    boxSizing: "border-box",
    boxShadow: "none"
  },
  uploadNFTChild: {
    borderRadius: "5px",
    padding: "10px",
    overflow: "auto",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    boxSizing: "border-box",
  },
  nftImage: {
    maxWidth: "33.33%",
    flex: "0 0 33.33%",
    boxSizing: "border-box",
    padding: "3px"
  },
  nftText: {
    fontSize: "14px",
    color: "#565a57",
    fontWeight: "700",
    lineHeight: "1.3",
    minHeight: "40px",
    margin: "0",
    fontFamily: "'Roboto', sans-serif",
  },
  paidCardText: {
    lineHeight: "1.3",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& img": {
      width: "18px",
      marginRight: "10px",
    }
  },
  formLabel:
  {
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "10px",
    whiteSpace: "nowrap",
    "@media (max-width: 1024px)": {
      fontSize: 12,
    },
  },
  selectComment: {
    width: "100%",
    "& .MuiInputBase-root": {
      maxWidth: "200px",
      width: "100%",
      marginLeft: "auto",
      margin:0,
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      }
    }
  },
  commentBlockWrapper: {
    borderTop: "1px solid #dcdcd7",
    margin: "0 15px 0",
    paddingTop: "10px",
    "& .MuiSelect-selectMenu": {
      textAlign: "right",
      fontSize: "11px",
      color: "#404341",
      fontWeight: 700,
      "&:focus":{
        background: "transparent",
      },
      "& em":{
        opacity: "0.7"
      }
    },
    "& .MuiSelect-icon": {
      color: "#404341"
    }
  },
  commentBlock: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0 0 10px",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiAvatar-root": {
      width: "35px",
      height: "35px"
    }
  },
  commentBar:{
    margin:"2px 0",
    position: "relative",
    maxWidth: "calc(100% - 40px)",
    flex: "0 0 calc(100% - 40px)",
    "&::before" : {
      position: "absolute",
      content: "",
      height: "10px",
      width: "2px",
      backgroundColor: "#ffffff",
      left: "0px"
    },
    "& .MuiInputBase-root" : {
      backgroundColor:"#ebecec",
      color:"#6e7370",
      borderRadius:"30px",
      height: "38px",
      fontWeight: "500",
      padding: "0 15px",
      cursor:"pointer",
      fontSize: "14px",
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      }
    },
    "& .MuiOutlinedInput-notchedOutline" : {
      display:"none",
    }
  },
  container: {
    padding: "0 20px",
    overflowX: "hidden",
    height: "100%",
    backgroundColor: "#f1f2f6",
  },
  upperBoxBg: {
    backgroundColor: "#fff",
    fontFamily: "'Poppins', sans-serif",
    margin: "0 0 20px 0",
    padding: "10px 15px",
    borderRadius: "0 0 10px 10px",
    boxShadow: "1px 1px 3px rgb(0 0 0 / 20%)",
  },
  bottomBoxBg: {
    maxWidth: "100%",
    margin: "0 auto",
    "& .MuiBox-root": {
      padding: 0,
    }
  },
  NFTCardWrapper: {
    maxWidth: "635px",
    margin: "0 auto",
    borderRadius: "10px",
    marginBottom: "23px",
    "& .MuiCardContent-root": {
      padding: "30px",
    }
  },
  heading: {
    margin: "0 0 0 20px",
    "& h2": {
      fontSize: "28px",
      fontWeight: "600",
      padding: "0",
      margin: "0"
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      margin: "0",
      color: "#9a9a9a",
    }
  },
  modelPopup: {
    "& fieldset": {
      display: "none",
    },
  },
  accordionUserWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  friendCard: {
    width: "650px",
    margin: "0 auto",
    boxShadow: "none",
    borderRadius: "10px"
  },
  buttonUpload: {
    display: "block",
    background: "#d8dddd",
    border: "2px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    color: "#1f7fb6",
    fontWeight: 700,
    width: "100%"
  },
  friendCardTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "2px solid #d8dddd",
    paddingBottom: "15px",
    "& h2": {
      color: "#000105",
      fontSize: "21px",
      fontWeight: "600",
      margin: "0",
      fontFamily: "'Poppins', sans-serif",
    },
    "& .MuiFormControl-root": {
      backgroundColor: "#d8dddd",
      height: "41px",
      fontSize: "16px",
      color: "#1f7fb6",
      fontWeight: "700",
      letterSpacing: "0",
      border: "1px solid #1f7fb6",
      borderRadius: "5px",
      textAlign: "center",
      maxWidth: "170px",
      "& .MuiInputBase-input": {
        "&::placeholder": {
          color: "#1f7fb6",
          fontWeight: "700",
          opacity: "1",
        }
      },
      "& .MuiOutlinedInput-root": {
        paddingLeft: "10px",
        "& .MuiSvgIcon-root": {
          color: "#1f7fb6"
        }
      },
      "& .MuiOutlinedInput-input": {
        padding: "11px 12px 11px 0px"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        top: "0"
      }
    }
  },
  profileImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& div": {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      padding: "0 !important",
      "& img": {
        maxHeight: "50px",
      }
    },
    "& p": {
      fontSize: "16px",
      fontWeight: "600",
      marginLeft: "10px",
      fontFamily: "'Poppins', sans-serif",
    }
  },
  createPost: {
    color: "#fff",
    backgroundColor: "#35a0d4",
    fontSize: "16px",
    fontWeight: "600",
    padding: "10px 20px",
    margin: "0 20px",
    borderRadius: "5px",
  },
  btnViewNFT: {
    color: "#1f7fb6",
    backgroundColor: "#d8dddd",
    fontSize: "16px",
    fontWeight: "700",
    padding: "6px 20px",
    borderRadius: "5px",
    border: "2px solid #1f7fb6",
    textTransform: "none",
    minWidth: "249px"
  },
  myNftImgWrap: {
    "& div": {
      paddingTop: "0 !important",
    },
  },
  myNftImg: {
    width: "100% !important",
    height: "auto !important",
    position: "relative !important",
  },
  textField: {
    padding: "0 0 15px",
    width: "100%",
    fontSize:"14px",
    color: "#000000",
    "& .MuiSelect-selectMenu":{
      border: "1px solid #c4c4c4",
      borderRadius: "5px",
      padding: "10px 14px !important",
    },
    "& .MuiInputBase-root": {
      fontSize: "14px",
      color: "#565a57",
      fontWeight: "400",
      "&::before": {
        display: "none",
      }
    },
    ".MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
      color: "#565a57",
      fontWeight: "400",
    }
  },
  tabsWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      color: "#1f7fb6",
      backgroundColor: "#deedf4",
      borderRadius: "5px"
    },
    "& .MuiTabs-scroller": {
      alignSelf: "center",
    }
  },
  leftTab: {
    paddingTop: "7px",
    "& button": {
      border: "0",
      textTransform: "none",
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      minWidth: "initial",
      minHeight: "40px",
      padding: "14px 20px",
      opacity: "1",
      color: "#6e7370",
      lineHeight: "1",
      borderRadius: "5px"
    },
  },
  mainContent: {
    width: "70%",
  },
  formWrap: {
    backgroundColor: "#fff",
    fontFamily: "'Poppins', sans-serif",
    borderRadius: "10px",
    boxShadow: "none",
    "& .MuiTextField-root": {
      width: "100%",
      padding: "0 0 15px",
      display: "flex"
    },
    "& .MuiOutlinedInput-input": {
      padding:"11px 14px !important",
    },
    "& .MuiButton-root": {
      backgroundColor: "#e5e6eb",
      border: "2px solid #50a0c6",
      color: "#50a0c6",
      boxShadow: "none",
    }
  },
  textWrapper: {
    paddingTop: "20px",
  },
  postCard: {
    boxShadow: "none",
    borderRadius: "10px",
    maxWidth: "80%",
    margin: "0 auto 25px",
    "& .MuiCardHeader-root": {
      padding: "10px",
      justifyContent: "space-beetween",
      "& .MuiCardHeader-avatar": {
        marginRight: "15px",
        "& .MuiAvatar-root": {
          width: "45px",
          height: "45px",
        }
      },
      "& .MuiCardHeader-content": {
        "& .MuiCardHeader-title": {
          fontSize: "16px",
          color: "#000307",
          fontWeight: "700",
          lineHeight: "1",
          paddingBottom: "5px",
        },
        "& .MuiCardHeader-subheader": {
          fontSize: "13px",
          color: "#888b87",
          fontWeight: "500",
          lineHeight: "1",
          paddingBottom: "0",
        }
      },
      "& .MuiCardHeader-action": {
        margin: "0",
        "& button": {
          color: "#1f7fb6",
          padding: "7px",
          height: "40px",
          width: "40px",
          backgroundColor: "transparent",
          border: "none"
        }
      }
    },
    "& .MuiCardContent-root": {
      padding: "0",
      "& p": {
        margin: "0 15px 0",
        color: "#6e7370",
        fontSize: "16px",
        fontWeight: "500",
        fontFamily: "'Roboto', sans-serif",
        paddingBottom: "5px",
        lineHeight: "1.4",
      },
      "& span": {
        borderTop: "1px solid #dcdcd7",
        color: "#565a57",
        fontSize: "16px",
        fontWeight: "700",
        display: "block",
        fontFamily: "'Roboto', sans-serif",
        margin: "0 15px 15px",
        paddingTop: "10px",
      }
    }
  },
  paidText: {
    fontSize: "12px",
    color: "#1f7fb6",
    fontWeight: "500",
    lineHeight: "1.3",
    minHeight: "25px",
    margin: "0",
    "& img": {
      width: "15px"
    }
  },
  likeCommentShare: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 0",
    margin: "0 15px",
    "& button": {
      padding: "0",
      margin: "0",
      color: "#6e7370",
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      height: "20px",
      borderRadius: "0",
      "& p":{
        margin: "0 0 0 5px",
      }
    }
  },
  postCardInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 0",
    margin: "0 15px",
    borderBottom: "1px solid #dcdcd7",
    "& div": {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#6e7370",
      maxWidth: "33.33%",
      flex: "0 0 33.33%",
      "& h3": {
        margin: "0 0 0 10px",
      },
      "& svg": {
        color: "#1f7fb6"
      }
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#6e7370 !important",
      margin: "0 !important",
      padding: "0 !important",
      border: "none !important"
    },
    "& h3": {
      margin: "0",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#1f7fb6",
      maxWidth: "45%",
      flex: "0 0 45%",
    },
  },
  menuListData: {
    whiteSpace: "pre-wrap",
    width: "346px",
    backgroundColor: "#fffffe",
    borderRadius: "10px",
    position: "relative",
    marginTop: "20px",
    "& ::before": {
      content: "",
      position: "absolute",
      boderLeft: "20px solid transparent",
      boderRight: "20px solid transparent",
      boderTop: "20px solid #ffffff",
      top: "-20px",
      right: "20px",
    },
    "& button": {
      width: "100%",
      padding: "5px 15px",
      "& h4": {
        display: "flex",
        alignItems: "center",
        fontSize: "16px",
        color: "#565a57",
        fontWeight: "700",
        fontFamily: "'Roboto', sans-serif",
        textTransform: "none",
        flexWrap: "wrap",
        margin: "0",
        width: "100%",
        "& img": {
          width: "24px",
          marginRight: "12px",
        }
      },
    },
  },
  accordionUserItem: {
    flex: "0 0 50%",
    maxWidth: "50%",
    padding: "25px 20px",
    boxSizing: "border-box",
  },
  accordionUser: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    boxSizing: "border-box",
  },
  closeBtn: {
    position: "absolute",
    top: "15px",
    background:" #e5e6eb",
    right: "15px",
    padding: "5px",
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "& .MuiButton-text": {
      width: "100%",
    },
    "& .MuiButton-label": {
      textAlign: "left",
      display: "block",
    },
    "& .MuiAvatar-root": {
      width: "72px",
      height: "72px",
      backgroundColor: "#bcbeba",
      borderRadius: "50%",
      marginRight: "10px",
    },
    "& .MuiTypography-root": {
      textTransform: "none",
      fontSize: "16px",
      color: "#000307",
      fontWeight: "700",
      lineHeight: "1.3",
      textAlign: "left",
      "& span": {
        display: "block",
        fontSize: "13px",
        color: "#505452",
        fontWeight: "500",
        fontFamily: "'Roboto', sans-serif",
        marginTop: "10px",
      }
    },
  },
  buttonMore: {
    width: "49px",
    height: "40px",
    backgroundColor: "#ebecec",
    color: "#1f7fb6",
    border: "1px solid #1f7fb6",
    borderRadius: "5px",
    "@media(max-width: 1199px)": {
      width: "30px",
      minWidth: "30px",
      height: "30px",
      "& .MuiSvgIcon-root":{
        width: "13px",
        height: "13px",
      }
    },
  },
  modalTitle:{
    color: "#000105",
    fontSize: "20px !important",
    textAlign: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
  },
  buttonPriview :{
    marginTop : "10px",
    display: "block",
    background: "#1f7fb6",
    border: "2px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    color: "#ffffff",
    fontWeight: 700,
    width: "100%",
    boxSizing: "border-box",
  },
  createPostModal:{
    "& .MuiDialog-paperWidthSm": {
      minWidth: "600px",
    },
    "& .MuiDialog-paper": {
      position: "relative",
      overflow: "visible"
    },
    "& .MuiInputBase-root": {
      padding: "0"
    },
    "& .MuiButton-root":{
      height: "43px",
      boxShadow: "none",
    },
    "& .MuiFormControl-root" :{
      padding: "10px 5px",
      width: "100%",
      "& .MuiInputBase-input":{
        color: "#565a57",
        opacity: "1",
        fontSize: "20px",
        fontWeight: "400",
        height: "40px",
        "&::placeholder": {
          color: "#565a57",
          opacity: "1",
        }
      }
    },
    "& .MuiDialogActions-root": {
      width: "100%",
      boxSizing: "border-box",
      flexWrap: "wrap",
      padding: "8px 24px",
      "& label": {
        width: "100%",
        boxSizing: "border-box",
        margin: "0",
        padding: "15px ​25px 5px",
      }
    },
    "& .MuiInput-underline" :{
      "&::before" :{
        display: "none"
      },
      "&::after" :{
        display: "none"
      }
    },
    "& .MuiCircularProgress-root": {
      width: "22px !important",
      height: "22px !important",
      position: "absolute"
    },
    
  },
  createPostForm: {
    position: "relative",
    "& .MuiIconButton-root": {
      position: "absolute",
      top: "50px",
      background: "#ffffff",
      borderRadius: "50%",
      zIndex: "9",
      width: "30px",
      height: "30px",
      right: "-10px",
      padding: "0",
      boxShadow: "1px 1px 3px rgb(0, 0, 0 ,20%)",
    }
  },
  createInnerModal: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  nftImg:{
    width:"100% !important",
    height:"auto !important",
    position:"relative !important",
  },
} as any;
// Customizable Area End

const mapStateToProps = (state: any) => {
  return {
    profileData: state.takeAction.profileData,
  };
};

//@ts-ignore
const PostSeparate = withRouter(connect(mapStateToProps, null)(postSeparate))
export default withStyles(useStyles)(PostSeparate)