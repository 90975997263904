// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import {ActionCableProvider} from 'react-actioncable-provider'
import ActionCable from "actioncable";
import Cookies from 'js-cookie';
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import combineReducers from "./reducer";
import { baseURL } from '../../framework/src/config.js'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk, promise];

const store = createStore(
  combineReducers(),
  composeEnhancers(applyMiddleware(...middleware))
);

const cable = ActionCable.createConsumer(`${baseURL}/cable`);

 ReactDOM.render(
  <Router>
  <Provider store={store}>
  <ActionCableProvider cable={cable}>
    <App />
  </ActionCableProvider>
  </Provider>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
