import React from "react";

// Customizable Area Start
import Button from "@material-ui/core/Button";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import IconButton from "@material-ui/core/IconButton";
import Image from "material-ui-image";
import {withRouter} from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import {bgImage} from "./assets";
import SettingUpProfilePicController, {
  Props,
} from "./SettingUpProfilePicController.web";
// Customizable Area Ends

class SettingUpProfilePic extends SettingUpProfilePicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }
  render() {
    const { classes } = this.props
    return (
      <>
        <div className={classes.mainWrapper}>
          <div className={classes.container}>
            <div  className={classes.leftBlock} style={{ }}>
              <h1 className={classes.title}>deegee</h1>
              <h2 className={classes.welcomeTitle}>Welcome!</h2>
              <span style={{ width: "100px", border:"2px solid #fff", display: "block", margin: "20px auto 30px auto" }}></span>
              <p>Social networking for NFT collectors. Showcase your <br></br>NFT's to the world around you.</p>
            </div>
            <div className={classes.rightBlock}>
          <Formik
            initialValues={{
              profilePic: "",
            }}
            onSubmit={(values): any => this.doFinalize(values)}
          >
            {({ handleSubmit,values ,setFieldValue,isSubmitting}) => (
              //@ts-ignore
              <Form className={classes.registerFrm}>
                <h2 className={classes.formHead1} style={{ margin: "0 0 20px 0" }}>Profile</h2>
                <p className={classes.formHead}>Profile Picture</p>
                <div style={{ width: "50%", margin:"0 auto", position:"relative"}}>
                  <Image src="https://www.studyheights.com/assets/img/Universitylogo/default.png" id="profilePicture"/>
                  <input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  style={{ display: "none"}}
                  name="profilePic"
                  onChange={(event)=> this.fileUploadHandler(event)}
                />
                  <label htmlFor="icon-button-file" style={{position:"absolute", bottom:"10px", right:"10px", background: "rgb(24, 130, 173)", borderRadius:"50%"}}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={()=>this.setState({checkDisabled:false})}
                  >
                    <PhotoCamera style={{color:"#fff"}} />
                  </IconButton>
                </label>
                </div>
                
                
                <div className={classes.formGrp}>
                <Button
                  className={classes.primaryBtn}
                  style={{backgroundColor: "#1882AD", color: "white", height: "50px", width:"100%", marginTop: "25px", padding:"10px 30px", textTransform:"none", fontSize:"16px", fontFamily: "'Poppins', sans-serif"}}                
                  variant="contained"
                  type="submit"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                  disabled={this.state.checkDisabled}
                >
                  Finalize
                </Button>
                </div>
                <div className={classes.formGrp} >
                <Button
                  className={classes.primaryBtn}
                  style={{backgroundColor: "white", color: "#1882AD", marginLeft:"auto", boxShadow: "none", textTransform:"none", fontSize:"16px", fontFamily: "'Poppins', sans-serif"}}                
                  variant="contained"
                  type="submit"
                  onClick={()=>this.gotoHome(this.props)}
                >
                  Later
                </Button>
                </div>
              </Form>
            )}
          </Formik>
          </div>
        </div>
        </div>
      </>
    );
  }
}
const SettingUpProfilePicStyles = {
  mainWrapper: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    fontFamily: "'Poppins', sans-serif",
    overflowY:"auto",
    padding:"30px 0",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    background:"url("+bgImage+")",
  },
  buttonOutline:
  {
    marginTop: "45px",
    color: "#fff",
    border: "1px solid white",
    padding:"0 25px",
    height: "44px",
    cursor: "pointer",
    textTransform:" none",
  },
  MuiTypographyBody1:{
    "& .MuiTypography-root":{
    fontSize: "14px",
    fontWeight: "400",
    color: "#9E9F9E",
    }
  },
  container: {
    display: "flex",
    width: "100%",
    margin: "0 10px",
    justifyContent: "center",
    maxWidth:"1280px",

    "@media (max-width: 767px)": {
      flexDirection: "column",
      justifyContent: "flex-start",
      
    },
    "@media (max-width: 1024px)": {
      width: "100%",
      padding: "10px"
    },
   
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "20px",
    }
  },
  title:
  {
    fontSize: "72px",
    fontWeight: "500",
    margin:"0",
    "@media (max-width: 767px)": {
      fontSize: "42px",
    }
  },
  welcomeTitle:
  {
    fontSize: "52px",
    fontWeight: "500",
    margin:"20px 0 0 0",
    "@media (max-width: 767px)": {
      fontSize: "36px",
    }
  },
  leftBlock:
  {
    textAlign: "center", color: "#fff" ,
    width:"50%",
    paddingTop:"50px",
    "@media (max-width: 767px)": {
      width:"100%",
    }
  },
  rightBlock: {
    width:"50%",
    "@media (max-width: 767px)": {
      display: "flex",
      justifyContent: "center",
      paddingLeft: "0",
      margin: "30px 0",
      width:"100%",
    },
  },
  registerFrm: {
    backgroundColor: "white",
    padding: "20px 40px",
    margin: "20px auto",
    borderRadius: "20px",
    width:"80%",
    color:"#4D4E4C",
    maxWidth:"450px",
    "@media (max-width: 767px)": {
      padding: "30px",
      width: "100%",
      margin: "7px auto",
    }
  },

  formHead1:{
    fontSize:"26px",
    fontWeight:"600",
    margin:"0",
  },
  formHead:{
    fontSize:"18px",
    fontWeight:"500",
    margin:"0",
  },
  formTxt:{
    fontSize:"14px",
    color: "#9E9F9E",
    margin: "0 0 10px 0"
  },
  mainFrmCard:
  {
    display: "flex",
    borderRadius: "20px",
    flexWrap: "wrap",
  },
  recaptchaBtn: {
    display: "flex", justifyContent: "center", alignItems: "center", width: "100%", margin: "15px 0",
  },
  textField: {
    width: "100%",
  },
  input: {
    borderRadius: "7px !important",
    margin: "5px 0 5px 0",
    backgroundColor: "#fff",
    "& input":
    {
      padding: "10px 17px",
      fontWeight: 500,
      fontSize: 15,
      color: "#a6a7af",
      backgroundColor: "#fff !important",
      borderRadius: 15,
    },
    "& textarea":
    {
      padding: "10px 17px",
      fontWeight: 500,
      fontSize: 15,
      color: "#a6a7af",
    },
    "&::before":
    {
      borderBottom: "0 !important",
    },
    "&::after":
    {
      borderBottom: "0 !important",
    },
    "&::hover":
    {
      borderBottom: "0 !important",
    },
    "&::focus":
    {
      borderBottom: "0 !important",
    },
    "&::placeholder":
    {
      color: "#a6a7af",
      opacity: 1,
    }
  },
  formGrp:
  {
    display: "flex",
    marginTop: "10px",
    width: "100%",
    "@media (max-width: 767px)": {
      flexDirection: "column",
      flexWrap: "wrap",
    },
  },
  formLabel:
  {
    fontSize:"16px",
    fontWeight:"500",
    marginBottom: "10px",
    whiteSpace: "nowrap",
    "@media (max-width: 1024px)": {
      fontSize: 12,
    },
  },
  formCol:
  {
    width: "100%",
    marginBottom: "10px",
  },
  ml: {
    marginLeft: "15px",
    "@media (max-width: 767px)": {
      marginLeft: "0",
    },
  }
} as any;
//@ts-ignore
const emailSettingPicWithRouter =withRouter(SettingUpProfilePic )
export default withStyles(SettingUpProfilePicStyles)(emailSettingPicWithRouter)
// export default  (withRouter(SettingUpProfilePic as any) as any)