Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AddFriends";
exports.labelBodyText = "AddFriends Body";

exports.musicTypes = ['mp3', 'wav']
exports.videoTypes = ['mp4', 'ogg', 'webm']
exports.imageTypes = ['jpg', 'png', 'gif', 'svg']
exports.object3dTypes = ['glb', 'gltf']

exports.btnExampleTitle = "CLICK ME";
exports.myInfoApiEndPoint = "bx_block_profile/profiles/user_profile"
exports.ImageUploadApiEndPoint = "bx_block_profile/profiles/update_profile_picture"
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.myInfoUpdateEndPoint = "bx_block_profile/profiles/update_profile"
exports.countryApiKey = "TjZUcUVESXpvcHVxWksxVVB6QUlCZGw1cndNNzhxWFVCQlRpMFlNQw==";
exports.getAllCountriesListUrl = "https://api.countrystatecity.in/v1/countries";
exports.getAllStatesListByCountryUrl = "https://api.countrystatecity.in/v1/countries/";
exports.getAllCityListByStatesAndCountryUrl = "https://api.countrystatecity.in/v1/countries/";
exports.headerForUserEmail = "ujjaval.barot@green-apex.com";

exports.contentTypeApiAddDetail = "application/json";
exports.metamaskWalletEndpoint="bx_block_cfmetamask/wallets"
exports.confirmNFTEndPoint = "bx_block_posts/posts"
exports.myMetamaskEndPoint="bx_block_cfmetamask/wallets"
exports.allMyNftPostEndPoint="bx_block_posts/posts/my_posts"
exports.deletePostEndPoint="bx_block_posts/posts"
exports.httpDeleteMethod="DELETE"
exports.likePostEndPoint="post"
exports.veriFiedNftEndPoint="bx_block_profile/profiles/verified_nfts"
exports.friendsListEndPoints="bx_block_conn/connections/friend_lists"
exports.getFriendProfileEndPoints="bx_block_profile/profiles/friend_profile?user_id="
exports.sendFriendRequestEndPoint="bx_block_conn/connections"
exports.unfollowReqEndPoint="bx_block_conn/connections/unfollow"
exports.unfriendEndPoint='bx_block_conn/connections'
exports.getFriendAllPostEndPoint = 'bx_block_posts/posts/friend_posts?user_id='
exports.getFriendOfFriendEndPoint = 'bx_block_conn/connections/user_friends?user_id='
exports.blockEndPoint='bx_block_block_users/block_users?account_id='
exports.followFriendEndPoint="bx_block_conn/connections/follow?connection_id="
exports.unBlockUserEndPoint="bx_block_block_users/block_users/"
exports.searchFriendENdpoint="bx_block_conn/connections/search_friend?query"
exports.createCommentEndPoint="comments/comments"
exports.commentdataEndPoint="post/"
exports.commentReplyEndPoint="comments/"
exports.getUserIdEndpoint='account_block/accounts/find_user_by_username'
exports.reportPostEndPoint="bx_block_contentflag/report_posts"
exports.reportReasonsEndPoint="bx_block_contentflag/report_reasons"
exports.getUserNFTsEndpoint="bx_block_profile/profiles/friend_nft?user_id="
exports.editCommentEndPoint="bx_block_comments/comments/edit_comment"
exports.deleteCommentEndPoint="bx_block_comments/comments/delete_comment"
exports.cancelFriendRequestEndPoint="bx_block_conn/connections/revert_friend"
exports.sendAcceptReqEndPoints="bx_block_conn/connections/accept_friend_request"
// Customizable Area End