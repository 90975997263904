import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
//import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
import Cookies from "js-cookie";
import {withRouter } from 'react-router-dom';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  submitBtn: boolean;
  countryData: any[];
  stateData: any[];
  cityData: any[];
  CountryName: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  GetCountryAuthTokenApiCallId: string = "";
  GetCountryListApiCallId: string = "";
  GetCountryStateListApiCallId: string = "";
  GetCountryCityListApiCallId: string = "";
  apiForgotPasswordCallId: string = "";
  // Customizable Area End

  constructor (props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      submitBtn: false,
      countryData: [],
      stateData: [],
      cityData: [],
      CountryName: "",
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    // this.imgPasswordVisible = imgPasswordVisible;
    //this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.emailLabel;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }
  //Customizable area start
  ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required(configJSON.emailIsRequired)
      .matches(configJSON.emailRegex, configJSON.invalidEmailAddress),
  });
  doResetPassword(values: any) {
    this.doResetPasswordAPI(values)
  }
  doResetPasswordAPI(values: any): boolean {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const attrs = {
      email: values.email
    };

    const data = {
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForgotPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ForgotPasswordApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.apiForgotPasswordCallId &&
            responseJson !== undefined
          ) {
            const OriginalEmail = responseJson?.data?.attributes?.email
            const pin = responseJson?.data?.attributes?.pin
            const Forgot_token = responseJson?.meta?.token
           // Cookies.set('Forgot_token', Forgot_token)
            Cookies.set('resend_token',Forgot_token)
            const partialEmail = OriginalEmail.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2")
            toast.success("Otp sent",{position: toast.POSITION.BOTTOM_RIGHT})
          // @ts-ignore
          this.props.history.push({
          pathname: '/ForgotPasswordConfirmation',
          state: { email: partialEmail,otp_code:pin,OriginalEmail:OriginalEmail }
        })
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        if (errors) {
            toast.error(errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT});
          return
        }
        if (errors[0].token === 'Invalid token' || errors[0].token==="Token has Expired" ) {
            toast.error(errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
            Cookies.remove('Login_Token')
             //@ts-ignore
             this.props.history.push({
              pathname: '/login'
            })
        } else
        {
            toast.error(errors,{position: toast.POSITION.BOTTOM_RIGHT})
        }

      }
    }
  }
  // Customizable Area End
}