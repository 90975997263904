import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

import Cookies from "js-cookie";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  value: number;
  allUsers:[];
  allPost:[];
  btnStatus:any;
  callingSearch:boolean;
  resultNoFound:any;
  noInput:any;
  pendingCount:number;
  pendingCo:number;
  allTab:boolean;
  peopleTab:boolean;
  nftsTab:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SearchResultController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  searchingApiCallId:any
  friendApiId:any
  checkComponentDid:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      value: 0,
      allUsers:[],
      allPost:[],
      btnStatus:'ok',
      callingSearch:false,
      resultNoFound:'',
      noInput:'',
      pendingCount:4,
      pendingCo:4,
      allTab:true,
      nftsTab:false,
      peopleTab:false
      // Customizable Area End
    }

  }
  //Customizable area start
  async componentDidMount() {
    if( Cookies.get("Login_Token")){
      //@ts-ignore
    const response=this.props?.location?.state?.detail;
    if(!!response){
    //@ts-ignore
    document.getElementById('searchNftAndUsers').value = response
    this.getSearching(response)
    //@ts-ignore
    if(document.getElementById('searchNftAndUsers').value === ""){
      this.setState({resultNoFound:'Please type something to search.'})
    }
  }else{
    //@ts-ignore
    this.props.history.push('/')
  }
    }
  }

  getSearching=(values?:any):boolean=>{
    if(Cookies.get('Login_Token')&&values){
    const headers= {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        "token" :Cookies.get('Login_Token')
      };
      const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
      this.searchingApiCallId = requestMessage.messageId;
      requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.advanceSearchEndPoint+values
      );
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
      );
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }else{
        this.setState({allUsers:[],allPost:[],resultNoFound:'Please type something to search.'})
        return false;
    }
    };
    sendRequest=(userId:any,index:number):boolean=>{

          //@ts-ignore
          const b = document.querySelector("#addFriendBtn"+index);
          //@ts-ignore
          b.setAttribute("style", 'pointer-events: none; color: darkgray;') 
             
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token" :Cookies.get('Login_Token')
          };
          
          const httpBody = {
            'receipient_id': userId,
          };
        
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.friendApiId= requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendFriendRequestEndPoint
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
          );
         runEngine.sendMessage(requestMessage.id, requestMessage);
           
     
          return true;
      }

     makeEmptyUserAndPost=()=>{
        this.setState({allUsers:[],allPost:[],resultNoFound:'Please type something to search.'})
        
     } 
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.searchingApiCallId &&
            responseJson !== undefined
          ) {
              this.setState({allUsers:responseJson.users,allPost:responseJson.posts})
              
          }
          if (
            apiRequestCallId === this.friendApiId &&
            responseJson !== undefined
          ) {
              toast.success('Friend request has been send.',{position: toast.POSITION.BOTTOM_RIGHT})
          }
          if (responseJson?.error) {
            this.setState({resultNoFound:`${responseJson?.status} ${responseJson.error}`})
          }
      } 
    }else {
      this.parseApiCatchErrorResponse(errorReponse);
      const errors = responseJson?.errors;
      if(errors){
      
          //@ts-ignore
         const search=document.getElementById('searchNftAndUsers').value
        if(errors[0].message==='No result found.' && search !==""){
         
           this.setState({resultNoFound:errors[0].message})
        }
      }
    }
  }
  // Customizable Area End
  
   // Customizable Area Start
  
   // Customizable Area End
}
}
