import React from "react";

import SidebarController, { Props } from "./SidebarController.web";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import Avatar from '@material-ui/core/Avatar';
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import {
  Menu,
  MenuList,
  MenuButton,
} from "@reach/menu-button";
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Scrollbar } from "react-scrollbars-custom";
import { connect } from "react-redux";
//@ts-ignore
import {GET_FRIENDSLIST} from "../../../web/src/allActions.js";
class Sidebar extends SidebarController {
  constructor (props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    if (this.props.friendUnfollowState) {
      this.getFriendsList();
    }
    if (this.props.friendUnfriendState) {
      this.getFriendsList();
    }
    if (this.props.friendBlockState) {
      this.getFriendsList();
    }

    return (
      // Customizable Area Start
      <div className={classes.sideBarWrapper}>
        <div className={classes.sideBar}>
          <Scrollbar className={classes.customScroll +" "+ classes.sidebarCustomScroll}>
            <div className={classes.sidebarTitle}>
              <h2>Friends</h2>
            </div>
            <div>
              <TextField
                className={`${classes.searchBar} search-icon`}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Find friends"
                onChange={(e:any)=>{
                  this.handleChangeDebounce(e.target.value)
                }}
                id='searchingFrd'
              />
            </div>
            <div className={classes.chatAccordion}>
              {//@ts-ignore
              this.state.isGettingList && (!this.props?.friendList || this.props?.friendList?.length === 0) ?
                <CircularProgress className={classes.loader} /> :
                <>
                  <h2 className={classes.titleTxt}>
                    {//@ts-ignore
                    this.props?.friendList?.length || 0} friends
                  </h2>
                  {//@ts-ignore
                  this.props?.friendList?.length !== 0 && this.props?.friendList && (

                    //@ts-ignore
                    this.props?.friendList?.map((element: any, index) => {

                      return (
                        <div key={element + index}>
                          <div className={classes.accordionUser}>
                            <div className={classes.userInfo}>
                              <Avatar src={element.attributes?.image}></Avatar>
                              <Button onClick={() => {
                                if (element.attributes.receipient_name) {
                                  //@ts-ignore
                                  this.props.history.push({
                                    pathname: '/' + element.attributes.receipient_name,
                                    state: { detail: element.attributes.receipient_name }
                                  })
                                } else if (element.attributes.receipient_id) {
                                  //@ts-ignore
                                  this.props.history.push({
                                    pathname: '/user/' + element.attributes.receipient_id,
                                    state: { detail: element.attributes.receipient_id }
                                  })
                                }
                                if (this.props.pageState !== 'home') {
                                  this.props.parentComponentDidMount()
                                }
                              }}>
                                <Typography key={element + index + index + element + element + element}>
                                  {element.attributes.first_name + " " + element.attributes.last_name}
                                  <span>{element.attributes.common_friends} friend(s) in common</span>
                                </Typography>
                              </Button>
                            </div>
                            {this.props.pageState === "home" ? '' : (
                              <Menu>
                                <MenuButton className={classes.btnMoreInfo}>
                                  <MoreHorizIcon />
                                </MenuButton>
                                <MenuList className={classes.menuListData}>
                                  <span className={classes.triangle}></span>
                                  <Button
                                    onClick={() =>
                                      this.props.unfollowRequest(element.id, element.attributes.receipient_id, element.attributes.follow)
                                    }
                                  >
                                    <h4>
                                      <img src="https://img.icons8.com/ios/50/000000/dislike.png" />
                                      Unfollow
                                    </h4>
                                    <Switch checked={!element.attributes.follow} />
                                  </Button>
                                  <Button onClick={() => this.setState({ friendFirst_name: element.attributes.first_name, friendLast_name: element.attributes.last_name, openDialoug: true, friendId: element.id })
                                  }>
                                    <h4>
                                      <img src="https://img.icons8.com/ios/50/000000/delete-user-male.png" />
                                      Unfriend
                                    </h4>
                                  </Button>
                                  <Button onClick={() =>
                                    this.setState({ openBlockDialogue: true, blockFriendId: element.attributes.receipient_id, friendFirst_name: element.attributes.first_name, friendLast_name: element.attributes.last_name })
                                  }>
                                    <h4>
                                      <img src="https://img.icons8.com/ios/50/000000/unfriend-male.png" />
                                      Block
                                    </h4>
                                  </Button>
                                </MenuList>
                              </Menu>
                            )}

                          </div>
                        </div>)
                    }))}
                </>}
            <Dialog
              open={this.state.openDialoug}
              onClose={() => this.setState({ openDialoug: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className={classes.unfriendModal}
            >
              <DialogTitle id="alert-dialog-title" >
                <IconButton className={classes.closeBtn} onClick={() => this.setState({ openDialoug: false })}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent >
                <DialogContentText id="alert-dialog-description" >
                <AnnouncementIcon />
                  <span>Are you sure want to remove <b>{this.state.friendFirst_name + ' ' + this.state.friendLast_name}</b> from your friends list?</span>
                </DialogContentText>
              </DialogContent>
              <DialogActions >
                <Button className={classes.agreebtn} onClick={() => this.setState({ openDialoug: false })}>Cancel</Button>
                <Button className={classes.disagreebtn} onClick={() => {
                  this.props.unfriendFunc(this.state.friendId)
                  this.setState({ openDialoug: false })
                }} autoFocus>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.openBlockDialogue}
              onClose={() => this.setState({ openBlockDialogue: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className={classes.blockModal}
            >
              <DialogTitle id="alert-dialog-title" >
                <span>Are you sure to block <b>{this.state.friendFirst_name + ' ' + this.state.friendLast_name} </b>?</span> 
                <IconButton className={classes.closeBtn} onClick={() => this.setState({ openBlockDialogue: false })}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent  className={classes.removeFriend}>
                <span>
                  {this.state.friendFirst_name + ' ' + this.state.friendLast_name} will no longer able to: </span>
                  <ul>
                    <li>See your posts in your bio</li>
                    <li>See you messages</li>
                    <li>Add you to their friends list</li>
                  </ul>
                  <p>If you are friends and block <b>{this.state.friendFirst_name + ' ' + this.state.friendLast_name}</b> will also remove him/her from your friends list.</p>
              </DialogContent>
              <DialogActions >
                <Button className={classes.agreebtn} onClick={() => this.setState({ openBlockDialogue: false })}>Cancel</Button>
                <Button className={classes.disagreebtn} onClick={() => {
                  this.props.blockfriendFunc(this.state.blockFriendId)
                  this.setState({ openBlockDialogue: false })
                }}
                  autoFocus>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          </Scrollbar>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SidebarStyles = {
  agreebtn: {
    border: "1px solid #1f7fb6",
    backgroundColor: "#e4e5e4",
    height: "40px",
    borderRadius: "50px",
    boxShadow: "none !important",
    display: "inline-flex",
    alignItems: "center",
    minWidth: "110px",
    "& .MuiButton-label": {
      fontSize: "16px !important",
      fontWeight: "700 !important",
      color: "#1f7fb6 !important",
      margin: "0 !important",
      textTransform: "none",
    },
    "& svg": {
      marginLeft: "5px",
      height: "23px"
    }
    },
    disagreebtn: {
    border: "1px solid #1f7fb6",
    backgroundColor: "#1f7fb6 !important",
    height: "40px",
    borderRadius: "50px",
    boxShadow: "none !important",
    display: "inline-flex",
    alignItems: "center",
    minWidth: "110px",
    "& .MuiButton-label": {
      fontSize: "16px !important",
      fontWeight: "700 !important",
      color: "#ffffff !important",
      margin: "0 !important",
      textTransform: "none",
    },
    "& svg": {
      marginLeft: "5px",
      height: "23px"
    }
    },
    unfriendModal: {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "350px",
      },
      "& .MuiDialogActions-root": {
        justifyContent: "space-between",
        padding: "15px"
      }
    },
    blockModal: {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "550px",
      },
      "& .MuiDialogActions-root": {
        justifyContent: "space-between",
        padding: "15px"
      },
      "& p": {
        textAlign: "left !important"
      },
      "& .MuiDialogTitle-root": {
        paddingRight: "60px"
      },
    },
    removeFriend: {
      "& span": {
        fontSize: "16px",
        color: "#000000",
        fontFamily: "'Poppins', sans-serif",
      },
      "& ul": {
        fontSize: "16px",
        color: "#000000",
        fontFamily: "'Poppins', sans-serif",
        margin: "0",
        paddingLeft: "20px",
        paddingBottom: "20px"
      },
      "& p": {
        fontFamily: "'Poppins', sans-serif",
        margin: "0",
        textAlign: "center",
        "& svg": {
          fontSize: "40px",
          color: "#1f7fb6",
          display: "block",
          margin: "0 auto",
        }
      },
    },
  closeBtn: {
    position: "absolute",
    top: "15px",
    background:" #e5e6eb",
    right: "15px",
    padding: "5px",
  },
  sidebarCustomScroll: {
    height: "calc(100vh - 118px) !important",
  },
  customScroll: {
    "& .ScrollbarsCustom-Track": {
      width: "4px !important",
      background: "transparent !important"
    }
  },
  sideBarWrapper: {
    paddingTop: "15px", 
    maxWidth: "25%",
    height: "100%",
    position:'sticky',
    top:'0',
    flex: "0 0 25%",
  },
  sideBar: {
    padding: "10px 5px 10px 15px",
    borderRadius: "10px",
    boxShadow: "1px 1px 3px rgb(0 0 0 / 20%)",
    backgroundColor: "#ffffff",
    fontFamily: "'Poppins', sans-serif",
    overflow: "hidden",
    "& h2": {
      fontSize: "18px",
      fontWeight: "600",
      margin: "0",
      fontFamily: "'Poppins', sans-serif",
      color: "#000105"
    }
  },
  sidebarTitle: {
    display: "flex",
    justifyContent: "space-between"
  },
  buttonNew: {
    border: "1px solid #1f7fb6",
    backgroundColor: "#e4e5e4",
    height: "40px",
    color: "#1f7fb6",
    fontSize: "16px",
    fontWeight: "700",
    borderRadius: "50px",
    boxShadow: "none !important",
    display: "inline-flex",
    alignItems: "center",
    "& .MuiButton-label": {
      textTransform: "none",
    },
    "& svg": {
      marginLeft: "5px",
      height: "23px"
    }
  },
  buttonMore: {
    width: "42px",
    height: "42px",
    backgroundColor: "#ebecec",
    color: "#1f7fb6",
    borderRadius: "5px",
    "@media(max-width: 1199px)": {
      width: "30px",
      minWidth: "30px",
      height: "30px",
      "& .MuiSvgIcon-root":{
        width: "13px",
        height: "13px",
      }
    },
  },
  accordionUser: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    boxSizing: "border-box",
    padding: "10px 15px 8px 3px",
  },
  activeUser: {
    borderRadius: "5px",
    backgroundColor: "#deedf4",
    overflow: "hidden",
    "& .MuiButtonBase-root": {
      backgroundColor: "#cddee9"
    }
  },
  titleTxt: {
    fontSize: "14px",
    color: "#101212",
    fontWeight: "700",
    paddingBottom: "15px",
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "& .MuiButton-text": {
      width: "100%",
    },
    "& .MuiButton-label": {
      textAlign: "left",
      display: "block",
    },
    "& .MuiAvatar-root": {
      width: "56px",
      height: "56px",
      backgroundColor: "#bcbeba",
      borderRadius: "50%",
      marginRight: "10px",
      "@media(max-width: 1199px)": {
        width: "40px",
        height: "40px",
      }
    },
    "& .MuiTypography-root": {
      textTransform: "none",
      fontSize: "14px",
      color: "#000307",
      fontWeight: "700",
      lineHeight: "1",
      textAlign: "left",
      "& span": {
        display: "block",
        fontSize: "10px",
        color: "#505452",
        fontWeight: "500",
        fontFamily: "'Roboto', sans-serif",
        marginTop: "10px",
      }
    },
  },
  chatAccordion: {
    paddingTop: "13px",
    marginTop: "15px",
    borderTop: "1px solid #dcdcd7",
  },
  btnMoreInfo: {
    minWidth: "49px",
    backgroundColor: "#d8dddd",
    height: "41px",
    fontSize: "16px",
    color: "#1f7fb6",
    fontWeight: "700",
    letterSpacing: "0",
    border: "1px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    textTransform: "none",
    margin: "0 0 0 4px",
    cursor: "pointer"
  },
  menuListData: {
    whiteSpace: "normal",
    width: "346px",
    backgroundColor: "#fffffe",
    borderRadius: "10px",
    position: "relative",
    marginTop: "20px",
    "& button": {
      width: "100%",
      padding: "5px 15px",
      "& h4": {
        display: "flex",
        alignItems: "center",
        fontSize: "16px",
        color: "#565a57",
        fontWeight: "700",
        fontFamily: "'Roboto', sans-serif",
        textTransform: "none",
        flexWrap: "wrap",
        margin: "0",
        width: "100%",
        "& img": {
          width: "24px",
          marginRight: "12px",
        }
      },
    },
  },
  triangle: {
    position: "absolute",
    width:"16px",
    height:"16px",
    borderBottom: "1px solid transparent",
    borderRight: "1px solid transparent",
    borderTop:"1px solid rgba(0,0,0,0.25)",
    borderLeft:"1px solid rgba(0,0,0,0.25)",
    transform:"rotate(45deg)",
    backgroundColor:"#fffffe",
    top: "-9px",
    right: "20px",
  },
  searchBar: {
    margin: "25px 0 10px",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: "0 10px",
      margin: "13px 0",
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ebecec",
      color: "#505452",
      borderRadius: "30px",
      cursor: "pointer",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    }
  },
  loader: {
    marginTop: '48%',
    marginLeft: '48%',
    color: '#0388c9',
    zIndex: 99,
  }
} as any;
// Customizable Area End
const mapDispatchToProps = (dispatch:any) => {
  return{
  addFriends: (friends:any) => {
  dispatch(GET_FRIENDSLIST(friends));
  }
 }
 }

const mapStateToProps = (state: any) => {
  //replace Reducer name with state.'Your Reducer name' and .property
  return {
    friendList: state?.takeAction?.friendsList[0],
  };
};

//@ts-ignore
const SidebarWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
export default withStyles(SidebarStyles)(SidebarWithRouter);
// export default  (withRouter(Navbar as any) as any)
