import React from "react";

//Customizable Area Start
import Button from "@material-ui/core/Button";
import { Formik, Form, Field, ErrorMessage } from "formik";

import PasswordRecoveryController, {
  Props,
} from "./PasswordRecoveryController.web";

//Customizable Area End

export default class PasswordRecovery extends PasswordRecoveryController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    return (
      <>
        <div>
          <Formik initialValues={{}} onSubmit={(e): any => this.doNext(e)}>
            {({ handleSubmit, isSubmitting }) => (
              //@ts-ignore
              <Form style={{ textAlign: "center" }}>
                <h1>deegee</h1>
                <b>Password recovery email has been sent.</b>
                <p>
                  A message was sent to your email *****@.com Follow the
                  instructions to reset your password.
                </p>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                  disabled={isSubmitting}
                >
                  Next
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </>
    );
  }
}
