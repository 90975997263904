import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import {withRouter} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";

class EmailAccountLogin extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    
    const { classes } = this.props
    return (
      <>
       <div className={classes.mainWrapper}>
         <div className={classes.container}>
           <div className={classes.comingSoonWrap}>
              <h1 className={classes.title}>deegee</h1>
              <h2 className={classes.comingSoonTitle}>We are coming soon</h2>
              <p className={classes.notifyText}>We are almost there! If you want to get notified when the website goes live.<br></br>Subscribe to our mailing list!</p>
              <Formik
                initialValues={{
                  password: "",
                  email: "",
                  remember: "",
                }}
                validationSchema={this.LoginSchema}
                onSubmit={(values): any => this.doLogin(values)}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  handleBlur,
                }) => (
                  //@ts-ignore
                  <Form className={classes.NotifyFrm}>
                      <div className={classes.formGrp}>
                        <div className={classes.formCol} >
                          <TextField
                            // error={touched.email && errors.email ? true : false}
                            id="email"
                            // className={classes.textField}
                            InputProps={{
                              className: classes.input,
                            }}
                            placeholder='Enter email'
                            variant="outlined"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            helperText={errors.email}
                            error={touched.email && errors.email ? true : false}
                            style={{ marginTop: "0", justifyContent: "center", display: "flex" }}
                            className={classes.textField}
                          />
                        </div>
                        <div className={classes.btnWrap} >
                          <Button type="submit" className={classes.notifyMe}>Notify Me!</Button>
                        </div>
                      </div>
                      <Button type="submit" className={classes.buttonOutline}>Tutorial</Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </>
    );
  }
} 
const EmailAccountLoginStyles = {
  mainWrapper: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    fontFamily: "'Poppins', sans-serif",
    overflowY:"auto",
    padding:"30px 0",
    color: "#fff",
    textAlign:"center",
  },
  container: {
    display: "flex",
    width: "100%",
    margin: "0 10px",
    justifyContent: "center",
    maxWidth:"1280px",
    alignItems:"center",
    "@media (max-width: 1024px)": {
      width: "100%",
      padding: "10px"
    },
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "20px",
    }
  },
  title:{
    fontSize: "72px",
    fontWeight: "500",
    margin:"0",
    lineHeight:"1.2",
  },
  comingSoonTitle:{
    fontSize: "52px",
    fontWeight: "500",
    margin:"20px 0 0 0",
    lineHeight:"1.2",
  },
  notifyText:{
    fontSize:"14px",
  },
  textField: {
    width: "100%",
    "& .MuiInputBase-root":{
      backgroundColor: "#fff !important",
    },
  },
  input: {
      borderRadius: "7px !important",
      margin: "5px 0 5px 0",
      backgroundColor: "#fff",
      "& input":{
        padding: "10px 17px",
        fontWeight: 500,
        fontSize: 15,
        color: "#a6a7af",
        backgroundColor: "#fff !important",
        borderRadius: 15,
      },
      "&::before":{
        borderBottom: "0 !important",
      },
      "&::after":{
        borderBottom: "0 !important",
      },
      "&::hover":{
        borderBottom: "0 !important",
      },
      "&::focus":{
        borderBottom: "0 !important",
      },
      "&::placeholder":{
        color: "#a6a7af",
        opacity: 1,
      }
    },
    formGrp:{
      display: "flex",
      margin: "10px 0",
      width: "100%",
      alignItems:"center",
      justifyContent:"space-between",
    },
    formCol:{
      width:"calc(100% - 130px)",
    },
    notifyMe:{
      whiteSpace:"nowrap",
      margin:"0 0 0 15px",
      color: "#fff",
      border: "1px solid white",
      padding:"0 25px",
      height: "37px",
      cursor: "pointer",
      textTransform:" none",
    },
    buttonOutline:{
      margin: "15px 0 0",
      color: "#fff",
      border: "1px solid white",
      padding:"0 25px",
      height: "44px",
      cursor: "pointer",
      textTransform:" none",
    },
} as any;
//@ts-ignore
const emailLoginWithRouter =withRouter(EmailAccountLogin)
export default withStyles(EmailAccountLoginStyles)(emailLoginWithRouter)