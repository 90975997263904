import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
toast.configure()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  allUsers:[]
  // Customizable Area Start
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiallUsersId:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      allUsers:[]
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    //@ts-ignore
    if(Cookies.get('Login_Token')){ 
     this.getAllUser()
    }else{
     //@ts-ignore
      this.props.history.push({
                pathname: '/login',
                state: { detail: "Something went wrong!! Please login to continue!!" }
      })
    }
    // Customizable Area End
  }
  // Customizable Area End
 
  getAllUser():boolean{
    const header = {
      "Content-Type":configJSON.exampleApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiallUsersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/user_list'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.apiallUsersId &&
            responseJson !== undefined
          ) {
              this.setState({allUsers:responseJson.data})
          }
        
        }
      } else {
       // this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
         this.parseApiCatchErrorResponse(errorReponse);
        if (errors) {
             if (errors[0].token === 'Invalid token' || errors[0].token==="Token has Expired" ) {
               toast.error(errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
            Cookies.remove('Login_Token')
             //@ts-ignore
             this.props.history.push({
              pathname: '/login'
            })
        } else if(errors[0].message)
             {
             }      
        }
        else {
               toast.error(errors,{position: toast.POSITION.BOTTOM_RIGHT})
             } 
      }
    }
  }
}
