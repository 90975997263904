import React from "react";

//Customizable Area Start
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik, Form, Field, ErrorMessage } from "formik";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";

import ResetPasswordController, { Props } from "./ResetPasswordController.web";
//@ts-ignore
import { ReactComponent as LogoTitle } from '../../logo-title.svg';
//Customizable Area End

class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    //@ts-ignore
    const { classes } = this.props
    return (
      <>
        <div className={classes.paper}>
          <Formik
            initialValues={{
              newPassword: "",
              reTypePassword: "",
            }}
            validationSchema={this.SubmitSchema}
            onSubmit={(values): any => this.doSubmit(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              handleBlur,
            }) => (
              //@ts-ignore
              <Form style={{ textAlign: "center" }}>
                <h1 className={classes.texHeaderStyle} > <LogoTitle /></h1>
                <b className={classes.texHeaderStyle}>Reset Password</b>
                <p>Please enter your new password below.</p>
                <TextField
                  id="newPassword"
                  label={this.labelPassword}
                  type={this.state.showNewPassword ? "text" : "password"}
                  variant="outlined"
                  name="newPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  helperText={errors.newPassword}
                  error={
                    touched.newPassword && errors.newPassword ? true : false
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={() =>
                            this.setState({
                              showNewPassword: !this.state.showNewPassword,
                            })
                          }
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showNewPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <br />
                <br />
                <TextField
                  id="reTypePassword"
                  label={this.labelRePassword}
                  type={this.state.showRetypePassword ? "text" : "password"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.reTypePassword}
                  name="reTypePassword"
                  helperText={errors.reTypePassword}
                  error={
                    touched.reTypePassword && errors.reTypePassword
                      ? true
                      : false
                  }
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={() => {
                            this.setState({
                              showRetypePassword: !this.state
                                .showRetypePassword,
                            });
                          }}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showRetypePassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  type="submit"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                  disabled={isSubmitting}
                  className={classes.cssButton}
                  style={{background:"#1f7fb6" }}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </>
    );
  }
}
const useStyles = {
    cssButton: {
     width: '470px',
     height: '50px',
    background: '#1f7fb6',
    marginTop: '20px',
    color: 'white',
    fontWeight: 'bold',
    },
  paper: {
    display: "flex",
    justifyContent:"center",
    width: '545.76px',
    height: '494.5px',
    background: '#fffffe!important',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '100px',
  },
  texHeaderStyle: {
   width:'auto',
    height: '58px',
    color: '#4d4e4c',
  fontFamily: 'Poppins',
  fontSize: '38.7826px',
  fontWeight: 600,
  lineHeight: '58px',
  },
  textStyle: {
  width:'auto',
  height: '30px',
  color: '#4d4e4c',
  fontFamily: 'Poppins',
  fontSize: '20px',
  fontWeight: 600,
    lineHeight: '30px',
  marginTop:'100px'
}
  } as any;

//@ts-ignore
const resetPassword = withRouter(ResetPassword)
export default withStyles(useStyles)(withRouter(resetPassword as any) as any)
