import React from "react";
import Slider from "react-slick";

// Customizable Area Start
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReCAPTCHA from "react-google-recaptcha";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { bgImage } from "./assets";
import {tutorialSliderFriend} from "./assets";
import {tutorialSliderLink} from "./assets";
import {tutorialSliderPost} from "./assets";
import {tutorialSliderSelection} from "./assets";
import {tutorialSliderUpload} from "./assets";
import { leftArrow } from "./assets";
import { rightArrow } from "./assets";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";

class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor (props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    const { classes } = this.props

    const redirectToLogin = () => {
      //@ts-ignore
      this.props.history.push('/login')
    }
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <>
        <div className={classes.mainWrapper} style={{}}>
          <div className={classes.container}>
            <div className={classes.leftBlock} style={{}}>
              <h1 className={classes.title}>deegee</h1>
              <h2 className={classes.welcomeTitle}>wagmi</h2>
              <span style={{ width: "100px", border: "2px solid #fff", display: "block", margin: "20px auto 30px auto" }}></span>
              <p>Social networking for NFT collectors. Showcase your <br></br>NFT's to the world around you.</p>
              <Button type="submit" onClick={() => this.setState({ openDialougtutorial: true })} className={classes.buttonOutline}>check out deegee
              </Button>
            </div>
            <div className={classes.rightBlock}>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  password: "",
                  repeatPassword: "",
                  email: "",
                  recaptcha: "",
                  termsAndcondition: "",
                }}
                validationSchema={this.SignupSchema}
                onSubmit={(values): any => this.doSignup(values)}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  handleBlur,
                }) => (
                  //@ts-ignore
                  // <div style={{padding:"30px"}}>
                  <Form className={classes.registerFrm}>
                    <h2 className={classes.formHead1} style={{ margin: "0 0 20px 0" }}>Sign Up</h2>
                    <p className={classes.formHead}>gm</p>
                    <p className={classes.formTxt}>To create your account please enter following information</p>
                    <div className={classes.mainFrmCard}>
                      <div className={classes.formGrp}>
                        <div className={classes.formCol} >
                          <label className={classes.formLabel}>First Name</label>
                          <TextField
                            error={touched.firstName && errors.firstName ? true : false}
                            value={values.firstName}
                            // label={this.labelFirstName}
                            className={classes.textField}
                            InputProps={{
                              className: classes.input,
                            }}
                            placeholder='First name'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="firstName"
                            id="firstName"
                            helperText={errors.firstName}
                            variant="outlined"
                          />
                        </div>
                        <div className={classes.formCol + " " + classes.ml}>
                          <label className={classes.formLabel}>Last Name</label>
                          <TextField
                            error={touched.lastName && errors.lastName ? true : false}
                            id="lastName"
                            className={classes.textField}
                            InputProps={{
                              className: classes.input,
                            }}
                            placeholder='Last name'
                            variant="outlined"
                            name="lastName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                            helperText={errors.lastName}
                          />
                        </div>
                      </div>
                      <div className={classes.formGrp}>
                        <div className={classes.formCol} >
                          <label className={classes.formLabel}>Email Address</label>
                          <TextField style={{ width: "100%" }}
                            error={touched.email && errors.email ? true : false}
                            id="email"
                            placeholder='Enter email address'
                            className={classes.textField}
                            InputProps={{
                              className: classes.input,
                            }}
                            variant="outlined"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            helperText={errors.email}
                          />
                        </div>
                      </div>
                      <div className={classes.formGrp}>
                        <div className={classes.formCol}>
                          <label className={classes.formLabel} style={{ paddingBottom: "6px" }}>Password</label>
                          <TextField
                            error={touched.password && errors.password ? true : false}
                            id="password"
                            className={classes.textField}
                            InputProps={{
                              className: classes.input,
                            }}
                            placeholder='Password'
                            variant="outlined"
                            name="password"
                            onChange={handleChange}
                            value={values.password}
                            onBlur={handleBlur}
                            helperText={errors.password}
                            type="password"
                          />
                        </div>

                        <div className={classes.formCol + " " + classes.ml}>
                          <label className={classes.formLabel}>Repeat Password</label>
                          <TextField
                            error={
                              touched.repeatPassword && errors.repeatPassword
                                ? true
                                : false
                            }
                            id="repeatPassword"
                            className={classes.textField}
                            InputProps={{
                              className: classes.input,
                            }}
                            placeholder='Repeat password'
                            variant="outlined"
                            name="repeatPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.repeatPassword}
                            value={values.repeatPassword}
                            type="password"
                          />
                        </div>
                      </div>
                      <div className={classes.recaptchaBtn}>
                        <ReCAPTCHA
                          sitekey={this.state.siteKeyOfCaptcha}
                          onChange={(value: any) =>
                            this.onRecaptchaChange(value)
                              ? this.setState({
                                reCaptchaVarified: true,
                              })
                              : this.setState({
                                reCaptchaVarified: false,
                              })
                          }
                        />
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        <Button
                          className={classes.primaryBtn}
                          type="submit"
                          onClick={(e) => {
                            handleSubmit();
                          }}
                          id="submitBTN"
                          disabled={this.checkDisabled(
                            errors,
                            this.state.reCaptchaVarified
                          )}
                        >
                          Sign up
                        </Button>
                      </div>
                    </div>
                    <span className={classes.loginLink}>Already have an account?<span className={classes.loginText} onClick={() => redirectToLogin()}>&nbsp;Login</span></span>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <Dialog open={this.state.openDialougtutorial}
                  onClose={() => this.setState({ openDialougtutorial: false })}
                  aria-labelledby="responsive-dialog-title" className={classes.tutorialPopup}>
                  <DialogContent>
                    <IconButton className={classes.closeTutorialBtn} onClick={() => this.setState({ openDialougtutorial: false })}>
                        <CloseIcon />
                      </IconButton>
                    <div>
                      <Slider {...settings}>
                        <div>
                          <img src={tutorialSliderFriend} />
                        </div>
                        <div>
                          <img src={tutorialSliderUpload} />
                        </div>
                        <div>
                          <img src={tutorialSliderLink} />
                        </div>
                        <div>
                          <img src={tutorialSliderSelection} />
                        </div>
                        <div>
                          <img src={tutorialSliderPost} />
                        </div>
                      </Slider>
                    </div>
                  </DialogContent>
                </Dialog>
        </div>
      </>
    );
  }
}

const EmailAccountStyles = {
  mainWrapper: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    fontFamily: "'Poppins', sans-serif",
    overflowY: "auto",
    padding: "30px 0",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    background: "url(" + bgImage + ")",
    "& .MuiFormHelperText-contained": {
      margin: "0"
    }
  },
  tutorialPopup: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(255,255,255,0.6)"
    },
    "& .slick-dots": {
      display: "none",
    },
    "& .MuiDialog-paper": {
      maxWidth: "100%",
      width: "100%",
      height: "100%",
      margin: "0",
      maxHeight: "100%",
      background: "transparent"
    },
    "& .MuiDialogContent-root": {
      padding: "0 !important",
      overflow: "hidden"
    },
    "& .slick-slide":{
      "& img":{
        width: "100%",
        height: "100vh",
        objectFit:"cover",
      }
    },
    "& .slick-prev":{
      left: "15px",
      height: "60px",
      width: "60px",
      zIndex: "9",
      backgroundImage: "url(" + leftArrow + ")",
      backgroundPosition: "center center",
      backgroundSize: "contain",
      backgroundRepeat:"no-repeat", 
      "&:before": {
        display:"none",
      }
    },
    "& .slick-next":{
      right: "15px",
      height: "60px",
      width: "60px",
      zIndex: "9",
      backgroundImage: "url(" + rightArrow + ")",
      backgroundPosition: "center center",
      backgroundSize: "contain",
      backgroundRepeat:"no-repeat", 
      "&:before": {
        display:"none",
      }
    },
  },
  closeTutorialBtn: {
    position: "absolute",
    background: "#ffffff !important",
    zIndex: "9",
    right: "10px",
    top: "15px",
    color: "#1f7fb6",
    width: "60px",
    height: "60px",
    "& .MuiSvgIcon-root":{
      width:"100%",
      height:"100%",
    },
  },
  buttonOutline:
  {
    marginTop: "45px",
    color: "#fff",
    border: "1px solid white",
    padding: "0 25px",
    height: "44px",
    cursor: "pointer",
    textTransform: " none",
  },
  MuiTypographyBody1: {
    margin: '0px',
    "& .MuiTypography-root": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#9E9F9E",
    }
  },
  container: {
    display: "flex",
    width: "100%",
    margin: "0 10px",
    justifyContent: "center",
    maxWidth: "1280px",

    "@media (max-width: 767px)": {
      flexDirection: "column",
      justifyContent: "flex-start",

    },
    "@media (max-width: 1024px)": {
      width: "100%",
      padding: "10px"
    },

    "& .MuiOutlinedInput-root":
    {
      borderRadius: "20px",
    }
  },
  title:
  {
    fontSize: "72px",
    fontWeight: "500",
    margin: "0",
    "@media (max-width: 767px)": {
      fontSize: "42px",
    }
  },
  welcomeTitle:
  {
    fontSize: "46px",
    fontWeight: "500",
    margin: "20px 0 0 0",
    "@media (max-width: 767px)": {
      fontSize: "36px",
    }
  },
  leftBlock:
  {
    textAlign: "center", color: "#fff",
    width: "50%",
    paddingTop: "50px",
    "@media (max-width: 767px)": {
      width: "100%",
    }
  },
  rightBlock: {
    width: "50%",
    "@media (max-width: 767px)": {
      display: "flex",
      justifyContent: "center",
      paddingLeft: "0",
      margin: "30px 0",
      width: "100%",
    },
  },
  registerFrm: {
    backgroundColor: "white",
    padding: "20px 40px",
    margin: "20px auto",
    borderRadius: "20px",
    width: "80%",
    color: "#4D4E4C",
    maxWidth: "450px",
    "@media (max-width: 767px)": {
      padding: "30px",
      width: "100%",
      margin: "7px auto",
    }
  },

  formHead1: {
    fontSize: "26px",
    fontWeight: "600",
    margin: "0",
  },
  formHead: {
    fontSize: "18px",
    fontWeight: "500",
    margin: "0",
  },
  formTxt: {
    fontSize: "14px",
    color: "#9E9F9E",
    margin: "0 0 10px 0"
  },
  mainFrmCard:
  {
    display: "flex",
    borderRadius: "20px",
    flexWrap: "wrap",
  },
  recaptchaBtn: {
    display: "flex", justifyContent: "center", alignItems: "center", width: "100%", margin: "15px 0",
  },
  textField: {
    width: "100%",
  },
  input: {
    borderRadius: "7px !important",
    margin: "5px 0 5px 0",
    backgroundColor: "#fff",
    "& input":
    {
      padding: "10px 17px",
      fontWeight: 500,
      fontSize: 15,
      color: "#a6a7af",
      backgroundColor: "#fff !important",
      borderRadius: 15,
    },
    "& textarea":
    {
      padding: "10px 17px",
      fontWeight: 500,
      fontSize: 15,
      color: "#a6a7af",
    },
    "&::before":
    {
      borderBottom: "0 !important",
    },
    "&::after":
    {
      borderBottom: "0 !important",
    },
    "&::hover":
    {
      borderBottom: "0 !important",
    },
    "&::focus":
    {
      borderBottom: "0 !important",
    },
    "&::placeholder":
    {
      color: "#a6a7af",
      opacity: 1,
    }
  },
  formGrp:
  {
    display: "flex",
    marginTop: "10px",
    width: "100%",
    "@media (max-width: 767px)": {
      flexDirection: "column",
      flexWrap: "wrap",
    },
  },
  formLabel:
  {
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "10px",
    whiteSpace: "nowrap",
    "@media (max-width: 1024px)": {
      fontSize: 12,
    },
  },
  formCol:
  {
    width: "100%",
    marginBottom: "10px",
  },
  ml: {
    marginLeft: "15px",
    "@media (max-width: 767px)": {
      marginLeft: "0",
    },
  },
  loginLink: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: '14px',
    marginTop: '8px'
  },
  loginText: {
    color:'#1882AD',
    fontWeight: 600,
    cursor: "pointer"
  },
  primaryBtn: {
    backgroundColor: "#1882AD",
    color: "#ffffff",
    padding: "10px 30px",
    textTransform: "none",
    fontSize: "16px",
    fontFamily: "'Poppins', sans-serif",
    marginTop: "3px !important"
  },
  termsText: {
    fontSize: '9.5px',
    fontWeight: 550,
    fontFamily: "'Poppins', sans-serif",
  }
} as any;
//@ts-ignore
const emailRegistrtinWithRouter = withRouter(EmailAccountRegistration)
//@ts-ignore
export default withStyles(EmailAccountStyles)(emailRegistrtinWithRouter)
