Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TargetedFeed";
exports.labelBodyText = "TargetedFeed Body";

exports.homeTutorialsKey = "Home-All"
exports.trendingTutorialsKey = "Trending-Post"
exports.createPostNftsTutorialsKey="CreatePost-NFTs"
exports.createPostUploadTutorialsKey="CreatePost-Upload"
exports.musicTypes = ['mp3', 'wav']
exports.videoTypes = ['mp4', 'ogg', 'webm']
exports.imageTypes = ['jpg', 'png', 'gif', 'svg']
exports.object3dTypes = ['glb', 'gltf']
exports.ENTER_KEY = "Enter"

exports.OPEN_SEA_API_KEY = "1bfb7e45af9b43ab87e729d64508b8d5"
exports.VALID_NFT_SCHEMA = "ERC721"

exports.btnExampleTitle = "CLICK ME";
exports.metamaskWalletEndpoint="bx_block_cfmetamask/wallets"
exports.confirmNFTEndPoint = "bx_block_posts/posts"
exports.myInfoApiEndPoint = "bx_block_profile/profiles/user_profile"
exports.httpGetMethod = "GET";
exports.myMetamaskEndPoint = "bx_block_cfmetamask/wallets"
exports.friendsListEndPoints = "bx_block_conn/connections/friend_lists"
exports.allMyNftPostEndPoint = "bx_block_posts/posts/my_posts"
exports.deletePostEndPoint = "bx_block_posts/posts"
exports.updatePostEndPoint='bx_block_posts/posts/'
exports.likePostEndPoint = "post"
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpDeleteMethod="DELETE"
exports.btnExampleTitle = "CLICK ME";
exports.chatAuthTokenEndPoint = "bx_block_chat/tokens";
exports.ContentType = "application/json";
exports.readNotificationEndPoint="bx_block_notifications/notifications/read_notification"
exports.searchFriendENdpoint="bx_block_conn/connections/search_friend?query"
exports.allPostApiEndPoint="posts/posts?page="
exports.createCommentEndPoint="comments/comments"
exports.commentdataEndPoint="post/"
exports.commentReplyEndPoint="comments/"
exports.allTrendPostEndPoint='bx_block_posts/posts/trending_posts?page='
exports.showMainPostEndPoint="bx_block_posts/posts/"
exports.reportPostEndPoint="bx_block_contentflag/report_posts"
exports.reportReasonsEndPoint="bx_block_contentflag/report_reasons"
exports.unfriendEndPoint = 'bx_block_conn/connections'
exports.sendAcceptReqEndPoints="bx_block_conn/connections/accept_friend_request"
exports.searchHistoryEndPoint="account_block/accounts/search_history"
exports.deleteHistory="bx_block_searchhistory/search_histories/"
exports.newChatUsersEndpoint="chat/chats/search?query"
exports.blockUserEndPoint='bx_block_block_users/block_users?account_id='
exports.completedTutorialsEndPoint="account_block/accounts/get_tutorial"
exports.updateTutorialsEndPoint="account_block/accounts/update_tutorial"
exports.notificationsCountEndPoint="bx_block_notifications/notifications/badge_count"
exports.notificationsEndPoint="bx_block_notifications/notifications"
exports.advanceSearchEndPoint="account_block/accounts/search?query="
exports.editCommentEndPoint="bx_block_comments/comments/edit_comment"
exports.deleteCommentEndPoint="bx_block_comments/comments/delete_comment"
// Customizable Area End