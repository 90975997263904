import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import Cookies from "js-cookie";
const Chat = require("twilio-chat");
import React from "react";
// import { Client, Conversation, Participant, Message, User } from '@twilio/conversations';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  receiveId: any
  accountId: any
  callChatUserJoinFunc: any
  userName: any
  chatUserList: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  chatAuthToken: string;
  loading: any;
  messages: any;
  channel: any;
  text: any;
  userProfileInfo: any;
  userProfileImage: any
  userFullName: any;
  recievedId: any;
  accountId: any;
  loadMessages: boolean;
  mediaMesg: [];
  imageFile: any;
  joinClient: boolean;
  prevRoomId: any;
  tempImgUrl: any;
  setDeletingImg: any
  getTokenCall: boolean;
  allChannels: any
  userEmail: any
  allUserActive: any
  useProfileId: any
  checkPro: any
  newUser: any
  one: any
  typingStart: boolean
  lastConsumedMessageIndex: any
  setConsumeMessage: boolean;
  cheking: any
  keepFocus: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class chatOneToOneController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  ChatAuthTokenApiCallId: any;
  Chat_Token: string;
  Token: string;
  allFirendsApiId: any;
  scrollDiv: React.RefObject<any>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      chatAuthToken: "",
      loading: false,
      messages: [],
      channel: "",
      text: "",
      userProfileInfo: [],
      userProfileImage: "",
      userFullName: "",
      accountId: '',
      recievedId: '',
      loadMessages: true,
      mediaMesg: [],
      imageFile: '',
      joinClient: false,
      prevRoomId: '',
      tempImgUrl: '',
      setDeletingImg: '',
      getTokenCall: true,
      allChannels: [],
      userEmail: '',
      allUserActive: [],
      useProfileId: '',
      checkPro: '',
      newUser: '',
      one: '',
      typingStart: false,
      lastConsumedMessageIndex: '',
      setConsumeMessage: true,
      cheking: '',
      keepFocus: ''
      // Customizable Area End
    };
    //@ts-ignore
    this.scrollDiv = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End

  }
  // Customizable Area Start
  componentDidMount = async () => {
    if (Cookies.get('Login_Token')) {
      this.generateChatAuthToken()
      //@ts-ignore
      if (this.props.deleteUserEmail) {
        //@ts-ignore
        this.props.deleteUserEmail()
      }

      //@ts-ignore

      const accountId = this.props?.location?.state?.accountId
      const receiveId = this.props?.location?.state?.receiveId
      this.setState({ recievedId: receiveId })

      if (receiveId)
        this.getFriendsList(receiveId)
      //@ts-ignore
      if (Number(accountId) && Number(receiveId)) {
        let roomId = ""
        if (accountId > receiveId) {
          roomId = receiveId + '_' + accountId
          // this.setState({roomId:roomId})
        } else {
          roomId = accountId + '_' + receiveId
          //this.setState({roomId:roomId})
        }

        this.setState({ prevRoomId: roomId })
        //@ts-ignore
        if (this.props.list) {
          //this.createClientComponent()
          let check = 0;
          //@ts-ignore
          const client = this.props.list


          client.on("channelInvited", async (income: any) => {

            setTimeout(() => {
              this.setState({ checkPro: income })
            }, 2000);



          });
          client.on("messageAdded", async (income: any) => {
            this.setState({ checkPro: income })


          });

          let channels = ''

          try {
            channels = await client.getChannelByUniqueName(roomId);
            //@ts-ignore
            const memb = await channels.getMembers()
            if (memb.length === 1 && this.state.userEmail) {
              try {

                //@ts-ignore
                await channels.invite(this.state.userEmail)
              } catch (error) {
                console.log("error", error);

              }
            }
            //@ts-ignore
            if (!this.props?.chatUserList?.some(e => e?.email === this.state.userEmail) && this.state.userEmail) {
              //@ts-ignore
              this.props.addInvitedChannels({ profile: this.state?.userProfileImage, name: this.state.userFullName, userId: this.state?.useProfileId, email: this.state?.userEmail })
              let userObj = {
                userId: this.state?.recievedId,
                lastMsg: '',
                lastConsumedMessageIndex: '',
                identity: Cookies.get("Login_Email"),
                dateUpdated: '',
                allMsgIndex: 0
              }
              //@ts-ignore
              this.props?.addNewUser(userObj)
            }
            this.setState({ channel: channels });
            if (this.state.allChannels.length !== 0) {
              this.state.allChannels.forEach((element: any) => {//@ts-ignore
                if (element.channelState.uniqueName === channels.channelState.uniqueName) {
                  check = 1
                }
              })
            }
            if (check === 0) {
              await this.joinChannel(channels);
            }
            //@ts-ignore
            const messages = await channels.getMessages();
            this.setState({ allChannels: [...this.state.allChannels, channels] })

            // this.setState({ channel: channels});
            messages.items.forEach((element: any, index: number) => {

              if (element.state.type === "media") {
                // this.getImgUrl(element.state.media)

                element.state.media.getContentTemporaryUrl().then(function (url: any) {
                  //@ts-ignore
                  document.getElementById('msgImG' + index)?.setAttribute("src", url)
                });
              }

            });

            this.setState({ messages: messages.items || [] });
            if (messages.items.length === 0) {
              this.setState({ loadMessages: false })
            }
            //@ts-ignore
            channels.on("memberUpdated", async (joinedChannel: any) => {


              let messageOwner = Cookies.get("Login_Email")
              if (joinedChannel?.member?.identity != messageOwner) {
                // await joinedChannel?.member?.channel?.setAllMessagesConsumed()

                this.setState({ lastConsumedMessageIndex: joinedChannel?.member?.lastConsumedMessageIndex })
              }

            });
            // @ts-ignore
            await channels.setAllMessagesConsumed()
            //@ts-ignore
            this.props?.removeBadge(receiveId)
            //@ts-ignore
            const member = await channels.getMemberByIdentity(this.state?.userEmail)

            this.setState({ lastConsumedMessageIndex: member?.lastConsumedMessageIndex })
            this.scrollToBottom();
          } catch (error) {

            this.setState({ messages: [], loadMessages: false });
            const channel = await client.createChannel({
              uniqueName: roomId,
              friendlyName: roomId,
            });
            await this.joinChannel(channel);
            this.setState({ channel: channel });
            this.setState({ allChannels: [...this.state.allChannels, channel] })
            if (this.state.userEmail) {
              await channel.invite(this.state.userEmail)
              const obj = [{ profile: this.state?.userProfileImage, name: this.state.userFullName, userId: this.state?.useProfileId, email: this.state?.userEmail }, {
                //@ts-ignore
                profile: this.props?.UserInfo[0]?.image, name: this.props?.UserInfo[0]?.fullName, userId: accountId, email: Cookies.get("Login_Email")
              }]
              await channel.updateAttributes({ obj })
              //@ts-ignore
              this.props.addInvitedChannels({ profile: this.state?.userProfileImage, name: this.state.userFullName, userId: this.state?.useProfileId, email: this.state?.userEmail })
              let userObj = {
                userId: this.state?.recievedId,
                lastMsg: '',
                lastConsumedMessageIndex: '',
                identity: Cookies.get("Login_Email"),
                dateUpdated: '',
                allMsgIndex: 0
              }
              //@ts-ignore
              this.props?.addNewUser(userObj)


            }

          }

        } else {
          await this.createClientComponent()
          let check = 0;
          //@ts-ignore
          const client = this.props.list
          client.on("channelInvited", async (income: any) => {

            this.setState({ checkPro: income })

          });

          client.on("messageAdded", async (income: any) => {
            this.setState({ checkPro: income })

          });

          let channels = ''
          try {
            channels = await client.getChannelByUniqueName(roomId);
            //@ts-ignore
            const memb = await channels.getMembers()
            if (memb.length === 1 && this.state.userEmail) {
              try {

                //@ts-ignore
                await channels.invite(this.state.userEmail)
              } catch (error) {
                console.log("error", error);

              }
            }
            //@ts-ignore
            if (!this.props?.chatUserList?.some(e => e?.email === this.state.userEmail) && this.state.userEmail) {
              //@ts-ignore
              this.props.addInvitedChannels({ profile: this.state?.userProfileImage, name: this.state.userFullName, userId: this.state?.useProfileId, email: this.state?.userEmail })
              let userObj = {
                userId: this.state?.recievedId,
                lastMsg: '',
                lastConsumedMessageIndex: '',
                identity: Cookies.get("Login_Email"),
                dateUpdated: '',
                allMsgIndex: 0
              }
              //@ts-ignore
              this.props?.addNewUser(userObj)
            }
            this.setState({ channel: channels });


            if (this.state.allChannels.length !== 0) {
              this.state.allChannels.forEach((element: any) => {//@ts-ignore
                if (element.channelState.uniqueName === channels.channelState.uniqueName) {
                  check = 1
                }
              })
            }
            if (check === 0) {
              await this.joinChannel(channels);
            }
            //@ts-ignore
            const messages = await channels.getMessages();
            this.setState({ allChannels: [...this.state.allChannels, channels] })

            // this.setState({ channel: channels});
            messages.items.forEach((element: any, index: number) => {

              if (element.state.type === "media") {
                // this.getImgUrl(element.state.media)

                element.state.media.getContentTemporaryUrl().then(function (url: any) {
                  //@ts-ignore
                  document.getElementById('msgImG' + index)?.setAttribute("src", url)
                });
              }

            });

            this.setState({ messages: messages.items || [] });
            if (messages.items.length === 0) {
              this.setState({ loadMessages: false })
            }
            //@ts-ignore
            channels.on("memberUpdated", async (joinedChannel: any) => {


              let messageOwner = Cookies.get("Login_Email")
              if (joinedChannel?.member?.identity != messageOwner) {

                this.setState({ lastConsumedMessageIndex: joinedChannel?.member?.lastConsumedMessageIndex })
              }

            });
            // @ts-ignore
            await channels.setAllMessagesConsumed()
            //@ts-ignore
            this.props?.removeBadge(receiveId)
            //@ts-ignore
            const member = await channels.getMemberByIdentity(this.state?.userEmail)

            this.setState({ lastConsumedMessageIndex: member?.lastConsumedMessageIndex })
            this.scrollToBottom();
          } catch (error) {
            this.setState({ messages: [], loadMessages: false });
            const channel = await client.createChannel({
              uniqueName: roomId,
              friendlyName: roomId,
            });
            await this.joinChannel(channel);
            this.setState({ channel: channel });
            this.setState({ allChannels: [...this.state.allChannels, channel] })
            if (this.state.userEmail) {

              await channel.invite(this.state.userEmail)
              const obj = [{ profile: this.state?.userProfileImage, name: this.state.userFullName, userId: this.state?.useProfileId, email: this.state?.userEmail }, {
                //@ts-ignore
                profile: this.props?.UserInfo[0]?.image, name: this.props?.UserInfo[0]?.fullName, userId: accountId, email: Cookies.get("Login_Email")
              }]
              await channel.updateAttributes({ obj })

              //@ts-ignore
              this.props?.addInvitedChannels({ profile: this.state?.userProfileImage, name: this.state.userFullName, userId: this.state?.useProfileId, email: this.state?.userEmail })
              let userObj = {
                userId: this.state?.recievedId,
                lastMsg: '',
                lastConsumedMessageIndex: '',
                identity: Cookies.get("Login_Email"),
                dateUpdated: '',
                allMsgIndex: 0
              }
              //@ts-ignore
              this.props?.addNewUser(userObj)


            }
          }
          // //@ts-ignore 
          // if(this.props?.chatUserList?.length ===0 && this.props?.lastMegs?.length===0){
          //   this.checkAllChatUsers()
          // }
        }
        //@ts-ignore
        if (this.props.deleteActiveUser) {
          //@ts-ignore
          this.props.deleteActiveUser()
        }
        this.getActiveness()
      }
    }
  };

  getActiveness = async () => {
    //@ts-ignore
    if (this.props.list) {
      //@ts-ignore
      const client = this.props.list
      const allChannel = await client?.channels?.getChannels()

      try {
        //@ts-ignore
        allChannel?.items?.forEach(async (element: any) => {

          let email = Cookies.get('Login_Email')
          const member = await element?.getUserDescriptors()


          if (member?.state?.items[0]?.descriptor?.identity != email) {

            //@ts-ignore
            if (this.props.addActiveUser && member?.state?.items[0]?.descriptor != null && member?.state?.items[0]?.descriptor != '' && member?.state?.items[0]?.descriptor != undefined && (!this.props.activeUser.some(e => e?.identity === member?.state?.items[0]?.descriptor?.identity))) {
              //@ts-ignore
              this.props.addActiveUser(member?.state?.items[0]?.descriptor)
            }

          } else if (member?.state?.items[1]?.descriptor?.identity != email) {

            //@ts-ignore
            if (this.props.addActiveUser && member?.state?.items[1]?.descriptor != null && member?.state?.items[1]?.descriptor != '' && member?.state?.items[1]?.descriptor != undefined && (!this.props.activeUser.some(e => e?.identity === member?.state?.items[1]?.descriptor?.identity))) {
              //@ts-ignore
              this.props.addActiveUser(member?.state?.items[1]?.descriptor)
            }

          }
        });

      } catch (error) {
        console.log("error", error);
      }

    } else {
      await this.createClientComponent()
      //@ts-ignore
      const client = this.props.list
      const allChannel = await client?.channels?.getChannels()
      try {
        //@ts-ignore
        allChannel?.items?.forEach(async (element: any) => {
          let email = Cookies.get('Login_Email')
          const member = await element?.getUserDescriptors()

          if (member?.state?.items[0]?.descriptor?.identity != email) {

            //@ts-ignore
            if (this.props.addActiveUser && member?.state?.items[0]?.descriptor != null && member?.state?.items[0]?.descriptor != '' && member?.state?.items[0]?.descriptor != undefined) {
              //@ts-ignore
              this.props.addActiveUser(member?.state?.items[0]?.descriptor)
            }
          } else if (member?.state?.items[1]?.descriptor?.identity != email) {

            //@ts-ignore
            if (this.props.addActiveUser && member?.state?.items[1]?.descriptor != null && member?.state?.items[1]?.descriptor != '' && member?.state?.items[1]?.descriptor != undefined) {
              //@ts-ignore
              this.props?.addActiveUser(member?.state?.items[1]?.descriptor)
            }
          }
        });

      } catch (error) {
        console.log("error", error);
      }
    }
  }


  callcomponentDidMountFromChat = async (receipientId?: any, accountId?: any) => {
    this.setState({ newUser: "" })
    this.setState({ setConsumeMessage: true })
    let Id = ""
    if (Number(accountId) && Number(receipientId)) {
      if (accountId > receipientId) {
        Id = receipientId + '_' + accountId
        //this.setState({roomId:roomId})
      } else {
        Id = accountId + '_' + receipientId
        // this.setState({roomId:roomId})
      }
    }
    if (this.state.prevRoomId !== Id) {
      this.setState({ messages: [], joinClient: false })

      if (receipientId) {
        this.getFriendsList(receipientId)
      }
      this.setState({ recievedId: receipientId })
      //@ts-ignore
      if (accountId && receipientId) {
        let roomId = ""
        if (accountId > receipientId) {
          roomId = receipientId + '_' + accountId
          //this.setState({roomId:roomId})
        } else {
          roomId = accountId + '_' + receipientId
          // this.setState({roomId:roomId})
        }

        this.setState({ prevRoomId: roomId })
        //@ts-ignore
        if (this.props.list) {
          let check = 0;
          //@ts-ignore
          const client = this.props.list
          client.on("channelInvited", async (income: any) => {
            setTimeout(() => {
              this.setState({ checkPro: income })
            }, 2000);


          });

          client.on("messageAdded", async (income: any) => {
            this.setState({ checkPro: income })

          });

          let channels = ''
          try {
            channels = await client.getChannelByUniqueName(roomId);
            //@ts-ignore
            //@ts-ignore
            const memb = await channels.getMembers()
            if (memb.length === 1 && this.state.userEmail) {
              try {

                //@ts-ignore
                await channels.invite(this.state.userEmail)
              } catch (error) {
                console.log("error", error);

              }
            }
            //@ts-ignore
            if (!this.props?.chatUserList?.some(e => e?.email === this.state.userEmail) && this.state.userEmail) {
              //@ts-ignore
              this.props.addInvitedChannels({ profile: this.state?.userProfileImage, name: this.state.userFullName, userId: this.state?.useProfileId, email: this.state?.userEmail })
              let userObj = {
                userId: this.state?.recievedId,
                lastMsg: '',
                lastConsumedMessageIndex: '',
                identity: Cookies.get("Login_Email"),
                dateUpdated: '',
                allMsgIndex: 0
              }
              //@ts-ignore
              this.props?.addNewUser(userObj)
            }
            this.setState({ channel: channels });
            if (this.state.allChannels.length !== 0) {
              this.state.allChannels.forEach((element: any) => {//@ts-ignore
                if (element.channelState.uniqueName === channels.channelState.uniqueName) {
                  check = 1
                }
              })
            }
            if (check === 0) {
              await this.joinChannel(channels);
            }

            //@ts-ignore
            const messages = await channels.getMessages();
            this.setState({ allChannels: [...this.state.allChannels, channels] })

            this.setState({ channel: channels });
            messages.items.forEach((element: any, index: number) => {

              if (element.state.type === "media") {
                // this.getImgUrl(element.state.media)

                element.state.media.getContentTemporaryUrl().then(function (url: any) {
                  //@ts-ignore
                  document.getElementById('msgImG' + index)?.setAttribute("src", url)
                });
              }

            });

            this.setState({ messages: messages.items || [] });
            if (this.state.messages.length === 0) {
              this.setState({ loadMessages: false })
            } else {
              this.setState({ loadMessages: true })
            }
            //@ts-ignore
            channels.on("memberUpdated", async (joinedChannel: any) => {


              let messageOwner = Cookies.get("Login_Email")
              if (joinedChannel?.member?.identity != messageOwner) {
                // await joinedChannel?.member?.channel?.setAllMessagesConsumed()


                this.setState({ lastConsumedMessageIndex: joinedChannel?.member?.lastConsumedMessageIndex })
              }

            });
            // @ts-ignore
            await channels.setAllMessagesConsumed()
            //@ts-ignore
            this.props?.removeBadge(receipientId)
            //@ts-ignore
            const member = await channels.getMemberByIdentity(this.state?.userEmail)

            this.setState({ lastConsumedMessageIndex: member?.lastConsumedMessageIndex })
            this.scrollToBottom();

          } catch (error) {
            this.setState({ messages: [], loadMessages: false });
            const channel = await client.createChannel({
              uniqueName: roomId,
              friendlyName: roomId,
            });
            await this.joinChannel(channel);
            this.setState({ channel: channel });
            this.setState({ allChannels: [...this.state.allChannels, channel] })
            if (this.state.userEmail) {
              await channel.invite(this.state.userEmail)
              const obj = [{ profile: this.state?.userProfileImage, name: this.state.userFullName, userId: this.state?.useProfileId, email: this.state?.userEmail }, {
                //@ts-ignore
                profile: this.props?.UserInfo[0]?.image, name: this.props?.UserInfo[0]?.fullName, userId: accountId, email: Cookies.get("Login_Email")
              }]
              await channel.updateAttributes({ obj })

              //@ts-ignore
              this.props?.addInvitedChannels({ profile: this.state?.userProfileImage, name: this.state.userFullName, userId: this.state?.useProfileId, email: this.state?.userEmail })
              let userObj = {
                userId: this.state?.recievedId,
                lastMsg: '',
                lastConsumedMessageIndex: '',
                identity: Cookies.get("Login_Email"),
                dateUpdated: '',
                allMsgIndex: 0
              }
              //@ts-ignore
              this.props?.addNewUser(userObj)


            }

          }
        }
      }
    }

  }
  //@ts-ignore
  componentWillUnmount = () => {
    //@ts-ignore
    this.state.channel = ''


  }
  createClientComponent = async (roomId?: any) => {

    let token = "";

    // this.setState({ loading: true });
    try {
      token = Cookies.get("Chat_Token") || await this.getToken();
    } catch {
      throw new Error("unable to get token, please reload this page");
    }
    // const userId=Cookies.get("Login_Id")
    const client = await Chat.Client.create(token);

    //@ts-ignore
    if (this.props.addClient) {
      //@ts-ignore
      this.props.addClient(client)
    }

  }
  fileUploadHandler = async (e: React.FormEvent<EventTarget>) => {
    this.state?.keepFocus?.focus()

    let target = e.target as HTMLInputElement;
    let files = target.files as any;

    if (files.length !== 0 && files.length < 11) {

      let name = ''
      let urlArr = [];
      let fileName = [];
      for (const key in files) {

        if (typeof files[key] === 'object' && files[key].name) {
          let url = URL.createObjectURL(files[key])
          urlArr.push(url)
          fileName.push(files[key].name)
          name = files[key].name + " " + name
        }
      }
      let allImg = {
        urlArr,
        fileName
      }

      this.setState({ imageFile: files, tempImgUrl: allImg })
    } else {
      if (files.length > 10) {
        toast.info("Maximum 10 files are allowed.")
      }

    }

  };

  setImages = (idOfImg: any) => {

    for (const key in this.state.imageFile) {

      if (this.state.imageFile[key].name === idOfImg) {

        //@ts-ignore
        document.getElementById(idOfImg)?.setAttribute('style', 'display:none;')
        //@ts-ignore
        document.getElementById(idOfImg + 'img')?.setAttribute('style', 'display:none;')
        //@ts-ignore
        document.getElementById(idOfImg + 'div')?.setAttribute('style', 'display:none;')
        this.setState({ setDeletingImg: [...this.state.setDeletingImg, this.state.imageFile[key].name] })
      }
    }

  }


  joinChannel = async (channel: any) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }
    channel.on("messageAdded", this.handleMessageAdded);
  };

  handleMessageAdded = async (message: any) => {
    if (message?.channel?.channelState?.uniqueName === this.state?.channel?.channelState?.uniqueName) {

      const { messages } = this.state;
      this.setState(
        {
          messages: !!messages ? [...messages, message] : [message],
        },
        this.scrollToBottom
      );
      let messageOwner = Cookies.get("Login_Email")
      await this.state.channel.setAllMessagesConsumed()


      if (message.state.type === "media") {
        // this.getImgUrl(element.state.media)

        message.state.media.getContentTemporaryUrl().then(function (url: any) {
          //@ts-ignore
          document.getElementById('msgImG' + messages.length)?.setAttribute("src", url)
        });
      }


      if (message.author != messageOwner) {
        //@ts-ignore
        const consume = await this.state.channel.getMemberByIdentity(message.author)



        // const detail= await message?.channel?.getMemberByIdentity(this.state?.userEmail)

        let userObj = {
          userId: this.state?.recievedId,
          lastMsg: message?.body,
          lastConsumedMessageIndex: '',
          identity: message.author,
          dateUpdated: message?.channel?.lastMessage?.dateCreated,
          allMsgIndex: message?.channel?.lastMessage?.index,
          getUnConsumedIndex: ''
        }

        //@ts-ignore
        this.props?.updateLastMsg(userObj, this.callState)
        this.setState({ lastConsumedMessageIndex: consume?.lastConsumedMessageIndex, })
      } else {

        const detail = await message?.channel?.getMemberByIdentity(this.state?.userEmail)

        let userObj = {
          userId: this.state?.recievedId,
          lastMsg: message?.body,
          lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
          identity: Cookies.get('Login_Email'),
          dateUpdated: message?.channel?.lastMessage?.dateCreated,
          allMsgIndex: message?.channel?.lastMessage?.index,
          getUnConsumedIndex: ''
        }
        //@ts-ignore
        this.props?.updateLastMsg(userObj, this.callState)

      }

    }

  };

  callState = (data: any) => {
    this.setState({ cheking: data })

  }
  sendImage = async (acceptedFiles: any) => {

    const { text, channel } = this.state;
    try {



      for (const key in acceptedFiles) {

        if (typeof acceptedFiles[key] === 'object' && acceptedFiles[key].name && acceptedFiles[key].name !== this.state.setDeletingImg[0] && acceptedFiles[key].name !== this.state.setDeletingImg[1] && acceptedFiles[key].name !== this.state.setDeletingImg[2] && acceptedFiles[key].name !== this.state.setDeletingImg[3] && acceptedFiles[key].name !== this.state.setDeletingImg[4] && acceptedFiles[key].name !== this.state.setDeletingImg[5] && acceptedFiles[key].name !== this.state.setDeletingImg[6] && acceptedFiles[key].name !== this.state.setDeletingImg[7] && acceptedFiles[key].name !== this.state.setDeletingImg[8] && acceptedFiles[key].name !== this.state.setDeletingImg[9]) {

          channel && channel.sendMessage({ contentType: acceptedFiles[key].type, media: acceptedFiles[key] });
          //@ts-ignore
          document.getElementById(acceptedFiles[key].name)?.setAttribute('style', 'display:none;')
          //@ts-ignore
          document.getElementById(acceptedFiles[key].name + 'img')?.setAttribute('style', 'display:none;')
          //@ts-ignore
          document.getElementById(acceptedFiles[key].name + 'div')?.setAttribute('style', 'display:none;')
        }
      }

      this.setState({ imageFile: '', setDeletingImg: [], tempImgUrl: '' })
    } catch (error) {
      console.error(error);

    }

  };
  sendMessage = async (event: any) => {
    const { text, channel } = this.state;

    if (this.state.imageFile && text && String(text).trim()) {
      this.sendImage(this.state.imageFile)
      channel && channel.sendMessage(text);
      //@ts-ignore
      const elem = this.state?.keepFocus
      if (elem !== '' && elem !== undefined && elem !== null) {
        //@ts-ignore
        elem.value = ''
      }
      event.target.value = ''
      const detail = await channel?.getMemberByIdentity(this.state?.userEmail)
      let userObj = {
        userId: this.state?.recievedId,
        lastMsg: null,
        lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
        identity: Cookies.get('Login_Email'),
        dateUpdated: channel?.lastMessage?.dateCreated,
        allMsgIndex: channel?.lastMessage?.index,
        getUnConsumedIndex: ''
      }
      //@ts-ignore
      this.props?.updateLastMsg(userObj, this.callState)

    } else if (this.state.imageFile) {
      this.sendImage(this.state.imageFile)
      //@ts-ignore
      const elem = this.state?.keepFocus
      if (elem !== '' && elem !== undefined && elem !== null) {
        //@ts-ignore
        elem.value = ''
      }
      event.target.value = ''
      const detail = await channel?.getMemberByIdentity(this.state?.userEmail)
      let userObj = {
        userId: this.state?.recievedId,
        lastMsg: null,
        lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
        identity: Cookies.get('Login_Email'),
        dateUpdated: channel?.lastMessage?.dateCreated,
        allMsgIndex: channel?.lastMessage?.index,
        getUnConsumedIndex: ''
      }
      //@ts-ignore
      this.props?.updateLastMsg(userObj, this.callState)

    }
    else if (text && String(text).trim()) {
      event.target.value = ''
      channel && await channel.sendMessage(text);
      //@ts-ignore
      const elem = this.state?.keepFocus
      if (elem !== '' && elem !== undefined && elem !== null) {
        //@ts-ignore
        elem.value = ''
      }
      const detail = await channel?.getMemberByIdentity(this.state?.userEmail)
      let userObj = {
        userId: this.state?.recievedId,
        lastMsg: this.state.text,
        lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
        identity: Cookies.get('Login_Email'),
        dateUpdated: channel?.lastMessage?.dateCreated,
        allMsgIndex: channel?.lastMessage?.index,
        getUnConsumedIndex: ''
      }
      //@ts-ignore
      this.props?.updateLastMsg(userObj, this.callState)

      this.setState({ text: "" });
      // //@ts-ignore
      // document.getElementById("sendOne2OneMessageBtn").value = ""
    }
    this.setState({ text: "" });
    this.setState({ typingStart: false });
    // this.setState({keepFocus:false})
  };

  scrollToBottom = async () => {
    //@ts-ignore
    document.getElementById('BottomViewID')?.scrollIntoView();
  }
  getToken = () => {

    try {
      //case:1 for chat_token
      if (Cookies.get("Chat_Token") !== "" && Cookies.get("Chat_Token") !== undefined) {
        return Cookies.get("Chat_Token") || ""
      }
      //case:2 for chat_token
      else if (this.state.chatAuthToken !== "" && this.state.chatAuthToken !== undefined) {
        return this.state.chatAuthToken
      }
      //case:3 for chat_token
      else if (this.Chat_Token !== "" && this.Chat_Token !== undefined) {
        return this.Chat_Token
      } else {
        this.generateChatAuthToken()
        return Cookies.get("Chat_Token") || ""
      }
    } catch (e) {
      console.error("errors : ", e);
    }
    return ""
  };
  // Customizable Area End

  generateChatAuthToken(): boolean {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ChatAuthTokenApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chatAuthTokenEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getFriendsList(friendId: any): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allFirendsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFriendProfileEndPoints + friendId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId != null &&
      apiRequestCallId === this.ChatAuthTokenApiCallId
    ) {
      if (responseJson.token) {
        // toast.success("chatAuthToken success",{position: toast.POSITION.BOTTOM_RIGHT});
        this.setState({ chatAuthToken: responseJson.token })

        Cookies.set("Chat_Token", responseJson.token)
        this.Chat_Token = responseJson.token
      }
      if (responseJson.errors) {
        if (responseJson.errors && responseJson.errors[0]?.token) {
          toast.error(responseJson.errors[0]?.token, { position: toast.POSITION.BOTTOM_RIGHT })
          return;
        }
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId != null &&
      apiRequestCallId === this.allFirendsApiId
    ) {
      this.setState({ userProfileInfo: responseJson?.data })
      this.setState({ userFullName: responseJson?.data?.attributes?.full_name })
      this.setState({ userProfileImage: responseJson?.data?.attributes?.image, userEmail: responseJson?.data?.attributes?.email, useProfileId: responseJson?.data?.attributes?.receipient_id })
    }
    if (responseJson.errors) {
      if (responseJson.errors) {
        toast.error(responseJson.errors[0]?.token, { position: toast.POSITION.BOTTOM_RIGHT })
        return;
      }
    }
    // Customizable Area End

  }
}
