import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import Web3 from "web3";
import React from "react"
import { InjectedConnector } from "@web3-react/injected-connector";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import { debounce} from 'lodash';
import { postCamera } from "./assets";
import firebase from "firebase/app";
import "firebase/messaging";
const Chat = require("twilio-chat");
import { trending } from "./assets";
const axios = require('axios');
const {baseURL} = require('../../../framework/src/config.js')
toast.configure()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes:any;
  // Customizable Area Start
  history: any
  getAllPostAfterUpload:any;
  getSearching:any;
  makeEmptyUserAndPost:any;
  callComponentDidMountFromSingle:any
  showNotification?:any
  list?: any
  profileData: any
  notificationsList: any
  setNotifications: (notifications: any) => void
  userLogout: () => void
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  password: string
  email: string
  enablePasswordField: boolean
  checkedRememberMe: boolean
  placeHolderEmail: string
  placeHolderPassword: string
  labelHeader: string
  btnTxtLogin: string
  labelRememberMe: string
  labelEmail: string
  labelPassword: string
  btnTxtSocialLogin: string
  labelOr: string
  reCaptchaVarified: boolean
  showPassword: boolean
  siteKeyOfCaptcha: string
  account:any
  open:boolean
  allAssets:[]
  confirm:boolean
  tokenId:string
  metamaskOwnerId: string
  profileImage: any
  fullName: string
  checkAccount:number
  metamaskBTN:boolean
  uploadNFT:boolean
  createPostText: string
  rooms:any
  loginId:any
  keepAllImage:boolean
  hideImg:boolean
  showAlert:boolean
  history:any
  isSearchHistory:boolean
  loader:boolean
  stepsEnabled: boolean
  initialStep: number
  steps: any
  selectedNFTUrl: string
  uploadNFTLoader: boolean
  searchLoder: boolean
  notificationLoader: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class NavbarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  openseaAccountApiCallId: any;
  openSeaApiCallId:any;
  createWalletAccountApiCallId:any;
  createNFTAccountApiCallId: any;
  apiMyInformationId: string = "";
  searchingApiId: string = ""
  apiMetaMaskWalletsId:any;
  apiNotificationId:any;
  notificationApiCallId:any;
  acceptRequestApiId: any;
  unfriendApiId: any;
  searchHistoryApiId: any;
  deleteHistoryApiId: any;
  libraryStep = [{
    element: ".tutorial-all-nfts",
    intro: `<h2> Library selection </h2> <p>Select any NFT assets that you own from your metamask wallet.</p>`,
  }]
  confirmBtnStep = [{
    element: ".tutorial-confirm-upload",
    intro: `<h2> Upload NFT </h2> <p>Click to share it in a post.</p>`,
  }]
  getTutorialsApiId: string = ""
  updateTutorialsApiId: string = ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      reCaptchaVarified: false,
      showPassword: false,
      labelEmail: configJSON.emailInputLabel,
      labelPassword: configJSON.passwordInputLabel,
      siteKeyOfCaptcha: configJSON.siteKeyOfCaptcha,
      account:'',
      open:false,
      allAssets:[],
      confirm:false,
      tokenId:'',
      metamaskOwnerId: '',
      profileImage: '',
      fullName:'',
      checkAccount:0,
      metamaskBTN:false,
      uploadNFT:false,
      createPostText: '',
      rooms:0,
      loginId:Cookies.get('Login_Id'),
      keepAllImage:false,
      hideImg:false,
      showAlert:true,
      history: null,
      isSearchHistory: false,
      loader: false,
      stepsEnabled: false,
      initialStep: 0,
      steps: [],
      selectedNFTUrl: '',
      uploadNFTLoader: false,
      searchLoder: false,
      notificationLoader: false
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    if (Cookies.get("Login_Token")) {
      this.GetMyInformationAPI()
      this.getSearchHistory()
      firebase.messaging().onMessage(payload => {
        if (this?.props?.showNotification === "showNotification") {
          if (payload?.data?.author && payload?.data?.twi_body) {
            toast.info((payload?.data?.author + " : " + payload?.data?.twi_body), { icon: false, position: toast.POSITION.BOTTOM_RIGHT, });
          }
        }
      });
      try {
        axios({
          method: "get",
          url: `${baseURL}/${configJSON.notificationsCountEndPoint}`,

          // mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get("Login_Token"),
          }
        })
          .then(function (response: any) {
            //handle success
          })
          .catch(function (response: any) {

          })
      } catch (error) {
        console.log("error", error)
      }
      const notifiaction = Cookies.get('notification')
      this.setState({ rooms: notifiaction })
      this.getAllNotification()
    } else {
      this.logoutUser()
    }
  }

  logoutUser = () => {
    Cookies.remove('Login_Token')
    Cookies.remove("notification")
    Cookies.remove('Chat_Token')
    Cookies.remove('Login_Id')
    Cookies.remove('Login_Email')
    Cookies.remove('Account_Verified')
    this.props.userLogout()
    this.props.history.push('/login')
  }

  metamaskNotFoundToast = () => (
    <div>Cannot find MetaMask browser extension installed. Download at <span style={{
      color: "blue",
      textDecoration: "underline"
    }} onClick={() => window.open('https://metamask.io/download/')}>metamask.io/download</span></div>
  )

  handleCreatePostTutorialsClose = () => {
    if (this.state.stepsEnabled) {
      const isNftsSteps = this.state.steps[0]?.element === ".tutorial-all-nfts"
      const isConfirmSteps = this.state.steps[0]?.element === ".tutorial-confirm-upload"
      if (isNftsSteps) {
        this.updateTutorials(configJSON.createPostNftsTutorialsKey)
      } else if (isConfirmSteps) {
        this.updateTutorials(configJSON.createPostUploadTutorialsKey)
      }
      this.setState({ ...this.state, stepsEnabled: false, steps: [] })
    }
  }

  handleResetCreatePost = () => {
    this.setState({ open: false,
      confirm: false,
      metamaskBTN: false,
      keepAllImage: false,
      createPostText: '',
      tokenId: '',
      selectedNFTUrl: '',
      uploadNFTLoader: false })
  }

  debounceLog = debounce((text: any) => {
    const url = window.location.href;
    const endPoint = url?.substring(url?.lastIndexOf('/') + 1);
    if (endPoint === 'searchresult') {
      this.props.getSearching(text)
    } else {
      //@ts-ignore
      this.props.history.push({
        pathname: '/searchresult',
        state: { detail: text }
      })
    }
  }, 1000)

  getSearchingResult = (searchValue: string) => {
    const url = window.location.href
    const endPoint = url?.substring(url?.lastIndexOf('/') + 1)
    if (endPoint === 'searchresult') {
      this.props.getSearching(searchValue)
    } else {
      //@ts-ignore
      this.props.history.push({
        pathname: '/searchresult',
        state: { detail: searchValue }
      })
    }
  }

  handleSearchKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === configJSON.ENTER_KEY) {
      const element = document?.getElementById('searchNftAndUsers') as HTMLInputElement
      const value = element?.value
      this.getSearchingResult(value || '')
      this.setState({ isSearchHistory: false })
    }
  }

  handleHistoryClick = (searchText: string) => {
    const url = window.location.href
    const endPoint = url.substring(url.lastIndexOf('/') + 1);
    if (endPoint === 'searchresult') {
      //@ts-ignore
      document.getElementById('searchNftAndUsers').value = searchText
      this.props.getSearching(searchText)
    } else {
      //@ts-ignore
      this.props.history.push({
        pathname: '/searchresult',
        state: { detail: searchText }
      })
    }
  }

  //Customizable area start
  getLibrary(provider:any){
    return new Web3(provider)
  }

  selectImg(url: any, token_id: any) {
    this.setState({ keepAllImage: false, selectedNFTUrl: url, tokenId: token_id })
  }

  async connect(activate: any, account: any) {
    //@ts-ignore
    if (!window?.web3) {
      toast.error(this.metamaskNotFoundToast, { autoClose: 7000, position: toast.POSITION.BOTTOM_RIGHT })
    } else {
      this.setState({ metamaskBTN: true })
      const injected = new InjectedConnector({
        supportedChainIds: [1, 3, 4, 5, 42],
      });
      await activate(injected)
      //@ts-ignore
      await window.ethereum.enable();
      //@ts-ignore
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      let acc = accounts[0];
      if (Cookies.get("Login_Token") && acc) {
        this.getAllMetaMaskWallets()
      }
    }
  }

  disconnect(deactivate:any){
    deactivate()
  }
  GoToMyProfile(props:any) {
  //@ts-ignore
    this.props.history.push('/myprofile')
  }
  GoToSetting(props:any) {
    //@ts-ignore
      this.props.history.push('/userSetting')
    }
  GoToSearchResult(props:any) {
  //@ts-ignore
    this.props.history.push('/searchresult')
  }
   GoToHome(props:any) {
  //@ts-ignore
    this.props.history.push('/')
  }

  RedirectToUsers(props: any) {
    //@ts-ignore
    this.props.history.push({
      pathname: '/chat',
      state: {
        //@ts-ignore
        receiveId: this.props.friendlist[0][0].attributes.receipient_id,
        //@ts-ignore
        accountId: this.props.friendlist[0][0].attributes.account_id
      }
    })
  }

  handleUpload = async (value: any) => {
    this.setState({ tokenId: '', selectedNFTUrl: '' })
    const OwnerId = Cookies.get('MetaMask_Owner_Id')
    if (!OwnerId || OwnerId === 'undefined') {
      this.connect(value?.activate, value?.account)
    } else {
      //@ts-ignore
      await window.ethereum.enable();
      //@ts-ignore
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      let acc = accounts[0];
      if (acc === OwnerId) {
        this.setState({ metamaskBTN: true, keepAllImage: true })
        this.openseaAccount(OwnerId)
      } else {
        this.getAllMetaMaskWallets()
      }
    }
  }

  getAllMetaMaskWallets(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMetaMaskWalletsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myMetamaskEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  createClientComponent = async (roomId?: any) => {
    //@ts-ignore
    if (this.props.clientList) {

      const userId = Cookies.get("Login_Id")
      const email = Cookies.get('Login_Email')
      //@ts-ignore
      const client = this.props.clientList;
      //@ts-ignore
      await client.user.updateAttributes({ profile: this.props?.UserInfo[0]?.image, name: this.props?.UserInfo[0]?.fullName, userId: userId, email: email })
    } else {

      let token = "";
      try {
        token = Cookies.get("Chat_Token") || '';
      } catch {
        throw new Error("unable to get token, please reload this page");
      }
      const userId = Cookies.get("Login_Id")
      const email = Cookies.get('Login_Email')
      const client = await Chat.Client.create(token);
      //@ts-ignore
      await client.user.updateAttributes({ profile: this.props?.UserInfo[0]?.image, name: this.props?.UserInfo[0]?.fullName, userId: userId, email: email })

      //@ts-ignore
      if (this.props.addClient) {
        //@ts-ignore
        this.props.addClient(client)
      }
    }
  }

  getSearching = (values?: any): boolean => {
    if (Cookies.get('Login_Token') && values?.length > 2) {
      this.setState({ searchLoder: true })
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.searchingApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.advanceSearchEndPoint + values || ''
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else if (!values) {
      this.setState({ searchLoder: true })
      this.getSearchHistory()
    }
    return false
  }

  getAllNotification = (): boolean => {
    this.props.notificationsList?.length === 0 && this.setState({ notificationLoader: true })
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiNotificationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  GetMyInformationAPI(): boolean {

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMyInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myInfoApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  confirmUpload = (): boolean => {

    if (!!this.state.createPostText) {
      this.setState({ uploadNFTLoader: true })
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };

      const fullName = `${this.props.profileData.fullName || this.props?.list[0]?.fullName}`

      const post = {
        name: fullName || '',
        description: this.state.createPostText,
        token_id: this.state.tokenId,
        owner_id: Cookies.get('MetaMask_Owner_Id'),
        wallet_type: 'metamask'
      }

      const httpBody = {
        post: post
      }


      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createNFTAccountApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.confirmNFTEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Please write something about post!", { position: toast.POSITION.BOTTOM_RIGHT })
      return false;
    }
  }

  acceptRequest = (userId:any):boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token" :Cookies.get('Login_Token')
    };

    const httpBody = {
      "account_id": userId,
      "status": "approved"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.acceptRequestApiId= requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendAcceptReqEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
   runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  rejectRequest = (userId:any):boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token" :Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unfriendApiId= requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unfriendEndPoint + "/" + userId + "/unfriend" 
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    
   runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  metamaskAccount = (account: any): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const data = {
      wallet_type: "metamask",
      address: account
    };

    const httpBody = {
      wallet: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createWalletAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.metamaskWalletEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  openseaAccount = (account: any): boolean => {

    this.setState({ ...this.state, loader: true })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      'X-API-KEY': configJSON.OPEN_SEA_API_KEY
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.openSeaApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'https://api.opensea.io/api/v1/assets?owner=' + account + '&order_direction=desc&offset=0&limit=20'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  readNotification = (notificationId: any): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const data = {
      id: notificationId
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.notificationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.readNotificationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSearchHistory():boolean{
    const header = {
      "Content-Type":configJSON.exampleApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchHistoryApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchHistoryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deleteSearchHistory = (id:number):boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token" :Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteHistoryApiId= requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteHistory + id
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    
   runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCompletedTutorials (): boolean {
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTutorialsApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.completedTutorialsEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateTutorials = (completedTutorial: string):boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token" :Cookies.get('Login_Token')
    };

    const httpBody = {
      tutorial: completedTutorial
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateTutorialsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTutorialsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
   runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          // CreateAccount response Api
          if (
            apiRequestCallId === this.openSeaApiCallId &&
            responseJson !== undefined
          ) {
            const sellNFTs = responseJson?.assets?.filter((asset: {
              asset_contract: { schema_name: string }
            }) => asset?.asset_contract?.schema_name === configJSON.VALID_NFT_SCHEMA)

            this.setState({
              allAssets: sellNFTs,
              loader: false,
              metamaskBTN: false
            })
            this.getCompletedTutorials()
          }
          if (
            apiRequestCallId === this.getTutorialsApiId &&
            responseJson !== undefined
          ) {
            const isNftsTutorial = responseJson?.tutorial?.includes(configJSON.createPostNftsTutorialsKey)
            const isConfirmUploadTutorial = responseJson?.tutorial?.includes(configJSON.createPostUploadTutorialsKey)
            if (!isNftsTutorial && this.state.allAssets?.length > 0 && this.state.keepAllImage) {
              this.setState({ stepsEnabled: true, steps: this.libraryStep })
            }
            if (!isConfirmUploadTutorial && this.state.confirm) {
              this.setState({ stepsEnabled: true, steps: this.confirmBtnStep })
            }
          }
          if (apiRequestCallId === this.searchHistoryApiId &&
            responseJson !== undefined
          ) {
            this.setState({ history: { search_history: responseJson?.search_history }, searchLoder: false})
          }
          if (apiRequestCallId === this.searchingApiId &&
            responseJson !== undefined) {
              this.setState({ history: responseJson, searchLoder: false })
            }
          if (apiRequestCallId === this.deleteHistoryApiId &&
            responseJson !== undefined
          ) {
            this.getSearchHistory()
            toast.success(responseJson?.message, { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.createWalletAccountApiCallId &&
            responseJson !== undefined
          ) {

            Cookies.set('MetaMask_Owner_Id', responseJson.data.attributes.address)
            this.setState({ metamaskOwnerId: responseJson.data.attributes.address })
            this.openseaAccount(responseJson.data.attributes.address)
            this.setState({ metamaskBTN: true, uploadNFT: true, keepAllImage: true })
          }
          if (
            apiRequestCallId === this.notificationApiCallId &&
            responseJson !== undefined
          ) {
            this.getAllNotification()
          }
          if (
            apiRequestCallId === this.acceptRequestApiId &&
            responseJson !== undefined
          ) {
            toast.success(responseJson.message, { position: toast.POSITION.BOTTOM_RIGHT })
            this.getAllNotification()
          }
          if (
            apiRequestCallId === this.unfriendApiId &&
            responseJson !== undefined
          ) {
            toast.success(responseJson.message, { position: toast.POSITION.BOTTOM_RIGHT })
            this.getAllNotification()
          }
          if (
            apiRequestCallId === this.apiMyInformationId &&
            responseJson !== undefined
          ) {
            let user = {
              image: responseJson?.data?.attributes?.image,
              fullName: responseJson?.data?.attributes?.full_name,
              trending: trending
            }
            //@ts-ignore
            if (this.props.list !== '') {
              //@ts-ignore
              this.props.getProfile(user)
            }
            //@ts-ignore
            this.setState({ profileImage: this.props.list[0].image, fullName: this.props.list[0].fullName })
            this.createClientComponent()
          }
          if (
            apiRequestCallId === this.apiNotificationId &&
            responseJson !== undefined
          ) {
            this.props.setNotifications(responseJson.data)
            this.setState({ notificationLoader: false })
          }
          if (
            apiRequestCallId === this.createNFTAccountApiCallId &&
            responseJson !== undefined
          ) {
            this.handleResetCreatePost()
            toast.success('post is uploaded successfully..!', { position: toast.POSITION.BOTTOM_RIGHT })
            if (!!this.props.getAllPostAfterUpload) {
              this.props.getAllPostAfterUpload()
            }
          }
          if (
            apiRequestCallId === this.apiMetaMaskWalletsId &&
            responseJson !== undefined
          ) {
            //@ts-ignore
            await window.ethereum.enable();
            //@ts-ignore
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            let acc = accounts[0];

            if (responseJson.data) {
              responseJson.data.forEach((element: any) => {
                if (element.attributes.address === acc) {
                  Cookies.set('MetaMask_Owner_Id', acc)
                  this.openseaAccount(acc)
                  this.setState({ metamaskBTN: true, uploadNFT: true, keepAllImage: true })
                  this.setState({ checkAccount: 1 })
                }
              });
            } else {
              this.metamaskAccount(acc)
              this.setState({ checkAccount: 1 })
            }
            if (this.state.checkAccount === 0) {
              this.metamaskAccount(acc)
            }
          }
        }
      } else {
        const errors = responseJson?.errors;
        if (errors) {
          if (apiRequestCallId === this.createNFTAccountApiCallId) {
            this.setState({ uploadNFTLoader: false })
          }
          if (apiRequestCallId === this.apiNotificationId) {
            this.setState({ notificationLoader: false })
          }
          if(apiRequestCallId === this.searchingApiId) {
            this.setState({ searchLoder: false })
          }

          if (apiRequestCallId === this.apiMyInformationId && (errors[0]?.token === 'Invalid token' || errors[0]?.token === "Token has Expired")) {
            toast.error('Token is expired, Please login again', { position: toast.POSITION.BOTTOM_RIGHT })
            this.logoutUser()
          } else if (errors[0]?.address) {
            toast.error(errors[0]?.address, { position: toast.POSITION.BOTTOM_RIGHT })
            this.setState({ metamaskBTN: false })
          } else if (errors[1]?.description) {
            toast.error(`Description ${errors[1]?.description}`, { position: toast.POSITION.BOTTOM_RIGHT })
          } else if(errors[0]?.token_id) {
            toast.error(errors[0]?.token_id, { position: toast.POSITION.BOTTOM_RIGHT })
          } else if(errors[1]?.token_id) {
            toast.error(errors[1]?.token_id, { position: toast.POSITION.BOTTOM_RIGHT })
          }
          else {
            toast.error(errors, { position: toast.POSITION.BOTTOM_RIGHT })
            if (errors === 'you are blocked...') {

              //@ts-ignore
              this.props.history.push({
                pathname: '/'
              })
            }

          }
        }
      }
    }
  }
  //Customizable area Ends
}
