import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any; 
    id: string;
    // Customizable Area Start
    history: any
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class NotFoundPageController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    //Customizable area start
    async componentDidMount() {

        // Customizable Area Start
        // Customizable Area End
    }

    redirectToHome = () => {
        this.props.history.push('/')
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (!!responseJson && !!apiRequestCallId && !responseJson.errors) {
            } else {
                this.parseApiCatchErrorResponse(errorReponse);
                const errors = responseJson?.errors;
                if (errors) {
                }

            }  
        }
    }
}
  // Customizable Area End
