import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//live
const firebaseConfig = {
  apiKey: "AIzaSyDtYInZpU7D10_DHTWr2fDKEDaxGQ3l1_A",
  authDomain: "deegee-868eb.firebaseapp.com",
  projectId: "deegee-868eb",
  storageBucket: "deegee-868eb.appspot.com",
  messagingSenderId: "236194103057",
  appId: "1:236194103057:web:45eb1101fd08b2cf4f56b0",
  measurementId: "G-XN65NLZQJM"
};

//local
// const firebaseConfig = {
//   apiKey: "AIzaSyAsxKA7td9RTndaRDZuaxqigqTjqsDGWFk",
//   authDomain: "deegee-c89b9.firebaseapp.com",
//   databaseURL: "https://deegee-c89b9-default-rtdb.firebaseio.com",
//   projectId: "deegee-c89b9",
//   storageBucket: "deegee-c89b9.appspot.com",
//   messagingSenderId: "568545386969",
//   appId: "1:568545386969:web:51150eb3ff7270bfbd79c8",
//   measurementId: "G-WDTTMJQR2Y"
// };

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

//const { REACT_APP_VAPID_KEY } = process.env;
//live
const publicKey = 'BNQTYvILDnSqRRQx2KrlnnaglLAhMGBw1gPAGLwoO2bBpeJS9MQ7E19CnObzQbo41jHl7zDfCfOeyW0eSSuCEAs';

//local
//const publicKey = 'BN-P-LYHikqxZqWASbNQKDCdeaD8cvOqD2m_JQ_O3C5F2v4rZR7JGnxzSrGywI8nTdlimif98yjro_1_Iw7igbA';

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
