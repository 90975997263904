import React from "react";

// Customizable Area Start
import LinkShareController, { Props } from "./LinkShareController.web";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import copy from 'copy-to-clipboard';
//@ts-ignore
import { USER_LOGOUT } from "../../../web/src/allActions.js";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// Customizable Area End

class LinkShare extends LinkShareController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    //@ts-ignore
    const { classes, open, handleClose, url, text } = this.props;

    const defaultButtonProps = {
      url:url,
      translate: ""
    }

    const defaultIconProps = {
      size: 55,
      round: true,
      crossOrigin: undefined,
      path: undefined,
    };

    const handleCopyToClipboard = () => {
      if(!!url){
        const isCopied = copy(url)

        if (isCopied) toast.success("Link copied successfully!", { position: toast.POSITION.BOTTOM_RIGHT })
        else toast.error("Something went wrong!", { position: toast.POSITION.BOTTOM_RIGHT })
      }
    }

    return (
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <span className={classes.title}>Share</span>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.bottomContainer}>
          <div className={classes.iconsContainer}>
            <FacebookShareButton {...defaultButtonProps} quote={text}>
              <FacebookIcon {...defaultIconProps} />
            </FacebookShareButton>
            <WhatsappShareButton {...defaultButtonProps}>
              <WhatsappIcon {...defaultIconProps} />
            </WhatsappShareButton>
            <TwitterShareButton {...defaultButtonProps}>
              <TwitterIcon {...defaultIconProps} />
            </TwitterShareButton>
            <EmailShareButton {...defaultButtonProps}>
              <EmailIcon {...defaultIconProps} />
            </EmailShareButton>
            <LinkedinShareButton {...defaultButtonProps}>
              <LinkedinIcon {...defaultIconProps} />
            </LinkedinShareButton>
            <TelegramShareButton {...defaultButtonProps}>
              <TelegramIcon {...defaultIconProps} />
            </TelegramShareButton>
          </div>
          <div className={classes.urlContainer}>
            <span>{url || 'URL will appear here'}</span>
            <IconButton onClick={handleCopyToClipboard}>
              <Button variant="contained">COPY</Button>
            </IconButton>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

// Customizable Area Start
const useStyles = {
  title: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "90%",
    marginBottom: '20px'
  },
  closeButton: {
    position: "absolute",
    top: "15px",
    background: " #e5e6eb",
    right: "15px",
    padding: "5px",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 10px",
    minHeight: "150px",
  },
  urlContainer: {
    border: "1.5px solid #e5e6eb",
    borderRadius: "5px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: "'Poppins', sans-serif",
    width: "90%",
    "& > span": {
      paddingLeft: '5px'
    }
  },
} as any;
// Customizable Area End

const mapDispatchToProps = (dispatch: any) => {
  return {
    userLogout: () => {
      dispatch(USER_LOGOUT());
    },
  };
};

//@ts-ignore
const LinkShareRouter = withRouter(connect(null, mapDispatchToProps)(LinkShare));
export default withStyles(useStyles)(LinkShareRouter);
