import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  submitBtn: boolean;
  selectedProfileImage: any;
  checkDisabled:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SettingUpProfilePicController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  profilePictureApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      submitBtn: false,
      selectedProfileImage: false,
      checkDisabled:false
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }
  //Customizable area start
  async componentDidMount() {
    // Customizable Area Start
    //@ts-ignore
    const response=this.props?.location?.state?.detail;
  
    if(response?.data?.attributes?.id  && response?.data?.attributes?.account_id){
// response?.data?.attributes?.country
    }else{
    //@ts-ignore
    this.props.history.push({
      pathname: '/signup',
      state: { detail: "Something went wrong!! Please sign up!!" }
    })
  }
    // Customizable Area End
  }
  //creating url for selected img
  doFinalize(values: any) {
    // this.uploadProfilePicture(values)
    let formData = new FormData();    //formdata object

    formData.append('profile[image]', this.state.selectedProfileImage);
    // Display the values
    //call API and pass formdata over there
    this.setState({checkDisabled:true})
    this.uploadProfilePicture(formData)
  }
  gotoHome(props:any){
    //@ts-ignore
    this.props.history.push('/verification')
   }
   
   fileUploadHandler = async (e:  React.FormEvent<EventTarget>) => {
    let target = e.target as HTMLInputElement;
    let files = target.files as any;
    let url=URL.createObjectURL(files[0])
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        // eslint-disable-next-line no-await-in-loop
        // const fileInBase64 = await this.converFileToBase64(file);
        // eslint-disable-next-line camelcase
        const [name, file_type] = file.name.split(/(\.[^.]+)$/gm);
        
        this.setState({
          selectedProfileImage: file
        })
         //@ts-ignore
    const b = document.querySelector("#profilePicture");
    //@ts-ignore
    b.setAttribute("src", url) 
      }
  };

  uploadProfilePicture(values:any): boolean {
    const header = {
      "token":Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profilePictureApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profilePictureAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      values
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.profilePicApiMethodAndDetail
    );

   runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.profilePictureApiCallId &&
            responseJson !== undefined
          ) {
             toast.success('Profile Picture set Successfully',{position: toast.POSITION.BOTTOM_RIGHT})
             
             //@ts-ignore
             this.props.history.push('/verification')
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        if (errors) {
          if(errors[0]?.profile?.image[0]==="Only JPEG, JPG and PNG file extensions are allowed."){
            toast.error(errors[0]?.profile?.image[0],{position: toast.POSITION.BOTTOM_RIGHT})
          } 
          // this.openSnackBarHandler('error', errors[0].message);
          
        }

      }
    }
  }
  // Customizable Area End
}
