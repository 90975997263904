import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { setTimeout } from "timers";
toast.configure()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  history: any
  userLogout: () => void
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  myAllPost: any;
  fullName: any;
  pageState: any;
  callDidMountHandle: string;
  profileImage: any;
  allComments: []
  allRepliedComments: []
  commentBoxId: any;
  commentCount: number;
  showReplyInbox: any;
  statusOfComment: any;
  pageNum: any;
  perPagePost: any;
  hideData: boolean;
  loading: boolean;
  postId: any;
  focus: boolean;
  postSingleId: any;
  reportDialog: boolean;
  confirmReportDialog: boolean;
  reportDialogMessage: string;
  reportReasons: any;
  reportSubReasons: any;
  postReportData: {
    post_id: number,
    report_reason_id: number
  }
  editPostId: number
  deleteDialog: boolean
  deletePostId: number
  editCommentId: number
  deleteCommentDialog: boolean
  deleteCommentId: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SinglePostController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  allMyNftPostsapiId: string = ""
  likePostApiId: string = ""
  dislikePostApiId: string = ""
  updateApiCallId: string = ""
  deletePostApiId: string = ""
  createCommentApiId: string = ""
  createReplyCommentApiId: string = ""
  commentDataApiId: string = ""
  commentReplyDataApiId: string = ""
  createLikeonCommentApiId: string = ""
  apiMyInformationId: string = ""
  reportReasonsApiId: string = ""
  postReportApiId: string = ""
  editCommentApiId: string = ""
  deleteCommentApiId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      myAllPost: '',
      fullName: "",
      pageState: "home",
      callDidMountHandle: "callOnce",
      profileImage: '',
      allComments: [],
      allRepliedComments: [],
      commentCount: 10,
      commentBoxId: '',
      showReplyInbox: '',
      statusOfComment: '',
      pageNum: 1,
      perPagePost: 5,
      hideData: true,
      loading: true,
      postId: '',
      focus: true,
      postSingleId: '',
      reportDialog: false,
      confirmReportDialog: false,
      reportDialogMessage: "",
      reportReasons: [],
      reportSubReasons: [],
      postReportData: {
        post_id: 0,
        report_reason_id: 0
      },
      editPostId: 0,
      deleteDialog: false,
      deletePostId: 0,
      editCommentId: 0,
      deleteCommentDialog: false,
      deleteCommentId: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    if (Cookies.get("Login_Token")) {
      //@ts-ignore
      let home = document.getElementById('homeIcon')
      //@ts-ignore
      home.setAttribute('style', 'border-bottom:none')

      //@ts-ignore
      let trend = document.getElementById('trendIcon')
      //@ts-ignore
      trend.setAttribute('style', 'border-bottom:none')
      // Customizable Area Start
      const url = window.location.href;
      const postId = url.substring(url.lastIndexOf('/') + 1)
      //@ts-ignore
      const response = this.props?.location?.state?.detail || postId;
      //@ts-ignore
      const focus = this.props?.location?.state?.focus;
      this.getReportReasons();
      if (response && focus === 'yes') {
        this.getAllMyNftPost(response);
        this.setState({ postId: response })
        this.GetMyInformationAPI();
      } else if (response) {
        this.getAllMyNftPost(response);
        this.setState({ postId: response })
        this.GetMyInformationAPI();
      } else {
        this.props.history.push({
          pathname: "/"
        });
      }
    } else {
      this.props.userLogout()
    }
    // Customizable Area End
  }

  logoutUser = () => {
    Cookies.remove('Login_Token')
    Cookies.remove("notification")
    Cookies.remove('Chat_Token')
    Cookies.remove('Login_Id')
    Cookies.remove('Login_Email')
    Cookies.remove('Account_Verified')
    this.props.userLogout()
    this.props.history.push('/login')
  }

  callComponentDidMountFromSingle = (id?: any) => {
    this.getAllMyNftPost(id)
  }

  handleDeleteDialogToogle = (id?: number) => {
    if(this.state.deleteDialog) {
      this.setState({ deleteDialog: false, deletePostId: 0 })
    }
    else {
      this.setState({ deleteDialog: true, deletePostId: id || 0 })
    }
  }

  handleDeleteCommentToogle = (id?: number) => {
    if (!this.state.deleteCommentDialog && !!id) {
      this.setState({ deleteCommentDialog: true, deleteCommentId: id })
    } else {
      this.setState({ deleteCommentDialog: false, deleteCommentId: 0 })
    }
  }

  postTypeCheck = (url: string) => {
    const extension = url.split('.')?.pop()?.toLowerCase()
    if (!!extension) {
      if (configJSON.musicTypes.includes(extension)) {
        return "audio"
      } else if (configJSON.videoTypes.includes(extension)) {
        return "video"
      } else if (configJSON.object3dTypes.includes(extension)) {
        return "3dObject"
      } else {
        return "iframe"
      }
    } else {
      return "iframe"
    }
  }

  getReportReasons(): boolean {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reportReasonsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportReasonsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  handlePostReport = (): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const httpBody = this.state.postReportData

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postReportApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportPostEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllMyNftPost(id?: any): boolean {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allMyNftPostsapiId = requestMessage.messageId;
    if (this.state.postSingleId !== '') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.showMainPostEndPoint + this.state.postSingleId
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.showMainPostEndPoint + id
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updatePost = (postId: any): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };
    const b = document.querySelector("#editBio") as HTMLInputElement;
    const desc = b?.value
    if (!!desc) {
      const httpBody = {
        description: desc || ''
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updatePostEndPoint + postId
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Write something about post", { position: toast.POSITION.BOTTOM_RIGHT })
      return false;
    }
  }

  deletePost = (): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deletePostEndPoint + "/" + this.state.deletePostId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  likePost = (postId: any, is_Liked: any): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };
    if (!is_Liked) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.likePostApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.likePostEndPoint + "/" + postId + "/like"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.dislikePostApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.likePostEndPoint + "/" + postId + "/dislike"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  createComment(values: any, idNum: any): boolean {

    //@ts-ignore
    const coment = document.getElementById('comentBox').value
    if (coment) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get("Login_Token"),
      };
      const attribute = {
        "post_id": values,
        "comment": coment,
      }
      const attributes = {
        attributes: attribute
      };

      const httpBody = {
        data: attributes
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      this.createCommentApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      setTimeout(() => {
        //@ts-ignore
        document.getElementById('comentBox').value = ''
      }, 10);
      return true;
    } else {
      return false;
    }
  }

  createReplyComment(postId: any, index: any, commentIndex: any, commentId: any): boolean {

    //@ts-ignore
    const coment = document.getElementById("comentReplyBox" + index + commentIndex).value
    if (coment) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get("Login_Token"),
      };
      const attribute = {
        "post_id": postId,
        "comment": coment,
        "comment_id": commentId
      }
      const attributes = {
        attributes: attribute
      };

      const httpBody = {
        data: attributes
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      this.createReplyCommentApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      setTimeout(() => {
        //@ts-ignore
        document.getElementById("comentReplyBox" + index + commentIndex).value = ''
      }, 10);
      return true;
    } else {
      return false;
    }
  }

  // comment data of post 
  getCommentData(values: any, type?: any): boolean {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.commentDataApiId = requestMessage.messageId;
    if (type === 'oldest' || type === 'newest' || type === 'most_relevant') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.commentdataEndPoint + values + "/comments?filter_param=" + type
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.commentdataEndPoint + values + "/comments"
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  // comment data of post 
  getRepliedCommentData(values: any): boolean {
    if (values) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get("Login_Token"),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.commentReplyDataApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.commentReplyEndPoint + values
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    } else {
      return false;
    }
  }

  GetMyInformationAPI(): boolean {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMyInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myInfoApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  //like/dislike comments
  likeOrDislikeComment(values: any, liked: any): boolean {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createLikeonCommentApiId = requestMessage.messageId;
    if (liked) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint + "/" + values + "/dislike"
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint + "/" + values + "/like"
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  editComment = (postId: number) => {
    const b = document.querySelector("#editCommentBox") as HTMLInputElement;
    const desc = b?.value

    if (!!desc) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };

      const httpBody = {
        data: {
          attributes: {
            post_id: postId,
            comment: desc
          },
          comment_id: this.state.editCommentId
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editCommentApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Please write a comment", { position: toast.POSITION.BOTTOM_RIGHT })
    }
    return false
  }

  deleteComment = () => {
    if (this.state.deleteCommentId > 0) {
      this.handleDeleteCommentToogle()
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };

      const httpBody = {
        data: {
          comment_id: this.state.deleteCommentId
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.deleteCommentApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Something went wrong!", { position: toast.POSITION.BOTTOM_RIGHT })
      return false
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.allMyNftPostsapiId &&
            responseJson !== undefined
          ) {
            let data = [responseJson.data]

            //@ts-ignore
            this.setState({ myAllPost: data })
            this.getCommentData(data[0]?.attributes?.id)
            if (this.state.myAllPost.length === 0) {
              this.setState({ loading: false })
            }
            //@ts-ignore
            if (this.props?.location?.state?.focus === "yes" && this.state.focus) {
              //@ts-ignore
              document.getElementById('comentBox')?.focus()
              this.setState({ focus: false })
            }

          }
          if (
            apiRequestCallId === this.apiMyInformationId &&
            responseJson !== undefined
          ) {
            this.setState({ profileImage: responseJson?.data?.attributes?.image })
            this.setState({ fullName: responseJson?.data?.attributes?.full_name })
          }
          if (
            apiRequestCallId === this.createCommentApiId &&
            responseJson !== undefined
          ) {
            //@ts-ignore
            this.props.userLogout()
            this.getAllMyNftPost(this.state?.postId)
            this.getCommentData(responseJson.data.attributes.post_id)
          }
          if (
            apiRequestCallId === this.createReplyCommentApiId &&
            responseJson !== undefined
          ) {
            //@ts-ignore
            this.props.userLogout()
            this.getAllMyNftPost(this.state?.postId)
            this.getCommentData(responseJson.data.attributes.post_id)
            this.getRepliedCommentData(responseJson.data.attributes.comment_id)
          }
          if (
            apiRequestCallId === this.commentDataApiId &&
            responseJson !== undefined
          ) {
            this.setState({ allComments: responseJson.data })
          }
          if (
            apiRequestCallId === this.commentReplyDataApiId &&
            responseJson !== undefined
          ) {
            this.setState({ allRepliedComments: responseJson.data })
          }
          if (
            apiRequestCallId === this.createLikeonCommentApiId &&
            responseJson !== undefined
          ) {
            this.getCommentData(responseJson.data.attributes.post_id)
            this.getRepliedCommentData(responseJson.data.attributes.comment_id)
            this.getAllMyNftPost(this.state?.postId)
          }
          if (
            apiRequestCallId === this.reportReasonsApiId &&
            responseJson !== undefined
          ) {
            this.setState({ ...this.state, reportReasons: responseJson.data })
          }
          if (
            apiRequestCallId === this.postReportApiId &&
            responseJson !== undefined
          ) {
            this.setState({ ...this.state, confirmReportDialog: false })
            toast.success(responseJson?.message, { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.likePostApiId &&
            responseJson !== undefined
          ) {
            //@ts-ignore
            this.props.userLogout()
            //@ts-ignore
            const b = document.querySelector("#likePost");
            //@ts-ignore
            b.setAttribute("style", "color:#3498db")

            this.getAllMyNftPost(this.state?.postId)
          }
          if (
            apiRequestCallId === this.dislikePostApiId &&
            responseJson !== undefined
          ) {
            //@ts-ignore
            this.props.userLogout()
            //@ts-ignore
            const b = document.querySelector("#likePost");
            //@ts-ignore
            b.setAttribute("style", "color:grey")

            this.getAllMyNftPost(this.state?.postId)
          }
          if (
            apiRequestCallId === this.editCommentApiId &&
            !!responseJson
          ) {
            this.getCommentData(responseJson.data.attributes.post_id)
            this.setState({ editCommentId: 0 })
          }
          if (
            apiRequestCallId === this.deleteCommentApiId &&
            !!responseJson
          ) {
            this.getCommentData(responseJson.data.attributes.post_id)
            this.setState({ deleteCommentId: 0 })
          }
          if (
            apiRequestCallId === this.deletePostApiId &&
            !!responseJson
          ) {
            //@ts-ignore
            this.props.history.goBack()
          }
          if (
            apiRequestCallId === this.updateApiCallId &&
            responseJson !== undefined
          ) {
            this.getAllMyNftPost(responseJson.data.id)
            this.setState({ editPostId: 0 })
          }
        } else {
          this.parseApiCatchErrorResponse(errorReponse);
          const errors = responseJson?.errors;


          if (errors) {

              toast.error("errors", { position: toast.POSITION.BOTTOM_RIGHT })

          }
        }
      }
      // Customizable Area End
    }
  }
}
