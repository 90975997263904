import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { createRef } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
};

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  submitBtn: boolean;
  reCaptchaVarified: boolean;
  siteKeyOfCaptcha: string;
  openDialoug:boolean;
  openDialougtutorial:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  checkBtn:number;
  currentCountryCode: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      submitBtn: false,
      reCaptchaVarified: false,
      siteKeyOfCaptcha: configJSON.siteKeyOfCaptcha,
      openDialoug:false,
      openDialougtutorial: false,
      // Customizable Area End
    };

    // Customizable Area Start
    
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.emailInputLabel;
    this.labelPassword = configJSON.passwordInputLabel;
    this.labelRePassword = configJSON.repeatPasswordInputLabel;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.checkBtn=0
    // Customizable Area End
  }
  //Customizable area start

  async componentDidMount(){
    //@ts-ignore
    const b = document.querySelector("#submitBTN");
    //@ts-ignore
    b.setAttribute("style", 'background-color: rgb(24, 130, 173); color: #ffffff52; height: 50px; margin-top: 25px; padding: 10px 30px; text-transform: none; font-size: 16px; font-family: Poppins, sans-serif;') 
  }

  _reCaptchaRef = createRef();

  SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .matches(/^[A-Za-z ._-]+$/,"Numbers & character not allowed")
      .required(configJSON.firstNameRequire),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .matches(/^[A-Za-z ._-]+$/,"Numbers & character not allowed")
      .required(configJSON.lastNameRquire),
    email: Yup.string()
      .required(configJSON.emailRequire)
      .matches(configJSON.emailRegex, configJSON.emailInValid),
    password: Yup.string()
      .required(configJSON.passwordRequire)
      .min(8, configJSON.passwordMinlength)
      .matches(configJSON.passwordRegex, configJSON.passwordInValid),
    repeatPassword: Yup.string()
      .required(configJSON.repeatPasswordRequire)
      .oneOf([Yup.ref("password"), null], configJSON.errorBothPasswordsNotSame),
    termsAndcondition: Yup.string(),
  });

  onRecaptchaChange = (value: any) => {
    try {
      if (value) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  checkDisabled = (errors: any, captcha: any) => {
    if (!Object.keys(errors).length && captcha) {
      //@ts-ignore
      const b = document.querySelector("#submitBTN");
      //@ts-ignore
      b.setAttribute("style", 'background-color: rgb(24, 130, 173); color: white; height: 50px; margin-top: 25px; padding: 10px 30px; text-transform: none; font-size: 16px; font-family: Poppins, sans-serif;')
      this.checkBtn = 1;
      return false;
    } else if (this.checkBtn === 1) {

      //@ts-ignore
      const b = document.querySelector("#submitBTN");
      //@ts-ignore
      b.setAttribute("style", 'background-color: rgb(24, 130, 173); color: #ffffff52; height: 50px; margin-top: 25px; padding: 10px 30px; text-transform: none; font-size: 16px; font-family: Poppins, sans-serif;')
      return true;
    } else {
      return true;
    }
  }

  doSignup(values: any) {
     //@ts-ignore
     const b = document.querySelector("#submitBTN");
     //@ts-ignore
     b.setAttribute("disabled", true) 
    this.createAccount(values)
  }

  createAccount(values:any): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      email: values.email,
      password: values.password,
      first_name: values.firstName,
      last_name: values.lastName
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

   runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.createAccountApiCallId &&
            responseJson !== undefined
          ) {
               //@ts-ignore
               this.props.history.push({
                pathname: '/profile',
                state: { detail: responseJson }
              })
          }
        }
      } else {
       // this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        if (errors) {
          toast.error(errors[0].account,{position: toast.POSITION.BOTTOM_RIGHT})
          return
        }

      }
    }
  }
  // Customizable Area End
}
