import React from "react";

import NavbarController, {
  Props} from "./NavbarController.web";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AddIcon from '@material-ui/icons/Add';
import TextsmsIcon from '@material-ui/icons/Textsms';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import SettingsIcon from '@material-ui/icons/Settings';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Cookies from 'js-cookie';
import Button from "@material-ui/core/Button";
import { Web3ReactProvider } from "@web3-react/core";
import {
  Menu,
  MenuList,
  MenuButton,
} from "@reach/menu-button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Image from "material-ui-image";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';;
import { getWeb3ReactContext } from "@web3-react/core";
import { postCamera } from "./assets";
import Avatar from '@material-ui/core/Avatar';
import { styled } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
//@ts-ignore
import {ActionCableConsumer} from 'react-actioncable-provider'
import Badge from '@material-ui/core/Badge';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
const axios = require('axios');
import { connect } from "react-redux";
import { Scrollbar } from "react-scrollbars-custom";
//@ts-ignore
import {USER_LOGOUT,USER_CHANGE,GET_USER,DELETE_FRIENDLIST,GET_CLIENT, SET_NOTIFICATIONS} from "../../../web/src/allActions.js";
import { Steps } from "intro.js-react";
import { trending } from "./assets";
 class Navbar extends NavbarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    const { classes,notificationsList, profileData: {
      profileImage,
      fullName
    } } = this.props
    const { 
      isSearchHistory, 
      history, 
      createPostText,
      stepsEnabled,
      steps,
      initialStep
    } = this.state
    const web3ReactContext = getWeb3ReactContext();
    const Input = styled('input')({
      display: 'none',
    });

    const handleUserRedirect = (notification: any) => {
      if (!!notification?.attributes?.sender_name) {
        this.props.history.push({
          pathname: '/' + notification.attributes?.sender_name,
          state: { detail: notification.attributes?.sender_name }
        })
      } else {
        this.props.history.push({
          pathname: '/user/' + notification.attributes.sender_id,
          state: { detail: notification.attributes.sender_id }
        })
      }
    }

    const handleRedirectHistory = (value: string | number, isUserName: boolean) => {
      if (isUserName) {
        this.props.history.push({
          pathname: '/' + value,
          state: { detail: value }
        })
      } else {
        this.props.history.push({
          pathname: '/user/' + value,
          state: { detail: value }
        })
      }
    }

    return (
      // Customizable Area Start
      <>
        <Steps
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            options={{
              hidePrev: true,
              doneLabel: "Finish",
              keyboardNavigation: true
            }}
            onExit={this.handleCreatePostTutorialsClose}
        />
        <ActionCableConsumer
          channel={{ channel: 'RoomsChannel', user: this.state.loginId }}
          onReceived={(res: any) => {
            Cookies.set('notification', res.count)
            this.setState({ rooms: res.count })
          }}
        />
        <Box>
          <AppBar position="static" className={classes.header}>
            <Toolbar variant="dense">
              <Typography variant="h6" color="inherit" component="div" style={{ width: "100%" }}>
                <div style={{ display: 'flex', justifyContent: "space-between", }}>
                  <div style={{ display: 'flex', alignItems: "center"}}>
                    <div className={`${classes.logo} ${classes.pointer}`} style={{ display: 'inline-flex'}} onClick={() => this.GoToHome(this.props)}>
                    <svg version="1.2" viewBox="0 0 108 29" width="108" style={{ fill: "#ffffff" }}>
                      <g id="group_logo-header">
                        <path id="Fill 37" fill-rule="evenodd" d="m12.6 13.3q0 1.4-0.6 2.5-0.6 1-1.6 1.5-0.9 0.6-2 0.6-1.1 0-2.1-0.6-0.9-0.5-1.5-1.6-0.6-1.1-0.6-2.5 0-1.4 0.6-2.5 0.6-1 1.5-1.5 1-0.6 2.1-0.6 1.1 0 2 0.6 1 0.5 1.6 1.6 0.6 1 0.6 2.5zm-11.5-4.3q-0.9 1.8-0.9 4.2 0 2.4 0.9 4.3 1 1.9 2.6 2.9 1.7 1 3.6 1 1.8 0 3.1-0.7 1.4-0.7 2.1-1.9v2.3h4.1v-21h-4.1v7.5q-0.8-1.1-2.2-1.8-1.4-0.7-2.9-0.7-2 0-3.7 1-1.6 1-2.6 2.9z"/>
                        <path id="Fill 38" fill-rule="evenodd" d="m23.5 11.8q0.2-1.6 1.2-2.5 1-0.9 2.5-0.9 1.6 0 2.7 0.9 1.1 1 1.1 2.5zm11.5 2.7q0.1-0.7 0.1-1.6 0-2.3-1-4.1-1-1.7-2.8-2.7-1.7-1-4-1-2.3 0-4.1 1-1.8 1-2.8 2.9-1 1.8-1 4.3 0 2.4 1 4.2 1 1.9 2.8 2.9 1.8 1 4.1 1 2.8 0 4.8-1.5 1.9-1.4 2.6-3.7h-4.3q-0.9 1.9-3.2 1.9-1.5 0-2.6-1-1-0.9-1.1-2.6z"/>
                        <path id="Fill 39" fill-rule="evenodd" d="m41 11.8q0.3-1.6 1.3-2.5 1-0.9 2.5-0.9 1.5 0 2.6 0.9 1.1 1 1.1 2.5zm11.5 2.7q0.1-0.7 0.1-1.6 0-2.3-0.9-4.1-1-1.7-2.8-2.7-1.8-1-4-1-2.4 0-4.2 1-1.7 1-2.7 2.9-1 1.8-1 4.3 0 2.4 1 4.2 1 1.9 2.8 2.9 1.8 1 4.1 1 2.8 0 4.7-1.5 2-1.4 2.6-3.7h-4.3q-0.9 1.9-3.1 1.9-1.6 0-2.6-1-1.1-0.9-1.2-2.6z"/>
                        <path id="Fill 40" fill-rule="evenodd" d="m96.5 11.8q0.2-1.6 1.2-2.5 1.1-0.9 2.5-0.9 1.6 0 2.7 0.9 1.1 1 1.1 2.5zm11.5 2.7q0.1-0.7 0.1-1.6 0-2.3-1-4.1-1-1.7-2.7-2.7-1.8-1-4.1-1-2.3 0-4.1 1-1.8 1-2.8 2.9-1 1.8-1 4.3 0 2.4 1 4.2 1 1.9 2.8 2.9 1.9 1 4.1 1 2.8 0 4.8-1.5 1.9-1.4 2.6-3.7h-4.3q-0.9 1.9-3.2 1.9-1.5 0-2.5-1-1.1-0.9-1.2-2.6z"/>
                        <path id="Fill 41" fill-rule="evenodd" d="m67.4 15.8q-0.5 1-1.5 1.5-1 0.6-2.1 0.6-1.1 0-2-0.6-1-0.5-1.5-1.6-0.6-1.1-0.6-2.5 0-1.4 0.6-2.5 0.5-1 1.5-1.5 0.9-0.6 2-0.6 1.1 0 2.1 0.6 1 0.5 1.5 1.6 0.6 1 0.6 2.5 0 1.4-0.6 2.5zm4.6 5.5v-15.9h-4v2.2q-0.8-1.1-2.1-1.8-1.3-0.7-3.1-0.7-2 0-3.6 1-1.7 1-2.6 2.9-1 1.8-1 4.2 0 2.4 1 4.3 0.9 1.9 2.6 2.9 1.6 1 3.6 1 1.7 0 3.1-0.7 1.3-0.8 2.1-1.9v2.5q0 2.1-1.1 3.1-1.1 1-2.8 1-1.5 0-2.5-0.6-1-0.6-1.4-1.7h-3.9q0.3 2.7 2.4 4.2 2.2 1.6 5.5 1.6 2.5 0 4.3-1 1.8-1 2.6-2.7 0.9-1.8 0.9-3.9z"/>
                        <path id="Fill 42" fill-rule="evenodd" d="m79 11.8q0.2-1.6 1.2-2.5 1-0.9 2.5-0.9 1.6 0 2.6 0.9 1.1 1 1.1 2.5zm11.5 2.7q0.1-0.7 0.1-1.6 0-2.3-0.9-4.1-1-1.7-2.8-2.7-1.8-1-4-1-2.4 0-4.1 1-1.8 1-2.8 2.9-1 1.8-1 4.3 0 2.4 1 4.2 1 1.9 2.8 2.9 1.8 1 4.1 1 2.8 0 4.7-1.5 2-1.4 2.6-3.7h-4.2q-1 1.9-3.2 1.9-1.5 0-2.6-1-1-0.9-1.2-2.6z"/>
                      </g>
                    </svg>
                    </div>
                    <ClickAwayListener onClickAway={() => this.setState({ ...this.state, isSearchHistory: false })}>
                      <div style={{ position: 'relative', }} >
                        <TextField
                          className={classes.searchBar}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                {this.state.searchLoder ? <CircularProgress size={23} style={{ color: '#ffffff' }}/> :
                                <CloseIcon onClick={() => {
                                  //@ts-ignore
                                  document.getElementById('searchNftAndUsers').value = ''
                                  !!this.props.makeEmptyUserAndPost && this.props.makeEmptyUserAndPost()
                                }} />}
                              </InputAdornment>
                            ),
                          }}
                          onFocus={() => this.setState({ ...this.state, isSearchHistory: true })}
                          id="searchNftAndUsers"
                          placeholder="Search NFTs or users"
                          onChange={(e: any) => {
                            this.getSearching(e.target.value)
                          }}
                          onKeyDown={(event: React.KeyboardEvent) => this.handleSearchKeyDown(event)}
                          autoComplete="off"
                        />
                        {isSearchHistory && !!history && <div className={classes.searchHistory}>
                          <div className={(history?.nfts?.length > 0 || history?.users?.length > 0 || history?.posts?.length > 0) ? classes.searchWrapper : ""}>
                            <h2>Search history</h2>
                            {history?.search_history?.map((record: any, historyIndex: number) => {
                              return (
                                <div key={historyIndex} className={classes.searchItem} onClick={() =>
                                  this.handleHistoryClick(record?.search_params)
                                }>
                                  <IconButton>
                                    <RestoreOutlinedIcon />
                                  </IconButton>
                                  <p className={classes.pointer}>{record?.search_params}</p>
                                  <IconButton style={NavbarStyles.iconStyle}
                                    onClick={(event) => {
                                      event.stopPropagation()
                                      this.deleteSearchHistory(record?.id)
                                    }}>
                                    <CloseIcon />
                                  </IconButton>
                                </div>
                              )
                            })}
                          </div>
                          {history?.posts?.length > 0 && <div className={history?.nfts?.length > 0 ? classes.searchWrapper : ""}>
                            <h2>Posts</h2>
                            {history?.posts?.slice(0, 2)?.map((post: any, historyPostIndex: number) => {
                              return (
                                <div key={historyPostIndex} className={classes.searchItem} onClick={() => {
                                  this.props.history.push({
                                    pathname: '/post/' + post.id,
                                    state: { detail: post.id }
                                  })
                                }}>
                                  <IconButton>
                                    <SearchIcon />
                                  </IconButton>
                                  <p className={classes.pointer}>{post?.description}</p>
                                </div>
                              )
                            })}
                          </div>}
                          {history?.nfts?.length > 0 && <div className={history?.users?.length > 0 ? classes.searchWrapper: ""}>
                            <h2>NFTs</h2>
                            {history?.nfts?.slice(0, 3)?.map((nft: any, historyNFTIndex: number) => {
                              return (
                                <div key={historyNFTIndex} className={classes.searchItem} onClick={() => {
                                  if (nft?.user_name) handleRedirectHistory(nft?.user_name, true)
                                  else handleRedirectHistory(nft.user_id, false)
                                }}>
                                  <IconButton>
                                    <SearchIcon />
                                  </IconButton>
                                  <p className={classes.pointer}>{nft?.name}</p>
                                </div>
                              )
                            })}
                          </div>}
                          {history?.users?.length > 0 && <div>
                            <h2>Users</h2>
                            {history?.users?.slice(0, 2)?.map((user: any, historyUserIndex: number) => {
                              return (
                                <div key={historyUserIndex} className={`${classes.searchItem} ${classes.pointer}`} onClick={() => {
                                  if (user?.user_name) {
                                    this.props.history.push({
                                      pathname: '/' + user.user_name,
                                      state: { detail: user.user_name }
                                    })
                                  } else {
                                    this.props.history.push({
                                      pathname: '/user/' + user.id,
                                      state: { detail: user.id }
                                    })
                                  }
                                }}>
                                  <Avatar src={user?.image} className={classes.avatar} >{user?.full_name.slice(0, 1)}</Avatar>
                                  <p>{user?.full_name}</p>
                                </div>
                              )
                            })}
                          </div>}
                        </div>}
                      </div>
                    </ClickAwayListener>
                    <IconButton className={`${classes.homeIcon} home-icon`} onClick={() => this.GoToHome(this.props)} id='homeIcon'>
                      <HomeOutlinedIcon />
                    </IconButton>
                    <IconButton className={`${classes.favoriteIcon} trending-icon`} onClick={() => {
                      this.props.history.push('/trendingpost')
                    }} id='trendIcon'>
                      <img src={trending} alt="trending-logo" /><p>Trending</p>
                    </IconButton>
                  </div>
                  <div style={{ display: 'flex', alignItems: "center", }}>
                    <div className={classes.profileImg}>
                      <Avatar src={
                        profileImage || this.state.profileImage || "https://www.studyheights.com/assets/img/Universitylogo/default.png"} />
                      <Button style={{ color: "#fffffe", textTransform: "capitalize", fontWeight: 600, fontSize: "14px", fontFamily: "'Roboto', sans-serif", letterSpacing: "0" }} onClick={() => this.GoToMyProfile(this.props)}>
                        {fullName || this.state.fullName}
                      </Button>
                    </div>
                    <IconButton className={classes.createPost} onClick={() => this.setState({ open: true })}>
                      <AddIcon /> Create post
                    </IconButton>
                    <IconButton className={classes.iconBtn} onClick={() => {
                      const accountMyId = Cookies.get("Login_Id")
                      this.props.history.push({
                        pathname: '/chat',
                        state: {
                          //@ts-ignore
                          receiveId: this.props?.chatUserList[0]?.userId,
                          //@ts-ignore
                          accountId: accountMyId
                        }
                      })
                    }}>
                      <TextsmsIcon />
                    </IconButton>
                    <Menu>
                      <MenuButton style={{ backgroundColor: 'inherit', border: 'none' }}>
                        <IconButton className={classes.iconBtn} onClick={() => this.getAllNotification()}>
                          {(this.state.rooms > 0) ? (
                            <Badge badgeContent={this.state.rooms} color="secondary">
                              <NotificationsNoneIcon />
                            </Badge>
                          ) : (
                            <NotificationsNoneIcon />
                          )}
                        </IconButton>
                      </MenuButton>
                      {this.state.notificationLoader ?
                        <MenuList className={classes.notificationMenu} style={{ padding: '20px'}} >
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress size={20} style={{ color: '#0388c9' }}/>
                          </div>
                        </MenuList>
                      : notificationsList.length > 0 ? (
                        <MenuList className={classes.notificationMenu}>
                          <span className={classes.triangle}></span>
                          <Scrollbar className={`${classes.customScroll} ${classes.chatCustomScroll}`}>
                          <div className={classes.sidebarTitle}>
                            <h2>Notifications</h2>
                          </div>
                          <div className={classes.notificationWrapper}>
                            {notificationsList?.map((notification: any, index: number) => {
                              const { id,
                                post_id,
                                headings,
                                sender_id,
                                sender_name,
                                sender_fullname,
                                connection,
                                is_read,
                                contents,
                                image } = notification?.attributes

                              return (
                                <div className={classes.notificationItem} key={index} onClick={() => {
                                  !is_read && this.readNotification(id)
                                  if (headings === "New comment on post" || headings === "Reply on your comment") {
                                    this.props.history.push({
                                      pathname: '/post/' + post_id,
                                      state: { detail: post_id }
                                    })
                                    if (this.props.callComponentDidMountFromSingle !== undefined) {
                                      this.props?.callComponentDidMountFromSingle(post_id)
                                    }
                                  } else if (sender_id || sender_name) {
                                    handleUserRedirect(notification)
                                  }
                                }
                                }>
                                  <Avatar
                                    onClick={(event) => {
                                      event.stopPropagation()
                                      handleUserRedirect(notification)
                                    }}
                                    src={image || "Remy Sharp"} />
                                  <p>
                                    {(headings === "New comment on post" || headings === "Reply on your comment") &&
                                      <p className={classes.notificationName}
                                        onClick={(event) => {
                                          event.stopPropagation()
                                          handleUserRedirect(notification)
                                        }}>
                                        {sender_fullname}
                                      </p>}

                                    {contents}

                                    {(headings === "Friend Request" || headings === "Friend Request Accepted") &&
                                      <p className={classes.notificationName}
                                        onClick={(event) => {
                                          event.stopPropagation()
                                          handleUserRedirect(notification)
                                        }}>{sender_fullname}
                                        {connection?.status === "pending" ? <>
                                          <div>
                                            <Button onClick={(event) => {
                                              event.stopPropagation()
                                              !is_read && this.readNotification(id)
                                              this.acceptRequest(sender_id)
                                            }} className={classes.btnRequest}><DoneOutlinedIcon /> Accept</Button>
                                            <Button onClick={(event) => {
                                              event.stopPropagation()
                                              !is_read && this.readNotification(id)
                                              this.rejectRequest(connection?.id)
                                            }} className={classes.btnReject}><CloseIcon /> Reject</Button>
                                          </div>
                                        </> : <span>Request accepted</span>}
                                      </p>}

                                    <span>{notification.attributes.created_at}</span>
                                  </p>
                                  {!is_read && (<FiberManualRecordIcon style={{ color: '#f50057', fontSize: '10px' }} />)}
                                </div>
                              )
                            })
                            }
                          </div>
                          </Scrollbar>
                        </MenuList>
                      ) :
                        (<MenuList className={classes.notificationMenu}>
                          <span className={classes.triangle}></span>
                          <div className={classes.noNotification}>
                            You don't have any notifications yet!
                          </div>
                        </MenuList>)}
                    </Menu>
                    <IconButton className={classes.iconBtn} onClick={() => this.GoToSetting(this.props)}>
                      <SettingsIcon />
                    </IconButton>
                  </div>
                </div>
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
        <Web3ReactProvider getLibrary={this.getLibrary}>
          <web3ReactContext.Consumer>
            {(value) => (
              <div>
                <Dialog className={classes.createPostModal} open={this.state.open}
                  onClose={this.handleResetCreatePost} aria-labelledby="responsive-dialog-title">
                  <DialogTitle style={NavbarStyles.modalTitle}>
                    Create post
                    <span className={classes.infoTxt}><InfoOutlinedIcon/> MetaMask wallets and Opensea assets supported</span>
                    <IconButton className={classes.closeBtn} onClick={this.handleResetCreatePost}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <div className={classes.profileImg} style={{ display: "flex", justifyContent: "flex-start", margin: "10px 0 0 0" }}>
                      <Avatar src={profileImage || "https://www.studyheights.com/assets/img/Universitylogo/default.png"} alt="avatar" />
                      <p>{fullName}</p>
                    </div>
                    <form onSubmit={(e) => {
                      e.preventDefault()
                    }} className={classes.createPostForm}>
                      <TextField
                        style={NavbarStyles.modalTextInfo}
                        id="bio"
                        value={createPostText}
                        onChange={(event) => this.setState({ ...this.state, createPostText: event.target.value  })}
                        multiline
                        fullWidth
                        variant="standard"
                        rows={3}
                        placeholder="Write something here.."
                        required
                      />
                      {!this.state.keepAllImage && this.state.selectedNFTUrl !== '' && <IconButton onClick={() => {
                        this.setState({ keepAllImage: true, selectedNFTUrl: '', tokenId: '' })
                      }} >
                        <CloseIcon />
                      </IconButton>}
                      {!this.state.keepAllImage && (
                        <div className={classes.nftImgWrap}>
                          <Image src={this.state.selectedNFTUrl || postCamera} id="nftImg" className={classes.nftImg} />
                        </div>
                      )}
                      {
                        this.state.keepAllImage && !this.state.loader? (
                          <div className={`${classes.createInnerModal} tutorial-all-nfts`}>
                          {this.state.allAssets.map((element: any, index: any) => {
                            return (
                              <div className={classes.nftImage} key={index}
                              >
                                <Image src={element?.image_url}
                                  onClick={() => this.selectImg(
                                    element?.image_url, element?.token_id
                                  )}
                                />
                              </div>
                            );
                          })}
                          {(this.state.allAssets?.length - 6) < 0 && (
                            [...Array(6 - this.state.allAssets?.length)].map((_, index: number) => (
                              <div className={classes.lessNFTBox} key={index}>
                                <div>
                                NFT
                                </div>
                              </div>
                            ))
                          )}
                          </div>) : (
                            this.state.loader && (<div className={classes.createInnerModal} style={{ justifyContent: 'center' }}>
                              <CircularProgress style={{ color: '#0388c9' }} />
                            </div>)
                          )
                      }
                    </form>
                  </DialogContent>
                  <DialogActions>
                  {!this.state.keepAllImage && <label>
                      <Button disabled={!!this.state.selectedNFTUrl} variant="contained" component="span" fullWidth style={NavbarStyles.buttonUpload} onClick={() => this.handleUpload(value)}>
                        {this.state.metamaskBTN
                          ? <>Connecting...&nbsp;&nbsp;<CircularProgress color="inherit" /></>
                          : 'Upload NFT'}
                      </Button>
                    </label>}
                      <label>
                        <Button
                          type='submit'
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            this.state.confirm ? this.confirmUpload() :
                              this.setState({ confirm: true })
                              this.getCompletedTutorials()}}
                          style={!this.state.createPostText ? {...NavbarStyles.buttonPriview, opacity: 0.5} : NavbarStyles.buttonPriview}
                          className="tutorial-confirm-upload"
                          disabled={!this.state.createPostText}
                        >
                          {this.state.uploadNFTLoader ? <CircularProgress size={35} color="inherit" /> : this.state.confirm ? 'Confirm Upload' : 'Preview Post'}
                        </Button>
                      </label>
                  </DialogActions>
                </Dialog>
              </div>
            )}
          </web3ReactContext.Consumer>
        </Web3ReactProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const NavbarStyles = {
  modelPopup: {
    "& fieldset":{
      display:"none",
    },
  },
  infoTxt: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    color: "#565a57",
    justifyContent: "center",
    lineHeight: "1",
    "& svg": {
      height: "17px",
      widht: "17px",
      paddingRight: "6px"
    }
  },
  textField: {
    padding:"0",
  },
  nftImgWrap:{
    "& div":{
      paddingTop:"0 !important",
    },
  },
  createInnerModal: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    border: '1.8px solid #6e7370',
    borderRadius: '5px',
    padding:'4px',
    minHeight: '230px'
  },
  nftImg:{
    width:"100% !important",
    height:"auto !important",
    position:"relative !important",
  },
  uploadNFTChild: {
    borderRadius: "5px",
    padding: "10px",
    overflow: "auto",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    boxSizing: "border-box",
  },
  nftImage: {
    maxWidth: "33.33%",
    flex: "0 0 33.33%",
    boxSizing: "border-box",
    padding: "3px",
    "& > div": {
      background: "#dedcdf !important",
      borderRadius: "5px",
      overflow: "hidden"
    }
  },
  lessNFTBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1.8px dashed #6e7370',
    borderRadius: '5px',
    margin: '3px',
    width: '31.5%',
    height: '168px',
    color:'#565a57',
    backgroundColor: '#e5e6eb',
    fontFamily: "'Poppins', sans-serif",
  },
  closeBtn: {
    position: "absolute",
    top: "15px",
    background:" #e5e6eb",
    right: "15px",
    padding: "5px",
  },
    header: {
      backgroundColor:"#0388c9",
      fontFamily: "'Poppins', sans-serif",
    },
    logo:
    {
      fontSize: "36px",
      fontWeight: "600",
      margin:"0",
      padding: "6px 0",
      "@media (max-width: 767px)": {
        fontSize: "26px",
      }
    },
    menuListData: {
      whiteSpace: "pre-wrap",
      width: "346px",
      backgroundColor: "#fffffe",
      borderRadius: "10px",
      position: "relative",
      marginTop: "20px",
      "& button": {
        width: "100%",
        padding: "5px 15px",
        "& h4": {
          display: "flex",
          alignItems: "center",
          fontSize: "16px",
          color: "#565a57",
          fontWeight: "700",
          fontFamily: "'Roboto', sans-serif",
          textTransform: "none",
          flexWrap: "wrap",
          margin: "0",
          width: "100%",
          "& img": {
            width: "24px",
            marginRight: "12px",
          }
        },
      },
    },
    triangle: {
      position: "absolute",
      width:"16px",
      height:"16px",
      borderBottom: "1px solid transparent",
      borderRight: "1px solid transparent",
      borderTop:"1px solid rgba(0,0,0,0.25)",
      borderLeft:"1px solid rgba(0,0,0,0.25)",
      transform:"rotate(45deg)",
      backgroundColor:"#fffffe",
      top: "-9px",
      left: "20px",
    },
    notificationItem: {
      display: "flex",
      padding: "5px",
      margin: "5px 0",
      cursor:"pointer",
      borderRadius: "5px",
      "&:hover":{
        background: "#ebecec"
      },
      "& p": {
        lineHeight: "1.5",
        width:"100%",
        display: "block"
      }
    },
    chatCustomScroll: {
      height: "calc(100vh - 120px) !important",
    },
    customScroll: {
      "& .ScrollbarsCustom-Track": {
        width: "4px !important",
        background: "transparent !important"
      }
    },
    sidebarCustomScroll: {
      height: "calc(100vh - px) !important",
    },
    btnWrapper: {
      paddingTop: "10px",
      paddingBottom: "10px",
      borderTop: "1px solid #dcdcd7",
      "& button": {
        border: "0",
        textTransform: "none",
        fontSize: "14px",
        fontWeight: "700",
        fontFamily: "'Roboto', sans-serif",
        minWidth: "initial",
        minHeight: "40px",
        padding: "14px 20px",
        opacity: "1",
        color: "#6e7370",
        lineHeight: "1",
        zIndex: "1",
        borderRadius: "20px",
        backgroundColor: "#ebecec"
      },
      "& .MuiTabs-indicator": {
        zIndex: "0",
        height: "100%",
        borderRadius: "20px",
      },
      "& .MuiTabs-fixed": {
        height: "100%"
      }
    },
    badgeNotification: {
      display: "inline-flex",
      width: "20px",
      height: "20px",
      background: "#0388c9",
      borderRadius: "50%",
      alignItems:"center",
      justifyContent: "center",
      color: "#ffffff",
      fontSize: "12px",
      fontWeight: "500",
    },
    noNotification: {
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "1",
      fontFamily: "'Roboto', sans-serif",
      color: "#000307",
      margin: "0",
      textAlign: "center",
      padding: "0 0 20px"
    },
    notificationMenu: {
      whiteSpace: "pre-wrap",
      width: "400px",
      backgroundColor: "#fffffe",
      borderRadius: "5px",
      marginTop: "20px",
      padding: "20px 15px 0",
      border: "0",
      boxShadow: "0 12px 28px 0 rgba(0, 0, 0, 0.2)",
      overflow: "auto",
      position:"relative",
      "& h2": {
        fontSize: "18px",
        fontWeight: "700",
        lineHeight: "1",
        fontFamily: "'Roboto', sans-serif",
        color: "#000307",
        margin: "0",
      },
      "& p": {
        margin: "0",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "1.3",
        fontFamily: "'Roboto', sans-serif",
        "& span": {
          paddingTop: "5px",
          display: "block",
          width: "100%",
          color: "#0388c9",
          fontSize: "10px",
          fontWeight: "700",
          fontFamily: "'Roboto', sans-serif",
        },
        "& b": {
          fontWeight: "700",
          color: "#000307",
          fontSize: "16px"
        }
      },
      "& .MuiAvatar-root": {
        width: "50px",
        height: "50px",
        marginRight: "10px",
      }
    },
    buttonMore: {
      width: "42px",
      height: "42px",
      backgroundColor: "#ebecec",
      color: "#1f7fb6",
      borderRadius: "5px",
      "@media(max-width: 1199px)": {
        width: "30px",
        minWidth: "30px",
        height: "30px",
        "& .MuiSvgIcon-root":{
          width: "13px",
          height: "13px",
        }
      },
    },
    sidebarTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: "10px",
      borderBottom: "1px solid #dcdcd7",
    },
    buttonNew: {
      border: "1px solid #1f7fb6",
      backgroundColor: "#e4e5e4",
      height: "35px",
      marginRight: "10px",
      color: "#1f7fb6",
      fontSize: "16px",
      fontWeight: "700",
      borderRadius: "50px",
      boxShadow: "none !important",
      display: "inline-flex",
      alignItems: "center",
      "& .MuiButton-label": {
        textTransform: "none",
      },
    },
    btnReject: {
      border: "1px solid #1f7fb6",
      backgroundColor: "#e4e5e4",
      height: "35px",
      marginTop: "10px !important",
      marginBottom: "5px !important",
      marginRight: "10px !important",
      borderRadius: "50px",
      boxShadow: "none !important",
      display: "inline-flex",
      alignItems: "center",
      textTransform: "none",
      padding: "0 10px",
      "& .MuiButton-label": {
        color: "#1f7fb6 !important",
        textTransform: "none",
        fontSize: "14px !important",
        fontWeight: "700 !important",
        padding: "0",
        display: "inline-flex",
        alignItems: "center",
      },
      "& svg": {
        width: "17px",
        marginRight: "0",
      },
    },
    btnRequest: {
      border: "1px solid #1f7fb6",
      backgroundColor: "#1f7fb6",
      height: "35px",
      marginBottom: "5px !important",
      marginTop: "10px !important",
      marginRight: "10px",
      borderRadius: "50px",
      boxShadow: "none !important",
      display: "inline-flex",
      alignItems: "center",
      padding: "0 10px",
      "& .MuiButton-label": {
        color: "#ffffff !important",
        textTransform: "none",
        fontSize: "14px !important",
        fontWeight: "700 !important",
        padding: "0",
        display: "inline-flex",
        alignItems: "center",
      },
      "& svg": {
        width: "17px",
        marginRight: "0",
      },
      "&:hover": {
        "& .MuiButton-label": {
          color: "#1f7fb6 !important",
        }
      }
    },
    searchBar:{
      margin:"2px 20px",
      position: "relative",
      "&::before" : {
        position: "absolute",
        content: "",
        height: "10px",
        width: "2px",
        backgroundColor: "#ffffff",
        left: "0px"
      },
      "@media(max-width: 1199px)": {
        margin: "2px 10px",
        maxWidth: "200px",
      },
      "& .MuiInputBase-input": {
        padding:"0 10px",
        margin:"13px 0",
        borderLeft: "1px solid #ffffff"
      },
      "& .MuiInputBase-root" : {
        backgroundColor:"#35a0d4",
        color:"#fff",
        borderRadius:"30px",
        margin:"10px 0",
        cursor:"pointer",
      },
      "& .MuiOutlinedInput-notchedOutline" : {
        display:"none",
      }
    },
    homeIcon:{
      color:"#ffffff",
      padding: "18px 10px",
      borderBottom:"2px solid #ffffff",
      borderRadius:"0",
      fontWeight: "500",
      "& .MuiSvgIcon-root" :{
        fontSize:"2rem",

      }
    },
    favoriteIcon :{
      color:"#fff",
      padding: "10px 20px",
      borderRadius:"0",
      "& .MuiSvgIcon-root" :{
        fontSize:"2rem",
      },
      "@media(max-width: 1199px)": {
        padding: "10px",
      },
      "& p":{
        fontSize:"16px",
        fontWeight: "600",
        marginLeft:"10px"
      },
      "& div": {
        width: "30px",
        height: "30px",
        paddingTop: "0 !important",
        backgroundColor: "transparent !important",
      }
    },
    profileImg:{
      display:"flex",
      justifyContent: "center",
      alignItems: "center",
      "& div" :{
        width: "32px", 
        height: "32px",
        borderRadius:"50%",
        padding: "0 !important",
        "& img" : {
          maxHeight:"32px",
        }
      },
      "& p": {
        fontSize:"16px",
        fontWeight: "600",
        marginLeft:"10px",
        fontFamily: "'Poppins', sans-serif",
      }
    },
    createPost:{
      color:"#fff",
      backgroundColor:"#35a0d4",
      fontSize:"16px",
      fontWeight: "600",
      padding: "8px 15px 8px 9px",
      margin:"0 3px",
      borderRadius:"30px",
      
    },
    modalTitle:{
      color: "#000105",
      fontSize: "20px !important",
      textAlign: "center",
      borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
    },
    buttonUpload :{
      marginBottom : "10px",
      display: "block",
      background: "#d8dddd",
      border: "2px solid #1f7fb6",
      borderRadius: "5px",
      textAlign: "center",
      color: "#1f7fb6",
      fontWeight: 700,
      width: "100%",
      boxSizing: "border-box",
    },
    buttonPriview :{
      marginBottom : "10px",
      display: "block",
      background: "#1f7fb6",
      border: "2px solid #1f7fb6",
      borderRadius: "5px",
      textAlign: "center",
      color: "#ffffff",
      fontWeight: 700,
      width: "100%",
      boxSizing: "border-box",
    },
    createPostModal:{
      "& .MuiButton-label": {
        display: "flex",
        alignItems: "center",
      },
      "& .MuiDialog-paperWidthSm": {
        minWidth: "600px",
      },
      "& .MuiDialog-paper": {
        position: "relative",
        overflow: "visible"
      },
      "& .MuiInputBase-root": {
        padding: "0"
      },
      "& .MuiButton-root":{
        height: "43px",
        boxShadow: "none",
      },
      "& .MuiFormControl-root" :{
        padding: "10px 5px",
        width: "100%",
        "& .MuiInputBase-input":{
          color: "#565a57",
          opacity: "1",
          fontSize: "20px",
          fontWeight: "400",
          height: "40px",
          "&::placeholder": {
            color: "#565a57",
            opacity: "1",
          }
        }
      },
      "& .MuiDialogActions-root": {
        width: "100%",
        boxSizing: "border-box",
        flexWrap: "wrap",
        padding: "8px 24px",
        "& label": {
          width: "100%",
          boxSizing: "border-box",
          margin: "0",
          padding: "15px ​25px 5px",
        }
      },
      "& .MuiInput-underline" :{
        "&::before" :{
          display: "none"
        },
        "&::after" :{
          display: "none"
        }
      },
      "& .MuiCircularProgress-root": {
        width: "22px !important",
        height: "22px !important",
        position: "absolute"
      },
      
    },
    createPostForm: {
      position: "relative",
      "& .MuiIconButton-root": {
        position: "absolute",
        top: "50px",
        background: "#ffffff",
        borderRadius: "50%",
        zIndex: "9",
        width: "30px",
        height: "30px",
        right: "-10px",
        padding: "0",
        boxShadow: "1px 1px 3px rgb(0, 0, 0 ,20%)",
      }
    },
    iconBtn:{
      color:"#ffffff",
      backgroundColor:"#35a0d4",
      margin:"0 3px",
      padding: "8px",
      borderRadius: "50%",
      border: "none"
    },
    pointer: {
      cursor: "pointer !important"
    },
    notificationName: {
      display: "inline !important",
      fontWeight: "700 !important",
    },
    iconStyle: {
      background: "transparent",
      justifyContent: "flex-end",
      position: "absolute",
      right: "0",
      top: "0",
      margin: "0",
      width: "24px",
      height: "24px",
      padding: "3px",
      color: "#a9aaa9",
    },
    searchHistory: {
      position: "absolute",
      top: "calc(100% - 5px)",
      zIndex: "9",
      background: "#fffffe",
      borderRadius: "5px",
      padding: "15px 15px 0",
      left: "20px",
      right: "20px",
      boxShadow: "1px 1px 3px rgb(0, 0, 0 ,20%)",
      "& h2": {
        color: "#000105",
        margin: "0",
        paddingBottom: "10px",
        fontSize: "14px",
        fontWeight: "600",
        fontFamily: "'Poppins', sans-serif",
      },
      "& .MuiIconButton-root": {
        padding: "5px",
        background: "#f1f0ef",
        marginRight: "12px",
        width:"34px",
        height:"34px",
        "& svg": {
          width:"100%",
          height:"100%",
        }
      },
      "& p": {
        margin: "0",
        color: "#272a29",
        fontSize: "14px",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "500",
        width: "calc(100% - 70px)",
        "& span": {
          color: "#505452",
          fontSize: "10px",
          display: "flex",
        }
      },
    },
    searchHistoryItem: {
      border: "0 !important",
      margin: "0 !important",
      cursor: "pointer",
      "& .MuiIconButton-root": {
        background: "#1f7fb6",
        "& svg": {
          color: "#ffffff",
        },
      },
      "& p": {
        color: "#1f7fb6",
      }
    },
    searchItem: {
      display: "flex",
      alignItems: "center",
      paddingBottom: "14px",
      position: "relative",
    },
    searchWrapper: {
      borderBottom: "1px solid #d7d8d6",
      marginBottom: "10px"
    },
    avatar: {
      width: 28,
      height: 28,
      marginRight: '0.5em'
    }
  } as any;
// Customizable Area End
const mapDispatchToProps = (dispatch:any) => {
  return{
  userLogout:()=>{
    dispatch(USER_LOGOUT())
  },
  userChange:()=>{
		dispatch(USER_CHANGE())
	},
  getProfile:(user:any)=>{
    dispatch(GET_USER(user))
  },
  eraseFriendList:()=>{
    dispatch(DELETE_FRIENDLIST())
  },  
  addClient:(client:any)=>{
    dispatch(GET_CLIENT(client))
  },
  setNotifications: (notifications: any) => {
    dispatch(SET_NOTIFICATIONS(notifications))
  }
}
 }
 const mapStateToProps = (state:any) => {
  //replace Reducer name with state.'Your Reducer name' and .property
          
        return {
          list: state.takeAction.UserInfo,
          friendlist: state.takeAction.friendsList,
          clientList: state.takeAction.clientData[0],
          UserInfo:state.takeAction.UserInfo,
          chatUserList:state.takeAction.chatUserList,
          lastMegs:state.takeAction.lastMegs,
          profileData: state.takeAction.profileData,
          notificationsList: state.takeAction.notificationsList
        };
       // this.setState({ myAllPost: state.takeAction.allIndexPost})
        
        
      };
//@ts-ignore
const NavbarWithRouter =withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar) )
export default withStyles(NavbarStyles)(NavbarWithRouter)
// export default  (withRouter(Navbar as any) as any)