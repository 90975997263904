import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
// import Chat from "twilio-chat";
const Chat = require("twilio-chat");
//@ts-ignore
import { debounce} from 'lodash';
import firebase from "firebase/app";
import "firebase/messaging";
import { notificationSound } from "./assets";
const {baseURL} = require('../../../framework/src/config.js')
const axios = require('axios');
toast.configure()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  pageState:any;
  unfollowRequest:any;
  unfriendFunc:any;
  blockfriendFunc:any;
  friendUnfriendState:boolean;
  friendUnfollowState:boolean;
  friendBlockState:boolean;
  getFriendOfFriend:any;
  getFriendsList:any;
   getFriendProfile:any;
  // Customizable Area Start
  history: any
  location: string;
  state: any;
  messages: any;
  email: any;
  key: any;
  callDidMountHandle: string;
  FromHome?: any;
  doNotCall:any;
  parentComponentDidMount:any;
  callcomponentDidMountFromChat:any
  chatUserList?: any
  chatOneToOne?:any
  addClient: (client: any) => void
  userLogout: () => void
  // Customizable Area End
}

export interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  allFriendList:[];
  friendFirst_name:any;
  friendLast_name:any;
  openDialoug:boolean;
  friendId:any;
  openBlockDialogue:boolean;
  blockFriendId:any;
  checkUnfollow:boolean;
  //
  loading: boolean;
  text: string;
  channel: any;
  location: string;
  state: any;
  email: any;
  chatAuthToken: string;
  chat_Message: any;
  callChatUserJoinFunc:any;
  userName:any;
  allFriendsCopy: [];
  searchedValFriend:any;
  expanded:any;
  getConversation:any
  myloginId:any,
  checkProp:any;
  loadChat:any;
  isGettingList: boolean;
  newChatUsers: any;
  myEmailId:any
  cheking:any
  newChatLoader: boolean
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SidebarController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  ChatAuthTokenApiCallId: any;
  Chat_Token: any;
  Token: any;
  searchFriendcallId:any
  newChatUsersApiId: any
  blockUserApiId:any
  audio = new Audio(notificationSound)
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
         getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
       allFriendList:[],
       friendFirst_name:'',
       friendLast_name:'',
       openDialoug:false,
       friendId:'',
       openBlockDialogue:false,
       blockFriendId:'',
       checkUnfollow:false,
       //
       loading: false,
       text: "",
       chat_Message: null,
       channel: "",
       location: "",
       state: "",
       email: "",
       chatAuthToken: "",
       callChatUserJoinFunc:'',
       userName:'',
       allFriendsCopy:[],
       searchedValFriend:'',
       expanded:'',
       getConversation:'',
       myloginId:Cookies.get("Login_Id"),
       checkProp:'',
       loadChat:true,
       isGettingList: false,
       newChatUsers: [],
       myEmailId:Cookies.get('Login_Email'),
       cheking:'',
       newChatLoader: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area Start
  getFriendRequestsApiId: any;
  allFirendsApiId:any;
  
  async componentDidMount() {

    //@ts-ignore
    if (Cookies.get('Login_Token')) {
      this.getFriendsList()
      this.createClientComponent() 
    } else {
      this.logoutUser()
    }
    // Customizable Area End
  }

  logoutUser = () => {
    Cookies.remove('Login_Token')
    Cookies.remove("notification")
    Cookies.remove('Chat_Token')
    Cookies.remove('Login_Id')
    Cookies.remove('Login_Email')
    Cookies.remove('Account_Verified')
    if (!!this.props.userLogout) {
      this.props?.userLogout()
    }
    this.props.history.push('/login')
  }
  
  debounceLog = debounce((text: any) => {
    if (text) {
      this.searchFriend(text)
    } else {
      this.getFriendsList()
    }
  }, 500)

handleChangeDebounce=(value:any)=>{
    this.debounceLog(value)
}

handleBlockUser = (accountId: any): boolean => {
 const header = {
   "Content-Type": configJSON.validationApiContentType,
   "token" :Cookies.get('Login_Token')
 };

 const requestMessage = new Message(
   getName(MessageEnum.RestAPIRequestMessage)
 );

 this.blockUserApiId= requestMessage.messageId;
 requestMessage.addData(
   getName(MessageEnum.RestAPIResponceEndPointMessage),
   configJSON.blockUserEndPoint + accountId
 );

 requestMessage.addData(
   getName(MessageEnum.RestAPIRequestHeaderMessage),
   JSON.stringify(header)
 );
 requestMessage.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.httpPostMethod
 );
 runEngine.sendMessage(requestMessage.id, requestMessage);
 return true;
}

handleNewChatSearch = (value: string) => {
  if(!!value) {
    this.getNewChatUsers(value)
  } else {
    this.setState({ ...this.state, newChatUsers: [] })
  }
}
searchFriend=(value:any): boolean =>{

this.setState({searchedValFriend:value})

const header = {
 "Content-Type":configJSON.exampleApiContentType,
 "token": Cookies.get("Login_Token"),
};

const requestMessage = new Message(
 getName(MessageEnum.RestAPIRequestMessage)
);

this.searchFriendcallId = requestMessage.messageId;
requestMessage.addData(
 getName(MessageEnum.RestAPIResponceEndPointMessage),
 configJSON.searchFriendENdpoint+'='+value+'&user_id='+Cookies.get("Login_Id")
);

requestMessage.addData(
 getName(MessageEnum.RestAPIRequestHeaderMessage),
 JSON.stringify(header)
);
requestMessage.addData(
 getName(MessageEnum.RestAPIRequestMethodMessage),
 configJSON.httpGetMethod
);

runEngine.sendMessage(requestMessage.id, requestMessage);
//@ts-ignore
document.getElementById('searchingFrd').value=value
return true;
}

  getNewChatUsers = (value: string): boolean => {
    this.setState({ expanded: '', getConversation: '', newChatLoader: true })
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.newChatUsersApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.newChatUsersEndpoint + "=" + value
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getFriendsList():boolean{
    this.setState({ ...this.state, isGettingList: true })
    const header = {
      "Content-Type":configJSON.contentTypeApiGetUserProfile,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allFirendsApiId= requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.friendsListEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  createClientComponent = async (roomId?: any) => {
    //@ts-ignore
    if (this.props.list && this.props.chatUserList.length === 0 && this.props.lastMegs.length === 0) {
      //@ts-ignore
      const client = this.props.list

      try {
        const pushNotificationId = Cookies.get('Push_NotificationToken')
        await client.setPushRegistrationId('fcm', pushNotificationId)
        await client.subscribeToPushNotifications('fcm')
        if (client.user.friendlyName) {
          //@ts-ignore
          await client.user.updateFriendlyName(this.props?.myInfo[0]?.fullName)
        }
      } catch (error) {
        console.log(error);
      }

      let allChannel;
      try {
        allChannel = await client?.channels?.getChannels()
      } catch (error) {
        console.log(error);

      }
      try {
        //@ts-ignore
        allChannel?.items?.forEach(async (element: any) => {

          if (element?.channelState?.friendlyName && element?.channelState?.uniqueName) {
            let email = Cookies.get('Login_Email')
            let myLoginId = Cookies.get("Login_Id")
            const member = await element?.getUserDescriptors()

            if (email && myLoginId) {


              if (member?.state?.items[0]?.descriptor?.identity != email) {
                let otheruser = '';
                try {
                  if (member?.state?.items[0]?.descriptor?.identity) {
                    const otherUser = await element?.getMemberByIdentity(member?.state?.items[0]?.descriptor?.identity)

                    if (otherUser?.lastConsumedMessageIndex) {
                      otheruser = otherUser?.lastConsumedMessageIndex
                    }
                  }
                } catch (error) {
                  console.log(error);
                }
                //@ts-ignore
                if (this.props.addActiveUser && member?.state?.items[0]?.descriptor && (!this.props.activeUser.some(e => e?.identity === member?.state?.items[0]?.descriptor?.identity))) {
                  //@ts-ignore
                  this.props.addActiveUser(member?.state?.items[0]?.descriptor)
                }

                //@ts-ignore
                if (member?.state?.items[0]?.attributes?.name && member?.state?.items[0]?.attributes?.userId && this.props.addChatUsers && (!this.props?.chatUserList?.some(e => e?.name === member?.state?.items[0]?.attributes?.name))) {
                  //@ts-ignore
                  this.props.addChatUsers(member?.state?.items[0]?.attributes)

                  const messages = await element?.getMessages(1);

                  //@ts-ignore
                  if (messages.items.length !== 0 && this.props.addLastMsg) {
                    if (messages?.items[messages.items.length - 1]?.author != email) {
                      const detail = element?.getMemberByIdentity(email)
                      let userObj = {
                        userId: member?.state?.items[0]?.attributes?.userId,
                        lastMsg: messages.items[messages.items.length - 1].body,
                        lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
                        identity: messages?.items[messages.items.length - 1]?.author,
                        dateUpdated: messages?.items[messages.items.length - 1]?.dateUpdated,
                        allMsgIndex: messages?.items[messages.items.length - 1]?.index == 0 ? 1 : messages?.items[messages.items.length - 1]?.index,
                        getUnConsumedIndex: messages?.items[messages.items.length - 1]?.channel?.lastConsumedMessageIndex === null ? 1 : messages?.items[messages.items.length - 1]?.index - messages?.items[messages.items.length - 1]?.channel?.lastConsumedMessageIndex

                      }
                      //@ts-ignore
                      this.props.addLastMsg(userObj)

                    } else {
                      let userObj = {
                        userId: member?.state?.items[0]?.attributes?.userId,
                        lastMsg: messages.items[messages.items.length - 1].body,
                        lastConsumedMessageIndex: otheruser,
                        identity: messages?.items[messages.items.length - 1]?.author,
                        dateUpdated: messages?.items[messages.items.length - 1]?.dateUpdated,
                        allMsgIndex: messages?.items[messages.items.length - 1]?.index,
                        getUnConsumedIndex: messages?.items[messages.items.length - 1]?.index - messages?.items[messages.items.length - 1]?.channel?.lastConsumedMessageIndex

                      }
                      //@ts-ignore
                      this.props.addLastMsg(userObj)

                    }
                  }
                }
              }
              else if (member?.state?.items[1]?.descriptor?.identity != email) {
                let otheruser = '';
                try {
                  if (member?.state?.items[1]?.descriptor?.identity) {

                    const otherUser = await element?.getMemberByIdentity(member?.state?.items[1]?.descriptor?.identity)

                    if (otherUser?.lastConsumedMessageIndex) {
                      otheruser = otherUser.lastConsumedMessageIndex
                    }
                  }
                } catch (error) {
                  console.log(error);
                }

                //@ts-ignore
                if (this.props.addActiveUser && member?.state?.items[1]?.descriptor && (!this.props.activeUser.some(e => e?.identity === member?.state?.items[1]?.descriptor?.identity))) {
                  //@ts-ignore
                  this.props.addActiveUser(member?.state?.items[1]?.descriptor)
                }
                //@ts-ignore
                if (member?.state?.items[1]?.attributes?.name && member?.state?.items[1]?.attributes?.userId && this.props.addChatUsers && (!this.props?.chatUserList?.some(e => e?.name === member?.state?.items[1]?.attributes?.name))) {
                  //@ts-ignore
                  this.props.addChatUsers(member?.state?.items[1]?.attributes)

                  const messages = await element?.getMessages(1);
                  //@ts-ignore
                  if (messages.items.length !== 0 && this.props.addLastMsg) {
                    if (messages?.items[messages.items.length - 1]?.author != email) {
                      const detail = element?.getMemberByIdentity(email)
                      let userObj = {
                        userId: member?.state?.items[1]?.attributes?.userId,
                        lastMsg: messages.items[messages.items.length - 1].body,
                        lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
                        identity: messages?.items[messages.items.length - 1]?.author,
                        dateUpdated: messages?.items[messages.items.length - 1]?.dateUpdated,
                        allMsgIndex: messages?.items[messages.items.length - 1]?.index == 0 ? 1 : messages?.items[messages.items.length - 1]?.index,
                        getUnConsumedIndex: messages?.items[messages.items.length - 1]?.channel?.lastConsumedMessageIndex === null ? 1 : messages?.items[messages.items.length - 1]?.index - messages?.items[messages.items.length - 1]?.channel?.lastConsumedMessageIndex

                      }
                      //@ts-ignore
                      this.props.addLastMsg(userObj)

                    } else {

                      let userObj = {
                        userId: member?.state?.items[1]?.attributes?.userId,
                        lastMsg: messages.items[messages.items.length - 1].body,
                        lastConsumedMessageIndex: otheruser,
                        identity: messages?.items[messages.items.length - 1]?.author,
                        dateUpdated: messages?.items[messages.items.length - 1]?.dateUpdated,
                        allMsgIndex: messages?.items[messages.items.length - 1]?.index,
                        getUnConsumedIndex: messages?.items[messages.items.length - 1]?.index - messages?.items[messages.items.length - 1]?.channel?.lastConsumedMessageIndex

                      }
                      //@ts-ignore
                      this.props.addLastMsg(userObj)

                    }
                  }
                }
              }

            }

          }
        });
      } catch (error) {
        console.log("error", error);
      }
      //@ts-ignore
      if (this.props?.chatUserList?.length === 0) {
        this.setState({ loadChat: false })
      }
      client.on("channelInvited", async (invitedChannel: any) => {

        const newMem = await invitedChannel?.getUserDescriptors()

        //@ts-ignore
        if (newMem?.state?.items[0]?.attributes?.name && newMem?.state?.items[0]?.attributes?.userId && this.props.addChatUsers && (!this.props?.chatUserList?.some(e => e?.name === newMem?.state?.items[0]?.attributes?.name))) {

          try {
            await invitedChannel.join();
            const newMsg = await invitedChannel.getMessages()
            if (newMsg.items.length !== 0) {
              const mem = await newMsg?.items[0]?.getMember()
              const dec = await mem?.getUserDescriptor()

              let userObj = {
                userId: dec?.attributes?.userId,
                lastMsg: newMsg?.items[0]?.body,
                lastConsumedMessageIndex: '',
                identity: newMsg?.items[0]?.author,
                dateUpdated: newMsg?.items[0]?.dateUpdated,
                allMsgIndex: newMsg?.items[0]?.index,
                getUnConsumedIndex: 1
              }
              //@ts-ignore
              this.props?.addNewUser(userObj)
              //@ts-ignore
              this.props.addInvitedChannels(newMem?.state?.items[0]?.attributes)
              this.setState({ expanded: '', getConversation: '' })

            } else {

              //@ts-ignore
              this.props.addInvitedChannels(newMem?.state?.items[0]?.attributes)

              this.setState({ expanded: '', getConversation: '' })
            }

          } catch (error) {
            console.log(error);

          }
          this.setState({ checkProp: newMem?.state?.items[0]?.attributes })
        }


      });
      client.on("messageAdded", async (income: any) => {

        const mem = await income?.getMember()
        const dec = await mem?.getUserDescriptor()
        const detail = await income?.channel?.getMemberByIdentity(income?.author)
        const users = await income?.channel?.getUserDescriptors()
        let messageOwner = Cookies.get("Login_Email")
        let userObj = {};

        if (mem?.identity == messageOwner) {
          userObj = {
            userId: mem?.channel?.channelState?.attributes?.userId,
            lastMsg: income?.body,
            lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
            identity: income?.author,
            dateUpdated: income.dateUpdated,
            allMsgIndex: income?.index,
            getUnConsumedIndex: income?.index - income?.channel?.channelState?.lastConsumedMessageIndex
          }
        } else if (mem?.identity == messageOwner && users?.state?.items[1]?.identity != messageOwner) {
          userObj = {
            userId: users?.state?.items[1]?.attributes?.userId,
            lastMsg: income?.body,
            lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
            identity: income?.author,
            dateUpdated: income.dateUpdated,
            allMsgIndex: income?.index,
            getUnConsumedIndex: income?.index - income?.channel?.channelState?.lastConsumedMessageIndex
          }
        } else {

          if (income?.index === 0) {
            userObj = {
              userId: dec?.attributes?.userId,
              lastMsg: income?.body,
              lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
              identity: income?.author,
              dateUpdated: income.dateUpdated,
              allMsgIndex: income?.index,
              getUnConsumedIndex: 1
            }

          } else {
            userObj = {
              userId: dec?.attributes?.userId,
              lastMsg: income?.body,
              lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
              identity: income?.author,
              dateUpdated: income.dateUpdated,
              allMsgIndex: income?.index,
              getUnConsumedIndex: income?.index - income?.channel?.channelState?.lastConsumedMessageIndex
            }
            this.audio.play();
          }
        }
        if (income?.index === 0) {
          //@ts-ignore
          this.props?.addNewUser(userObj)

        } else {

          //@ts-ignore
          this.props?.updateLastMsg(userObj, this.callState)

        }



      });
      client.on("typingStarted", async (channel: any) => {

        //@ts-ignore
        document.getElementById(channel?.identity)?.setAttribute('style', 'display: inherit;')
        //@ts-ignore
        document.getElementById(channel?.identity + 'chat')?.setAttribute('style', 'display: inherit;')
      });
      client.on("typingEnded", async (channel: any) => {

        //@ts-ignore
        document.getElementById(channel?.identity)?.setAttribute('style', 'display: none')
        //@ts-ignore
        document.getElementById(channel?.identity + 'chat')?.setAttribute('style', 'display: none')
      });
      client.on("tokenExpired", async (invitedChannel: any) => {

        this.generateChatAuthToken()

      });
      client.on("tokenAboutToExpire", async (channel: any) => {
        this.generateChatAuthToken()

      });
      window.addEventListener("tokenExpired", async (invitedChannel: any) => {

        this.generateChatAuthToken()

      })
      client.on("memberUpdated", async (joinedChannel: any) => {
        if (joinedChannel?.member?.identity != Cookies.get('Login_Email')) {

          const detail = await joinedChannel?.member?.channel?.getMessages(1)
          const msgUser = await joinedChannel?.member?.getUserDescriptor()


          let userObj = {
            userId: msgUser?.attributes?.userId,
            lastMsg: detail?.items[0]?.body,
            lastConsumedMessageIndex: joinedChannel?.member?.lastConsumedMessageIndex,
            identity: detail?.items[0]?.author,
            dateUpdated: detail?.items[0]?.dateUpdated,
            allMsgIndex: detail?.items[0]?.index,
            getUnConsumedIndex: detail?.items[0]?.index == 0 ? 1 : joinedChannel?.member?.channel?.channelState?.lastMessage?.index - joinedChannel?.member?.channel?.channelState?.lastConsumedMessageIndex

          }
          //@ts-ignore
          //@ts-ignore
          this.props?.updateLastMsg(userObj, this.callState)

        }
      });

      client.on("channelRemoved", async (invitedChannel: any) => {
        try {
          let email = Cookies.get('Login_Email')
          if (invitedChannel?.channelState?.attributes?.obj[0].email != email) {
            //@ts-ignore
            this.props?.deleteUserchat(invitedChannel?.channelState?.attributes?.obj[0].userId, this.callState)
            //@ts-ignore
            this.props?.deleteLastMesg(invitedChannel?.channelState?.attributes?.obj[0].userId, this.callState)
            this.setState({ expanded: '', getConversation: '' })
            if (this.props.callcomponentDidMountFromChat !== undefined) {
              //@ts-ignore
              this.props.history.push({
                pathname: '/chat',
                state: {
                  //@ts-ignore
                  receiveId: this.props?.chatUserList[1]?.userId,
                  //@ts-ignore
                  accountId: accountMyId
                }
              })
              location.reload()

              //@ts-ignore
              this.props?.callcomponentDidMountFromChat(this.props?.chatUserList[1]?.userId, this.state.myloginId)
            }
          } else if (invitedChannel?.channelState?.attributes?.obj[1].email != email) {
            //@ts-ignore
            this.props?.deleteUserchat(invitedChannel?.channelState?.attributes?.obj[1].userId, this.callState)
            //@ts-ignore
            this.props?.deleteLastMesg(invitedChannel?.channelState?.attributes?.obj[1].userId, this.callState)
            this.setState({ expanded: '', getConversation: '' })
            if (this.props.callcomponentDidMountFromChat !== undefined) {
              //@ts-ignore
              this.props.history.push({
                pathname: '/chat',
                state: {
                  //@ts-ignore
                  receiveId: this.props?.chatUserList[1]?.userId,
                  //@ts-ignore
                  accountId: accountMyId
                }
              })
              location.reload()

              //@ts-ignore
              this.props?.callcomponentDidMountFromChat(this.props?.chatUserList[1]?.userId, this.state.myloginId)
            }
          }

        } catch (error) {
          console.log(error);
        }
      });
      //@ts-ignore
    } else if ((!this.props.list) && this.props?.chatUserList?.length === 0 && this.props?.lastMegs.length === 0) {

      let token = ""
      try {
        if (Cookies.get("Chat_Token")) {
          //@ts-ignore
          token = Cookies.get("Chat_Token")
        } else {
          try {
           await axios({
              method: "post",
              url: `${baseURL}/${configJSON.chatAuthTokenEndPoint}`,
              headers: {
                'Content-Type': 'application/json',
                'token': Cookies.get("Login_Token"),
              }
            })
              .then(function (response: any) {
                token = response?.data?.token || ""
                Cookies.set("Chat_Token", response?.data?.token)
              })
          } catch (error) {
            console.log("error", error)
          }
        }
      } catch {
        throw new Error("unable to get token, please reload this page");
      }
      let client = null
      try {
        client = await Chat.Client.create(token)
      } catch (error) {
        await axios({
          method: "post",
          url: `${baseURL}/${configJSON.chatAuthTokenEndPoint}`,
          headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get("Login_Token"),
          }
        })
          .then((response: any) => {
            token = response?.data?.token || ""
            Cookies.set("Chat_Token", response?.data?.token)
          })
          client = await Chat.Client.create(token)
      }
      //@ts-ignore
      if (this.props.addClient) {
        //@ts-ignore
        this.props.addClient(client)
      }

      try {

        const pushNotificationId = Cookies.get('Push_NotificationToken')
        await client.setPushRegistrationId('fcm', pushNotificationId)
        await client.subscribeToPushNotifications('fcm')
        if (client.user.friendlyName) {
          //@ts-ignore
          await client.user.updateFriendlyName(this.props?.myInfo[0]?.fullName)
        }
      } catch (error) {
        console.log(error);
      }


      let allChannel;
      try {
        allChannel = await client?.channels?.getChannels()
      } catch (error) {
        console.log(error);

      }

      try {
        //@ts-ignore
        allChannel?.items?.forEach(async (element: any) => {


          if (element?.channelState?.friendlyName && element?.channelState?.uniqueName) {
            let email = Cookies.get('Login_Email')
            let myLoginId = Cookies.get("Login_Id")
            const member = await element?.getUserDescriptors()

            if (email && myLoginId) {
              
              if (member?.state?.items[0]?.descriptor?.identity != email) {
                let otheruser = '';
                try {
                  if (member?.state?.items[0]?.descriptor?.identity) {
                    const otherUser = await element?.getMemberByIdentity(member?.state?.items[0]?.descriptor?.identity)

                    if (otherUser?.lastConsumedMessageIndex) {
                      otheruser = otherUser.lastConsumedMessageIndex
                    }
                  }
                } catch (error) {
                  console.log(error);
                }

                //@ts-ignore
                if (this.props.addActiveUser && member?.state?.items[0]?.descriptor && (!this.props.activeUser.some(e => e?.identity === member?.state?.items[0]?.descriptor?.identity))) {
                  //@ts-ignore
                  this.props.addActiveUser(member.state.items[0].descriptor)
                }

                //@ts-ignore
                if (member?.state?.items[0]?.attributes?.name && member?.state?.items[0]?.attributes?.userId && this.props.addChatUsers && (!this.props?.chatUserList?.some(e => e?.name === member?.state?.items[0]?.attributes?.name))) {
                  //@ts-ignore
                  this.props.addChatUsers(member?.state?.items[0]?.attributes)
                  const messages = await element?.getMessages(1);

                  //@ts-ignore
                  if (messages.items.length !== 0 && this.props.addLastMsg) {

                    if (messages?.items[messages.items.length - 1]?.author != email) {
                      const detail = element?.getMemberByIdentity(email)
                      let userObj = {
                        userId: member?.state?.items[0]?.attributes?.userId,
                        lastMsg: messages.items[messages.items.length - 1].body,
                        lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
                        identity: messages?.items[messages.items.length - 1]?.author,
                        dateUpdated: messages?.items[messages.items.length - 1]?.dateUpdated,
                        allMsgIndex: messages?.items[messages.items.length - 1]?.index == 0 ? 1 : messages?.items[messages.items.length - 1]?.index,
                        getUnConsumedIndex: messages?.items[messages.items.length - 1]?.channel?.lastConsumedMessageIndex === null ? 1 : messages?.items[messages.items.length - 1]?.index - messages?.items[messages.items.length - 1]?.channel?.lastConsumedMessageIndex
                      }
                      //@ts-ignore
                      this.props.addLastMsg(userObj)

                    } else {
                      let userObj = {
                        userId: member?.state?.items[0]?.attributes?.userId,
                        lastMsg: messages.items[messages.items.length - 1].body,
                        lastConsumedMessageIndex: otheruser,
                        identity: messages?.items[messages.items.length - 1]?.author,
                        dateUpdated: messages?.items[messages.items.length - 1]?.dateUpdated,
                        allMsgIndex: messages?.items[messages.items.length - 1]?.index,
                        getUnConsumedIndex: messages?.items[messages.items.length - 1]?.index - messages?.items[messages.items.length - 1]?.channel?.lastConsumedMessageIndex
                      }
                      //@ts-ignore
                      this.props.addLastMsg(userObj)

                    }
                  }
                }
              }

              else if (member?.state?.items[1]?.descriptor?.identity != email) {
                let otheruser = '';
                try {
                  if (member?.state?.items[1]?.descriptor?.identity) {

                    const otherUser = await element?.getMemberByIdentity(member?.state?.items[1]?.descriptor?.identity)

                    if (otherUser?.lastConsumedMessageIndex) {
                      otheruser = otherUser.lastConsumedMessageIndex
                    }
                  }
                } catch (error) {
                  console.log(error);
                }

                //@ts-ignore
                if (this.props.addActiveUser && member?.state?.items[1]?.descriptor && (!this.props.activeUser.some(e => e?.identity === member?.state?.items[1]?.descriptor?.identity))) {
                  //@ts-ignore
                  this.props.addActiveUser(member?.state?.items[1]?.descriptor)
                }
                //@ts-ignore
                if (member?.state?.items[1]?.attributes?.name && member?.state?.items[1]?.attributes?.userId && this.props.addChatUsers && (!this.props?.chatUserList?.some(e => e?.name === member?.state?.items[1]?.attributes?.name))) {
                  //@ts-ignore
                  this.props.addChatUsers(member?.state?.items[1]?.attributes)

                  const messages = await element?.getMessages(1);
                  //@ts-ignore
                  if (messages.items.length !== 0 && this.props.addLastMsg) {
                    if (messages?.items[messages.items.length - 1]?.author != email) {
                      const detail = element?.getMemberByIdentity(email)
                      let userObj = {
                        userId: member?.state?.items[1]?.attributes?.userId,
                        lastMsg: messages.items[messages.items.length - 1].body,
                        lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
                        identity: messages?.items[messages.items.length - 1]?.author,
                        dateUpdated: messages?.items[messages.items.length - 1]?.dateUpdated,
                        allMsgIndex: messages?.items[messages.items.length - 1]?.index == 0 ? 1 : messages?.items[messages.items.length - 1]?.index,
                        getUnConsumedIndex: messages?.items[messages.items.length - 1]?.channel?.lastConsumedMessageIndex === null ? 1 : messages?.items[messages.items.length - 1]?.index - messages?.items[messages.items.length - 1]?.channel?.lastConsumedMessageIndex

                      }

                      //@ts-ignore
                      this.props.addLastMsg(userObj)
                    } else {
                      let userObj = {
                        userId: member?.state?.items[1]?.attributes?.userId,
                        lastMsg: messages.items[messages.items.length - 1].body,
                        lastConsumedMessageIndex: otheruser,
                        identity: messages?.items[messages.items.length - 1]?.author,
                        dateUpdated: messages?.items[messages.items.length - 1]?.dateUpdated,
                        allMsgIndex: messages?.items[messages.items.length - 1]?.index,
                        getUnConsumedIndex: messages?.items[messages.items.length - 1]?.index - messages?.items[messages.items.length - 1]?.channel?.lastConsumedMessageIndex

                      }

                      //@ts-ignore
                      this.props.addLastMsg(userObj)
                    }
                  }
                }
              }

            }

          }
        });
      } catch (error) {
        console.log("error", error);
      }
      //@ts-ignore
      if (this.props?.chatUserList?.length === 0) {
        this.setState({ loadChat: false })
      }
      client.on("channelInvited", async (invitedChannel: any) => {

        const newMem = await invitedChannel?.getUserDescriptors()

        //@ts-ignore
        if (newMem?.state?.items[0]?.attributes?.name && newMem?.state?.items[0]?.attributes?.userId && this.props.addChatUsers && (!this.props?.chatUserList?.some(e => e?.name === newMem?.state?.items[0]?.attributes?.name))) {

          try {
            await invitedChannel.join();
            const newMsg = await invitedChannel.getMessages()
            if (newMsg.items.length !== 0) {
              const mem = await newMsg?.items[0]?.getMember()
              const dec = await mem?.getUserDescriptor()

              let userObj = {
                userId: dec?.attributes?.userId,
                lastMsg: newMsg?.items[0]?.body,
                lastConsumedMessageIndex: '',
                identity: newMsg?.items[0]?.author,
                dateUpdated: newMsg?.items[0]?.dateUpdated,
                allMsgIndex: newMsg?.items[0]?.index,
                getUnConsumedIndex: 1
              }
              //@ts-ignore
              this.props?.addNewUser(userObj)
              //@ts-ignore
              this.props.addInvitedChannels(newMem?.state?.items[0]?.attributes)
              this.setState({ expanded: '', getConversation: '' })
            } else {

              //@ts-ignore
              this.props.addInvitedChannels(newMem?.state?.items[0]?.attributes)

              this.setState({ expanded: '', getConversation: '' })
            }
            this.getActiveness()
          } catch (error) {
            console.log(error);

          }
          this.setState({ checkProp: newMem?.state?.items[0]?.attributes })
        }

      });
      client.on("messageAdded", async (income: any) => {

        const mem = await income?.getMember()
        const dec = await mem?.getUserDescriptor()
        const detail = await income?.channel?.getMemberByIdentity(income?.author)
        const users = await income?.channel?.getUserDescriptors()
        let messageOwner = Cookies.get("Login_Email")
        let userObj = {};

        if (mem?.identity == messageOwner && users?.state?.items[0]?.identity != messageOwner) {
          userObj = {
            userId: users?.state?.items[0]?.attributes?.userId,
            lastMsg: income?.body,
            lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
            identity: income?.author,
            dateUpdated: income.dateUpdated,
            allMsgIndex: income?.index,
            getUnConsumedIndex: income?.index - income?.channel?.channelState?.lastConsumedMessageIndex
          }
        } else if (mem?.identity == messageOwner && users?.state?.items[1]?.identity != messageOwner) {
          userObj = {
            userId: users?.state?.items[1]?.attributes?.userId,
            lastMsg: income?.body,
            lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
            identity: income?.author,
            dateUpdated: income.dateUpdated,
            allMsgIndex: income?.index,
            getUnConsumedIndex: income?.index - income?.channel?.channelState?.lastConsumedMessageIndex
          }
        } else {
          if (income?.index === 0) {
            userObj = {
              userId: dec?.attributes?.userId,
              lastMsg: income?.body,
              lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
              identity: income?.author,
              dateUpdated: income.dateUpdated,
              allMsgIndex: income?.index,
              getUnConsumedIndex: 1
            }

          } else {
            userObj = {
              userId: dec?.attributes?.userId,
              lastMsg: income?.body,
              lastConsumedMessageIndex: detail?.lastConsumedMessageIndex,
              identity: income?.author,
              dateUpdated: income.dateUpdated,
              allMsgIndex: income?.index,
              getUnConsumedIndex: income?.index - income?.channel?.channelState?.lastConsumedMessageIndex
            }

          }
          this.audio.play()
        }
        if (income?.index === 0) {
          //@ts-ignore
          this.props?.addNewUser(userObj)

        } else {

          //@ts-ignore
          this.props?.updateLastMsg(userObj, this.callState)

        }


      });

      client.on("typingStarted", async (channel: any) => {

        //@ts-ignore
        document.getElementById(channel?.identity)?.setAttribute('style', 'display: inherit;')
        //@ts-ignore
        document.getElementById(channel?.identity + 'chat')?.setAttribute('style', 'display: inherit;')
      });
      client.on("tokenAboutToExpire", async (channel: any) => {
        this.generateChatAuthToken()

      });
      client.on("typingEnded", async (channel: any) => {

        //@ts-ignore
        document.getElementById(channel?.identity)?.setAttribute('style', 'display:none')
        //@ts-ignore
        document.getElementById(channel?.identity + 'chat')?.setAttribute('style', 'display: none')
      });
      client.on("tokenExpired", async (invitedChannel: any) => {

        this.generateChatAuthToken()

      });
      window.addEventListener("tokenExpired", async (invitedChannel: any) => {

        this.generateChatAuthToken()

      })
      client.on("memberUpdated", async (joinedChannel: any) => {

        if (joinedChannel?.member?.identity != Cookies.get('Login_Email')) {

          const detail = await joinedChannel?.member?.channel?.getMessages(1)
          const msgUser = await joinedChannel?.member?.getUserDescriptor()

          let userObj = {
            userId: msgUser?.attributes?.userId,
            lastMsg: detail?.items[0]?.body,
            lastConsumedMessageIndex: joinedChannel?.member?.lastConsumedMessageIndex,
            identity: detail?.items[0]?.author,
            dateUpdated: detail?.items[0]?.dateUpdated,
            allMsgIndex: detail?.items[0]?.index,
            getUnConsumedIndex: detail?.items[0]?.index == 0 ? 1 : joinedChannel?.member?.channel?.channelState?.lastMessage?.index - joinedChannel?.member?.channel?.channelState?.lastConsumedMessageIndex
          }
          //@ts-ignore
          //@ts-ignore
          this.props?.updateLastMsg(userObj, this.callState)

        }


      });
      client.on("channelRemoved", async (invitedChannel: any) => {
        try {
          let email = Cookies.get('Login_Email')
          if (invitedChannel?.channelState?.attributes?.obj[0].email != email) {
            //@ts-ignore
            this.props?.deleteUserchat(invitedChannel?.channelState?.attributes?.obj[0].userId, this.callState)
            //@ts-ignore
            this.props?.deleteLastMesg(invitedChannel?.channelState?.attributes?.obj[0].userId, this.callState)
            this.setState({ expanded: '', getConversation: '' })
            if (this.props.callcomponentDidMountFromChat !== undefined) {
              //@ts-ignore
              this.props.history.push({
                pathname: '/chat',
                state: {
                  //@ts-ignore
                  receiveId: this.props?.chatUserList[1]?.userId,
                  //@ts-ignore
                  accountId: accountMyId
                }
              })
              location.reload()

              //@ts-ignore
              this.props?.callcomponentDidMountFromChat(this.props?.chatUserList[1]?.userId, this.state.myloginId)
            }
          } else if (invitedChannel?.channelState?.attributes?.obj[1].email != email) {
            //@ts-ignore
            this.props?.deleteUserchat(invitedChannel?.channelState?.attributes?.obj[1].userId, this.callState)
            //@ts-ignore
            this.props?.deleteLastMesg(invitedChannel?.channelState?.attributes?.obj[1].userId, this.callState)
            this.setState({ expanded: '', getConversation: '' })
            if (this.props.callcomponentDidMountFromChat !== undefined) {
              //@ts-ignore
              this.props.history.push({
                pathname: '/chat',
                state: {
                  //@ts-ignore
                  receiveId: this.props?.chatUserList[1]?.userId,
                  //@ts-ignore
                  accountId: accountMyId
                }
              })
              location.reload()

              //@ts-ignore
              this.props?.callcomponentDidMountFromChat(this.props?.chatUserList[1]?.userId, this.state.myloginId)
            }
          }

        } catch (error) {
          console.log(error);
        }
      });

    }

  }

  callState=(data:any)=>{
    this.setState({cheking:data})
    
  }  
  getActiveness=async()=>{
    //@ts-ignore
    if(this.props.list){
      //@ts-ignore
      const client =this.props.list
      const allChannel =await client?.channels?.getChannels()
      
      try {
            //@ts-ignore
            allChannel?.items?.forEach(async (element:any) => {
              
            let email =Cookies.get('Login_Email')
            const member = await element?.getUserDescriptors()
            
            
            if(member?.state?.items[0]?.descriptor?.identity != email){
              
              //@ts-ignore
              if(this.props.addActiveUser && member?.state?.items[0]?.descriptor != null && member?.state?.items[0]?.descriptor != ''&& member?.state?.items[0]?.descriptor != undefined &&(!this.props.activeUser.some(e => e?.identity === member?.state?.items[0]?.descriptor?.identity))){
                //@ts-ignore
                this.props.addActiveUser(member?.state?.items[0]?.descriptor)
              }
             
            }else if(member?.state?.items[1]?.descriptor?.identity != email){
      
              //@ts-ignore
              if(this.props.addActiveUser && member?.state?.items[1]?.descriptor != null && member?.state?.items[1]?.descriptor != ''&& member?.state?.items[1]?.descriptor != undefined &&(!this.props.activeUser.some(e => e?.identity === member?.state?.items[1]?.descriptor?.identity))){
                //@ts-ignore
                this.props.addActiveUser(member?.state?.items[1]?.descriptor )
              }
             
            }
         }); 
      
      } catch (error) {
       console.log("error",error);
      }

    }else{
      await this.createClientComponent()
      //@ts-ignore
      const client =this.props.list
      const allChannel =await client?.channels?.getChannels()
      try {
        //@ts-ignore
        allChannel?.items?.forEach(async (element:any) => {
        let email =Cookies.get('Login_Email')
        const member = await element?.getUserDescriptors()
        
        if(member?.state?.items[0]?.descriptor?.identity != email){
          
          //@ts-ignore
          if(this.props.addActiveUser && member?.state?.items[0]?.descriptor != null && member?.state?.items[0]?.descriptor != ''&& member?.state?.items[0]?.descriptor != undefined){
            //@ts-ignore
            this.props.addActiveUser(member?.state?.items[0]?.descriptor)
          }
        }else if(member?.state?.items[1]?.descriptor?.identity != email){
         
          //@ts-ignore
          if(this.props.addActiveUser && member?.state?.items[1]?.descriptor != null && member?.state?.items[1]?.descriptor != ''&& member?.state?.items[1]?.descriptor != undefined){
            //@ts-ignore
            this.props?.addActiveUser(member?.state?.items[1]?.descriptor )
          }
        }
     }); 
  
  } catch (error) {
   console.log("error",error);
  }
    }
}
  deleteChat=async(userId:any,index:any)=>{
    let myLoginId=Cookies.get("Login_Id")
    let email =Cookies.get('Login_Email')
     let roomId=';'
    if (Number(myLoginId) > Number(userId)) {
                roomId = userId+'_'+myLoginId
    } else {
      roomId = myLoginId+'_'+userId
    }
    //@ts-ignore
    if(this.props.list){
      //@ts-ignore
      const client =this.props.list
      try {
        const channel= await client.getChannelByUniqueName(roomId)
        
        if(channel){
        // const memChannel =await channel.membersEntity.removeByIdentity(email)
        const memChannel =await channel.delete()

        if(memChannel){
          //@ts-ignore
          this.props?.deleteUserchat(userId,this.callState)
          //this.setState({cheking:userId})
          this.setState({ expanded:'', getConversation:''})
          toast.success("Deleted successfully",{position: toast.POSITION.BOTTOM_RIGHT})
          //@ts-ignore
          this.props?.deleteLastMesg(userId,this.callState)
          if(this.props?.chatUserList?.length ===0){
            this.setState({loadChat:false})
          }
        }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
    generateChatAuthToken() : boolean {
    
      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        "token": Cookies.get('Login_Token'),
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.ChatAuthTokenApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.chatAuthTokenEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  async receive(from: string, message: Message) {
    // Customizable Area Start
   if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
           if (
            apiRequestCallId === this.allFirendsApiId &&
            responseJson !== undefined
          ) {
            
            this.setState({ allFriendList: responseJson.data,allFriendsCopy:responseJson.data, isGettingList: false })
            //@ts-ignore
            if(this.props.addFriends !== undefined){
              //@ts-ignore
              this.props.addFriends(responseJson.data)
            }
          }
          if (
            apiRequestCallId === this.searchFriendcallId &&
            responseJson !== undefined
          ) {
             
             this.setState({allFriendList:responseJson.data})
             //@ts-ignore
             if(this.props.addFriends !== undefined){
              //@ts-ignore
              this.props.addFriends(responseJson.data)
            }
             //@ts-ignore
            document.getElementById('searchingFrd').value=this.state.searchedValFriend
          }
          if (
            apiRequestCallId === this.blockUserApiId &&
            responseJson !== undefined
          ) {
              toast.success("You have blocked " + responseJson?.data?.attributes?.account?.full_name,{position: toast.POSITION.BOTTOM_RIGHT})
              this.getFriendsList()
          }
          if (
            apiRequestCallId === this.newChatUsersApiId &&
            responseJson !== undefined
          ) {
            this.setState({ ...this.state, newChatUsers: responseJson, newChatLoader: false })
          }
          if (
            apiRequestCallId === this.ChatAuthTokenApiCallId &&
            responseJson !== undefined
          ) {
            
            const newToken = responseJson.token
            Cookies.set("Chat_Token", newToken)

            const newClient = await Chat.Client.create(newToken)
            if (!!this.props.addClient) {
              this.props.addClient(newClient)
            }
            this.createClientComponent()
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        
        
        if (errors) {
         if (errors[0].message === 'Not found any friend.') {
           this.setState({ allFriendList: [], isGettingList: false })

         }
         else if (errors[0].message === 'No result found.') {

           if (this.state.searchedValFriend !== null && this.state.searchedValFriend !== undefined && this.state.searchedValFriend !== '' && this.props.doNotCall !== 'friends') {
             this.setState({ allFriendList: [] })
             //@ts-ignore
             document.getElementById('searchingFrd').value = this.state.searchedValFriend
           }

           if (apiRequestCallId === this.newChatUsersApiId) {
             this.setState({ ...this.state, newChatUsers: [], newChatLoader: false })
           }
         } else if (errors[0].message) {

           // toast.error(errors[0].message,{position: toast.POSITION.BOTTOM_RIGHT})
         }
       }
       else {

         toast.error(errors, { position: toast.POSITION.BOTTOM_RIGHT })

       }

     }
   }
  }
  // Customizable Area End
  
}
