import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { setTimeout } from "timers";
toast.configure()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any
  // Customizable Area Start
  editComment: (commentDetails: {
    postId: number,
    commentId: number,
    comment: string
  }) => void
  deleteComment: (commentDetails: {
    postId: number,
    commentId: number,
  }) => void
  emptyIndexPost: () => void
  userLogout: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  myAllPost: [];
  pageState:string;
  profileImage:string;
  allComments:[]
  allRepliedComments:[]
  commentBoxId:any;
  commentCount:number;
  showReplyInbox:any;
  statusOfComment:any;
  pageNum:number;
  perPagePost:number;
  hideData:boolean;
  loading:boolean;
  reportDialog:boolean;
  confirmReportDialog:boolean;
  reportDialogMessage: string;
  reportReasons: any;
  reportSubReasons: any;
  postReportData: {
    post_id: number,
    report_reason_id: number
  },
  stepsEnabled: boolean,
  initialStep: number,
  steps: any,
  editPostId: number
  editPostDesc: string
  deleteDialog: boolean
  deletePostId: number
  editCommentId: number
  deleteCommentId: number
  deleteCommentDialog: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TrendingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  allMyNftPostsapiId: string = ""
  likePostApiId:string = ""
  dislikePostApiId: string = ""
  createCommentApiId:string = ""
  createReplyCommentApiId:string = ""
  commentDataApiId:string = ""
  commentReplyDataApiId:string = ""
  createLikeonCommentApiId :string = ""
  apiMyInformationId:string = ""
  reportReasonsApiId:string = ""
  postReportApiId: string = ""
  getTutorialsApiId: string = ""
  updateTutorialsApiId: string = ""
  deletePostApiId: string = ""
  updateApiCallId: string = ""
  editCommentApiId: string = ""
  deleteCommentApiId: string = ""
   // Customizable Area End

  constructor (props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      myAllPost: [],
       pageState:"home",
       profileImage:'',
       allComments:[],
       allRepliedComments:[],
       commentCount:1,
       commentBoxId:'',
       showReplyInbox:'',
       statusOfComment:'',
       pageNum:1,
       perPagePost:5,
       hideData:true,
       loading:true,
       reportDialog: false,
       confirmReportDialog: false,
       reportDialogMessage: "",
       reportReasons: [],
       reportSubReasons: [],
       postReportData: {
        post_id: 0,
        report_reason_id: 0
      },
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: ".tutorial-nft-title",
          intro: `<h2> NFT Title </h2> <p>The title of your NFT is automatically pulled.</p>`,
        },
        {
          element: ".tutorial-like",
          intro: `<h2> Like </h2>`,
        },
        {
          element: ".tutorial-comment",
          intro: `<h2> Comment </h2>`,
        },
        {
          element: ".tutorial-nft-price",
          intro: `<h2> Price NFT </h2> <p>Purchase price displayed for each NFT. If transferred or sold, this will be reflected in the post as previously owned.</p>`,
        },
      ],
      editPostId: 0,
      editPostDesc: '',
      deleteDialog: false,
      deletePostId: 0,
      editCommentId: 0,
      deleteCommentId: 0,
      deleteCommentDialog: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    //@ts-ignore
    let home= document.getElementById('homeIcon')
    //@ts-ignore
    home.setAttribute('style','border-bottom:none')

    //@ts-ignore
    let trend= document.getElementById('trendIcon')
    //@ts-ignore
    trend.setAttribute('style','border-bottom:2px solid #ffffff')
    // Customizable Area Start
    //@ts-ignore
    const response = this.props?.location?.state?.detail;
  
    if (response?.data?.attributes?.first_name && response?.data?.attributes?.last_name && response?.data?.attributes?.email && response?.meta?.token) {
      Cookies.set('first_name', response?.data?.attributes?.first_name)
      Cookies.set('last_name', response?.data?.attributes?.last_name)
    }

    if (Cookies.get("Login_Token")) {
      this.props.emptyIndexPost()
      this.getAllMyNftPost();
      this.GetMyInformationAPI();
      this.getReportReasons()
    } else {
      this.props.userLogout()
      this.props.history.push({
        pathname: "/login",
        state: { detail: "Something went wrong!! Please login to continue!!" },
      });
    }
  // Customizable Area End
  }

  logoutUser = () => {
    Cookies.remove('Login_Token')
    Cookies.remove("notification")
    Cookies.remove('Chat_Token')
    Cookies.remove('Login_Id')
    Cookies.remove('Login_Email')
    Cookies.remove('Account_Verified')
    this.props.userLogout()
    this.props.history.push('/login')
  }

  handleDeleteDialogToogle = (id?: number) => {
    if(this.state.deleteDialog) this.setState({ deleteDialog: false, deletePostId: 0 })
    else this.setState({ deleteDialog: true, deletePostId: id || 0 })
  }

  handleDeleteCommentToogle = (id?: number) => {
    if (!this.state.deleteCommentDialog && !!id) {
      this.setState({ deleteCommentDialog: true, deleteCommentId: id, editCommentId: 0 })
    } else {
      this.setState({ deleteCommentDialog: false, deleteCommentId: 0 })
    }
  }

  handleUpdateTutorials = () => {
    if (this.state.stepsEnabled) {
      this.setState({ stepsEnabled: false })
      this.updateTutorials()
    }
  }

  postTypeCheck = (url:string) => {
    const extension = url.split('.')?.pop()?.toLowerCase()
    if (!!extension) {
      if(configJSON.musicTypes.includes(extension)) {
        return "audio"
      } else if (configJSON.videoTypes.includes(extension)) {
        return "video"
      } else if (configJSON.object3dTypes.includes(extension)) {
        return "3dObject"
      } else {
        return "iframe"
      }
    } else {
      return "iframe"
    }
  }

  handleTrendingTutorialsClose = () => {
    this.setState({ ...this.state, stepsEnabled: false})
    this.updateTutorials()
  }

  handleCommentsToggle = (id: string) => {
    const element = document.getElementById(id)

    if (element?.style.display === "none") {
      element.style.display = "block"
    } else if (element?.style.display === "block") {
      element.style.display = "none"
    }
  }

  getReportReasons(): boolean {
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.reportReasonsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportReasonsEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
   
    return true;
  
  }

  handlePostReport =():boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token" :Cookies.get('Login_Token')
    };

    const httpBody = this.state.postReportData

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postReportApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportPostEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
   runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  getAllMyNftPost(pageNo?:any,post?:any): boolean {
  
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allMyNftPostsapiId = requestMessage.messageId;
    if(pageNo && post){
      
      let pageCount = this.state.pageNum+1
      this.setState({pageNum:this.state.pageNum+1})
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.allTrendPostEndPoint+pageCount+"&per_page="+this.state.perPagePost
      );
  }else{
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allTrendPostEndPoint+this.state.pageNum+"&per_page="+this.state.perPagePost
    );
  }
    

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  likePost=(postId:any,is_Liked:any):boolean=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token" :Cookies.get('Login_Token')
    };
   if(!is_Liked){
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likePostApiId= requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likePostEndPoint+"/"+postId+"/like"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
   runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
   }else{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.dislikePostApiId= requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likePostEndPoint+"/"+postId+"/dislike"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
   runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
   }
  }

  createComment(values:any,idNum:any): boolean {
  
    //@ts-ignore
    const coment = document.getElementById('comentBox'+idNum).value
    if(coment){
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };
  const attribute = {
    "post_id": values,
    "comment": coment,
  }
    const attributes = {
      attributes: attribute
    };
  
    const httpBody = {
      data : attributes
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.createCommentApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCommentEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    setTimeout(() => {
      //@ts-ignore
      document.getElementById('comentBox'+idNum).value=''
    }, 10);
    return true;
  }else{
    return false;
  }
  }
  createReplyComment(postId:any,index:any,commentIndex:any,commentId:any): boolean {
    
    //@ts-ignore
    const coment = document.getElementById("comentReplyBox"+index+commentIndex).value
    if(coment){
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };
  const attribute = {
    "post_id": postId,
    "comment": coment,
    "comment_id": commentId
  }
    const attributes = {
      attributes: attribute
    };
  
    const httpBody = {
      data : attributes
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.createReplyCommentApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCommentEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    setTimeout(() => {
      //@ts-ignore
      document.getElementById("comentReplyBox"+index+commentIndex).value=''
    }, 10);
    return true;
  }else{
    return false;
  }
  }
  
  // comment data of post 
  getCommentData(values:any,type?:any): boolean {
    
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.commentDataApiId = requestMessage.messageId;
    if(type ==='oldest' || type ==='newest'||type ==='most_relevant'){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.commentdataEndPoint+values+"/comments?filter_param="+type
      );
    }else{
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentdataEndPoint+values+"/comments"
    );
  }
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
   
    return true;
  
  }
  
  // comment data of post 
  getRepliedCommentData(values:any): boolean {
    if(values){
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.commentReplyDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentReplyEndPoint+values
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
   
    return true;
    }else{
      return false;
    }
  }
  GetMyInformationAPI(): boolean {

    const header = {
     "Content-Type":configJSON.validationApiContentType,
     "token": Cookies.get("Login_Token"),
   };

   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );

   this.apiMyInformationId = requestMessage.messageId;
   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.myInfoApiEndPoint
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.httpGetMethod
   );

   runEngine.sendMessage(requestMessage.id, requestMessage);
   return true;
 }
  //like/dislike comments
  likeOrDislikeComment(values:any,liked:any): boolean {
    
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createLikeonCommentApiId = requestMessage.messageId;
    if(liked){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint+"/"+values+"/dislike"
      );
    }else{
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint+"/"+values+"/like"
      );  
   }
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  
  }

  getCompletedTutorials (): boolean {
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTutorialsApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.completedTutorialsEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateTutorials = ():boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token" :Cookies.get('Login_Token')
    };

    const httpBody = {
      tutorial: configJSON.trendingTutorialsKey
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateTutorialsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTutorialsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
   runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deletePost = (): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deletePostEndPoint + "/" + this.state.deletePostId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updatePost = (postId: any): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };
    const b = document.querySelector("#editBio") as HTMLInputElement;
    const desc = b?.value
    if (!!desc) {
      const httpBody = {
        description: desc || ''
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updatePostEndPoint + postId
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Write something about post", { position: toast.POSITION.BOTTOM_RIGHT })
      return false;
    }
  }

  editComment = (postId: number) => {
    const b = document.querySelector("#editCommentBox") as HTMLInputElement;
    const desc = b?.value

    if (!!desc) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };

      const httpBody = {
        data: {
          attributes: {
            post_id: postId,
            comment: desc
          },
          comment_id: this.state.editCommentId
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editCommentApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Please write a comment", { position: toast.POSITION.BOTTOM_RIGHT })
    }
    return false
  }

  deleteComment = () => {
    if (this.state.deleteCommentId > 0) {
      this.handleDeleteCommentToogle()
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };

      const httpBody = {
        data: {
          comment_id: this.state.deleteCommentId
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.deleteCommentApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Something went wrong!", { position: toast.POSITION.BOTTOM_RIGHT })
      return false
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.allMyNftPostsapiId &&
            responseJson !== undefined
          ) {
            const tempData: any = []
            responseJson.data.forEach((element:any) => {
              tempData.push(element.data)
           });
             if(responseJson.data.length !== 5){
               this.setState({hideData:false})
             }
             this.setState({loading:false})
             tempData.forEach((element:any) => {
                 //@ts-ignore
                 this.props.addPosts(element)   
              });
              //@ts-ignore
              this.setState({ myAllPost: this.props.list})
              if(this.state.myAllPost.length ===0){
               this.setState({loading:false})
              }
              if(this.state.myAllPost?.length < 6) {
                this.getCompletedTutorials()
              }
          }
          if (
            apiRequestCallId === this.apiMyInformationId &&
            responseJson !== undefined
          ) {
              
             this.setState({ profileImage: responseJson?.data?.attributes?.image })
          }
          if (
            apiRequestCallId === this.createCommentApiId &&
            responseJson !== undefined
          ) {
            //@ts-ignore
           if(this.props.addTempComment !== undefined){
            //@ts-ignore
            this.props.addTempComment(responseJson.data)
           }
           //@ts-ignore
           if(this.props.createComment !== undefined){
            //@ts-ignore
            this.props.createComment(responseJson.data.attributes.post_id)
           }

           //@ts-ignore
           this.setState({ myAllPost: this.props.list})
          } 
          if (
            apiRequestCallId === this.createReplyCommentApiId &&
            responseJson !== undefined
          ) {
             //@ts-ignore
            this.props.userLogout()
            this.getAllMyNftPost()
            this.getRepliedCommentData(responseJson.data.attributes.comment_id)
          } 
          if (
            apiRequestCallId === this.commentDataApiId &&
            responseJson !== undefined
          ) {
            this.setState({allComments:responseJson.data})
          }
          if (
            apiRequestCallId === this.reportReasonsApiId &&
            responseJson !== undefined
          ) {
            this.setState({ ...this.state, reportReasons: responseJson.data })
          }
          if (
            apiRequestCallId === this.postReportApiId &&
            responseJson !== undefined
          ) {
            this.getAllMyNftPost();
            this.setState({ ...this.state, confirmReportDialog: false })
            toast.success(responseJson?.message, {position: toast.POSITION.BOTTOM_RIGHT})
          }
          if (
            apiRequestCallId === this.commentReplyDataApiId &&
            responseJson !== undefined
          ) {
            this.setState({allRepliedComments:responseJson.data})
          }
          if (
            apiRequestCallId === this.createLikeonCommentApiId &&
            responseJson !== undefined
          ) {
          //  this.getCommentData(responseJson.data.attributes.post_id)
          //@ts-ignore
          if(this.props.likeComment !== undefined){
            //@ts-ignore
            this.props.likeComment(responseJson.data.attributes.post_id,responseJson.data.attributes.id)
          }
           //@ts-ignore
           this.setState({ myAllPost: this.props.list})
           this.getRepliedCommentData(responseJson.data.attributes.comment_id)
          }
          if (
            apiRequestCallId === this.likePostApiId&&
            responseJson !== undefined
          ) {
               //@ts-ignore
                const b = document.querySelector("#likePost");
                //@ts-ignore
                b.setAttribute("style", "color:#3498db")
                //@ts-ignore
                if(this.props.likePost !== undefined){
                  //@ts-ignore
                  this.props.likePost(responseJson.data.attributes.id)
                }
                //@ts-ignore
              this.setState({ myAllPost: this.props.list})
                //this.getAllMyNftPost()
          }
          if (
            apiRequestCallId === this.getTutorialsApiId && 
            responseJson !== undefined
          ) {
            const isTrendingTutorial = responseJson?.tutorial?.includes(configJSON.trendingTutorialsKey)
            !isTrendingTutorial && this.setState({ stepsEnabled: true })
          }
          if (
            apiRequestCallId === this.dislikePostApiId&&
            responseJson !== undefined
          ) {
              //@ts-ignore
              //this.props.userLogout()
               //@ts-ignore
                const b = document.querySelector("#likePost");
                //@ts-ignore
                b.setAttribute("style", "color:grey")
                 //@ts-ignore
                if(this.props.likePost !== undefined){
                  //@ts-ignore
                  this.props.likePost(responseJson.data.attributes.id)
                }
                //@ts-ignore
              this.setState({ myAllPost: this.props.list})
               // this.getAllMyNftPost()
          }
          if (
            apiRequestCallId === this.deletePostApiId &&
            responseJson !== undefined
          ) {
              //@ts-ignore
              this.props.deleteParticularPost(responseJson?.data?.id)
              //@ts-ignore
              this.setState({ myAllPost: this.props.list })
              this.handleDeleteDialogToogle()
              toast.success(responseJson.message,{position: toast.POSITION.BOTTOM_RIGHT})
          }
          if (
            apiRequestCallId === this.updateApiCallId&&
            responseJson !== undefined
          ) {
              const b = document.querySelector("#editBio") as HTMLInputElement;
              const desc = b?.value
              //@ts-ignore
              this.props.updatePostDesc({ id: this.state.editPostId, description: desc })
              //@ts-ignore
              this.setState({ myAllPost: this.props.list })
              this.setState({ editPostDesc: '', editPostId: 0})
          }
          if (
            apiRequestCallId === this.editCommentApiId &&
            !!responseJson
          ) {
            this.props.editComment({
              postId: responseJson.data.attributes.post_id,
              commentId: responseJson.data.id,
              comment: responseJson.data.attributes.comment
            })
            this.setState({ editCommentId: 0 })
          }
          if (
            apiRequestCallId === this.deleteCommentApiId &&
            !!responseJson
          ) {
            this,this.props.deleteComment({
              postId: responseJson.data.attributes.post_id,
              commentId: responseJson.data.id,
            })
            this.setState({ deleteCommentId: 0 })
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
      
      
        if (errors) {

          if (apiRequestCallId === this.apiMyInformationId && errors[0]?.account === "Record not found") {
            this.logoutUser()
          }
        
          if (!!errors[0]?.description) {
            toast.error(errors[0]?.description, { position: toast.POSITION.BOTTOM_RIGHT })
          }
        }
      }
      // Customizable Area End
    }
  }
}
