import React from "react";

//Customizable Area Start
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";
import { withRouter } from 'react-router-dom';
import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";
import { withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
//@ts-ignore
import { ReactComponent as LogoTitle } from '../../logo-title.svg';
//Customizable Area End

class ForgotPassword extends ForgotPasswordController {
  constructor (props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    //@ts-ignore
    const { classes } = this.props

    const redirectToLogin = () => {
      //@ts-ignore
      this.props.history.push('/login')
    }

    return (
      <>
        <div>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={this.ResetPasswordSchema}
            onSubmit={(values): any => this.doResetPassword(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              handleBlur,
            }) => (
              //@ts-ignore
              <Form style={{ textAlign: "center" }}>
                <div>
                  <Dialog open={true} classes={{
                    paper: classes.paper,
                    scrollPaper: classes.topScrollPaper,
                    paperScrollBody: classes.topPaperScrollBody,
                  }}>
                    <DialogTitle style={{ paddingBottom: '0' }}><LogoTitle className={classes.logo} /></DialogTitle>
                    <DialogContent>
                      <DialogContentText className={classes.texHeaderStyle}>
                        Forgot Password
                      </DialogContentText>
                      <DialogContentText className={classes.textStyle} >
                        Please enter your deegee login email and we will send you instructions.
                      </DialogContentText>
                      <TextField
                        id="email"
                        placeholder={this.labelEmail}
                        variant="outlined"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        helperText={errors.email}
                        error={touched.email && errors.email ? true : false}
                        //style={{ marginTop: "55px", justifyContent: "center", display: "flex" }}
                        className={classes.inputText}
                      />
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={(e) => {
                          handleSubmit();
                        }}
                        style={{ background: "#1f7fb6" }} className={classes.cssButton}
                      >
                        Reset Password
                      </Button>
                      <span className={classes.loginLink}>Already have an account?<span className={classes.loginText} onClick={() => redirectToLogin()}>&nbsp;Login</span></span>
                    </DialogContent>
                  </Dialog>
                </div>

              </Form>
            )}
          </Formik>
        </div>
      </>
    );
  }
}
const useStyles = {
  logo: {
    width: "200px",
    height: "60px"
  },
  loginLink: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: '14px',
    marginTop: '15px',
    fontFamily: "'Poppins', sans-serif",
  },
  loginText: {
    color:'#1882AD',
    fontWeight: 600,
    cursor: "pointer"
  },
  cssButton: {
    height: '45px',
    width: '100%',
    color: "#ffffff",
    fontFamily: "'Poppins', sans-serif",
    fontSize: '14px',
    marginTop: '5px'
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    width: '500px',    
    background: '#fffffe!important',
    "& .MuiDialogContent-root": {
      padding: "15px 25px 25px",
    },
    "& .MuiDialogTitle-root":{
      textAlign: "center",
      padding: "25px"
    },
    "& .MuiFormHelperText-root.Mui-error": {
      padding: "3px 0 0",
      lineHeight: "1",
      fontSize: "12px"
    }
  },
  texHeaderStyle: {
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '1',
    paddingBottom: "10px",
    margin: "0",
    color: "#000000"
  },
  textStyle: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: '14px',
    lineHeight: '1.3',
    margin:'0',
    color: "#000000",

  },
  inputText: {
    marginTop: "0",
    width: "100%",
    padding: "15px 0",
    "& input": {
      padding: "10px 15px",
      color: "#000000",
      fontFamily: "'Poppins', sans-serif",
      fontSize: '16px',
    }
  }
} as any;

//@ts-ignore
const forgotPassword = withRouter(ForgotPassword)
export default withStyles(useStyles)(withRouter(forgotPassword as any) as any)
