import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
const Chat = require("twilio-chat");
import { setTimeout } from "timers";
const axios = require('axios');
const {baseURL} = require('../../../framework/src/config.js')
toast.configure()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  location: any
  list: any
  history: any
  profileData: any
  metaPost: any
  listClient: any
  activeUser: any
  addActiveUser: any
  addClient: any
  addChatUsers: any
  chatUserList: any
  addLastMsg: any
  setProfile: (profileData: any) => void
  editComment: (commentDetails: {
    postId: number,
    commentId: number,
    comment: string
  }) => void
  deleteComment: (commentDetails: {
    postId: number,
    commentId: number,
  }) => void
  updatePost: (element: any) => void
  deletePost: () => void
  updateData: () => void
  addPosts: (postData: any) => void
  addTempComment: (commentData: any) => void
  deleteActiveUser: () => void
  createComment: (id: number) => void
  deleteParticularPost: (id: number) => void
  updatePostDesc: (updateData: { id: number, description: string }) => void
  emptyIndexPost: () => void
  userLogout: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stepsEnabled: boolean,
  initialStep: number,
  steps: any,
  myAllPost: [];
  allPostMeta: []
  pageState: string;
  profileImage: string;
  allComments: []
  allRepliedComments: []
  commentBoxId: any;
  commentCount: number;
  showReplyInbox: any;
  pageNum: number;
  perPagePost: number;
  hideData: boolean;
  loading: boolean;
  isLikedOrDisLiked: boolean;
  setIndex: any;
  reportDialog: boolean;
  confirmReportDialog: boolean;
  reportDialogMessage: string;
  reportReasons: any;
  reportSubReasons: any;
  postReportData: {
    post_id: number,
    report_reason_id: number
  },
  shareDialog: boolean
  shareLinkText: string
  shareLinkURL: string
  editPostId: number
  editPostDesc: string
  deleteDialog: boolean
  deletePostId: number
  editCommentId: number
  deleteCommentId: number
  deleteCommentDialog: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HomeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  allMyNftPostsapiId: string = ""
  likePostApiId: string = ""
  dislikePostApiId: string = ""
  createCommentApiId: string = ""
  createReplyCommentApiId: string = ""
  commentDataApiId: string = ""
  commentReplyDataApiId: string = ""
  createLikeonCommentApiId: string = ""
  apiMyInformationId: string = ""
  reportReasonsApiId: string = ""
  postReportApiId: string = ""
  getTutorialsApiId: string = ""
  updateTutorialsApiId: string = ""
  deletePostApiId: string = ""
  updateApiCallId: string = ""
  editCommentApiId: string = ""
  deleteCommentApiId: string = ""
   // Customizable Area End

  constructor (props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: ".new-chat",
          intro: `<h2> Chat </h2> <p>chat is always available so you can stay connected</p>`,
          tooltipClass: 'customTooltip'
        },
        {
          element: ".home-icon",
          intro: `<h2> Home </h2> <p>catch up on your friend's recent NFT snags</p>`,
        },
        {
          element: ".trending-icon",
          intro: `<h2> Trending </h2> <p>check out the latest and greatest NFTs</p>`,
        },
        {
          element: ".search-icon",
          intro: `<h2> Search friends </h2> <p>quickly find your friends and browse their NFTs or just say hi</p>`,
        }
      ],
      myAllPost: [],
      allPostMeta: [],
      pageState: "home",
      profileImage: '',
      allComments: [],
      allRepliedComments: [],
      commentCount: 1,
      commentBoxId: '',
      showReplyInbox: '',
      pageNum: 1,
      perPagePost: 5,
      hideData: true,
      loading: true,
      isLikedOrDisLiked: false,
      setIndex: '',
      reportDialog: false,
      confirmReportDialog: false,
      reportDialogMessage: "",
      reportReasons: [],
      reportSubReasons: [],
      postReportData: {
        post_id: 0,
        report_reason_id: 0
      },
      shareDialog: false,
      shareLinkText: '',
      shareLinkURL: '',
      editPostId: 0,
      editPostDesc: '',
      deleteDialog: false,
      deletePostId: 0,
      editCommentId: 0,
      deleteCommentId: 0,
      deleteCommentDialog: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    const response = this.props?.location?.state?.detail;

    if (response?.data?.attributes?.first_name && response?.data?.attributes?.last_name && response?.data?.attributes?.email && response?.meta?.token) {
      Cookies.set('first_name', response?.data?.attributes?.first_name)
      Cookies.set('last_name', response?.data?.attributes?.last_name)
    }

    const verifiedMail = Cookies.get('Account_Verified')
    const currentMail = Cookies.get('Login_Email')

    if (Cookies.get("Login_Token") && (currentMail === verifiedMail)) {

      if (this.props.deleteActiveUser) {
        this.props.deleteActiveUser()
      }
      this.props.emptyIndexPost()
      this.getActiveness()
      this.getAllMyNftPost();
      this.GetMyInformationAPI();
      this.getReportReasons();
      this.getCompletedTutorials()
    } else {
      this.logoutUser()
    }
    // Customizable Area End
  }

  logoutUser = () => {
    Cookies.remove('Login_Token')
    Cookies.remove("notification")
    Cookies.remove('Chat_Token')
    Cookies.remove('Login_Id')
    Cookies.remove('Login_Email')
    Cookies.remove('Account_Verified')
    this.props.userLogout()
    this.props.history.push('/login')
  }

  handleDeleteDialogToogle = (id?: number) => {
    if (this.state.deleteDialog) this.setState({ deleteDialog: false, deletePostId: 0 })
    else this.setState({ deleteDialog: true, deletePostId: id || 0 })
  }

  handleDeleteCommentToogle = (id?: number) => {
    if (!this.state.deleteCommentDialog && !!id) {
      this.setState({ deleteCommentDialog: true, deleteCommentId: id })
    } else {
      this.setState({ deleteCommentDialog: false, deleteCommentId: 0 })
    }
  }

  postTypeCheck = (url: string) => {
    const extension = url.split('.')?.pop()?.toLowerCase()
    if (!!extension) {
      if (configJSON.musicTypes.includes(extension)) {
        return "audio"
      } else if (configJSON.videoTypes.includes(extension)) {
        return "video"
      } else if (configJSON.object3dTypes.includes(extension)) {
        return "3dObject"
      } else {
        return "iframe"
      }
    } else {
      return "iframe"
    }
  }

  handleHomeTutorialsClose = () => {
    if (this.state.stepsEnabled) {
      this.setState({ ...this.state, stepsEnabled: false })
      this.updateTutorials()
    }
  }

  getActiveness = async () => {
    if (this.props.listClient) {
      const client = this.props.listClient
      const allChannel = await client?.channels?.getChannels()

      try {
        allChannel?.items?.forEach(async (element: any) => {
          let email = Cookies.get('Login_Email')
          const member = await element?.getUserDescriptors()

          if (member?.state?.items[0]?.descriptor?.identity != email) {

            if (this.props.addActiveUser && member?.state?.items[0]?.descriptor != null && member?.state?.items[0]?.descriptor != '' && member?.state?.items[0]?.descriptor != undefined && (!this.props.activeUser.some((e: any) => e?.identity === member?.state?.items[0]?.descriptor?.identity))) {
              this.props.addActiveUser(member?.state?.items[0]?.descriptor)
            }

          } else if (member?.state?.items[1]?.descriptor?.identity != email) {

            if (this.props.addActiveUser && member?.state?.items[1]?.descriptor != null && member?.state?.items[1]?.descriptor != '' && member?.state?.items[1]?.descriptor != undefined && (!this.props.activeUser.some((e: any) => e?.identity === member?.state?.items[1]?.descriptor?.identity))) {
              this.props.addActiveUser(member?.state?.items[1]?.descriptor)
            }

          }
        });

      } catch (error) {
        console.log("error", error);
      }

    } else {

      let token = "";
      try {
        token = Cookies.get("Chat_Token") || ''
      } catch {
        throw new Error("unable to get token, please reload this page");
      }

      let clientNew = null
      try {
        clientNew = await Chat.Client.create(token)
      } catch(error) {
        await axios({
          method: "post",
          url: `${baseURL}/${configJSON.chatAuthTokenEndPoint}`,
          headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get("Login_Token"),
          }
        })
          .then((response: any) => {
            token = response?.data?.token || ""
            Cookies.set("Chat_Token", response?.data?.token)
          })
          clientNew = await Chat.Client.create(token)
      }
      if (this.props.addClient) {
        this.props.addClient(clientNew)
      }
      const client = this.props.listClient
      const allChannel = await client?.channels?.getChannels()
      try {
        allChannel?.items?.forEach(async (element: any) => {
          let email = Cookies.get('Login_Email')
          const member = await element?.getUserDescriptors()

          if (member?.state?.items[0]?.descriptor?.identity != email) {
            if (this.props.addActiveUser && member?.state?.items[0]?.descriptor != null && member?.state?.items[0]?.descriptor != '' && member?.state?.items[0]?.descriptor != undefined) {
              this.props.addActiveUser(member?.state?.items[0]?.descriptor)
            }
          } else if (member?.state?.items[1]?.descriptor?.identity != email) {
            if (this.props.addActiveUser && member?.state?.items[1]?.descriptor != null && member?.state?.items[1]?.descriptor != '' && member?.state?.items[1]?.descriptor != undefined) {
              this.props?.addActiveUser(member?.state?.items[1]?.descriptor)
            }
          }
        });

      } catch (error) {
        console.log("error", error);
      }
    }
  }

  createClientComponent = async (roomId?: any) => {

    // await this.generateChatAuthToken()
    let token = "";
    try {
      token = Cookies.get("Chat_Token") || ''
    } catch {
      throw new Error("unable to get token, please reload this page");
    }

    const client = await Chat.Client.create(token);
    if (this.props.addClient) {
      this.props.addClient(client)
    }

    const allChannel = await client?.channels?.getChannels()
    try {
      allChannel?.items?.forEach(async (element: any) => {

        if (element?.channelState?.friendlyName && element?.channelState?.uniqueName && element?.channelState?.friendlyName != null && element?.channelState?.uniqueName != null) {
          let email = Cookies.get('Login_Email')
          let myLoginId = Cookies.get("Login_Id")
          const member = await element?.getUserDescriptors()

          if (member?.state?.items.length === 1 && email && myLoginId) {


            if (element?.channelState?.attributes?.userId != myLoginId) {
              if (element?.channelState?.attributes?.name && element?.channelState?.attributes?.userId && this.props.addChatUsers && (!this.props?.chatUserList?.some((e: any) => e?.name === element?.channelState?.attributes?.name))) {
                this.props.addChatUsers(element?.channelState?.attributes)

                const messages = await element?.getMessages();

                if (messages.items.length !== 0 && this.props.addLastMsg) {

                  let userObj = {
                    userId: element?.channelState?.attributes?.userId,
                    lastMsg: messages.items[messages.items.length - 1].body
                  }
                  this.props.addLastMsg(userObj)
                }
              }

            } else {
              if (member?.state?.items[0]?.descriptor?.identity != email) {
                if (this.props.addActiveUser && member?.state?.items[0]?.descriptor != null && member?.state?.items[0]?.descriptor != '' && member?.state?.items[0]?.descriptor != undefined && (!this.props.activeUser.some((e: any) => e?.identity === member?.state?.items[0]?.descriptor?.identity))) {
                  this.props.addActiveUser(member?.state?.items[0]?.descriptor)
                }

                if (member?.state?.items[0]?.attributes?.name && member?.state?.items[0]?.attributes?.userId && this.props.addChatUsers && (!this.props?.chatUserList?.some((e: any) => e?.name === member?.state?.items[0]?.attributes?.name))) {
                  this.props.addChatUsers(member?.state?.items[0]?.attributes)

                  const messages = await element?.getMessages()
                  if (messages.items.length !== 0 && this.props.addLastMsg) {

                    let userObj = {
                      userId: member?.state?.items[0]?.attributes?.userId,
                      lastMsg: messages.items[messages.items.length - 1].body
                    }
                    this.props.addLastMsg(userObj)
                  }
                }
              }
            }
          } else if (member?.state?.items.length === 2 && email && myLoginId) {
            if (member?.state?.items[0]?.descriptor?.identity != email) {
              if (this.props.addActiveUser && member?.state?.items[0]?.descriptor != null && member?.state?.items[0]?.descriptor != '' && member?.state?.items[0]?.descriptor != undefined && (!this.props.activeUser.some((e: any) => e?.identity === member?.state?.items[0]?.descriptor?.identity))) {
                this.props.addActiveUser(member?.state?.items[0]?.descriptor)
              }

              if (member?.state?.items[0]?.attributes?.name && member?.state?.items[0]?.attributes?.userId && this.props.addChatUsers && (!this.props?.chatUserList?.some((e: any) => e?.name === member?.state?.items[0]?.attributes?.name))) {
                this.props.addChatUsers(member?.state?.items[0]?.attributes)

                const messages = await element?.getMessages();

                if (messages.items.length !== 0 && this.props.addLastMsg) {

                  let userObj = {
                    userId: member?.state?.items[0]?.attributes?.userId,
                    lastMsg: messages.items[messages.items.length - 1].body
                  }
                  this.props.addLastMsg(userObj)
                }
              }
            } else if (member?.state?.items[1]?.descriptor?.identity != email) {
              if (this.props.addActiveUser && member?.state?.items[1]?.descriptor != null && member?.state?.items[1]?.descriptor != '' && member?.state?.items[1]?.descriptor != undefined && (!this.props.activeUser.some((e: any) => e?.identity === member?.state?.items[1]?.descriptor?.identity))) {
                this.props.addActiveUser(member?.state?.items[1]?.descriptor)
              }

              if (member?.state?.items[1]?.attributes?.name && member?.state?.items[1]?.attributes?.userId && this.props.addChatUsers && (!this.props?.chatUserList?.some((e: any) => e?.name === member?.state?.items[1]?.attributes?.name))) {
                this.props.addChatUsers(member?.state?.items[1]?.attributes)

                const messages = await element?.getMessages();
                if (messages.items.length !== 0 && this.props.addLastMsg) {

                  let userObj = {
                    userId: member?.state?.items[1]?.attributes?.userId,
                    lastMsg: messages.items[messages.items.length - 1].body
                  }
                  this.props.addLastMsg(userObj)
                }
              }
            }

          }

        }
      });
    } catch (error) {
      console.log("error", error);
    }

  }

  handleCommentsToggle = (id: string) => {
    const element = document.getElementById(id)

    if (element?.style.display === "none") {
      element.style.display = "block"
    } else if (element?.style.display === "block") {
      element.style.display = "none"
    }
  }

  handleShareLinkClose = () => {
    this.setState({ shareDialog: false, shareLinkText: '', shareLinkURL: '' })
  }

  getAllMyNftPost(pageNo?: any, post?: any): boolean {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allMyNftPostsapiId = requestMessage.messageId;
    if (pageNo && post) {

      let pageCount = this.state.pageNum + 1
      this.setState({ pageNum: this.state.pageNum + 1 })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.allPostApiEndPoint + pageCount + "&per_page=" + this.state.perPagePost
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.allPostApiEndPoint + this.state.pageNum + "&per_page=" + this.state.perPagePost
      );
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getReportReasons(): boolean {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reportReasonsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportReasonsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  handlePostReport = (): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const httpBody = this.state.postReportData

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postReportApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportPostEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllMyNftPostFromLike(pageNo?: any, post?: any): boolean {

    let pageNum = (this.state.setIndex / 5)
    if (pageNum > Math.round(pageNum)) {
      pageNum = pageNum + 0.5
    }
    pageNum = Math.round(pageNum)

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allMyNftPostsapiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allPostApiEndPoint + pageNum + "&per_page=" + this.state.perPagePost
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  likePost = (postId: any, is_Liked: any, index?: any): boolean => {
    this.setState({ setIndex: index + 1 })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };
    if (!is_Liked) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.likePostApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.likePostEndPoint + "/" + postId + "/like"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.dislikePostApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.likePostEndPoint + "/" + postId + "/dislike"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  createComment(values: any, idNum: any): boolean {

    //@ts-ignore
    const coment = document.getElementById('comentBox' + idNum).value
    if (coment) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get("Login_Token"),
      };
      const attribute = {
        "post_id": values,
        "comment": coment,
      }
      const attributes = {
        attributes: attribute
      };

      const httpBody = {
        data: attributes
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      this.createCommentApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      setTimeout(() => {
        //@ts-ignore
        document.getElementById('comentBox' + idNum).value = ''
      }, 10);
      return true;
    } else {
      return false;
    }
  }

  createReplyComment(postId: any, index: any, commentIndex: any, commentId: any): boolean {

    //@ts-ignore
    const coment = document.getElementById("comentReplyBox" + index + commentIndex).value
    if (coment) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get("Login_Token"),
      };
      const attribute = {
        "post_id": postId,
        "comment": coment,
        "comment_id": commentId
      }
      const attributes = {
        attributes: attribute
      };

      const httpBody = {
        data: attributes
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      this.createReplyCommentApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      setTimeout(() => {
        //@ts-ignore
        document.getElementById("comentReplyBox" + index + commentIndex).value = ''
      }, 10);
      return true;
    } else {
      return false;
    }
  }

  // comment data of post 
  getCommentData(values: any, type?: any): boolean {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.commentDataApiId = requestMessage.messageId;
    if (type === 'oldest' || type === 'newest' || type === 'most_relevant') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.commentdataEndPoint + values + "/comments?filter_param=" + type
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.commentdataEndPoint + values + "/comments"
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  // comment data of post 
  getRepliedCommentData(values: any): boolean {
    if (values) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get("Login_Token"),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.commentReplyDataApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.commentReplyEndPoint + values
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    } else {
      return false;
    }
  }
  GetMyInformationAPI(): boolean {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMyInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myInfoApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  //like/dislike comments
  likeOrDislikeComment(values: any, liked: any, index?: any): boolean {
    this.setState({ setIndex: index + 1 })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createLikeonCommentApiId = requestMessage.messageId;
    if (liked) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint + "/" + values + "/dislike"
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint + "/" + values + "/like"
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  getCompletedTutorials(): boolean {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTutorialsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.completedTutorialsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateTutorials = (): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const httpBody = {
      tutorial: configJSON.homeTutorialsKey
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateTutorialsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTutorialsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deletePost = (): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deletePostEndPoint + "/" + this.state.deletePostId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updatePost = (postId: any): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };
    const b = document.querySelector("#editBio") as HTMLInputElement;
    const desc = b?.value
    if (!!desc) {
      const httpBody = {
        description: desc || ''
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updatePostEndPoint + postId
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Write something about post", { position: toast.POSITION.BOTTOM_RIGHT })
      return false;
    }
  }

  editComment = (postId: number) => {
    const b = document.querySelector("#editCommentBox") as HTMLInputElement;
    const desc = b?.value

    if (!!desc) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };

      const httpBody = {
        data: {
          attributes: {
            post_id: postId,
            comment: desc
          },
          comment_id: this.state.editCommentId
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editCommentApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Please write a comment", { position: toast.POSITION.BOTTOM_RIGHT })
    }
    return false
  }

  deleteComment = () => {
    if (this.state.deleteCommentId > 0) {
      this.handleDeleteCommentToogle()
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };

      const httpBody = {
        data: {
          comment_id: this.state.deleteCommentId
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.deleteCommentApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Something went wrong!", { position: toast.POSITION.BOTTOM_RIGHT })
      return false
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.allMyNftPostsapiId &&
            responseJson !== undefined
          ) {
            const tempPost: any = []
            responseJson.data.forEach((element: any) => {
              element?.meta ? tempPost.push({...element.data, meta: element.meta}) : tempPost.push(element.data)
            })
            if (responseJson.data.length !== 5) {
              this.setState({ hideData: false })
            }
            this.setState({ loading: false })
            if (this.state.isLikedOrDisLiked) {
              this.props.deletePost()
              tempPost.forEach((element: any) => {
                this.props.updatePost(element)
              })
              this.props.updateData()
              this.setState({ isLikedOrDisLiked: false })
            } else {
              this.setState({ isLikedOrDisLiked: false })
              tempPost.forEach((element: any) => {
                this.props.addPosts(element)
              });
            }
            this.setState({ myAllPost: this.props.list })
            if (this.state.myAllPost.length === 0) {
              this.setState({ loading: false })
            }
          }

          if (
            apiRequestCallId === this.apiMyInformationId &&
            responseJson !== undefined
          ) {
            const profileData = {
              location: responseJson?.data?.attributes?.city,
              information: responseJson?.data?.attributes?.about,
              emailId: responseJson?.data?.attributes?.email,
              phoneNumber: responseJson?.data?.attributes?.mobile_number,
              dateOfBirth: responseJson?.data?.attributes?.date_of_birth,
              profileImage: responseJson?.data?.attributes?.image,
              fullName: responseJson?.data?.attributes?.full_name,
              firstName: responseJson?.data?.attributes?.first_name,
              lastName: responseJson?.data?.attributes?.last_name,
              userName: responseJson?.data?.attributes?.user_name,
              country: responseJson?.data?.attributes?.country,
              state: responseJson?.data?.attributes?.state,
              city: responseJson?.data?.attributes?.city,
              userIdResetPassword: responseJson?.data?.attributes?.account_id,
              countryCode: responseJson?.data?.attributes?.country_code,
              isTwoFactAuth: responseJson?.data?.attributes?.two_factor_auth_status
            }

            this.props.setProfile(profileData)
          }
          if (
            apiRequestCallId === this.getTutorialsApiId &&
            responseJson !== undefined
          ) {
            const isHomeTutorial = responseJson?.tutorial?.includes(configJSON.homeTutorialsKey)
            !isHomeTutorial && this.setState({ stepsEnabled: true })
          }
          if (
            apiRequestCallId === this.createCommentApiId &&
            responseJson !== undefined
          ) {

            if (this.props.addTempComment !== undefined) {
              this.props.addTempComment(responseJson.data)
            }
            if (this.props.createComment !== undefined) {
              this.props.createComment(responseJson.data.attributes.post_id)
            }
            this.setState({ myAllPost: this.props.list })
          }
          if (
            apiRequestCallId === this.createReplyCommentApiId &&
            responseJson !== undefined
          ) {
            this.getAllMyNftPost()
            // this.getCommentData(responseJson.data.attributes.post_id)
            this.getRepliedCommentData(responseJson.data.attributes.comment_id)
          }
          if (
            apiRequestCallId === this.commentDataApiId &&
            responseJson !== undefined
          ) {
            this.setState({ allComments: responseJson.data })
          }
          if (
            apiRequestCallId === this.commentReplyDataApiId &&
            responseJson !== undefined
          ) {
            this.setState({ allRepliedComments: responseJson.data })
          }
          if (
            apiRequestCallId === this.createLikeonCommentApiId &&
            responseJson !== undefined
          ) {
            this.setState({ isLikedOrDisLiked: true })
            this.getAllMyNftPostFromLike()
            this.getRepliedCommentData(responseJson.data.attributes.comment_id)
          }
          if (
            apiRequestCallId === this.likePostApiId &&
            responseJson !== undefined
          ) {
            //@ts-ignore
            const b = document.querySelector("#likePost");
            //@ts-ignore
            b.setAttribute("style", "color:#3498db")
            this.setState({ isLikedOrDisLiked: true })
            this.getAllMyNftPostFromLike()
          }
          if (
            apiRequestCallId === this.dislikePostApiId &&
            responseJson !== undefined
          ) {
            //@ts-ignore
            const b = document.querySelector("#likePost");
            //@ts-ignore
            b.setAttribute("style", "color:grey")
            this.setState({ isLikedOrDisLiked: true })
            this.getAllMyNftPostFromLike()
          }
          if (
            apiRequestCallId === this.reportReasonsApiId &&
            responseJson !== undefined
          ) {
            this.setState({ ...this.state, reportReasons: responseJson.data })
          }
          if (
            apiRequestCallId === this.postReportApiId &&
            responseJson !== undefined
          ) {
            this.setState({ ...this.state, confirmReportDialog: false })
            toast.success(responseJson?.message, { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.deletePostApiId &&
            responseJson !== undefined
          ) {
            this.props.deleteParticularPost(responseJson?.data?.id)
            this.setState({ myAllPost: this.props.list })
            this.handleDeleteDialogToogle()
            toast.success(responseJson.message, { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.updateApiCallId &&
            responseJson !== undefined
          ) {
            const b = document.querySelector("#editBio") as HTMLInputElement;
            const desc = b?.value

            this.props.updatePostDesc({ id: this.state.editPostId, description: desc })
            this.setState({ myAllPost: this.props.list })
            this.setState({ editPostDesc: '', editPostId: 0 })
          }
          if (
            apiRequestCallId === this.editCommentApiId &&
            !!responseJson
          ) {
            this.props.editComment({
              postId: responseJson.data.attributes.post_id,
              commentId: responseJson.data.id,
              comment: responseJson.data.attributes.comment
            })
            this.setState({ editCommentId: 0 })
          }
          if (
            apiRequestCallId === this.deleteCommentApiId &&
            !!responseJson
          ) {
            this, this.props.deleteComment({
              postId: responseJson.data.attributes.post_id,
              commentId: responseJson.data.id,
            })
            this.setState({ deleteCommentId: 0 })
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;

        if (errors) {

          if (apiRequestCallId === this.apiMyInformationId && errors[0]?.account === "Record not found") {
            this.logoutUser()
          }

          if (apiRequestCallId === this.editCommentApiId) {
            toast.error("Something went wrong!", { position: toast.POSITION.BOTTOM_RIGHT })
          }

          if (!!errors[0]?.description) {
            toast.error(errors[0]?.description, { position: toast.POSITION.BOTTOM_RIGHT })
          }
        }
      }
      // Customizable Area End
    }
  }
}
