import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
//import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  submitBtn: boolean;
  countryData: any[];
  stateData: any[];
  cityData: any[];
  CountryName: string;
  showNewPassword: boolean;
  showRetypePassword: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  GetCountryAuthTokenApiCallId: string = "";
  GetCountryListApiCallId: string = "";
  GetCountryStateListApiCallId: string = "";
  GetCountryCityListApiCallId: string = "";
  apiResetPasswordCallId: string = "";
  // Customizable Area End

  constructor (props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      submitBtn: false,
      countryData: [],
      stateData: [],
      cityData: [],
      CountryName: "",
      showNewPassword: false,
      showRetypePassword: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    // this.imgPasswordVisible = imgPasswordVisible;
    //this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.passwordLabel;
    this.labelRePassword = configJSON.reTypeLabel;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }
  //Customizable area start
  SubmitSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(configJSON.passwordRequire)
      .min(8, configJSON.passwordMinlength)
      .matches(configJSON.passwordRegex, configJSON.passwordInValid),
    reTypePassword: Yup.string()
      .required(configJSON.repeatPasswordRequire)
      .oneOf(
        [Yup.ref("newPassword"), null],
        configJSON.errorBothPasswordsNotSame
      ),
  });

  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  doSubmit(values: any) {
    this.doResetPasswordAPI(values)
  }
  doResetPasswordAPI(values: any): boolean {

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const data = {
      token: Cookies.get('resend_token'),
      new_password:values.newPassword
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ResetPasswordApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.apiResetPasswordCallId &&
            responseJson !== undefined
          ) {
            toast.success("Password successfully reset",{position: toast.POSITION.BOTTOM_RIGHT});
            this.props.navigation.navigate('EmailAccountLogin');
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        this.props.navigation.navigate('EmailAccountLogin');
        if (errors[0].otp) {
          toast.error(errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT})
          return;
        }
        if (errors[0].token === 'Invalid token' || errors[0].token === "Token has Expired") {
          toast.error(errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
          Cookies.remove('Login_Token')
          //@ts-ignore
          this.props.history.push({
            pathname: '/login'
          })
        }
        if (errors) {
          toast.error(errors,{position: toast.POSITION.BOTTOM_RIGHT})
          return;
        }
      }
    }
  }
  // Customizable Area End
}
