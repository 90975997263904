import React from "react";
// Customizable Area Start
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Image from "material-ui-image";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import InfoIcon from "@material-ui/icons/Info";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import CakeIcon from "@material-ui/icons/Cake";
import AddIcon from '@material-ui/icons/Add';
import { Web3ReactProvider } from "@web3-react/core";
import { Formik, Form, FormikProps } from "formik";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Send } from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ConfirmDeleteDialog from "../../TargetedFeed/src/ConfirmDeleteDialog.web";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import MyProfileController, { Props } from "./MyProfileController.web";
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import { getWeb3ReactContext } from "@web3-react/core";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import NavbarWeb from "../../TargetedFeed/src/Navbar.web";
import { postCamera } from "../../TargetedFeed/src/assets";
import SidebarWeb from "../../TargetedFeed/src/Sidebar.web";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import ReactSelect from 'react-select'
import moment from "moment";
import { connect } from "react-redux"
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';

//@ts-ignore
import { SET_OWN_POSTS, EDIT_OWN_POST, DELETE_OWN_POST,USER_LOGOUT, SET_OWN_NFTS, SET_PREVIOUS_NFTS, SET_PROFILE, SET_FRIENDS, UPDATE_FRIENDS, SET_BLOCKED_USERS, UNBLOCK_USER } from "../../../web/src/allActions.js";
import Cookies from "js-cookie"
// Customizable Area End

class MyProfile extends MyProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 
  render() {
    //@ts-ignore
    const { classes, allUserPosts, allUserNFTs, friendsList, blockedUsers, previouslyOwnedNFTs, profileData: {
      profileImage,
      fullName,
      userName,
      country,
      state,
      city,
      phoneNumber,
      emailId,
      information,
      dateOfBirth
    } } = this.props
    const web3ReactContext = getWeb3ReactContext();

    const {
      previouslyNFTsCount,
      myNftopen,
      myNftconfirm,
      setImgUrl,
      myNftopenChild,
      myNftmetamaskBTN,
      myNftallAssets,
      keepAllImage,
      uploadNFTLoader,
     } = this.state

    function TabPanel(props: any) {
      const { children, value, index, ...other } = props;

      return (
        <div {...other}>{value === index && <Box p={3}>{children}</Box>}
        </div>
      );
    }
    return (
      <>
        <NavbarWeb
          //@ts-ignore
          getAllPostAfterUpload={this.getAllPostAfterUpload}
        />
        <div className={classes.container}>
          <div style={{ display: 'flex', justifyContent: "space-between", }}>
            <SidebarWeb //@ts-ignore
              FromHome={"FromHome"} />
            {/* Right */}
            <div className={classes.mainContent}>
              {/* Upper */}
              <div className={classes.upperBoxBg}>
                {/* ImageContent */}
                <div style={{ display: 'flex', alignItems: "center", padding: "0 0 10px 0", borderBottom: "1px solid rgba(0,0,0,0.2)" }}>
                  <div style={{ width: "15%", position: "relative", justifyContent: 'left', display: "left" }}>
                    {(this.state.userInfoLoader && !profileImage) || this.state.updateImageLoader ?
                      <Image src="" loading={<CircularProgress style={{ color: '#1f7fb6' }} />} /> :
                      <>
                        <div style={{ borderRadius: '50%', overflow: 'hidden' }}>
                          <Image
                            id="profilePicture"
                            src={profileImage || "https://www.studyheights.com/assets/img/Universitylogo/default.png"} />
                          <input
                            accept=".png, .jpg, .jpeg"
                            id="icon-button-file"
                            type="file"
                            style={{ display: "none" }}
                            name="profilePic"
                            onChange={(event) => this.fileUploadHandler(event)}
                          />
                        </div>
                        <label htmlFor="icon-button-file" style={{ position: "absolute", bottom: "0", right: "0", background: "#cccccc", borderRadius: "50%" }}>
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <PhotoCamera style={{ color: "#9a9a9a" }} />
                          </IconButton>
                        </label>
                      </>}
                  </div>
                  <div className={classes.heading}>
                    <h2>{fullName}</h2>
                    <p>{userName}</p>
                  </div>
                </div>
                {/* Tab Div */}
                <div className={classes.tabsWrap}>
                  <div className={classes.leftTab}>
                    <Tabs
                      value={this.state.value}
                      onChange={(event: any, newValue: number) => {
                        this.setState({
                          value: newValue,
                        });
                      }}
                      aria-label="simple tabs example"
                    >

                      <Tab label="Home" />
                      <Tab label="My Bio" />
                      <Tab label={`My NFTs(${allUserNFTs.length})`} />
                      <Tab label={`Friends(${friendsList.length})`} />
                    </Tabs>
                  </div>
                </div>
              </div>
              <TabPanel value={this.state.value} index={0}>
                <div className={classes.bottomBoxBg}>
                  {this.state.loader ? <div className={classes.emptyPostBox} style={{ background: 'transparent' }}><CircularProgress style={{ color: '#1f7fb6' }} /></div> : (
                    allUserPosts?.length > 0 ? (allUserPosts?.map((element: any, index: number) => {
                      const { attributes: { animation_url, image_url, is_current_owner } } = element
                      return (
                        <Card className={classes.postCard} key={index}>
                          <CardHeader
                            avatar={
                              //@ts-ignore
                              <Avatar src={profileImage || "https://www.studyheights.com/assets/img/Universitylogo/default.png"} aria-label="recipe">
                              </Avatar>
                            }
                            action={
                              <div className={classes.menuOuter} id={`demo-${element.attributes.id}`}>
                                <IconButton
                                  onClick={this.handleClick}
                                  id={`account-${element.attributes.id}`}
                                  aria-controls={this.state.menuAnchor?.getAttribute('id') == `account-${element.attributes.id}` ? `account-menu ${element.attributes.id}` : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={!!this.state.menuAnchor ? 'true' : undefined}
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                              
                              <Menu
                              anchorEl={this.state.menuAnchor}
                              className={classes.menuItemCss}
                              id={`account-menu ${element.attributes.id}`}
                              open={this.state.menuAnchor?.getAttribute('id') == `account-${element.attributes.id}`}
                              onClose={this.handleClose}
                              onClick={this.handleClose}
                              container={() => document.getElementById(`demo-${element.attributes.id}`)}
                            >
                              <MenuItem>
                              <Button onClick={() => this.setState({ editPost: element.attributes.id, descVal: element.attributes.description })}> <h4><EditOutlinedIcon />Edit</h4>
                              </Button>
                            </MenuItem>
                              <MenuItem>
                              <Button onClick={() => this.handleDeleteDialogToogle(element?.attributes?.id)}><h4><DeleteOutlineOutlinedIcon />Delete</h4>
                              </Button>
                              </MenuItem>
                            </Menu>
                            </div>
                            }
                            title={<span className={classes.defaultCursor}>{fullName}</span>}
                            subheader={<span className={classes.defaultCursor}>{element.attributes.time}</span>}
                          />
                          <CardContent>
                            <div>

                              {this.state.editPost === element.attributes.id ? (
                                <form className={classes.discrtxt} onSubmit={(e) => e.preventDefault()}>
                                  <div style={{ display: 'flex' }} onClick={(event) => event?.stopPropagation()}>
                                    <TextField
                                      id="editBio"
                                      fullWidth
                                      variant="standard"
                                      autoComplete="off"
                                      placeholder="Write something here.."
                                      defaultValue={element.attributes.description}
                                    />
                                    <Button className={classes.btnSubmit} type='submit' onClick={(event) => {
                                      this.updatePost(element.attributes.id)
                                    }}>
                                      save
                                    </Button>
                                  </div>
                                </form>
                              ) : (
                                <p
                                  className={classes.pointer}
                                  onClick={() => this.postRedirect(element.attributes.id)}>
                                  {element.attributes.description}
                                </p>
                              )}

                              {element.attributes.nft_name && <span 
                                className={classes.pointer}
                                onClick={() => this.postRedirect(element.attributes.id)}>{element.attributes.nft_name}</span>}
                              {animation_url ? <>
                                {this.postTypeCheck(animation_url) === "audio" &&
                                  <audio
                                    className={classes.pointer}
                                    onClick={() => this.postRedirect(element.attributes.id)}
                                    style={{ width: '100%', objectFit: 'inherit' }} controls>
                                    <source src={animation_url} type="audio/mp3" />
                                    <source src={animation_url} type="audio/wav" />
                                    Your browser does not support the audio element.
                                  </audio>}
                                {this.postTypeCheck(animation_url) === "video" &&
                                  <video
                                    className={classes.pointer}
                                    onClick={() => this.postRedirect(element.attributes.id)}
                                    style={{ width: '100%', height: '530px', objectFit: 'inherit' }} controls>
                                    <source src={animation_url} type="video/mp4" />
                                    <source src={animation_url} type="video/ogg" />
                                    <source src={animation_url} type="video/webm" />
                                    Your browser does not support the video element.
                                  </video>}
                                {this.postTypeCheck(animation_url) === "iframe" &&
                                  <iframe
                                    className={classes.pointer}
                                    onClick={() => this.postRedirect(element.attributes.id)}
                                    style={{ width: '100%', height: '530px', objectFit: 'inherit' }} src={animation_url} />}
                                {this.postTypeCheck(animation_url) === "3dObject" &&
                                  <img
                                    className={classes.pointer}
                                    onClick={() => this.postRedirect(element.attributes.id)}
                                    style={{ width: '100%', height: '530px', objectFit: 'inherit' }}
                                    src={image_url} />}
                              </> :
                                image_url &&
                                <img
                                  className={classes.pointer}
                                  onClick={() => this.postRedirect(element.attributes.id)}
                                  style={{ width: '100%', height: '530px', objectFit: 'inherit' }}
                                  src={image_url} />}
                              <div className={classes.postCardInfo}>
                                <div>
                                  <FavoriteOutlinedIcon />
                                  <h3>
                                    {//@ts-ignore
                                      element.attributes.likes_count}
                                  </h3>
                                </div>
                                <p className={classes.defaultCursor}>
                                  {//@ts-ignore
                                    element.attributes.comments_count} comments
                                </p>
                                {image_url && <h3 className={`${classes.paidCardText} ${classes.defaultCursor} ${!is_current_owner && classes.soldNFT}`}><img src="https://img.icons8.com/ios/24/000000/price-tag--v1.png" />
                                  {`Paid $ ${element?.attributes?.usd_amount || 0}(${element?.attributes?.eth_amount || 0} ETH)`}
                                </h3>}
                              </div>
                            </div>
                          </CardContent>
                          <div className={classes.likeCommentShare}>
                            <IconButton className={classes.txtHover} aria-label="add to favorites" onClick={() => this.likePost(//@ts-ignore
                              element.attributes.id, element.attributes.is_liked)}>
                              <FavoriteBorderOutlinedIcon style={
                                //@ts-ignore
                                element.attributes.is_liked ?
                                  { color: '#0388c9' } : { color: 'grey' }} id="likePost" />
                              <p> {
                                element.attributes.is_liked ?
                                  'Unlike' : 'Like'}
                              </p>
                            </IconButton>
                            <IconButton className={classes.txtHover} aria-label="comment" onClick={() => {
                              if (this.state.commentBoxId !== index) {
                                this.setState({ commentBoxId: index, allComments: [], commentCount: 1 })
                                this.getCommentData(element.attributes.id)
                              } else {
                                this.setState({ commentBoxId: '' })
                              }
                            }}>
                              <ModeCommentOutlinedIcon /><p>Comment</p>
                            </IconButton>
                          </div>
                          {this.state.commentBoxId === index ?
                            (
                              <div className={classes.commentBlockWrapper}>
                                <FormControl className={classes.selectComment}>
                                  <InputLabel></InputLabel>
                                  <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    autoWidth
                                    defaultValue={10}
                                  >
                                    <MenuItem disabled value={30}>
                                      <em>Comment filter</em>
                                    </MenuItem>
                                    <MenuItem value={10} onClick={() => { this.getCommentData(element.attributes.id, 'most_relevant') }}>Most relevant comments</MenuItem>
                                    <MenuItem value={21} onClick={() => { this.getCommentData(element.attributes.id, 'oldest') }}>Old comments</MenuItem>
                                    <MenuItem value={22} onClick={() => { this.getCommentData(element.attributes.id, 'newest') }}>New comments</MenuItem>
                                  </Select>
                                </FormControl>
                                <div className={classes.commentBlock}>
                                  <Avatar aria-label="recipe" src={profileImage || "https://www.studyheights.com/assets/img/Universitylogo/default.png"}>
                                  </Avatar>
                                  <TextField
                                    className={classes.commentBar}
                                    placeholder="Write a comment"
                                    id={"comentBox" + index}
                                  />
                                  <IconButton
                                    style={useStyles.sendButton} onClick={() => this.createComment(element.attributes.id, index)}>
                                    <Send style={useStyles.sendIcon} />
                                  </IconButton>
                                </div>
                                {this.state.allComments.length !== 0 && (
                                  this.state.allComments.map((comment: any, commentIndex: any) => {
                                    if (commentIndex < this.state.commentCount) {

                                      return (
                                        <div className={classes.commentBlock} key={commentIndex}>
                                          <Avatar aria-label="recipe" src={comment.attributes.account.image}>
                                          </Avatar>
                                          <div className={classes.commentWrap}>
                                            <p>
                                              <span>{comment.attributes.account.full_name}</span>
                                              {this.state.editCommentId === comment.attributes.id ?
                                                <TextField
                                                  id="editCommentBox"
                                                  fullWidth
                                                  variant="standard"
                                                  autoComplete="off"
                                                  placeholder="Write a comment"
                                                  autoFocus={this.state.editCommentId === comment.attributes.id}
                                                  defaultValue={comment.attributes.comment}
                                                  onClick={(e) => e.stopPropagation()}
                                                />
                                                : comment.attributes.deleted ? 'This comment was deleted' : comment.attributes.comment}
                                            </p>
                                            <ul className={classes.listComment}>
                                              <li>
                                                <Button onClick={() => this.likeOrDislikeComment(comment.attributes.id, comment.attributes.is_liked)}>
                                                  {comment.attributes.is_liked ? (<div style={{ color: '#1f7fb6' }}>Dislike</div>) : 'Like'}
                                                </Button>
                                              </li>
                                              <li>
                                                <Button key={commentIndex + comment + index + commentIndex} onClick={() => {
                                                  if (this.state.showReplyInbox !== comment.attributes.id) {
                                                    this.setState({ showReplyInbox: comment.attributes.id, allRepliedComments: [] })

                                                    this.getRepliedCommentData(comment.attributes.id)
                                                  }
                                                }}>
                                                  Reply
                                                </Button>
                                              </li>
                                              <li style={{ color: '#1f7fb6' }}><FavoriteIcon style={{ fontSize: 'larger' }} /> ({comment.attributes.likes_count})&nbsp;</li>
                                              <li>{comment.attributes.time}</li>
                                              {Cookies.get('Login_Id') == comment.attributes.account_id && !comment.attributes.deleted && (
                                                <>
                                                  <li>
                                                    {this.state.editCommentId === comment.attributes.id ?
                                                      <Button onClick={() => this.editComment(element.attributes.id)}>
                                                        Save
                                                      </Button>
                                                      : <Button onClick={() => this.setState({ editCommentId: comment.attributes.id })}>
                                                        Edit
                                                      </Button>}
                                                  </li>
                                                  <li>
                                                    <Button onClick={() => this.handleDeleteCommentToogle(comment.attributes.id)}>
                                                      Delete
                                                    </Button>
                                                  </li></>)}
                                            </ul>

                                            {this.state.showReplyInbox === comment.attributes.id ? (
                                              <div className={classes.replyCommentBlock}>

                                                <div className={classes.commentBlock}>
                                                  {this.state.allRepliedComments.length !== 0 && (
                                                    this.state.allRepliedComments.map((replyComment: any, replyIndex: any) => {
                                                      return (
                                                        <>
                                                          <Avatar aria-label="recipe" key={replyIndex + replyComment} src={replyComment.attributes.account.image}>
                                                          </Avatar>
                                                          <div className={classes.commentWrap} key={replyIndex + replyComment + replyIndex}>
                                                            <p key={replyIndex + replyComment + replyComment}>
                                                              <span key={replyIndex + replyComment + replyIndex + replyIndex}>{replyComment.attributes.account.full_name}</span>
                                                              {replyComment.attributes.comment}
                                                            </p>
                                                            <ul className={classes.listComment} key={replyIndex + replyComment + replyIndex + replyComment}>
                                                              <li key={replyComment}>
                                                                <Button key={replyIndex + replyComment + replyComment + replyIndex} onClick={() => this.likeOrDislikeComment(replyComment.attributes.id, replyComment.attributes.is_liked)}>
                                                                  {replyComment.attributes.is_liked ? (<div style={{ color: '#1f7fb6' }}>Dislike</div>) : 'Like'}
                                                                </Button>
                                                              </li>
                                                              <li key={replyIndex + replyComment + index}>
                                                                <Button key={replyIndex + replyComment + index + replyIndex} >
                                                                  Reply
                                                                </Button>
                                                              </li>
                                                              <li style={{ color: '#1f7fb6' }} key={replyIndex + replyComment + index + replyComment + index + index}>{replyComment.attributes.likes_count} &nbsp;<FavoriteIcon style={{ fontSize: 'larger' }} /></li>
                                                              <li key={replyIndex + replyComment + index + replyComment}>{replyComment.attributes.time}</li>
                                                            </ul>
                                                          </div></>
                                                      )
                                                    })
                                                  )}
                                                  <Avatar aria-label="recipe" src={profileImage || "https://www.studyheights.com/assets/img/Universitylogo/default.png"}>
                                                  </Avatar>
                                                  <TextField
                                                    className={classes.commentBar}
                                                    placeholder="Write a comment"
                                                    id={"comentReplyBox" + index + commentIndex}
                                                  />
                                                  <IconButton
                                                    style={useStyles.sendButton} onClick={() => this.createReplyComment(element.attributes.id, index, commentIndex, comment.attributes.id)}>
                                                    <Send style={useStyles.sendIcon} />
                                                  </IconButton>
                                                </div>
                                              </div>
                                            ) : ('')}
                                          </div>
                                        </div>
                                      )
                                    }
                                  })
                                )}
                                {this.state.allComments.length !== 0 && (
                                  <div className={classes.paginationBlock}>
                                    <Button onClick={() => {
                                      if (this.state.commentCount !== this.state.allComments.length) {
                                        this.setState({ commentCount: this.state.allComments.length })
                                      } else {
                                        this.setState({ commentCount: 1 })
                                      }
                                    }}>{this.state.commentCount !== this.state.allComments.length ? "See more comments" : "See less comments"}</Button>
                                    <div className={classes.paginationBlockWrapper}>
                                      <span>{this.state.commentCount}</span> of <span>{this.state.allComments.length}</span>
                                    </div>
                                  </div>)
                                }
                              </div>
                            ) : ('')
                          }
                        </Card>
                      )
                    })) : (<div className={classes.emptyPostBox}><h4 style={useStyles.poppinsFont}>Nothing to see here, yet</h4></div>)
                  )}
                </div>
              </TabPanel>
              <TabPanel value={this.state.value} index={1}>
                <Formik
                  initialValues={{
                    location: city || "",
                    phoneNumber: phoneNumber,
                    information: information,
                    email: emailId,
                    dateOfbirth: dateOfBirth,
                    country: country,
                    state: state,
                    city: city
                  }}
                  onSubmit={(values): any => { this.doSave(values) }}
                >
                  {(formik) => (
                    //@ts-ignore
                    <Form className={classes.formWrap} style={{ background: "#ffffff", width: "80%", padding: "30px 30px 15px", margin: "0 auto" }}>
                      {this.state.userInfoLoader && !emailId ?
                        <div className={classes.genLoaderBox}><CircularProgress style={{ color: '#1f7fb6' }} /></div> : <>
                          <div className={classes.friendCardTitle}>
                            <h2>Bio</h2>
                            {this.state.showSaveBtn ? (
                              <Button
                                className={classes.btnViewNFT}
                                variant="contained"
                                type="submit"
                                id="icon-button-edit"
                                style={{ minWidth: "initial" }}
                                onClick={() => {

                                  this.setState({
                                    showSaveBtn: !this.state.showSaveBtn,
                                  });
                                  this.setState({
                                    showDatePicker: !this.state.showDatePicker,
                                  });
                                  this.setState({
                                    inputFieldDisable: !this.state.inputFieldDisable,
                                  });
                                }}
                              >
                                <EditIcon />
                                Edit Bio
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                type="submit"
                                style={{ height: '50px', width: '100px' }}
                                onClick={(e) => {
                                  formik.handleSubmit();
                                  this.setState({
                                    showSaveBtn: !this.state.showSaveBtn,
                                  });
                                  this.setState({
                                    showDatePicker: !this.state.showDatePicker,
                                  });
                                  this.setState({
                                    inputFieldDisable: !this.state.inputFieldDisable,
                                  });
                                }}
                              >
                                <SaveIcon />
                                Save
                              </Button>
                            )}
                          </div>
                          <div className={classes.textWrapper}>
                            {/* Start */}
                            {this.state.showSaveBtn ? (
                              <TextField
                                InputProps={{
                                  startAdornment: <LocationOnIcon />,
                                }}
                                className={classes.textField}
                                value={formik.values.location}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="location"
                                id="location"
                                helperText={formik.errors.location}
                                variant="outlined"
                                disabled={this.state.inputFieldDisable}
                              />
                            )
                              : (
                                <div>
                                  <FormControl className={classes.textField}>
                                    <span>Country</span>
                                    <ReactSelect
                                      value={this.state.countryData.find((option: any) => option.iso2 === formik.values.country) || ''}
                                      onChange={(e: any) => {
                                        formik.setFieldValue("country", e.iso2)
                                        this.props.setProfile({
                                          country: e.iso2,
                                          state: "",
                                          city: ""
                                        })
                                      }}
                                      options={this.state.countryData}
                                      isSearchable
                                      placeholder="Select a country"
                                      getOptionLabel={(country: any) => country.name}
                                      getOptionValue={(country: any) => country.iso2}
                                    />
                                  </FormControl>
                                  <FormControl className={classes.textField}>
                                    <span>State</span>
                                    <ReactSelect
                                      value={this.state.stateData.find((option: any) => option.iso2 === formik.values.state) || ''}
                                      onChange={(e: any) => {
                                        formik.setFieldValue("state", e.iso2)
                                        this.props.setProfile({
                                          state: e.iso2,
                                          city: ""
                                        })
                                      }}
                                      options={this.state.stateData}
                                      isSearchable
                                      placeholder="Select a state"
                                      getOptionLabel={(state: any) => state.name}
                                      getOptionValue={(state: any) => state.iso2}
                                    />
                                  </FormControl>
                                  <FormControl className={classes.textField}>
                                    <span>City</span>
                                    <ReactSelect
                                      value={this.state.cityData.find((option: any) => option.name === formik.values.city) || ''}
                                      onChange={(e: any) => formik.setFieldValue("city", e.name)}
                                      options={this.state.cityData}
                                      isSearchable
                                      placeholder="Select a city"
                                      getOptionLabel={(city: any) => city.name}
                                      getOptionValue={(city: any) => city.name}
                                    />
                                  </FormControl>
                                </div>
                              )}
                            {/* End */}
                            <TextField
                              InputProps={{
                                startAdornment: <InfoIcon />,
                              }}
                              className={classes.textField}
                              value={formik.values.information}
                              autoComplete="off"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="information"
                              id="information"
                              helperText={formik.errors.information}
                              variant="outlined"
                              disabled={this.state.inputFieldDisable}
                              error={formik.touched.information && formik.errors.information ? true : false}
                            />
                            <TextField
                              InputProps={{
                                startAdornment: <CallIcon />,
                              }}
                              className={classes.textField}
                              value={formik.values.phoneNumber}
                              aria-readonly="true"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="phoneNumber"
                              id="phoneNumber"
                              variant="outlined"
                              disabled
                            />
                            <TextField
                              InputProps={{
                                startAdornment: <EmailIcon />,
                              }}
                              autoComplete="off"
                              className={classes.textField}
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="email"
                              id="email"
                              variant="outlined"
                              disabled
                            />
                            {this.state.showDatePicker ? (
                              <TextField
                                InputProps={{
                                  startAdornment: <CakeIcon />,
                                }}
                                className={classes.textField}
                                value={formik.values.dateOfbirth}
                                variant="outlined"
                                disabled={this.state.inputFieldDisable}
                              />
                            ) : (
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  value={moment(formik.values.dateOfbirth, "DD-MM-YYYY")}
                                  className={classes.textField}
                                  name="dateOfbirth"
                                  placeholder="Date of birth"
                                  onChange={() => { }}
                                  format="dd-MM-yyyy"
                                  onAccept={(date: any) => formik.setFieldValue("dateOfbirth", date)}
                                  error={false}
                                  helperText={null}
                                  inputVariant="outlined"
                                  size="small"
                                  maxDate={new Date()}
                                  inputProps={{ readOnly: true }}
                                />
                              </MuiPickersUtilsProvider>
                            )}
                          </div>
                        </>}
                    </Form>
                  )}
                </Formik>
              </TabPanel>
              <TabPanel value={this.state.value} index={2}>
                <Card className={classes.NFTCardWrapper} >
                  <CardContent>
                    <div>
                      <div className={classes.friendCardTitle} style={{ border: "none" }}>
                        <h2>Verified NFTs</h2>
                        <IconButton className={classes.btnViewNFT} onClick={() => this.setState({ ...this.state, myNftopen: true })}>
                          <AddIcon /> Create Post
                        </IconButton>
                      </div>
                      <hr />
                      <div className={classes.accordionUserWrapper}>
                        {this.state.nftLoader && allUserNFTs?.length < 1 ? <div className={classes.genLoaderBox}><CircularProgress style={{ color: '#1f7fb6' }} /></div> :
                          allUserNFTs?.length > 0 ? (allUserNFTs?.map((element: any, index: number) => {
                            if (index <= this.state.nftCount) {
                              const { attributes } = element
                              return (
                                <Card style={useStyles.colThree} className={classes.profileNFTCard} key={element + index}>
                                  <CardContent>
                                    <div>
                                      <h3 style={useStyles.nftText}>{attributes.name}</h3>
                                      <img
                                        src={attributes?.image_url}
                                        className={`${classes.verifiedNFT} ${!!attributes.post && classes.pointer}`}
                                        onClick={() => this.nftToPostRedirect(attributes.post)} />
                                      <h3 style={useStyles.paidText} className={classes.paidWrapper}>
                                        <img src="https://img.icons8.com/ios/24/000000/price-tag--v1.png" />
                                        {`Paid $ ${attributes?.usd_amount || '0'}(${attributes?.eth_amount || 0} ETH)`}
                                      </h3>
                                    </div>
                                  </CardContent>
                                </Card>);
                            }

                          })) : (<h4 style={useStyles.poppinsFont}>No NFTs Found</h4>)}


                      </div>
                      {allUserNFTs.length > 10 && ((this.state.nftCount === 9) ? (<Button
                        type="submit"
                        className={classes.btnViewNFT} style={{ width: "100%" }}
                        onClick={() => this.seeAllNft(allUserNFTs)}>
                        View all</Button>) : (<Button
                          type="submit"
                          className={classes.btnViewNFT} style={{ width: "100%" }}
                          onClick={() => this.setState({ nftCount: 9 })}>
                          View less</Button>))}

                    </div>
                  </CardContent>
                </Card>
                <Card className={classes.NFTCardWrapper} >
                  <CardContent>
                    <div>
                      <div className={classes.friendCardTitle} style={{ border: "none" }}>
                        <h2>Previously owned NFTs</h2>
                      </div>
                      <hr />
                      <div className={classes.accordionUserWrapper}>
                        {this.state.prevNFTLoder && previouslyOwnedNFTs.length === 0 ? <div className={classes.genLoaderBox}><CircularProgress style={{ color: '#1f7fb6' }} /></div> :
                          previouslyOwnedNFTs?.length > 0 ? (
                            previouslyOwnedNFTs.map((element: any, index: number) => {
                              if (index <= previouslyNFTsCount) {
                                return (
                                  <Card
                                    key={index}
                                    style={useStyles.colThree}
                                    className={classes.profileNFTCard}
                                  >
                                    <CardContent>
                                      <div>
                                        <h3 style={useStyles.nftText}>{element.name}</h3>
                                        <img
                                          style={{
                                            float: 'left',
                                            width: '150px',
                                            height: '150px',
                                            objectFit: 'inherit',
                                            borderRadius: '5px',
                                          }}
                                          src={element.image_url}
                                        />
                                        <h3
                                          style={{ ...useStyles.paidText, ...useStyles.previousNFTText }}
                                          className={classes.paidWrapper}
                                        >
                                          <img
                                            src="https://img.icons8.com/ios/24/000000/price-tag--v1.png"
                                            alt="price tag"
                                            height="15"
                                            width="15"
                                          />
                                          {`Paid $ ${element?.usd_amount || 0}(${element?.eth_amount || 0
                                            } ETH)`}
                                        </h3>
                                      </div>
                                    </CardContent>
                                  </Card>
                                )
                              }
                            })
                          ) : (
                            <h4 style={useStyles.poppinsFont}>Nothing to see here, yet</h4>
                          )
                        }
                      </div>
                      {previouslyOwnedNFTs?.length > 10 && (previouslyNFTsCount === 9 ?
                        (<Button
                          type="submit"
                          className={classes.btnViewNFT} style={{ width: "100%" }}
                          onClick={() => this.setState({ ...this.state, previouslyNFTsCount: previouslyOwnedNFTs.length })}>
                          View all</Button>) :
                        (<Button
                          type="submit"
                          className={classes.btnViewNFT} style={{ width: "100%" }}
                          onClick={() => this.setState({ previouslyNFTsCount: 9 })}>
                          View less</Button>))}
                    </div>
                  </CardContent>
                </Card>
              </TabPanel>
              <TabPanel value={this.state.value} index={3}>
                <Card className={classes.friendCard}>
                  <CardContent>
                    <div className={classes.friendUserTab}>
                      <div className={classes.friendCardTitle}>
                        <Tabs
                          value={this.state.friendsTab}
                          onChange={(_, newValue: number) => {
                            this.setState({
                              friendsTab: newValue,
                            });
                          }}
                        >
                          <Tab label={`Friends(${friendsList?.length})`} />
                          <Tab label={`Blocked Users(${blockedUsers.length})`} />
                        </Tabs>
                        {this.state.friendsTab === 0 && <TextField
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position={"start"}>
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          autoComplete="off"
                          id='searchFriend'
                          placeholder="Search friends"
                          onChange={(e: any) => {
                            this.handleChangeDebounce(e.target.value)
                          }}
                        />}
                      </div>
                      <TabPanel value={this.state.friendsTab} index={0}>
                        {this.state.getFriendsLoder && friendsList.length === 0 ?
                          <div className={classes.genLoaderBox}><CircularProgress style={{ color: '#1f7fb6' }} /></div> :
                          friendsList?.length !== 0 ? (
                            <div className={classes.accordionUserWrapper}>
                              {friendsList.map((element: any, index: number) => {
                                if (index <= this.state.frdCount) {
                                  return (
                                    <div className={classes.accordionUserItem} key={index + index}>
                                      <div className={classes.accordionUser}>
                                        <div className={classes.userInfo}>
                                          <Avatar src={element?.attributes?.image ? element.attributes.image : "https://www.studyheights.com/assets/img/Universitylogo/default.png"} />
                                          <Button style={{ textTransform: 'initial' }} onClick={() => {
                                            if (element.attributes.receipient_name) this.RedirectToUser(element.attributes.receipient_name, true)
                                            else this.RedirectToUser(element.attributes.receipient_id, false)
                                          }}>
                                            <Typography>{element.attributes.first_name + ' ' + element.attributes.last_name}
                                            </Typography>
                                          </Button>
                                        </div>
                                        {/* <Menu
                                        >
                                          <IconButton className={classes.buttonMore}>
                                            <MoreHorizIcon />
                                          </IconButton>
                                          <MenuList className={classes.menuListData}>
                                            <span className={classes.triangle}></span>
                                            <Button onClick={() => this.unfollowRequest(element.id, element.attributes.receipient_id, element.attributes.follow)}> <h4 key={element}><img src="https://img.icons8.com/ios/50/000000/dislike.png" /> Unfollow</h4>
                                              <Switch checked={!element.attributes.follow} />
                                            </Button>
                                            <Button onClick={() => this.setState({ friendFirst_name: element.attributes.first_name, friendLast_name: element.attributes.last_name, openDialoug: true, friendId: element.id })}>
                                              <h4>
                                                <img src="https://img.icons8.com/ios/50/000000/delete-user-male.png" />
                                                Unfriend
                                              </h4>
                                            </Button>
                                            <Button onClick={() => this.setState({ blockFriendId: element.attributes.receipient_id, openBlockDialogue: true, friendFirst_name: element.attributes.first_name, friendLast_name: element.attributes.last_name })} >
                                              <h4 >
                                                <img src="https://img.icons8.com/ios/50/000000/unfriend-male.png" />
                                                Block
                                              </h4>
                                            </Button>
                                          </MenuList>
                                        </Menu> */}
                                        <div className={classes.menuOuter} id={`demo-${element.attributes.receipient_id}`}>
                                          <IconButton
                                            onClick={this.handleClick}
                                            className={classes.buttonMore}
                                            id={`account-${element.attributes.receipient_id}`}
                                            aria-controls={this.state.menuAnchor?.getAttribute('id') == `account-${element.attributes.receipient_id}` ? `account-menu ${element.attributes.receipient_id}` : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={!!this.state.menuAnchor ? 'true' : undefined}
                                          >
                                            <MoreHorizIcon />
                                          </IconButton>
                                        
                                        <Menu
                                        anchorEl={this.state.menuAnchor}
                                        className={classes.menuItemCss}
                                        id={`account-menu ${element.attributes.receipient_id}`}
                                        open={this.state.menuAnchor?.getAttribute('id') == `account-${element.attributes.receipient_id}`}
                                        onClose={this.handleClose}
                                        onClick={this.handleClose}
                                        container={() => document.getElementById(`demo-${element.attributes.receipient_id}`)}
                                      >
                                        <MenuItem>
                                          <Button onClick={() => this.unfollowRequest(element.id, element.attributes.receipient_id, element.attributes.follow)}> <h4 key={element}><img src="https://img.icons8.com/ios/50/000000/dislike.png" /> Unfollow</h4>
                                              <Switch checked={!element.attributes.follow} />
                                            </Button>
                                      </MenuItem>
                                        <MenuItem>
                                          <Button onClick={() => this.setState({ friendFirst_name: element.attributes.first_name, friendLast_name: element.attributes.last_name, openDialoug: true, friendId: element.id })}>
                                              <h4>
                                                <img src="https://img.icons8.com/ios/50/000000/delete-user-male.png" />
                                                Unfriend
                                              </h4>
                                            </Button>
                                        </MenuItem>
                                        <MenuItem>
                                          <Button onClick={() => this.setState({ blockFriendId: element.attributes.receipient_id, openBlockDialogue: true, friendFirst_name: element.attributes.first_name, friendLast_name: element.attributes.last_name })} >
                                              <h4 >
                                                <img src="https://img.icons8.com/ios/50/000000/unfriend-male.png" />
                                                Block
                                              </h4>
                                            </Button>
                                        </MenuItem>
                                      </Menu>
                                      </div>
                                      </div>
                                    </div>
                                  )
                                }
                              })
                              }
                            </div>
                          ) : (
                            <h4 style={{ ...useStyles.poppinsFont, textAlign: 'center' }} >You haven't any friend</h4>
                          )}
                        {friendsList?.length > 10 && (this.state.frdCount === 9 ? (<Button
                          type="submit"
                          className={classes.btnViewNFT} style={useStyles.buttonUpload}
                          onClick={() => this.setState({ frdCount: friendsList.length })}>
                          View all</Button>) : (<Button
                            type="submit"
                            className={classes.btnViewNFT} style={useStyles.buttonUpload}
                            onClick={() => this.setState({ frdCount: 9 })}>
                            View less</Button>))}
                      </TabPanel>
                      <TabPanel value={this.state.friendsTab} index={1}>
                        <div className={classes.accordionUserWrapper}>
                          {blockedUsers?.length > 0 ? (
                            blockedUsers.map((blocked: any, index: number) => {
                              if (index <= this.state.blockCount) {
                                return (
                                  <div className={classes.accordionUserItem} key={index}>
                                    <div className={classes.accordionUser} key={index + blocked}>
                                      <div className={classes.userInfo} key={index + index + blocked}>
                                        <Avatar src={blocked.attributes.image ? blocked.attributes.image : "https://www.studyheights.com/assets/img/Universitylogo/default.png"} key={blocked + index} />
                                        <Button style={{ textTransform: 'initial' }} onClick={() => {
                                          if (blocked.attributes?.account?.user_name) this.RedirectToUser(blocked.attributes.account.user_name, true)
                                          else this.RedirectToUser(blocked.attributes.account?.id, false)
                                        }}>
                                          <Typography key={index + blocked + index}>{blocked.attributes.account.full_name}</Typography>
                                        </Button>
                                      </div>
                                      {/* <Menu key={index + blocked + index + blocked}>
                                        <IconButton className={classes.buttonMore} key={index + blocked + blocked + index}>
                                          <MoreHorizIcon />
                                        </IconButton>
                                        <MenuList className={classes.menuListData}>
                                          <span className={classes.triangle}></span>
                                          <Button key={blocked + index + index + index} onClick={() => this.unblockUser(
                                            blocked.attributes.account_id)}> <h4><img src="https://img.icons8.com/ios/50/000000/ok--v4.png" />Unblock</h4></Button>
                                        </MenuList>
                                      </Menu> */}
                                      <div className={classes.menuOuter} id={`demo-${blocked.attributes.account_id}`}>
                                          <IconButton
                                            onClick={this.handleClick}
                                            className={classes.buttonMore}
                                            id={`account-${blocked.attributes.account_id}`}
                                            aria-controls={this.state.menuAnchor?.getAttribute('id') == `account-${blocked.attributes.account_id}` ? `account-menu ${blocked.attributes.account_id}` : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={!!this.state.menuAnchor ? 'true' : undefined}
                                          >
                                            <MoreHorizIcon />
                                          </IconButton>
                                        
                                        <Menu
                                        anchorEl={this.state.menuAnchor}
                                        className={classes.menuItemCss}
                                        id={`account-menu ${blocked.attributes.account_id}`}
                                        open={this.state.menuAnchor?.getAttribute('id') == `account-${blocked.attributes.account_id}`}
                                        onClose={this.handleClose}
                                        onClick={this.handleClose}
                                        container={() => document.getElementById(`demo-${blocked.attributes.account_id}`)}
                                      >
                                        <MenuItem>
                                        <Button key={blocked + index + index + index} onClick={() => this.unblockUser(
                                            blocked.attributes.account_id)}> <h4><img src="https://img.icons8.com/ios/50/000000/ok--v4.png" />Unblock</h4></Button>
                                      </MenuItem>
                                      </Menu>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            })
                          ) : (<div className={classes.emptyList}>
                            <h4>You haven't any blocked user</h4>
                          </div>)}
                        </div>
                        {blockedUsers?.length > 10 && (this.state.blockCount === 2 ? (<Button
                          type="submit"
                          className={classes.btnViewNFT} style={useStyles.buttonUpload}
                          onClick={() => this.setState({ blockCount: blockedUsers.length })}>
                          View all</Button>) : (<Button
                            type="submit"
                            className={classes.btnViewNFT} style={useStyles.buttonUpload}
                            onClick={() => this.setState({ blockCount: 9 })}>
                            View less</Button>))}
                      </TabPanel>

                      <Dialog
                        open={this.state.openDialoug}
                        onClose={() => this.setState({ openDialoug: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className={classes.unfriendModal}
                      >
                        <DialogTitle id="alert-dialog-title" >
                          <IconButton className={classes.closeBtn} onClick={() => this.setState({ openDialoug: false })}>
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent className={classes.removeFriend}>
                          <DialogContentText id="alert-dialog-description" >
                            <AnnouncementIcon />
                            <span>Are you sure want to remove <b>{this.state.friendFirst_name + ' ' + this.state.friendLast_name}p</b> from your friends list?</span>
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions >
                          <Button className={classes.agreebtn} onClick={() => this.setState({ openDialoug: false })}>Cancel</Button>
                          <Button className={classes.disagreebtn} onClick={() => this.unfriendFunc(this.state.friendId)
                          } autoFocus>
                            Confirm
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Dialog
                        open={this.state.openBlockDialogue}
                        onClose={() => this.setState({ openBlockDialogue: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className={classes.blockModal}
                      >
                        <DialogTitle id="alert-dialog-title" >
                          <span>Are you sure to block <b>{this.state.friendFirst_name + ' ' + this.state.friendLast_name} </b>?</span>
                          <IconButton className={classes.closeBtn} onClick={() => this.setState({ openBlockDialogue: false })}>
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent className={classes.removeFriend}>
                          <span>
                            {this.state.friendFirst_name + ' ' + this.state.friendLast_name} will no longer able to: </span>
                          <ul>
                            <li>See your posts in your bio</li>
                            <li>See you messages</li>
                            <li>Add you to their friends list</li>
                          </ul>
                          <p>If you are friends and block <b>{this.state.friendFirst_name + ' ' + this.state.friendLast_name}</b> will also remove him/her from your friends list.</p>

                        </DialogContent>
                        <DialogActions >
                          <Button className={classes.agreebtn} onClick={() => this.setState({ openBlockDialogue: false })}>Cancel</Button>
                          <Button className={classes.disagreebtn} onClick={() => this.blockfriendFunc(this.state.blockFriendId)}
                            autoFocus>
                            Confirm
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </CardContent>
                </Card>
              </TabPanel>
              <ConfirmDeleteDialog
                //@ts-ignore
                title={"comment"}
                isOpen={this.state.deleteCommentDialog}
                handleCancelDelete={this.handleDeleteCommentToogle}
                handleConfirmDelete={this.deleteComment}
              />
              <ConfirmDeleteDialog
                //@ts-ignore
                isOpen={this.state.deleteDialog}
                handleCancelDelete={this.handleDeleteDialogToogle}
                handleConfirmDelete={this.deletePost}
              />
              <Web3ReactProvider getLibrary={this.getLibrary}>
                <web3ReactContext.Consumer>
                  {(value: any) => (
                    <div>
                      <Dialog className={classes.createPostModal}
                        open={myNftopen}
                        onClose={this.resetCreatePostDialog}
                      >
                        <DialogTitle style={useStyles.modalTitle}>
                          Create post
                          <span className={classes.infoTxt}><InfoOutlinedIcon /> MetaMask wallets and Opensea assets supported</span>
                          <IconButton
                            className={classes.closeBtn}
                            onClick={this.resetCreatePostDialog}>
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent className={classes.modelPopup}>
                          <div className={classes.profileImg} style={{ display: "flex", justifyContent: "flex-start", margin: "10px 0 0 0" }}>
                            <Avatar src={profileImage || "https://www.studyheights.com/assets/img/Universitylogo/default.png"} />
                            <p>{fullName || ''}</p>
                          </div>
                          <form onSubmit={(e) => e.preventDefault()} className={classes.createPostForm}>
                            <TextField
                              className={classes.textField}
                              style={useStyles.modalTextInfo}
                              id="bio"
                              multiline
                              rows={3}
                              variant="standard"
                              placeholder="Write something here.."
                              required
                            />
                            {setImgUrl &&
                              <IconButton onClick={() => this.setState({ ...this.state, myNftopenChild: false, keepAllImage: true, setImgUrl: '', myNftmetamaskBTN: false, myNfttokenId: '' })}>
                                <CloseIcon />
                              </IconButton>}
                            {myNftopenChild && !keepAllImage && <div className={classes.myNftImgWrap}>
                              <Image src={setImgUrl || postCamera} id="myNftImg" className={classes.nftImg} />
                            </div>}
                          </form>
                          {!myNftopenChild && keepAllImage && !this.state.createPostNftLoader ? <div style={{ background: myNftmetamaskBTN ? '#ffffff' : 'transparent' }} className={classes.uploadNFTChild}>
                            {myNftallAssets?.map((element: any, index: number) => {
                              return (
                                <div className={classes.nftImage} key={element + index}
                                >
                                  <Image src={element?.image_url
                                  } key={index}
                                    onClick={() => this.selectNftImg(
                                      element?.image_url, element?.token_id
                                    )}
                                  />
                                </div>
                              );
                            })}
                            {(myNftallAssets?.length - 6) < 0 && (
                              [...Array(6 - myNftallAssets?.length)].map((_, index: number) => (
                                <div className={classes.lessNFTBox} key={index}>
                                  <div>
                                    NFT
                                  </div>
                                </div>
                              ))
                            )}
                          </div> :
                            this.state.createPostNftLoader && <div className={classes.nftLoader}>
                              <CircularProgress style={{ color: '#0388c9' }} />
                            </div>}
                        </DialogContent>
                        <DialogActions>
                          {!keepAllImage &&
                            <Button
                              style={!!setImgUrl ? useStyles.buttonPriview : useStyles.buttonUpload}
                              disabled={!!setImgUrl}
                              onClick={() => this.handleUpload(value)}
                            >
                              {myNftmetamaskBTN ? <>
                                Connecting...
                                <CircularProgress color="inherit" /></> : 'Upload NFT'}
                            </Button>}
                          {keepAllImage &&
                            <Button
                              style={useStyles.buttonPriview}
                              disabled
                            >
                              Select a NFT
                            </Button>}
                          <Button
                            type="submit"
                            style={useStyles.previewButton}
                            onClick={myNftconfirm ? this.confirmUpload : () => this.setState({ myNftconfirm: true })}
                          >
                            {uploadNFTLoader ? <CircularProgress color="inherit" /> : myNftconfirm ? 'Confirm Upload' : 'Preview Post'}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  )}
                </web3ReactContext.Consumer>
              </Web3ReactProvider>
            </div>
          </div>
        </div>
      </>
    );
  }
}
// Customizable Area Start
const useStyles = {
  txtHover: {
    "&:hover": {
      background: "transparent",
      color: "#1f7fb6"
    }
  },
  agreebtn: {
    border: "1px solid #1f7fb6",
    backgroundColor: "#e4e5e4",
    height: "40px",
    borderRadius: "50px",
    boxShadow: "none !important",
    display: "inline-flex",
    alignItems: "center",
    minWidth: "110px",
    "& .MuiButton-label": {
      fontSize: "16px !important",
      fontWeight: "700 !important",
      color: "#1f7fb6 !important",
      margin: "0 !important",
      textTransform: "none",
    },
    "& svg": {
      marginLeft: "5px",
      height: "23px"
    }
  },
  disagreebtn: {
    border: "1px solid #1f7fb6",
    backgroundColor: "#1f7fb6 !important",
    height: "40px",
    borderRadius: "50px",
    boxShadow: "none !important",
    display: "inline-flex",
    alignItems: "center",
    minWidth: "110px",
    "& .MuiButton-label": {
      fontSize: "16px !important",
      fontWeight: "700 !important",
      color: "#ffffff !important",
      margin: "0 !important",
      textTransform: "none",
    },
    "& svg": {
      marginLeft: "5px",
      height: "23px"
    }
  },
  unfriendModal: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "350px",
    },
    "& .MuiDialogActions-root": {
      justifyContent: "space-between",
      padding: "15px"
    }
  },
  blockModal: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "550px",
    },
    "& .MuiDialogActions-root": {
      justifyContent: "space-between",
      padding: "15px"
    },
    "& p": {
      textAlign: "left !important"
    },
    "& .MuiDialogTitle-root": {
      paddingRight: "60px"
    },
  },
  removeFriend: {
    "& span": {
      fontSize: "16px",
      color: "#000000",
      fontFamily: "'Poppins', sans-serif",
    },
    "& ul": {
      fontSize: "16px",
      color: "#000000",
      fontFamily: "'Poppins', sans-serif",
      margin: "0",
      paddingLeft: "20px",
      paddingBottom: "20px"
    },
    "& p": {
      fontFamily: "'Poppins', sans-serif",
      margin: "0",
      textAlign: "center",
      "& svg": {
        fontSize: "40px",
        color: "#1f7fb6",
        display: "block",
        margin: "0 auto",
      }
    },
  },
  infoTxt: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    color: "#565a57",
    justifyContent: "center",
    lineHeight: "1",
    "& svg": {
      height: "17px",
      widht: "17px",
      paddingRight: "6px"
    }
  },
  sideBar: {
    display: 'flex',
    background: '#ffffff',
    borderRight: '1px solid rgba(255, 255, 255, 0.07)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1em 0.3em',
    zIndex: 1,
    contentJustify: "center",
    width: "15%"
  },
  profileNFTCard: {
    "& .MuiCardContent-root": {
      padding: "0 !important",
    }
  },
  colThree: {
    flex: "0 0 33.333%",
    maxWidth: "33.333%",
    padding: "20px",
    boxSizing: "border-box",
    boxShadow: "none"
  },
  createInnerModal: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  nftImg: {
    width: "100% !important",
    height: "auto !important",
    position: "relative !important",
  },
  uploadNFTChild: {
    border: '1.8px solid #6e7370',
    borderRadius: "5px",
    padding: "5px",
    overflow: "auto",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    boxSizing: "border-box",
  },
  nftLoader: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    border: '1.8px solid #6e7370',
    borderRadius: '5px',
    minHeight: '230px',
    position: 'relative'
  },
  nftImage: {
    maxWidth: "33.33%",
    flex: "0 0 33.33%",
    boxSizing: "border-box",
    padding: "3px",
    "& > div": {
      background: "#dedcdf !important",
      borderRadius: "5px",
      overflow: "hidden"
    }
  },
  lessNFTBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1.8px dashed #6e7370',
    borderRadius: '5px',
    margin: '3px',
    width: '31%',
    height: '168px',
    color: '#565a57',
    backgroundColor: '#e5e6eb',
    fontFamily: "'Poppins', sans-serif",
  },
  nftText: {
    fontSize: "14px",
    color: "#565a57",
    fontWeight: "700",
    lineHeight: "1.3",
    minHeight: "40px",
    margin: "0",
    fontFamily: "'Roboto', sans-serif",
  },
  paidCardText: {
    lineHeight: "1.3",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& img": {
      width: "18px",
      marginRight: "10px",
    }
  },
  formLabel:
  {
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "10px",
    whiteSpace: "nowrap",
    "@media (max-width: 1024px)": {
      fontSize: 12,
    },
  },
  selectComment: {
    width: "100%",
    "& .MuiInputBase-root": {
      maxWidth: "200px",
      width: "100%",
      marginLeft: "auto",
      margin: 0,
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      }
    }
  },
  commentBlockWrapper: {
    borderTop: "1px solid #dcdcd7",
    margin: "0 15px 0",
    paddingTop: "10px",
    "& .MuiSelect-selectMenu": {
      textAlign: "right",
      fontSize: "11px",
      color: "#404341",
      fontWeight: 700,
      "&:focus": {
        background: "transparent",
      },
      "& em": {
        opacity: "0.7"
      }
    },
    "& .MuiSelect-icon": {
      color: "#404341"
    }
  },
  commentBlock: {
    display: "flex",
    flexWrap: "wrap",
    padding: "7px 0",
    justifyContent: "space-between",
    "& .MuiAvatar-root": {
      width: "35px",
      height: "35px",
      marginTop: "5px",
    }
  },
  paginationBlock: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 0 15px",
    alignItems: "center",
    "& button": {
      padding: "0",
      margin: "0",
      color: "#6e7370",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "transparent"
      }
    }
  },
  paginationBlockWrapper: {
    color: "#6e7370",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "'Roboto', sans-serif",
  },
  commentBar: {
    margin: "2px 0 2px 10px",
    position: "relative",
    maxWidth: "calc(100% - 95px)",
    flex: "0 0 calc(100% - 95px)",
    "&::before": {
      position: "absolute",
      content: "",
      height: "10px",
      width: "2px",
      backgroundColor: "#ffffff",
      left: "0px"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ebecec",
      color: "#6e7370",
      borderRadius: "30px",
      height: "38px",
      fontWeight: "500",
      padding: "0 15px",
      cursor: "pointer",
      fontSize: "14px",
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    }
  },
  replyCommentBlock: {
    padding: "0 0 0 10px"
  },
  commentWrap: {
    maxWidth: "calc(100% - 47px)",
    flex: "0 0 calc(100% - 47px)",
    "& p": {
      backgroundColor: "#ebecec",
      borderRadius: "30px",
      padding: "5px 15px",
      color: "#6e7370",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      width: "100%",
      maxWidth: "100%",
      boxSizing: "border-box",
      margin: "0",
      "& span": {
        paddingBottom: "3px",
      }
    },
    "& span": {
      color: "#000307",
      fontSize: "11px",
      display: "block",
      paddingBottom: "0",
      margin: "0",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
    },
  },
  container: {
    padding: "0 20px",
    overflowX: "hidden",
    height: "100%",
    backgroundColor: "#f1f2f6",
  },
  upperBoxBg: {
    backgroundColor: "#fff",
    fontFamily: "'Poppins', sans-serif",
    margin: "0 0 20px 0",
    padding: "10px 15px",
    borderRadius: "0 0 10px 10px",
    boxShadow: "1px 1px 3px rgb(0 0 0 / 20%)",
  },
  bottomBoxBg: {
    maxWidth: "100%",
    minWidth: "300px",
    margin: "0 auto",
    "& .MuiBox-root": {
      padding: 0,
    }
  },
  emptyPostBox: {
    display: 'grid',
    placeItems: 'center',
    height: '300px',
    borderRadius: '5px',
    width: '100%',
    backgroundColor: '#ffffff'
  },
  genLoaderBox: {
    display: 'grid',
    placeItems: 'center',
    height: '200px',
    borderRadius: '5px',
    width: '100%',
    backgroundColor: '#ffffff'
  },
  emptyList: {
    display: 'grid',
    placeItems: 'center',
    width: '100%',
    "& > h6": {
      fontFamily: "'Poppins', sans-serif",
    },
    "& > h4": {
      fontFamily: "'Poppins', sans-serif",
    }
  },
  NFTCardWrapper: {
    maxWidth: "635px",
    margin: "0 auto",
    borderRadius: "10px",
    marginBottom: "23px",
    "& .MuiCardContent-root": {
      padding: "30px",
    }
  },
  heading: {
    margin: "0 0 0 20px",
    "& h2": {
      fontSize: "28px",
      fontWeight: "600",
      padding: "0",
      margin: "0"
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      margin: "0",
      color: "#9a9a9a",
    }
  },
  modelPopup: {
    "& fieldset": {
      display: "none",
    },
  },
  accordionUserWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  friendCard: {
    width: "650px",
    margin: "0 auto",
    boxShadow: "none",
    borderRadius: "10px",
    overflow: "visible",
  },
  sendButton: {
    backgroundColor: "#1f7fb6",
    width: "40px",
    padding: "10px",
    height: "40px",
    marginLeft: "10px"
  },
  sendIcon: {
    color: "#ffffff",
    width: "auto",
    height: "auto"
  },
  buttonUpload: {
    display: "block",
    background: "#d8dddd",
    border: "2px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    color: "#1f7fb6",
    fontWeight: 700,
    width: "100%"
  },
  previewButton: {
    margin: "8px 0 10px 0",
    display: "block",
    background: "#1f7fb6",
    border: "2px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    color: "#ffffff",
    fontWeight: 700,
    width: "100%",
    boxSizing: "border-box",
  },
  friendUserTab: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      color: "#1f7fb6 !important",
      backgroundColor: "#deedf4",
      borderRadius: "5px"
    },
    "& .MuiTabs-root": {
      "& button": {
        border: "0",
        textTransform: "none",
        fontSize: "14px",
        fontWeight: "700",
        fontFamily: "'Roboto', sans-serif",
        minWidth: "initial",
        minHeight: "40px",
        padding: "14px 20px",
        opacity: "1",
        color: "#6e7370",
        lineHeight: "1",
        borderRadius: "5px"
      },
    },
    "& .MuiBox-root": {
      padding: "0",
    }
  },
  friendCardTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "2px solid #d8dddd",
    paddingBottom: "15px",
    "& h2": {
      color: "#000105",
      fontSize: "21px",
      fontWeight: "600",
      margin: "0",
      fontFamily: "'Poppins', sans-serif",
    },
    "& .MuiFormControl-root": {
      backgroundColor: "#d8dddd",
      height: "41px",
      fontSize: "16px",
      color: "#1f7fb6",
      fontWeight: "700",
      letterSpacing: "0",
      border: "1px solid #1f7fb6",
      borderRadius: "5px",
      textAlign: "center",
      maxWidth: "170px",
      "& .MuiInputBase-input": {
        "&::placeholder": {
          color: "#1f7fb6",
          fontWeight: "700",
          opacity: "1",
        }
      },
      "& .MuiOutlinedInput-root": {
        paddingLeft: "10px",
        "& .MuiSvgIcon-root": {
          color: "#1f7fb6"
        }
      },
      "& .MuiOutlinedInput-input": {
        padding: "11px 12px 11px 0px"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        top: "0"
      }
    }
  },
  profileImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& div": {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      padding: "0 !important",
      "& img": {
        maxHeight: "50px",
      }
    },
    "& p": {
      fontSize: "16px",
      fontWeight: "600",
      marginLeft: "10px",
      fontFamily: "'Poppins', sans-serif",
    }
  },
  createPost: {
    color: "#fff",
    backgroundColor: "#35a0d4",
    fontSize: "16px",
    fontWeight: "600",
    padding: "10px 20px",
    margin: "0 20px",
    borderRadius: "5px",
  },
  btnViewNFT: {
    color: "#1f7fb6",
    backgroundColor: "#d8dddd",
    fontSize: "16px",
    fontWeight: "700",
    padding: "6px 20px",
    borderRadius: "5px",
    border: "2px solid #1f7fb6",
    textTransform: "none",
    minWidth: "249px"
  },
  myNftImgWrap: {
    "& div": {
      paddingTop: "0 !important",
    },
  },
  myNftImg: {
    width: "100% !important",
    height: "auto !important",
    position: "relative !important",
  },
  textField: {
    padding: "0 0 15px",
    width: "100%",
    fontSize: "14px",
    color: "#000000",
    "& >span": {
      paddingBottom: "10px",
      display: "block",
    },
    "& .MuiOutlinedInput-notchedOutline":{
      border: "none",
    },
    "& .MuiSelect-selectMenu": {
      border: "1px solid #c4c4c4",
      borderRadius: "5px",
      padding: "10px 14px !important",
    },
    "& .css-yk16xz-control": {
      border:"1px solid #565a57",
    },
    "& .MuiInputBase-root": {
      fontSize: "14px",
      color: "#565a57",
      fontWeight: "400",
      border:"1px solid #565a57",
      borderRadius: "4px",
      "&::before": {
        display: "none",
      }
    },
    ".MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
      color: "#565a57",
      fontWeight: "400",
    },

  },
  tabsWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      color: "#1f7fb6",
      backgroundColor: "#deedf4",
      borderRadius: "5px"
    },
    "& .MuiTabs-scroller": {
      alignSelf: "center",
    },
  },
  leftTab: {
    paddingTop: "7px",
    "& button": {
      border: "0",
      textTransform: "none",
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      minWidth: "initial",
      minHeight: "40px",
      padding: "14px 20px",
      opacity: "1",
      color: "#6e7370",
      lineHeight: "1",
      borderRadius: "5px"
    },
  },
  mainContent: {
    width: "70%",
  },
  formWrap: {
    backgroundColor: "#fff",
    fontFamily: "'Poppins', sans-serif",
    borderRadius: "10px",
    boxShadow: "none",
    "& .MuiTextField-root": {
      width: "100%",
      padding: "0 0 15px",
      display: "flex"
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px !important",
    },
    "& .MuiButton-root": {
      backgroundColor: "#e5e6eb",
      border: "2px solid #50a0c6",
      color: "#50a0c6",
      boxShadow: "none",
    }
  },
  textWrapper: {
    paddingTop: "20px",
  },
  btnSubmit: {
    border: "1px solid #1f7fb6",
    backgroundColor: "#e4e5e4",
    height: "40px",
    borderRadius: "50px",
    boxShadow: "none !important",
    display: "inline-flex",
    alignItems: "center",
    "& .MuiButton-label": {
      fontSize: "16px !important",
      fontWeight: "700 !important",
      color: "#1f7fb6 !important",
      margin: "0 !important",
      textTransform: "none",
    },
    "& svg": {
      marginLeft: "5px",
      height: "23px"
    }
  },
  discrtxt: {
    padding: "5px 10px 15px",
    "& .MuiInput-underline": {
      "&:after": {
        display: "none"
      },
      "&:before": {
        display: "none"
      }
    },
    "& .MuiFormControl-root": {
      marginRight: "10px",
    },
    "& .MuiInputBase-input": {
      height: "40px",
      padding: "9px 10px",
      border: "1px solid #cccccc",
      borderRadius: "5px",
      boxSizing: "border-box",
      fontSize: " 14px",
      fontWeight: 500,
    },
  },
  postCard: {
    boxShadow: "none",
    borderRadius: "10px",
    maxWidth: "550px",
    margin: "0 auto 25px",
    overflow:'visible',
    "& .MuiCardHeader-root": {
      padding: "10px",
      justifyContent: "space-beetween",
      "& .MuiCardHeader-avatar": {
        marginRight: "15px",
        "& .MuiAvatar-root": {
          width: "45px",
          height: "45px",
        }
      },
      "& .MuiCardHeader-content": {
        "& .MuiCardHeader-title": {
          fontSize: "16px",
          color: "#000307",
          fontWeight: "700",
          lineHeight: "1",
          paddingBottom: "5px",
        },
        "& .MuiCardHeader-subheader": {
          fontSize: "13px",
          color: "#888b87",
          fontWeight: "500",
          lineHeight: "1",
          paddingBottom: "0",
        }
      },
      "& .MuiCardHeader-action": {
        margin: "0",
        "& button": {
          color: "#1f7fb6",
          padding: "7px",
          height: "40px",
          width: "40px",
          backgroundColor: "transparent",
          border: "none"
        }
      }
    },
    "& .MuiCardContent-root": {
      padding: "0",
      "& p": {
        margin: "0 15px 0",
        color: "#6e7370",
        fontSize: "16px",
        fontWeight: "500",
        fontFamily: "'Roboto', sans-serif",
        paddingBottom: "5px",
        lineHeight: "1.4",
      },
      "& span": {
        borderTop: "1px solid #dcdcd7",
        color: "#565a57",
        fontSize: "16px",
        fontWeight: "700",
        display: "block",
        fontFamily: "'Roboto', sans-serif",
        margin: "0 15px 15px",
        paddingTop: "10px",
      }
    }
  },
  paidText: {
    fontSize: "12px",
    color: "#1f7fb6",
    fontWeight: "600",
    lineHeight: "1.3",
    minHeight: "25px",
    margin: "0",
    fontFamily: "'Poppins', sans-serif",
  },
  previousNFTText: {
    color: "#9a9a9a"
  },
  paidWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "& img": {
      maxWidth: "12px",
      marginRight: "5px"
    }
  },
  poppinsFont: {
    fontFamily: "'Poppins', sans-serif",
  },
  likeCommentShare: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 0",
    margin: "0 15px",
    "& button": {
      padding: "0",
      margin: "0",
      color: "#6e7370",
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      height: "20px",
      borderRadius: "0",
      "& p": {
        margin: "0 0 0 5px",
      }
    }
  },
  postCardInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 0",
    margin: "0 15px",
    borderBottom: "1px solid #dcdcd7",
    "& div": {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#6e7370",
      maxWidth: "33.33%",
      flex: "0 0 33.33%",
      "& h3": {
        margin: "0 0 0 10px",
      },
      "& svg": {
        color: "#1f7fb6"
      }
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#6e7370 !important",
      margin: "0 !important",
      padding: "0 !important",
      border: "none !important"
    },
    "& h3": {
      margin: "0",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#1f7fb6",
      maxWidth: "45%",
      flex: "0 0 45%",
    },
  },
  soldNFT: {
    color: "#9a9a9a!important"
  },
  menuListData: {
    whiteSpace: "pre-wrap",
    width: "345px",
    backgroundColor: "#fffffe",
    borderRadius: "10px",
    position: "relative",
    margin: "20px 0",
    // border: "1px solid rgba(0,0,0,0.25)",
    border:'none',
    "& button": {
      width: "100%",
      padding: "5px 15px",
      "& h4": {
        display: "flex",
        alignItems: "center",
        fontSize: "16px",
        color: "#565a57",
        fontWeight: "700",
        fontFamily: "'Roboto', sans-serif",
        textTransform: "none",
        flexWrap: "wrap",
        margin: "0",
        width: "100%",
        "& img": {
          width: "24px",
          marginRight: "12px",
        }
      },
    },
  },
  customTooltip:{
    padding:'0',
    border: "1px solid rgba(0,0,0,0.25)",
    backgroundColor: "#fffffe",
    borderRadius: "10px",
    maxwidth: "346px !important",
    width:'100%',
    minWidth:'346px !important',
    // margin:'0',

  },
  customArrow:{
    '&:before':{
      color: "#fffffe",
      border: "1px solid rgba(0,0,0,0.25)",
    }
  },
  triangle: {
    position: "absolute",
    width: "16px",
    height: "16px",
    borderTop: "1px solid transparent",
    borderLeft: "1px solid transparent",
    borderBottom: "1px solid rgba(0,0,0,0.25)",
    borderRight: "1px solid rgba(0,0,0,0.25)",
    transform: "rotate(45deg)",
    backgroundColor: "#fffffe",
    bottom: "-9px",
    right: "20px",
  },
  listComment: {
    margin: "0",
    marginTop: "5px",
    padding: "0",
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    color: "#404341",
    "& li": {
      fontSize: "11px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      padding: "0 5px",
      display: "inline-flex",
      alignItems: "center",
    },
    "& button": {
      margin: "0",
      padding: "0",
      textAlign: "left",
      minWidth: "auto",
      "& span": {
        fontSize: "11px",
        fontWeight: "700",
        fontFamily: "'Roboto', sans-serif",
        textTransform: "none",
      },
      "&:hover": {
        backgroundColor: "none"
      }
    }
  },
  accordionUserItem: {
    flex: "0 0 50%",
    maxWidth: "50%",
    padding: "25px 20px",
    boxSizing: "border-box",
  },
  accordionUser: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    boxSizing: "border-box",
  },
  closeBtn: {
    position: "absolute",
    top: "15px",
    background: " #e5e6eb",
    right: "15px",
    padding: "5px",
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "& .MuiButton-text": {
      width: "100%",
    },
    "& .MuiButton-label": {
      textAlign: "left",
      display: "block",
    },
    "& .MuiAvatar-root": {
      width: "72px",
      height: "72px",
      backgroundColor: "#bcbeba",
      borderRadius: "50%",
      marginRight: "10px",
    },
    "& .MuiTypography-root": {
      textTransform: "none",
      fontSize: "16px",
      color: "#000307",
      fontWeight: "700",
      lineHeight: "1.3",
      textAlign: "left",
      "& span": {
        display: "block",
        fontSize: "13px",
        color: "#505452",
        fontWeight: "500",
        fontFamily: "'Roboto', sans-serif",
        marginTop: "10px",
      }
    },
  },
  buttonMore: {
    width: "49px",
    height: "40px",
    backgroundColor: "#ebecec",
    color: "#1f7fb6",
    border: "1px solid #1f7fb6",
    borderRadius: "5px",
    "@media(max-width: 1199px)": {
      width: "30px",
      minWidth: "30px",
      height: "30px",
      "& .MuiSvgIcon-root":{
        width: "13px",
        height: "13px",
      }
    },
  },
  modalTitle: {
    color: "#000105",
    fontSize: "20px !important",
    textAlign: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
  },
  buttonPriview: {
    marginTop: "10px",
    display: "block",
    background: "#d8dddd",
    border: "2px solid #d8dddd",
    borderRadius: "5px",
    textAlign: "center",
    color: "#888b87",
    fontWeight: 700,
    width: "100%",
    boxSizing: "border-box",
  },
  createPostModal: {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "600px",
    },
    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiDialog-paper": {
      position: "relative",
      overflow: "visible"
    },
    "& .MuiInputBase-root": {
      padding: "0"
    },
    "& .MuiButton-root": {
      height: "43px",
      boxShadow: "none",
    },
    "& .MuiFormControl-root": {
      padding: "10px 5px",
      width: "100%",
      "& .MuiInputBase-input": {
        color: "#565a57",
        opacity: "1",
        fontSize: "20px",
        fontWeight: "400",
        height: "40px",
        "&::placeholder": {
          color: "#565a57",
          opacity: "1",
        }
      }
    },
    "& .MuiDialogActions-root": {
      width: "100%",
      boxSizing: "border-box",
      flexWrap: "wrap",
      padding: "8px 24px",
      "& label": {
        width: "100%",
        boxSizing: "border-box",
        margin: "0",
        padding: "15px ?25px 5px",
      }
    },
    "& .MuiInput-underline": {
      "&::before": {
        display: "none"
      },
      "&::after": {
        display: "none"
      }
    },
    "& .MuiCircularProgress-root": {
      width: "22px !important",
      height: "22px !important",
      position: "absolute"
    },

  },
  createPostForm: {
    position: "relative",
    "& .MuiIconButton-root": {
      position: "absolute",
      top: "50px",
      background: "#ffffff",
      borderRadius: "50%",
      zIndex: "9",
      width: "30px",
      height: "30px",
      right: "-10px",
      padding: "0",
      boxShadow: "1px 1px 3px rgb(0, 0, 0 ,20%)",
    }
  },
  verifiedNFT: {
    float: 'left',
    width: '150px',
    height: '150px',
    objectFit: 'inherit',
    borderRadius: '5px'
  },
  pointer: {
    cursor: 'pointer'
  },
  menuOuter:{
    position:'relative',
    overflow:'visible !important',
    padding:"0 !important",
  },
  menuItemCss: {
    position: "absolute !important",
    "& .MuiMenu-paper": {
      overflow: 'visible',
      whiteSpace: "pre-wrap",
      width: "250px",
      backgroundColor: "#fffffe",
      borderRadius: "10px",
      position: "relative",
      margin: "20px 0",
      maxWidth:'345px',
      minWidth:'180px',
      maxHeight:'none',
      border:'none',
      left:'0 !important',
      "& button": {
        width: "100% !important",
        padding: "0 !important",
        "& h4": {
          display: "flex",
          alignItems: "center",
          fontSize: "16px",
          color: "#565a57",
          fontWeight: "700",
          fontFamily: "'Roboto', sans-serif",
          textTransform: "none",
          flexWrap: "wrap",
          margin: "0",
          width: "100%",
          "& .MuiSvgIcon-root": {
            width: "24px",
            marginRight: "12px",
          },
          "& img": {
            width: "24px",
            marginRight: "12px",
          }
        },
        "&:hover": {
          background: "transparent",
        }
      },
      '& .MuiList-root':{
        border: "1px solid rgba(0,0,0,0.25)",
        borderRadius:'10px',
      },
      '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 14,
        width: 10,
        height: 10,
        backgroundColor: '#ffffff',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 9,
        borderTop: "1px solid rgba(0,0,0,0.25)",
        borderLeft: "1px solid rgba(0,0,0,0.25)",
      },
    },
  }
} as any;
// Customizable Area End

const mapStateToProps = (state: any) => {
  return {
    allUserPosts: state.takeAction.ownPost,
    allUserNFTs: state.takeAction.ownNFTs,
    profileData: state.takeAction.profileData,
    friendsList: state.takeAction.usersFriends,
    blockedUsers: state.takeAction.blockedUsers,
    previouslyOwnedNFTs: state.takeAction.previouslyOwnedNFTs
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setOwnPosts: (posts: any) => {
      dispatch(SET_OWN_POSTS(posts))
    },
    editOwnPost: (postDetails: {
      id: number,
      description: string
    }) => {
      dispatch(EDIT_OWN_POST(postDetails))
    },
    deleteOwnPost: (id: number) => {
      dispatch(DELETE_OWN_POST(id))
    },
    setOwnNFTs: (allNFTs: any) => {
      dispatch(SET_OWN_NFTS(allNFTs))
    },
    setPreviousNFTs: (previousNFTs: any) => {
      dispatch(SET_PREVIOUS_NFTS(previousNFTs))
    },
    setProfile: (profileData: any) => {
      dispatch(SET_PROFILE(profileData))
    },
    setFriendsList: (friendsList: any) => {
      dispatch(SET_FRIENDS(friendsList))
    },
    updateFriendsList: (updateDetails: {
      id: number,
      action: string
    }) => {
      dispatch(UPDATE_FRIENDS(updateDetails))
    },
    setBlockedUsers: (usersList: any) => {
      dispatch(SET_BLOCKED_USERS(usersList))
    },
    unBlockUser: (id: number) => {
      dispatch(UNBLOCK_USER(id))
    },
    userLogout: () => {
      dispatch(USER_LOGOUT())
    },
  }
}
//@ts-ignore
const myProfile = withRouter(connect(mapStateToProps, mapDispatchToProps)(MyProfile))
export default withStyles(useStyles)(myProfile)
