import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}
export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  submitBtn: boolean;
  countryData:any[];
  stateData:any[];
  cityData:any[];
  CountryName:string;
  nameOfCountry:string;
  nameOfState:string;
  continueLoder: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CreateProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createProfileApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPhone: string;
  labelCountry: string;
  labelState: string;
  labelCity: string;
  labelBio: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  GetCountryAuthTokenApiCallId:string='';
  GetCountryListApiCallId:string='';
  GetCountryStateListApiCallId:string='';
  GetCountryCityListApiCallId:string='';
  responseFirstName:any;
  responseLastName:any;
  responseEmail:any;
  responseToken:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);



    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      submitBtn: false,
      countryData:[],
      stateData:[],
      cityData:[],
      CountryName:'',
      nameOfCountry:'',
      nameOfState:'',
      continueLoder: false
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPhone = configJSON.labelPhone;
    this.labelCountry = configJSON.labelCountry;
    this.labelState = configJSON.labelState;
    this.labelCity = configJSON.labelCity;
    this.labelBio = configJSON.labelBio;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }
  //Customizable area start
  async componentDidMount() {
    // Customizable Area Start
    //@ts-ignore
    const response=this.props?.location?.state?.detail;
  
    if(response?.data?.attributes?.first_name && response?.data?.attributes?.last_name && response?.data?.attributes?.email && response?.meta?.token){
        this.setState({submitBtn:true})
        this.responseFirstName=response?.data?.attributes?.first_name
        Cookies.set('first_name', response?.data?.attributes?.first_name)
        this.responseLastName=response?.data?.attributes?.last_name
        Cookies.set('last_name',response?.data?.attributes?.last_name)
        this.responseEmail=response?.data?.attributes?.email
        Cookies.set('Login_Email', response?.data?.attributes?.email)
        this.responseToken=response?.meta?.token
        Cookies.set('Login_Token', response?.meta?.token)
        Cookies.set('Login_Id', response.data.id)
        this.callGetCountrylistApi()
    }else{
     //@ts-ignore
      this.props.history.push({
                pathname: '/signup',
                state: { detail: "Something went wrong!! Please sign up again!!" }
      })
    }
    // Customizable Area End
  }
  ContinueSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required(configJSON.firstNameRequire),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required(configJSON.lastNameRquire),
    email: Yup.string()
      .required(configJSON.emailRequire)
      .matches(configJSON.emailRegex, configJSON.emailInValid),
    phone: Yup.string()
      .matches(configJSON.phoneRegex, configJSON.phoneInValid),
    dateOfbirth: Yup.date().notRequired(),
    country: Yup.string().notRequired(),//(configJSON.countryRequire),
    state: Yup.string().notRequired(),
    city: Yup.string().notRequired(),
    bio: Yup.string().notRequired(), // (configJSON.bioRequire),
  });

  doContinue(values: any) {
    this.createProfile(values)
   }

   sortData = (list: any[], sortProperty: string) => {
    const tempList = [...list]
    tempList.sort((a: any, b: any) => {
      let fa = a[sortProperty].toLowerCase(),
        fb = b[sortProperty].toLowerCase();

      if (fa < fb) {
        return -1
      }
      if (fa > fb) {
        return 1
      }
      return 0
    })
    return tempList
  }
    
    //Api For getting CountryLists
    callGetCountrylistApi = (): boolean => {
      const headers= {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        "X-CSCAPI-KEY":configJSON.countryApiKey,
        "user-email":configJSON.headerForUserEmail
      };
      const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
      this.GetCountryListApiCallId = requestMessage.messageId;
      requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // 'https://api.countrystatecity.in/v1/countries'
      configJSON.getAllCountriesListUrl
      );
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
      );
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };

    //Api for getting Country's State list
    getStatesByCountryNameApi = (event:any,setFieldValue:any):boolean => {
      this.setState({cityData:[]})
      setFieldValue('state', '')
      setFieldValue('city', '')

      if(event.iso2){
        this.setState({CountryName:event.iso2})
        setFieldValue('country', event.iso2)
        this.state.countryData.forEach(element => {
          if(element.iso2 === event.iso2){
              this.setState({nameOfCountry:element.name})
          }
        });
      const headers= {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        "X-CSCAPI-KEY":configJSON.countryApiKey,
        "user-email":configJSON.headerForUserEmail
      };
      const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
      this.GetCountryStateListApiCallId = requestMessage.messageId;
      requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // 'https://www.universal-tutorial.com/api/states/'+data.target.value
      configJSON.getAllStatesListByCountryUrl+event.iso2+'/states'
      );
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
      );
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }else{
        return false;
      }
   };
    
   //Api to get Country's CityLists
   getCitiesByStateNameApi = (event:any,setFieldValue:any): boolean => {
    setFieldValue('city', '')
    if(event.iso2){
      setFieldValue('state', event.iso2)
      this.state.stateData.forEach(element => {
        if(element.iso2===event.iso2){
             this.setState({nameOfState:element.name})
        }
      });
    const headers= {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "X-CSCAPI-KEY":configJSON.countryApiKey,
      "user-email":configJSON.headerForUserEmail
    };
    const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCountryCityListApiCallId = requestMessage.messageId;
    requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getAllCityListByStatesAndCountryUrl+this.state.CountryName+'/states/'+event.iso2+'/cities'
    );
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
    );
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }else{
    return false;
  }
  };

 //Create profile api
  createProfile(values:any): boolean {
    this.setState({ continueLoder: true })
    
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token":this.responseToken
    };

    const profile = {
        "mobile_number": values.phone,
        "state": values.state || '',
        "country": values.country || '',
        "about": values.bio,
        "city" : values.city,
        "date_of_birth": values?.dateOfbirth ? moment(values?.dateOfbirth)?.format('DD-MM-YYYY') : null,
        "country_code": values?.countryCode || ''
    };

    const httpBody = {
      profile: profile,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

   runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

    async receive(from: string, message: Message) {
      // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (responseJson && !responseJson.errors) {
          if (apiRequestCallId != null) {
            //CountryList Api
            if (
              apiRequestCallId === this.GetCountryListApiCallId &&
              responseJson !== undefined
            ) {
               const sortList = this.sortData(responseJson, "name")
               this.setState({countryData: sortList})
            }
            //CountryStateList Api
            if (
              apiRequestCallId === this.GetCountryStateListApiCallId &&
              responseJson !== undefined
            ) {
               const sortList = this.sortData(responseJson, "name")
               this.setState({stateData: sortList})
            }
            //Country City Api
            if (
              apiRequestCallId === this.GetCountryCityListApiCallId &&
              responseJson !== undefined
            ) {
               const sortList = this.sortData(responseJson, "name")
               this.setState({cityData: sortList})
            }
           //Create profile api response
            if (
              apiRequestCallId === this.createProfileApiCallId &&
              responseJson !== undefined
            ) {
              toast.success("Profile created successfully!",{position: toast.POSITION.BOTTOM_RIGHT})
              this.setState({ continueLoder: false})
              //@ts-ignore
              this.props.history.push({
                pathname: '/settingProfilePic',
                state: { detail: responseJson }
              })
            } 
          }
        } else {
          this.parseApiCatchErrorResponse(errorReponse);
          const errors = responseJson?.errors;
          if (errors) {
            console.log("errors", errors);
            if (apiRequestCallId === this.createProfileApiCallId) {
              this.setState({ continueLoder: false })
            }
             this.showAlert(configJSON.errorTitle, errors[0].message);
          return
          }
         
        }
      }
    }

  // Customizable Area End
}
