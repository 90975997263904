import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react"
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Web3 from "web3";
import { InjectedConnector } from "@web3-react/injected-connector";
//@ts-ignore
import { debounce } from 'lodash';
import { setTimeout } from "timers";
import moment from "moment";
toast.configure()
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any
  allUserPosts: any
  profileData: {
    location: string,
    information: string,
    emailId: string,
    phoneNumber: string,
    dateOfBirth: string,
    profileImage: string,
    fullName: string,
    userName: string,
    country: string,
    state: string,
    city: string,
    userIdResetPassword: string
  }
  setOwnPosts: (posts: any) => void
  editOwnPost: (postDetails: {
    id: number,
    description: string
  }) => void
  deleteOwnPost: (id: number) => void
  setOwnNFTs: (allNFTs: any) => void
  setPreviousNFTs: (previousNFTs: any) => void
  setProfile: (profileData: any) => void
  setFriendsList: (friendsList: any) => void
  updateFriendsList: (updateDetails: {
    id: number,
    action: string
  }) => void
  setBlockedUsers: (usersList: any) => void
  unBlockUser: (id: number) => void
  userLogout: () => void
  // Customizable Area End
}

export interface S {
  // Customizable Area Start

  value: number
  friendsTab: number;
  showSaveBtn: boolean;
  showDatePicker: boolean;
  inputFieldDisable: boolean;

  selectedProfileImage: any;

  countryData: any[];
  stateData: any[];
  cityData: any[];
  CountryName: string;
  nameOfCountry: string;
  nameOfState: string;
  nameOfCity: string;
  city: string;
  state: string;
  country: string;

  myNftopen: boolean;
  myNftopenChild: boolean;
  firstName: any;
  lastName: any;
  myNftallAssets: [];
  myNftconfirm: boolean;
  keepAllImage: boolean;
  createPostText: string;
  myNfttokenId: any;
  myNftmetamaskOwnerId: any;
  myNftcheckAccount: number;
  myNftmetamaskBTN: boolean;
  myNftuploadNFT: boolean;
  setImgUrl: any;
  anchorEl: any;
  nftCount: number;
  previouslyNFTsCount: number;
  openDialoug: boolean;
  defaultCheckUnfollowBtn: boolean;
  friendId: any;
  openBlockDialogue: boolean;
  blockFriendId: any;
  friendFirst_name: any;
  friendLast_name: any;
  editPost: number;
  descVal: string;
  frdCount: number;
  pendingCount: number;
  blockCount: number;

  AccountInfo: boolean;
  Notifications: boolean;
  Security: boolean;
  HelpCenter: boolean;
  Logout: boolean;
  ContactUs: boolean;

  notify_receive_request: boolean;
  notify_accept_request: boolean;
  notify_post_comment_reply: boolean;
  push_receive_request: boolean;
  push_accept_request: boolean;
  push_post_comment_reply: boolean;
  FaQList: [];
  searchedVal: any;
  commentBoxId: any;
  allComments: [];
  commentCount: number;
  showReplyInbox: any;
  allRepliedComments: [];
  statusOfComment: any;
  loader: boolean;
  getFriendsLoder: boolean;
  userInfoLoader: boolean;
  nftLoader: boolean;
  prevNFTLoder: boolean;
  updateImageLoader: boolean;
  createPostNftLoader: boolean;
  uploadNFTLoader: boolean
  deleteDialog: boolean
  deletePostId: number
  followUnfollowId: number
  menuAnchor: any;
  unBlockId: number
  editCommentId: number
  deleteCommentId: number
  deleteCommentDialog: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MyProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;
  apiMyInformationId: string = "";
  apiImageUploadId: string = "";

  myInfoUpdateApiId: string;
  profilePictureApiCallId: any;

  GetCountryAuthTokenApiCallId: string = '';
  GetCountryListApiCallId: string = '';
  GetCountryStateListApiCallId: string = '';
  GetCountryCityListApiCallId: string = '';
  openseaAccountApiCallId: any;
  myNftopenSeaApiCallId: any;
  myNftcreateWalletAccountApiCallId: any;
  myNftcreateNFTAccountApiCallId: any;
  myNftapiMetaMaskWalletsId: string;
  allMyNftPostsapiId: any;
  deletePostApiId: any;
  likePostApiId: any;
  dislikePostApiId: any;
  verifiedNFTApiCallId: any;
  previouslyOwnedNftsApiId: any;
  allFirendsApiId: any;
  acceptRequestApiId: any;
  unfollowFriendApi: any;
  unfriendApiId: any;
  blockApiId: any;
  updateApiCallId: any;
  getBlockedUserListApiCallId: any;
  blockedUserApiId: any;
  followFriendApi: any;
  PushAndEmailNotificationId: any;
  FaQEndApiId: any;
  ContactUsApiId: any;
  resetPasswordApiId: any;
  searchFriendcallId: any;
  createCommentApiId: any;
  commentDataApiId: any;
  createLikeonCommentApiId: any;
  createReplyCommentApiId: any
  commentReplyDataApiId: any;
  editCommentApiId: string = ""
  deleteCommentApiId: string = ""
  // Customizable Area End
  GoToHome(props: any) {
    //@ts-ignore
    this.props.history.push('/')
  }
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      value: 0,
      friendsTab: 0,
      showSaveBtn: true,
      showDatePicker: true,
      inputFieldDisable: true,
      selectedProfileImage: false,
      countryData: [],
      stateData: [],
      cityData: [],
      CountryName: '',
      nameOfCountry: '',
      nameOfState: '',
      nameOfCity: '',
      city: '',
      state: '',
      country: '',
      myNftopen: false,
      myNftopenChild: true,
      myNftallAssets: [],
      keepAllImage: false,
      myNftconfirm: false,
      createPostText: '',
      myNfttokenId: '',
      myNftmetamaskOwnerId: '',
      firstName: Cookies.get("first_name") ? Cookies.get("first_name") : 'Profile',
      lastName: Cookies.get("last_name") ? Cookies.get("last_name") : 'Name',
      myNftcheckAccount: 0,
      myNftmetamaskBTN: false,
      myNftuploadNFT: false,
      setImgUrl: '',
      anchorEl: '',
      nftCount: 9,
      previouslyNFTsCount: 9,
      openDialoug: false,
      defaultCheckUnfollowBtn: false,
      friendId: '',
      openBlockDialogue: false,
      blockFriendId: '',
      friendFirst_name: '',
      friendLast_name: '',
      editPost: 0,
      descVal: '',
      frdCount: 9,
      pendingCount: 9,
      blockCount: 9,

      AccountInfo: true,
      Notifications: false,
      Security: false,
      HelpCenter: false,
      ContactUs: false,
      Logout: false,

      notify_receive_request: true,
      notify_accept_request: true,
      notify_post_comment_reply: true,
      push_receive_request: true,
      push_accept_request: true,
      push_post_comment_reply: true,
      FaQList: [],
      searchedVal: '',
      commentBoxId: '',
      allComments: [],
      commentCount: 1,
      showReplyInbox: '',
      allRepliedComments: [],
      statusOfComment: '',
      loader: false,
      getFriendsLoder: false,
      userInfoLoader: false,
      nftLoader: false,
      prevNFTLoder: false,
      updateImageLoader: false,
      createPostNftLoader: false,
      uploadNFTLoader: false,
      deleteDialog: false,
      deletePostId: 0,
      followUnfollowId: 0,
      unBlockId: 0,
      menuAnchor: null,
      editCommentId: 0,
      deleteCommentId: 0,
      deleteCommentDialog: false
      // Customizable Area End
    };

    // Customizable Area Start

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }
  //Customizable area start
  async componentDidMount() {
    // Customizable Area Start
    //@ts-ignore
    if (Cookies.get("Login_Token")) {
      this.GetMyInformationAPI()
      this.callGetCountrylistApi()
      // this.getInitialStatesApi(this.state.country)
      this.getAllMyNftPost()
      this.verifiedNFT()
      this.getPreviouslyNFTs()
      this.getFriendsList()
      this.getAllBlockedUsers()
      this.GetFaQ_Data()

      //@ts-ignore
      const response = this.props?.location?.state?.detail;
      if (response == 4) {
        this.setState({
          value: 4,
        });
      }
      //   this.callGetCountrylistApi()
    } else {
      this.logoutUser()
    }
    // Customizable Area End
  }
  handleClose = () => {
    this.setState({ menuAnchor: null })
  };
  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ menuAnchor: event.currentTarget })
  };
  async componentDidUpdate(prevProp: any) {
    if (prevProp.profileData.country !== this.props.profileData.country) {
      this.getStatesByCountryNameApi(this.props.profileData.country)
      this.getCitiesByStateNameApi(this.props.profileData.state, this.props.profileData.country)
    }
    if (prevProp.profileData.state !== this.props.profileData.state) {
      this.getCitiesByStateNameApi(this.props.profileData.state, this.props.profileData.country)
    }
  }

  logoutUser = () => {
    Cookies.remove('Login_Token')
    Cookies.remove("notification")
    Cookies.remove('Chat_Token')
    Cookies.remove('Login_Id')
    Cookies.remove('Login_Email')
    Cookies.remove('Account_Verified')
    this.props.userLogout()
    this.props.history.push('/login')
  }

  metamaskNotFoundToast = () => (
    <div>Cannot find MetaMask browser extension installed. Download at <span style={{
      color: "blue",
      textDecoration: "underline"
    }} onClick={() => window.open('https://metamask.io/download/')}>metamask.io/download</span></div>
  )

  sortData = (list: any[], sortProperty: string) => {
    const tempList = [...list]
    tempList.sort((a: any, b: any) => {
      let fa = a[sortProperty].toLowerCase(),
        fb = b[sortProperty].toLowerCase();

      if (fa < fb) {
        return -1
      }
      if (fa > fb) {
        return 1
      }
      return 0
    })
    return tempList
  }

  postRedirect = (postId: number) => {
    this.props.history.push({
      pathname: '/post/' + postId,
      state: { detail: postId }
    })
  }

  SaveSchema = Yup.object().shape({
    phoneNumber: Yup.string().notRequired()
      .matches(configJSON.phoneRegex, configJSON.phoneInValid),
  });

  fAQSchema = Yup.object().shape({
    current_password: Yup.string()
      .required("Current Password is required"),
    new_password: Yup.string()
      .required("New Password is required")
      .min(8, configJSON.passwordMinlength)
      .matches(configJSON.passwordRegex, configJSON.passwordInValid),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("new_password"), null], configJSON.errorBothPasswordsNotSame)
  });

  handleDeleteDialogToogle = (id?: number) => {
    if (this.state.deleteDialog) this.setState({ deleteDialog: false, deletePostId: 0 })
    else this.setState({ deleteDialog: true, deletePostId: id || 0 })
  }

  handleDeleteCommentToogle = (id?: number) => {
    if (!this.state.deleteCommentDialog && !!id) {
      this.setState({ deleteCommentDialog: true, deleteCommentId: id, editCommentId: 0 })
    } else {
      this.setState({ deleteCommentDialog: false, deleteCommentId: 0 })
    }
  }

  nftToPostRedirect = (postData: any) => {
    if (!!postData?.id) {
      //@ts-ignore
      this.props.history.push({
        pathname: '/post/' + postData?.id,
        state: { detail: postData?.id }
      })
    }
  }

  postTypeCheck = (url: string) => {
    const extension = url.split('.')?.pop()?.toLowerCase()
    if (!!extension) {
      if (configJSON.musicTypes.includes(extension)) {
        return "audio"
      } else if (configJSON.videoTypes.includes(extension)) {
        return "video"
      } else if (configJSON.object3dTypes.includes(extension)) {
        return "3dObject"
      } else {
        return "iframe"
      }
    } else {
      return "iframe"
    }
  }

  resetCreatePostDialog = () => {
    this.setState({ myNftopen: false, keepAllImage: false, myNftconfirm: false, myNftmetamaskBTN: false, setImgUrl: '', myNftopenChild: true, myNfttokenId: '' })
  }

  //Api For getting CountryLists
  callGetCountrylistApi = (): boolean => {
    const headers = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "X-CSCAPI-KEY": configJSON.countryApiKey,
      "user-email": configJSON.headerForUserEmail
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCountryListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCountriesListUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  //Api for getting Country's State list
  getStatesByCountryNameApi = (countryName: string): boolean => {
    // this.setState({ cityData: [] })
    if (countryName) {
      const headers = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        "X-CSCAPI-KEY": configJSON.countryApiKey,
        "user-email": configJSON.headerForUserEmail
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.GetCountryStateListApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllStatesListByCountryUrl + countryName + '/states'
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      return false;
    }
  };

  //Api to get Country's CityLists
  getCitiesByStateNameApi = (state: string, country: string): boolean => {
    if (!!state) {
      const headers = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        "X-CSCAPI-KEY": configJSON.countryApiKey,
        "user-email": configJSON.headerForUserEmail
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.GetCountryCityListApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllCityListByStatesAndCountryUrl + country + '/states/' + state + '/cities'
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      return false;
    }
  };

  seeAllNft(allNft: any) {

    this.setState({ nftCount: allNft.length })
  }
  RedirectToLogin(props: any) {
    Cookies.remove('Login_Token')
    //@ts-ignore
    this.props.history.push('/login')
    toast.success("Logout successfully", { position: toast.POSITION.BOTTOM_RIGHT })
  }
  updatePushAndEmailNotification() {

    const header = {
      "Content-Type": configJSON.loginApiContentType,
      "token": Cookies.get('Login_Token')
    };
    const httpBody = {
      "notify_receive_request": this.state.notify_receive_request,
      "notify_accept_request": this.state.notify_accept_request,
      "notify_post_comment_reply": this.state.notify_post_comment_reply,
      "push_receive_request": this.state.push_receive_request,
      "push_accept_request": this.state.push_accept_request,
      "push_post_comment_reply": this.state.push_post_comment_reply
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.PushAndEmailNotificationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PushAndEmailNotificationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getLibrary(provider: any) {
    return new Web3(provider)
  }
  async connect(activate: any, account: any) {
    //@ts-ignore
    if (window.web3 === undefined) {
      toast.error(this.metamaskNotFoundToast, { autoClose: 7000, position: toast.POSITION.BOTTOM_RIGHT })
    } else {
      this.setState({ ...this.state, myNftmetamaskBTN: true })
      const injected = new InjectedConnector({
        supportedChainIds: [1, 3, 4, 5, 42],
      });
      await activate(injected)
      //@ts-ignore
      await window.ethereum.enable();
      //@ts-ignore
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      let acc = accounts[0];
      if (Cookies.get("Login_Token") && acc) {
        this.getAllMyNftMetaMaskWallets()
      }
    }
  }
  handleUpload = async (value: any) => {
    this.setState({ myNfttokenId: '', setImgUrl: '' })
    const OwnerId = Cookies.get('MetaMask_Owner_Id')
    if (!OwnerId || OwnerId === 'undefined') {
      this.connect(
        //@ts-ignore
        value.activate,
        value.account
      )
    } else {
      //@ts-ignore
      await window.ethereum.enable();
      //@ts-ignore
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      let acc = accounts[0];
      if (acc === OwnerId) {
        this.setState({ ...this.state, myNftmetamaskBTN: true, keepAllImage: true, myNftuploadNFT: true, myNftconfirm: false })
        this.myNftopenseaAccount(OwnerId)
      } else {
        this.getAllMyNftMetaMaskWallets()
      }
    }
  }
  updatePost = (postId: any): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const b = document.querySelector("#editBio") as HTMLInputElement
    const desc = b.value

    if (!!desc) {
      const httpBody = {
        description: desc ? desc : '',
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updatePostEndPoint + postId
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage)
      return true
    } else {
      toast.error("Write something about post!", { position: toast.POSITION.BOTTOM_RIGHT })
      return false
    }
  }

  getAllBlockedUsers = (): boolean => {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBlockedUserListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockUserEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  selectNftImg(url: any, token_id: any) {
    this.setState({ ...this.state, setImgUrl: url, myNftopenChild: true, keepAllImage: false, myNfttokenId: token_id })
  }
  myNftopenseaAccount = (account: any): boolean => {
    this.setState({ createPostNftLoader: true })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      'X-API-KEY': configJSON.OPEN_SEA_API_KEY
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.myNftopenSeaApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'https://api.opensea.io/api/v1/assets?owner=' + account + '&order_direction=desc&offset=0&limit=20'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFriendsList(): boolean {
    this.setState({ getFriendsLoder: true })
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allFirendsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.friendsListEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllMyNftPost(): boolean {
    this.props.allUserPosts.length === 0 && this.setState({ loader: true })
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allMyNftPostsapiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allMyNftPostEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getAllMyNftMetaMaskWallets(): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.myNftapiMetaMaskWalletsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myMetamaskEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllPostAfterUpload = () => {
    this.getAllMyNftPost();
    this.verifiedNFT();
  }

  confirmUpload = (): boolean => {
    const b = document?.querySelector("#bio") as HTMLInputElement
    const desc = b?.value

    if (!!desc) {
      this.setState({ uploadNFTLoader: true })
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };

      const name = `${this.state.firstName} ${this.state.lastName}`

      const httpBody = {
        name: name || '',
        description: desc,
        token_id: this.state.myNfttokenId,
        owner_id: Cookies.get('MetaMask_Owner_Id')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      )

      this.myNftcreateNFTAccountApiCallId = requestMessage.messageId

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.confirmNFTEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Please write something about post!", { position: toast.POSITION.BOTTOM_RIGHT })
      return false;
    }
  }

  fileUploadHandler = async (e: React.FormEvent<EventTarget>) => {

    let target = e.target as HTMLInputElement;
    let files = target.files as any;
    let url = URL.createObjectURL(files[0])
    const fileExtension = files[0]?.name?.split('.')?.pop()?.toLowerCase();

    if (configJSON.allowedImageExtension.includes(fileExtension)) {
      this.setState({ updateImageLoader: true })
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {

        this.setState({
          selectedProfileImage: file
        })
        //@ts-ignore
        const b = document.querySelector("#profilePicture");
        b?.setAttribute("src", url)
        let formData = new FormData();
        formData.append('profile[image]', file);

        this.uploadProfilePicture(formData)
      }
    } else {
      toast.error("Only JPEG, JPG and PNG file extensions are allowed!", { position: toast.POSITION.BOTTOM_RIGHT })
    }
  }

  doSave(values: any) {
    this.updateInformationAPI(values)
  }

  uploadProfilePicture(values: any): boolean {

    const header = {
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profilePictureApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ImageUploadApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      values
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  acceptRequest = (accountId: any): boolean => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };
    const httpBody = {
      "account_id": accountId,
      "status": "approved"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acceptRequestApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendAcceptReqEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  blockfriendFunc = (accountId: any): boolean => {
    this.setState({ openBlockDialogue: false })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.blockApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockEndPoint + accountId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  unfollowRequest = (connection_id: any, receipientId: any, status: boolean): boolean => {
    this.setState({ followUnfollowId: connection_id })
    if (status) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };
      const httpBody = {
        "connection_id": connection_id,
        "receipient_id": receipientId
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.unfollowFriendApi = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.unfollowReqEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.followFriendApi = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.followFriendEndPoint + connection_id
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }
  GetMyInformationAPI(): boolean {
    this.setState({ userInfoLoader: true })
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMyInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myInfoApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  GetFaQ_Data(): boolean {

    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.FaQEndApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FaQEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  debounceLog = debounce((text: any) => {
    if (text) {
      this.searchFriend(text)
    } else {
      this.getFriendsList()
      //@ts-ignore
      document.getElementById('searchFriend').focus()
    }
  }, 500)

  handleChangeDebounce = (value: any) => {
    this.debounceLog(value)
  }
  searchFriend = (value: any): boolean => {

    this.setState({ searchedVal: value, getFriendsLoder: true })

    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchFriendcallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchFriendENdpoint + '=' + value + '&user_id=' + Cookies.get("Login_Id")
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    //@ts-ignore
    document.getElementById('searchFriend').value = value
    //@ts-ignore
    document.getElementById('searchFriend').focus()
    return true;
  }

  contactUsRequest(values: any): boolean {

    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      "token": Cookies.get("Login_Token"),
    };
    const attribute = {
      "subject": values.subject,
      "message": values.message
    }
    const attributes = {
      attributes: attribute
    };

    const httpBody = {
      data: attributes
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.ContactUsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ContactUsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  resetPasswordRequest(values: any, userId: any): boolean {

    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      "token": Cookies.get("Login_Token"),
    };
    const attributes = {
      "current_password": values.current_password,
      "new_password": values.new_password
    }
    const httpBody = {
      data: attributes
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.resetPasswordApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPassword + userId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  updateInformationAPI(values: any): boolean {

    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      "token": Cookies.get("Login_Token"),
    };

    const profileData = {
      "about": values.information,
      "date_of_birth": moment(values?.dateOfbirth).format('DD-MM-YYYY') || "",
      "mobile_number": values.phoneNumber,
      "state": values.state,
      "country": values.country,
      "city": values.city
    };

    const httpBody = {
      profile: profileData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.myInfoUpdateApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myInfoUpdateEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  metamaskAccount = (account: any): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const data = {
      wallet_type: "metamask",
      address: account
    };

    const httpBody = {
      wallet: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.myNftcreateWalletAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.metamaskWalletEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  RedirectToUser = (values: any, isUserName: boolean) => {

    if (!!isUserName) {
      //@ts-ignore
      this.props.history.push({
        pathname: '/' + values,
        state: { detail: values }
      })
    } else {
      //@ts-ignore
      this.props.history.push({
        pathname: '/user/' + values,
        state: { detail: values }
      })
    }
  }

  unfriendFunc = (friId: any): boolean => {
    this.setState({ openDialoug: false })

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.unfriendApiId = requestMessage.messageId;

    if (friId !== "" && friId) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.unfriendEndPoint + "/" + friId + "/unfriend"
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.unfriendEndPoint + "/" + this.state.friendId + "/unfriend"
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  verifiedNFT = (): boolean => {
    this.setState({ nftLoader: true })

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifiedNFTApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.veriFiedNftEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPreviouslyNFTs = (): boolean => {
    this.setState({ prevNFTLoder: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.previouslyOwnedNftsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.previouslyOwnedNftsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  unblockUser = (blockUserId: any): boolean => {
    this.setState({ unBlockId: blockUserId })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockedUserApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unBlockUserEndPoint + blockUserId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deletePost = (): boolean => {
    this.setState({ deleteDialog: false })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deletePostEndPoint + "/" + this.state.deletePostId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  likePost = (postId: any, is_Liked: any): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };
    if (!is_Liked) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.likePostApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.likePostEndPoint + "/" + postId + "/like"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.dislikePostApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.likePostEndPoint + "/" + postId + "/dislike"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }


  //comenting to post 
  createComment(values: any, idNum: any): boolean {

    //@ts-ignore
    const coment = document.getElementById('comentBox' + idNum).value
    if (coment) {
      const header = {
        "Content-Type": configJSON.contenttypeApiValidateMobileNo,
        "token": Cookies.get("Login_Token"),
      };
      const attribute = {
        "post_id": values,
        "comment": coment,
      }
      const attributes = {
        attributes: attribute
      };

      const httpBody = {
        data: attributes
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      this.createCommentApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      setTimeout(() => {
        //@ts-ignore
        document.getElementById('comentBox' + idNum).value = ''
      }, 10);
      return true;
    } else {
      return false;
    }
  }
  createReplyComment(postId: any, index: any, commentIndex: any, commentId: any): boolean {

    //@ts-ignore
    const coment = document.getElementById("comentReplyBox" + index + commentIndex).value
    if (coment) {
      const header = {
        "Content-Type": configJSON.contenttypeApiValidateMobileNo,
        "token": Cookies.get("Login_Token"),
      };
      const attribute = {
        "post_id": postId,
        "comment": coment,
        "comment_id": commentId
      }
      const attributes = {
        attributes: attribute
      };

      const httpBody = {
        data: attributes
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      this.createReplyCommentApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      setTimeout(() => {
        //@ts-ignore
        document.getElementById("comentReplyBox" + index + commentIndex).value = ''
      }, 10);
      return true;
    } else {
      return false;
    }
  }


  // comment data of post 
  getCommentData(values: any, type?: any): boolean {

    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.commentDataApiId = requestMessage.messageId;
    if (type === 'oldest' || type === 'newest' || type === 'most_relevant') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.commentdataEndPoint + values + "/comments?filter_param=" + type
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.commentdataEndPoint + values + "/comments"
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  // comment data of post 
  getRepliedCommentData(values: any): boolean {
    if (values) {
      const header = {
        "Content-Type": configJSON.contenttypeApiValidateMobileNo,
        "token": Cookies.get("Login_Token"),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.commentReplyDataApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.commentReplyEndPoint + values
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    } else {
      return false;
    }
  }

  //like/dislike comments
  likeOrDislikeComment(values: any, liked: any): boolean {

    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createLikeonCommentApiId = requestMessage.messageId;
    if (liked) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint + "/" + values + "/dislike"
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint + "/" + values + "/like"
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  editComment = (postId: number) => {
    const b = document.querySelector("#editCommentBox") as HTMLInputElement;
    const desc = b?.value

    if (!!desc) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };

      const httpBody = {
        data: {
          attributes: {
            post_id: postId,
            comment: desc
          },
          comment_id: this.state.editCommentId
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editCommentApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Please write a comment", { position: toast.POSITION.BOTTOM_RIGHT })
    }
    return false
  }

  deleteComment = () => {
    if (this.state.deleteCommentId > 0) {
      this.handleDeleteCommentToogle()
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };

      const httpBody = {
        data: {
          comment_id: this.state.deleteCommentId
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.deleteCommentApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Something went wrong!", { position: toast.POSITION.BOTTOM_RIGHT })
      return false
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.apiMyInformationId &&
            responseJson !== undefined
          ) {
            const profileData = {
              location: responseJson?.data?.attributes?.city,
              information: responseJson?.data?.attributes?.about,
              emailId: responseJson?.data?.attributes?.email,
              phoneNumber: responseJson?.data?.attributes?.mobile_number,
              dateOfBirth: responseJson?.data?.attributes?.date_of_birth,
              profileImage: responseJson?.data?.attributes?.image,
              fullName: responseJson?.data?.attributes?.full_name,
              firstName: responseJson?.data?.attributes?.first_name,
              lastName: responseJson?.data?.attributes?.last_name,
              userName: responseJson?.data?.attributes?.user_name,
              country: responseJson?.data?.attributes?.country,
              state: responseJson?.data?.attributes?.state,
              city: responseJson?.data?.attributes?.city,
              countryCode: responseJson?.data?.attributes?.country_code,
              userIdResetPassword: responseJson?.data?.attributes?.account_id,
              isTwoFactAuth: responseJson?.data?.attributes?.two_factor_auth_status
            }

            this.props.setProfile(profileData)

            !!profileData.country && this.getStatesByCountryNameApi(this.props.profileData.country)
            !!profileData.state && this.getCitiesByStateNameApi(this.props.profileData.state, this.props.profileData.country)

            this.setState({ userInfoLoader: false })
          }
          //CountryList Api
          if (
            apiRequestCallId === this.GetCountryListApiCallId &&
            responseJson !== undefined
          ) {

            const sortList = this.sortData(responseJson, "name")
            this.setState({ countryData: sortList })
          }
          //CountryStateList Api
          if (
            apiRequestCallId === this.GetCountryStateListApiCallId &&
            responseJson !== undefined
          ) {
            const sortList = this.sortData(responseJson, "name")
            this.setState({ stateData: sortList })

          }
          //Country City Api
          if (
            apiRequestCallId === this.GetCountryCityListApiCallId &&
            responseJson !== undefined
          ) {
            const sortList = this.sortData(responseJson, "name")
            this.setState({ cityData: sortList })
          }
          if (
            apiRequestCallId === this.myInfoUpdateApiId &&
            responseJson !== undefined
          ) {
            const profileData = {
              location: responseJson?.data?.attributes?.city,
              information: responseJson?.data?.attributes?.about,
              emailId: responseJson?.data?.attributes?.email,
              phoneNumber: responseJson?.data?.attributes?.mobile_number,
              dateOfBirth: responseJson?.data?.attributes?.date_of_birth,
              profileImage: responseJson?.data?.attributes?.image,
              fullName: responseJson?.data?.attributes?.full_name,
              firstName: responseJson?.data?.attributes?.first_name,
              lastName: responseJson?.data?.attributes?.last_name,
              userName: responseJson?.data?.attributes?.user_name,
              country: responseJson?.data?.attributes?.country,
              state: responseJson?.data?.attributes?.state,
              city: responseJson?.data?.attributes?.city,
              userIdResetPassword: responseJson?.data?.attributes?.account_id,
              countryCode: responseJson?.data?.attributes?.country_code,
              isTwoFactAuth: responseJson?.data?.attributes?.two_factor_auth_status
            }
            this.props.setProfile(profileData)
            toast.success('Profile Updated Successfully', { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.createCommentApiId &&
            responseJson !== undefined
          ) {
            this.getAllMyNftPost()
            this.getCommentData(responseJson.data.attributes.post_id)
          }
          if (
            apiRequestCallId === this.createReplyCommentApiId &&
            responseJson !== undefined
          ) {
            this.getAllMyNftPost()
            this.getCommentData(responseJson.data.attributes.post_id)
            this.getRepliedCommentData(responseJson.data.attributes.comment_id)
          }
          if (
            apiRequestCallId === this.commentDataApiId &&
            responseJson !== undefined
          ) {
            this.setState({ allComments: responseJson.data })
          }
          if (
            apiRequestCallId === this.commentReplyDataApiId &&
            responseJson !== undefined
          ) {
            this.setState({ allRepliedComments: responseJson.data })
          }
          if (
            apiRequestCallId === this.createLikeonCommentApiId &&
            responseJson !== undefined
          ) {
            this.getCommentData(responseJson.data.attributes.post_id)
            this.getRepliedCommentData(responseJson.data.attributes.comment_id)
          }
          if (
            apiRequestCallId === this.searchFriendcallId &&
            responseJson !== undefined
          ) {
            this.props.setFriendsList(responseJson.data)
            this.setState({ getFriendsLoder: false })
            //@ts-ignore
            document.getElementById('searchFriend').value = this.state.searchedVal

            //@ts-ignore
            document.getElementById('searchFriend').focus()
          }
          if (
            apiRequestCallId === this.profilePictureApiCallId &&
            responseJson !== undefined
          ) {
            this.props.setProfile({ profileImage: responseJson.data.attributes.image })
            toast.success('Profile Picture updated Successfully', { position: toast.POSITION.BOTTOM_RIGHT })
            this.setState({ updateImageLoader: false })
          }
          if (
            apiRequestCallId === this.allMyNftPostsapiId &&
            responseJson !== undefined
          ) {
            this.props.setOwnPosts(responseJson.data)
            this.setState({ loader: false })
          }
          if (
            apiRequestCallId === this.allFirendsApiId &&
            responseJson !== undefined
          ) {
            this.props.setFriendsList(responseJson.data)
            this.setState({ getFriendsLoder: false })
            //@ts-ignore
            document?.getElementById('searchFriend')?.focus()
          }
          if (
            apiRequestCallId === this.myNftopenSeaApiCallId &&
            responseJson !== undefined
          ) {
            const sellNFTs = responseJson?.assets?.filter((asset: {
              asset_contract: { schema_name: string }
            }) => asset?.asset_contract?.schema_name === configJSON.VALID_NFT_SCHEMA)
            this.setState({
              myNftallAssets: sellNFTs,
              myNftopenChild: false,
              myNftmetamaskBTN: false,
              createPostNftLoader: false
            })
          }
          if (
            apiRequestCallId === this.getBlockedUserListApiCallId &&
            responseJson !== undefined
          ) {
            this.props.setBlockedUsers(responseJson.data)
          }
          if (
            apiRequestCallId === this.deletePostApiId &&
            responseJson !== undefined
          ) {
            this.props.deleteOwnPost(this.state.deletePostId)
            this.setState({ deletePostId: 0 })
            toast.success(responseJson.message, { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.blockedUserApiId &&
            responseJson !== undefined
          ) {
            this.props.unBlockUser(this.state.unBlockId)
            this.setState({ unBlockId: 0 })
            toast.success(responseJson.message, { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.likePostApiId &&
            responseJson !== undefined
          ) {

            //@ts-ignore
            const b = document.querySelector("#likePost");
            //@ts-ignore
            b.setAttribute("style", "color:#3498db")
            this.getAllMyNftPost()
          }
          if (
            apiRequestCallId === this.dislikePostApiId &&
            responseJson !== undefined
          ) {

            //@ts-ignore
            const b = document.querySelector("#likePost");
            //@ts-ignore
            b.setAttribute("style", "color:grey")
            this.getAllMyNftPost()
          }
          if (
            apiRequestCallId === this.myNftcreateWalletAccountApiCallId &&
            responseJson !== undefined
          ) {

            Cookies.set('MetaMask_Owner_Id', responseJson.data.attributes.address)
            this.setState({ myNftmetamaskOwnerId: responseJson.data.attributes.address })
            this.myNftopenseaAccount(responseJson.data.attributes.address)
            this.setState({ myNftmetamaskBTN: true, myNftuploadNFT: true, keepAllImage: true })
          }
          if (
            apiRequestCallId === this.unfriendApiId &&
            responseJson !== undefined
          ) {
            this.props.updateFriendsList({ action: "UNFRIEND", id: this.state.friendId })
            this.setState({ friendId: 0 })
            toast.success(responseJson.message, { position: toast.POSITION.BOTTOM_RIGHT })
            // this.getFriendsList()
          }
          if (
            apiRequestCallId === this.acceptRequestApiId &&
            responseJson !== undefined
          ) {
            toast.success(responseJson.message, { position: toast.POSITION.BOTTOM_RIGHT })
            this.getFriendsList()
          }
          if (
            apiRequestCallId === this.updateApiCallId &&
            responseJson !== undefined
          ) {
            const b = document.querySelector("#editBio") as HTMLInputElement
            const desc = b.value

            this.props.editOwnPost({ id: this.state.editPost, description: desc })
            this.setState({ editPost: 0, descVal: '' })
          }
          if (
            apiRequestCallId === this.blockApiId &&
            responseJson !== undefined
          ) {
            this.props.updateFriendsList({ action: 'BLOCK', id: this.state.blockFriendId })
            this.setState({ blockFriendId: 0 })
            toast.success("You have blocked " + responseJson.data.attributes.account.full_name, { position: toast.POSITION.BOTTOM_RIGHT })
            this.getAllBlockedUsers()
          }
          if (
            apiRequestCallId === this.unfollowFriendApi &&
            responseJson !== undefined
          ) {
            this.props.updateFriendsList({ action: "UNFOLLOW", id: this.state.followUnfollowId })
            this.setState({ followUnfollowId: 0 })
            toast.success("You have unfollowed friend", { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.followFriendApi &&
            responseJson !== undefined
          ) {
            this.props.updateFriendsList({ action: "UNFOLLOW", id: this.state.followUnfollowId })
            this.setState({ followUnfollowId: 0 })
            toast.success("You have followed friend", { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.verifiedNFTApiCallId &&
            responseJson !== undefined
          ) {
            this.props.setOwnNFTs(responseJson.data)
            this.state.nftLoader && this.setState({ nftLoader: false })
          }
          if (
            apiRequestCallId === this.previouslyOwnedNftsApiId &&
            responseJson !== undefined
          ) {
            this.setState({ prevNFTLoder: false })
            this.props.setPreviousNFTs(responseJson.previously_owned_nfts)
          }
          if (
            apiRequestCallId === this.myNftcreateNFTAccountApiCallId &&
            responseJson !== undefined
          ) {
            this.resetCreatePostDialog()
            this.setState({ uploadNFTLoader: false })
            toast.success('post is uploaded successfully..!', { position: toast.POSITION.BOTTOM_RIGHT })
            this.getAllMyNftPost()
            this.verifiedNFT()
          }
          //Country City Api
          if (
            apiRequestCallId === this.PushAndEmailNotificationId &&
            responseJson !== undefined
          ) {
          }
          if (
            apiRequestCallId === this.FaQEndApiId &&
            responseJson !== undefined
          ) {

            this.setState({ FaQList: responseJson.data })
          }
          if (
            apiRequestCallId === this.resetPasswordApiId &&
            responseJson !== undefined
          ) {
            toast.success('Password Updated Successfully', { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.ContactUsApiId &&
            responseJson !== undefined
          ) {

            toast.success("Your request submitted successfully", { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.myNftapiMetaMaskWalletsId &&
            responseJson !== undefined
          ) {


            //@ts-ignore
            await window.ethereum.enable();
            //@ts-ignore
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            let acc = accounts[0];

            if (responseJson.data) {
              responseJson.data.forEach((element: any) => {
                if (element.attributes.address.toUpperCase() === acc.toUpperCase()) {
                  Cookies.set('MetaMask_Owner_Id', acc)
                  this.myNftopenseaAccount(acc)
                  this.setState({ myNftmetamaskBTN: true, myNftuploadNFT: true, keepAllImage: true })
                  this.setState({ myNftcheckAccount: 1 })
                }
              });
            } else {
              this.metamaskAccount(acc)
              this.setState({ myNftcheckAccount: 1 })
            }
            if (this.state.myNftcheckAccount === 0) {
              this.metamaskAccount(acc)
            }
          }
          if (
            apiRequestCallId === this.editCommentApiId &&
            !!responseJson
          ) {
            this.getCommentData(responseJson.data.attributes.post_id)
            this.setState({ editCommentId: 0 })
          }
          if (
            apiRequestCallId === this.deleteCommentApiId &&
            !!responseJson
          ) {
            this.getCommentData(responseJson.data.attributes.post_id)
            this.setState({ deleteCommentId: 0 })
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;

        if (errors) {

          if (apiRequestCallId === this.apiMyInformationId && errors[0]?.account === "Record not found") {
            this.logoutUser()
          }

          if (apiRequestCallId === this.apiMyInformationId) {
            this.setState({ userInfoLoader: false })
          } else if (apiRequestCallId === this.verifiedNFTApiCallId) {
            this.setState({ nftLoader: false })
          } else if (apiRequestCallId === this.previouslyOwnedNftsApiId) {
            this.setState({ prevNFTLoder: false })
          } else if (apiRequestCallId === this.myNftopenSeaApiCallId) {
            this.setState({ createPostNftLoader: false })
          } else if (apiRequestCallId === this.myNftcreateNFTAccountApiCallId) {
            this.setState({ uploadNFTLoader: false })
          }

          if (apiRequestCallId === this.allMyNftPostsapiId) {
            this.setState({ loader: false })
          }
          /* if (errors[0].profile) {
            toast.error(errors[0].profile, { position: toast.POSITION.BOTTOM_RIGHT })
            return
          } */
          if (errors[0]?.profile?.image[0]) {
            toast.error(errors[0]?.profile?.image[0], { position: toast.POSITION.BOTTOM_RIGHT })
            this.setState({ updateImageLoader: false })
          } else if (errors[0].address === 'This metamask wallet account is already linked. Please connect another wallet account.') {
            this.setState({ myNftmetamaskBTN: false })
          } else if (errors[0]?.message === 'No result found.') {
            this.props.setFriendsList([])
            this.setState({ getFriendsLoder: false })

            if (!!this.state.searchedVal) {
              //@ts-ignore
              document.getElementById('searchFriend').value = this.state.searchedVal
              //@ts-ignore
              document.getElementById('searchFriend')?.focus()
            }
          }
          else if (errors[0]?.subject || errors[1]?.message) {
            toast.error('subject or message ' + errors[0]?.subject, { position: toast.POSITION.BOTTOM_RIGHT })

          }
          else if (errors) {
            toast.error(responseJson?.errors, { position: toast.POSITION.BOTTOM_RIGHT })
          }
        }
        else {
          toast.error(errors, { position: toast.POSITION.BOTTOM_RIGHT })

        }
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  ImageUploadAPI(values: any) {

    const header = {
      "token": Cookies.get("Login_Token"),
    };
    const attrs = {
      "profile[image_attributes][image]": values
    };
    const formData = new FormData();
    // Update the formData object
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      JSON.stringify(attrs)
    );

    this.apiImageUploadId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ImageUploadApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}
