import React from "react";

// Customizable Area Start
import Button from "@material-ui/core/Button";
import NotFoundPageController, { Props } from "./404Controller.web";
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import NavbarWeb from "../../TargetedFeed/src/Navbar.web";
// Customizable Area End

class InternalServerErrorPage extends NotFoundPageController {
   constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
   }
   render() {
      //@ts-ignore
      const { classes } = this.props
      return (
         <>
            {//@ts-ignore
            <NavbarWeb />}
            <div className={classes.container}>
               <div className={classes.errorPageNotFound}>
                  <div className={classes.errorPageWrapper}>
                     <h1>500</h1>
                     <p className={classes.pageNotFound}>Internal Server Error</p>
                     <h2><span>Oops!</span> Something went wrong.</h2>
                     <p className={classes.searchAgain}>There were some technical issues while processing your request.</p>
                     <Button onClick={this.redirectToHome} className={classes.goToHome}>Go to homepage</Button>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

// Customizable Area Start
const useStyles = {
   container: {
      overflowX: "hidden"
   },
   errorPageNotFound: {
      padding: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#ffffff",
      minHeight: "calc(100vh - 70px)",
      boxSizing: "border-box",
      overflowX: "hidden",
      "& *": {
         boxSizing: "border-box"
      }
   },
   errorPageWrapper: {
      maxWidth: "1199px",
      padding: "0 15px",
      margin: "0 auto",
      textAlign: "center",
      "& h1": {
         fontSize: "180px",
         margin: "0",
         fontFamily: "'Poppins', sans-serif",
         marginBottom: "15px",
         fontWeight: 600,
         lineHeight: 1,
         color: "#0388c9",
      },
      "& h2": {
         fontSize: "40px",
         margin: "0",
         fontFamily: "'Poppins', sans-serif",
         paddingBottom: "15px",
         fontWeight: 600,
         lineHeight: 1.3,
         color: "#000000",
      },
   },
   pageNotFound: {
      fontSize: "26px",
      margin: "0",
      color: "#000000",
      fontFamily: "'Poppins', sans-serif",
      marginBottom: "15px",
      fontWeight: 600,
      lineHeight: 1,
   },
   searchAgain: {
      fontSize: "20px",
      margin: "0",
      color: "#000000",
      fontFamily: "'Poppins', sans-serif",
      marginBottom: "25px",
      lineHeight: 1,
   },
   goToHome: {
      display: "inline-block",
      background: "#d8dddd",
      border: "2px solid #1f7fb6",
      borderRadius: "5px",
      textAlign: "center",
      color: "#1f7fb6",
      fontWeight: 700,
      fontFamily: "'Poppins', sans-serif",
      padding: "10px 20px",
   }
} as any;
// Customizable Area End

//@ts-ignore
const InternalServerErrorPageWithRouter = withRouter(InternalServerErrorPage)
//@ts-ignore
export default withStyles(useStyles)(InternalServerErrorPageWithRouter)
