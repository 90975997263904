import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import Cookies from "js-cookie";
import * as Yup from "yup";
// Customizable Area Start
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ValidateInputOtpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string;
  submitButtonColor: any = configJSON.submitButtonColor;
  apiResendOtpId: string = "";
  // Customizable Area End

  constructor (props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }
  //@ts-ignore
  componentDidMount() {

  }
  // Customizable Area Start
  SubmitSchema = Yup.object().shape({
    otp: Yup.string()
      .min(4, "Too Short!")
      .required("OTP is Required"),
  });
  // Customizable Area End

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpAuthApiCallId != null &&
      this.otpAuthApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      console.log('responseJson', responseJson)
      if (responseJson.messages && responseJson?.messages[0]?.otp === "OTP validation success") {
        toast.success("OTP validation success",{position: toast.POSITION.BOTTOM_RIGHT});
        this.props.navigation.navigate('ResetPassword')
      }
      if (responseJson.errors) {
        if (responseJson.errors && responseJson.errors[0]?.otp) {
          // this.showAlert(configJSON.errorTitle, responseJson.errors[0]?.otp);
          toast.error(responseJson.errors[0]?.otp,{position: toast.POSITION.BOTTOM_RIGHT})
          return;
        }
        if (responseJson.errors && responseJson.errors[0]?.pin) {
          toast.error(responseJson.errors[0]?.pin,{position: toast.POSITION.BOTTOM_RIGHT})
          // this.showAlert(configJSON.errorTitle, responseJson.errors[0]?.pin);
          return;
        }
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResendOtpId != null &&
      this.apiResendOtpId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      //@ts-ignore
      if (responseJson?.data?.attributes?.pin) {
        toast.success('Otp Sent',{position: toast.POSITION.BOTTOM_RIGHT})
        const resend_token = responseJson?.meta?.token
        Cookies.set('resend_token', resend_token)
      } else {
        const errors = responseJson?.errors;
        if (errors[0].token === 'Invalid token' || errors[0].token === "Token has Expired") {
          toast.error(errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
          Cookies.remove('Login_Token')
          //@ts-ignore
          this.props.history.push({
            pathname: '/login'
          })
        } else {
          toast.error(errors,{position: toast.POSITION.BOTTOM_RIGHT})
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async ResendOTP() {
    console.log('ResendOTP:')
    //@ts-ignore
    const email = this.props?.location?.state?.OriginalEmail
    console.log('Resend Email:', email)
    this.doResendOtpAPI(email)
  }
  doResendOtpAPI(values: any): boolean {
    console.log('email:values', values)
    const header = {
      "Content-Type": configJSON.ContentType,
    };

    const attrs = {
      email: values
    };

    const data = {
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResendOtpId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ResendOtpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log('sendMessage:Email')
    return true;
  }
  async submitEmailOtp(values: any) {
    console.log('otp: ', values.otp)
    if (!values.otp || values.otp.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorOtpNotValid);
      return;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType
    };
    console.log('header', header)
    //GO TO REQUEST STATE
    this.otpAuthApiCallId = requestMessage.messageId;
    console.log('apiVerifyOtpContentType', this.otpAuthApiCallId)
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiVerifyEmailOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const data = {
      token: Cookies.get('resend_token') ? Cookies.get('resend_token') : "",
      otp_code: values.otp ? values.otp : ""
    };
    console.log('data :', data)
    const httpBody = {
      data: data
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text })
  };
  // Customizable Area End
}
