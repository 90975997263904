import React,{useState} from "react";

// Customizable Area Start
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
  //@ts-ignore
import { ReactComponent as LogoTitle } from '../../logo-title.svg';
// Customizable Area End

import ForgotPasswordConfirmationController, { Props } from "./ForgotPasswordConfirmationController.web";
class ForgotPasswordConfirmation extends ForgotPasswordConfirmationController{
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }
  render() {
    //@ts-ignore
    const { classes } = this.props
    //@ts-ignore
    const email = this.props?.location?.state?.email
    
    return (
      <div>
         <Dialog open={true} classes={{
        paper: classes.paper,
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}>
          <DialogTitle>
            <LogoTitle />
          </DialogTitle>
        <DialogContent>
            <DialogContentText className={classes.texHeaderStyle}>
            Password recovery email has been sent.
            </DialogContentText>
            <DialogContentText className={classes.textStyle} >
              A message was sent to your email {email}.Follow the instructions to reset your password.
            </DialogContentText>
            <DialogActions style={{justifyContent:"center" }}>
          <Button onClick={() => this.doSubmit()}  style={{background:"#1f7fb6",marginTop:"100px"}} className={classes.cssButton}>
            Next
          </Button>
        </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
    );
  }
}
const useStyles = {
    cssButton: {
     width: '470px',
     height: '50px',
    background: '#1f7fb6'
    },
  paper: {
    display: "flex",
    justifyContent:"center",
    width: '545.76px',
    height: '494.5px',
    background: '#fffffe!important',
  },
  texHeaderStyle: {
   width:'auto',
    height: '58px',
    color: '#4d4e4c',
  fontFamily: 'Poppins',
  fontSize: '38.7826px',
  fontWeight: 600,
  lineHeight: '58px',
  },
  textStyle: {
  width:'auto',
  height: '30px',
  color: '#4d4e4c',
  fontFamily: 'Poppins',
  fontSize: '20px',
  fontWeight: 600,
    lineHeight: '30px',
  marginTop:'100px'
}
  } as any;

//@ts-ignore
const forgotPasswordConfirmation = withRouter(ForgotPasswordConfirmation)
export default withStyles(useStyles)(withRouter(forgotPasswordConfirmation as any) as any)
