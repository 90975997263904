import React from "react";
import SidebarController, { Props } from "./SidebarController.web";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";
import Avatar from '@material-ui/core/Avatar';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import TextsmsIcon from '@material-ui/icons/Textsms';
import CloseIcon from "@material-ui/icons/Close";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CoversationWeb from "../../Chat9/src/Conversation.web";
import { connect } from "react-redux";
import moment from "moment";
import Badge from '@material-ui/core/Badge';
import InputAdornment from '@material-ui/core/InputAdornment'
//@ts-ignore
import {GET_FRIENDSLIST,USER_LOGOUT, GET_CLIENT,GET_CHAT_USERLIST,GET_LASTMESG,GET_INVITED_CHANNELS,UPDATE_LAST_MSG,UPDATED_USR_LAST_MSG,USELESS_CALL,REMOVE_BADGE,UPDATE_CHAT_LIST,UPDATE_CHAT_LAST_MEG_LIST} from "../../../web/src/allActions.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Scrollbar } from "react-scrollbars-custom";
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem
} from "@reach/menu-button";
import TextField from "@material-ui/core/TextField";
import MediaIcon from '@material-ui/icons/PermMediaOutlined';
class Sidebar extends SidebarController {
  constructor (props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;

    const userRedirect = (userId: string) => {
      //@ts-ignore
      this.props.history.push({
        pathname: '/user/' + userId,
        state: { detail: userId }
      })
    }

    return (
      // Customizable Area Start
      
      <div className={classes.sideBarWrapper}>
        <div className={classes.sideBar}>
          <Scrollbar className={`${classes.customScroll} ${classes.sidebarCustomScroll}`}>
            <div className={classes.sidebarTitle}>
              <h2>Chat</h2>
              {
                //@ts-ignore
                this.props?.chatOneToOne === 'chatOneToOne' ? (
                  <Accordion>
                    <AccordionSummary aria-controls="panel1a-content" id="newChat">
                      <Button variant="contained" className={`${classes.buttonNew} new-chat`}>
                        New
                        <TextsmsIcon />
                      </Button>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={classes.newchatHead}>
                        <div className={classes.newchatTitle}>
                          <h3>New message</h3>
                          <IconButton onClick={() => document.getElementById("newChat")?.click()}>
                            <CloseIcon style={{ color: "#c2c3c1", fontSize: "17px" }} />
                          </IconButton>
                        </div>
                        <div className={classes.newchatTitle}>
                          <h3>To:</h3>
                          <TextField
                            className={classes.toUser}
                            placeholder="Type a name..."
                            autoComplete="off"
                            variant="outlined"
                            id="chatSerachBox"
                            onChange={(event) => this.getNewChatUsers(event.target.value)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  {this.state.newChatLoader && <CircularProgress size={20} style={{ color: '#0388c9' }} />}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                      <div className={classes.newchatSearch}>
                        <Scrollbar className={`${classes.customScroll} ${classes.chatCustomScroll}`}>
                          {this.state.newChatUsers?.friends?.data?.length > 0 &&
                            <div className={classes.newchatUser}>
                              <h3>Friends</h3>
                              {this.state.newChatUsers?.friends?.data?.map((friend: any) => {
                                const { attributes } = friend
                                return (
                                  <div className={classes.userInfo} key={friend?.id} style={{ padding: "7px 0" }} onClick={() => {

                                    //@ts-ignore
                                    if (!this.props?.chatUserList.some(e => e?.userId === friend?.id)) {
                                      if (this.props.callcomponentDidMountFromChat !== undefined) {
                                        this.props?.callcomponentDidMountFromChat(friend?.id, this.state.myloginId)
                                      }
                                      //@ts-ignore
                                      document.getElementById("chatSerachBox").value = ''
                                      document.getElementById("newChat")?.click()

                                      this.setState({ newChatUsers: [] })
                                    } else {
                                      //@ts-ignore
                                      this.props?.chatUserList?.forEach((chatUser: any, userIndex: number) => {
                                        if (chatUser?.userId == friend?.id) {
                                          if (this.props.callcomponentDidMountFromChat !== undefined) {
                                            this.props?.callcomponentDidMountFromChat(friend?.id, this.state.myloginId)
                                          }
                                          this.setState({ newChatUsers: [] })
                                          //@ts-ignore
                                          document.getElementById("chatSerachBox").value = ''
                                          document.getElementById("newChat")?.click()
                                        }
                                      });
                                    }

                                  }}>
                                    <Avatar src={attributes?.image}>{attributes?.full_name?.slice(0, 1)}</Avatar>
                                    <Typography>{attributes?.full_name}</Typography>
                                  </div>
                                )
                              })}
                            </div>}
                          {this.state.newChatUsers?.users?.data?.length > 0 &&
                            <div className={classes.newchatUser}>
                              <h3>Users</h3>
                              {this.state.newChatUsers?.users?.data?.map((user: any) => {
                                const { attributes } = user
                                return (
                                  <div className={classes.userInfo} key={user?.id} style={{ padding: "7px 0" }} onClick={() => {

                                    //@ts-ignore
                                    if (!this.props?.chatUserList.some(e => e?.userId === user?.id)) {
                                      if (this.props.callcomponentDidMountFromChat !== undefined) {
                                        this.props?.callcomponentDidMountFromChat(user?.id, this.state.myloginId)
                                      }
                                      //@ts-ignore
                                      document.getElementById("chatSerachBox").value = ''
                                      document.getElementById("newChat")?.click()

                                      this.setState({ newChatUsers: [] })
                                    } else {
                                      //@ts-ignore
                                      this.props?.chatUserList?.forEach((chatUser: any, userIndex: number) => {
                                        if (chatUser?.userId == user?.id) {
                                          if (this.props.callcomponentDidMountFromChat !== undefined) {
                                            this.props?.callcomponentDidMountFromChat(user?.id, this.state.myloginId)
                                          }
                                          this.setState({ newChatUsers: [] })
                                          //@ts-ignore
                                          document.getElementById("chatSerachBox").value = ''
                                          document.getElementById("newChat")?.click()
                                        }
                                      });
                                    }
                                  }}>
                                    <Avatar src={attributes?.image}>{attributes?.full_name?.slice(0, 1)}</Avatar>
                                    <Typography>{attributes?.full_name}</Typography>
                                  </div>
                                )
                              })}
                            </div>}
                        </Scrollbar>
                      </div>
                      {/* <div className={classes.textArea}>
                      <label htmlFor="icon-button-file">
                        <IconButton
                          aria-label="upload picture"
                          component="span">
                          <AddPhotoAlternateOutlinedIcon style={{ color: "#1f7fb6" }} />
                        </IconButton>
                      </label>
                      <TextField
                        id="sendOne2OneMessageBtn"
                        className={classes.searchBar}
                        variant="outlined"
                        placeholder="Message"
                        autoComplete="off"
                      />
                      <IconButton>
                        <FavoriteBorderOutlinedIcon style={{ color: "#1f7fb6" }} />
                      </IconButton>
                    </div> */}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <Accordion>
                    <AccordionSummary aria-controls="panel1a-content" id="newChat">
                      <Button variant="contained" className={`${classes.buttonNew} new-chat`}>
                        New
                        <TextsmsIcon />
                      </Button>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={classes.newchatHead}>
                        <div className={classes.newchatTitle}>
                          <h3>New message</h3>
                          <IconButton onClick={() => document.getElementById("newChat")?.click()}>
                            <CloseIcon style={{ color: "#c2c3c1", fontSize: "17px" }} />
                          </IconButton>
                        </div>
                        <div className={classes.newchatTitle}>
                          <h3>To:</h3>
                          <TextField
                            className={classes.toUser}
                            placeholder="Type a name..."
                            autoComplete="off"
                            variant="outlined"
                            id="chatSerachBox"
                            onChange={(event) => this.getNewChatUsers(event.target.value)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='start'>
                                  {this.state.newChatLoader && <CircularProgress size={20} style={{ color: '#0388c9' }} />}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                      <div className={classes.newchatSearch}>
                        <Scrollbar className={classes.customScroll + " " + classes.chatCustomScroll}>
                          {this.state.newChatUsers?.friends?.data?.length > 0 &&
                            <div className={classes.newchatUser}>
                              <h3>Friends</h3>
                              {this.state.newChatUsers?.friends?.data?.map((friend: any) => {
                                const { attributes } = friend
                                return (
                                  <div className={classes.userInfo} key={friend?.id} style={{ padding: "7px 0" }} onClick={() => {

                                    //@ts-ignore
                                    if (!this.props?.chatUserList.some(e => e?.userId === friend?.id)) {
                                      let userObj = {
                                        userId: friend?.id,
                                        lastMsg: '',
                                        lastConsumedMessageIndex: '',
                                        identity: friend?.attributes.email,
                                        dateUpdated: '',
                                        allMsgIndex: 0
                                      }
                                      //@ts-ignore
                                      this.props?.addNewUser(userObj)
                                      //@ts-ignore
                                      this.props.addInvitedChannels({ profile: friend?.attributes.image, name: friend?.attributes.full_name, userId: friend?.id, email: friend?.attributes.email })
                                      //@ts-ignore
                                      document.getElementById("chatSerachBox").value = ''
                                      document.getElementById("newChat")?.click()

                                      this.setState({ expanded: 0, getConversation: 0, newChatUsers: [] })
                                    } else {
                                      //@ts-ignore
                                      this.props?.chatUserList?.forEach((chatUser: any, userIndex: number) => {
                                        if (chatUser?.userId == friend?.id) {
                                          this.setState({ expanded: userIndex, getConversation: userIndex, newChatUsers: [] })
                                          //@ts-ignore
                                          document.getElementById("chatSerachBox").value = ''
                                          document.getElementById("newChat")?.click()
                                          document.getElementById("converstation")?.scrollIntoView()
                                        }
                                      });
                                    }
                                  }}>
                                    <Avatar src={attributes?.image}>{attributes?.full_name?.slice(0, 1)}</Avatar>
                                    <Typography>{attributes?.full_name}</Typography>
                                  </div>
                                )
                              })}
                            </div>}
                          {this.state.newChatUsers?.users?.data?.length > 0 &&
                            <div className={classes.newchatUser}>
                              <h3>Users</h3>
                              {this.state.newChatUsers?.users?.data?.map((user: any) => {
                                const { attributes } = user
                                return (
                                  <div className={classes.userInfo} key={user?.id} style={{ padding: "7px 0" }} onClick={() => {

                                    //@ts-ignore
                                    if (!this.props?.chatUserList.some(e => e?.userId === user?.id)) {
                                      let userObj = {
                                        userId: user?.id,
                                        lastMsg: '',
                                        lastConsumedMessageIndex: '',
                                        identity: user?.attributes.email,
                                        dateUpdated: '',
                                        allMsgIndex: 0
                                      }
                                      //@ts-ignore
                                      this.props?.addNewUser(userObj)
                                      //@ts-ignore
                                      this.props.addInvitedChannels({ profile: user?.attributes.image, name: user?.attributes.full_name, userId: user?.id, email: user?.attributes.email })
                                      //@ts-ignore
                                      document.getElementById("chatSerachBox").value = ''
                                      document.getElementById("newChat")?.click()
                                      this.setState({ expanded: 0, getConversation: 0, newChatUsers: [] })
                                    } else {
                                      //@ts-ignore
                                      this.props?.chatUserList?.forEach((chatUser: any, userIndex: number) => {
                                        if (chatUser?.userId == user?.id) {
                                          this.setState({ expanded: userIndex, getConversation: userIndex, newChatUsers: [] })
                                          //@ts-ignore
                                          document.getElementById("chatSerachBox").value = ''
                                          document.getElementById("newChat")?.click()
                                          document.getElementById("converstation")?.scrollIntoView()
                                        }
                                      });
                                    }

                                  }}>
                                    <Avatar src={attributes?.image}>{attributes?.full_name?.slice(0, 1)}</Avatar>
                                    <Typography>{attributes?.full_name}</Typography>
                                  </div>
                                )
                              })}
                            </div>}
                        </Scrollbar>
                      </div>
                      {/* <div className={classes.textArea}>
                      <label htmlFor="icon-button-file">
                        <IconButton
                          aria-label="upload picture"
                          component="span">
                          <AddPhotoAlternateOutlinedIcon style={{ color: "#1f7fb6" }} />
                        </IconButton>
                      </label>
                      <TextField
                        id="sendOne2OneMessageBtn"
                        className={classes.searchBar}
                        variant="outlined"
                        placeholder="Message"
                        autoComplete="off"
                      />
                      <IconButton>
                        <FavoriteBorderOutlinedIcon style={{ color: "#1f7fb6" }} />
                      </IconButton>
                    </div> */}
                    </AccordionDetails>
                  </Accordion>
                )
              }

            </div>
            <div className={classes.chatAccordion}>

              {
                //@ts-ignore
                this.props?.chatUserList?.length !== 0 ? (

                  //@ts-ignore
                  this.props?.chatUserList.map((element: any, index) => {
                    return (
                      this.props.FromHome === "FromHome" ?
                        (
                          <Accordion key={index} id={"Accordians" + index}
                            expanded={this.state.expanded === index ? true : false}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={"panel" + index + "a-content"}
                              id="chatUser-1"
                              onClick={() => {
                                this.setState({ callChatUserJoinFunc: element?.name })
                                if (this.state.expanded !== index) {
                                  this.setState({ expanded: index, getConversation: index })
                                } else {
                                  this.setState({ expanded: '' })
                                  this.forceUpdate()
                                }
                              }}
                            >
                              <div className={classes.accordionUser}>
                                <div className={classes.userInfo}>
                                  <div className={classes.userActivate}>
                                    <Avatar alt={element.name} src={element.profile && element.profile} onClick={(e) => { e.stopPropagation(); userRedirect(element.userId)}} />
                                    {
                                      //@ts-ignore
                                      this.props?.activeUser?.length !== 0 && (
                                        //@ts-ignore
                                        this.props?.activeUser.map((user: any, index: number) => {
                                          if (user?.identity == element?.email && user?.is_online == true) {
                                            return (
                                              <span key={index} className={classes.imgWrap}><FiberManualRecordIcon style={{ color: 'green', fontSize: '15px' }} /></span>
                                            )
                                          }
                                        })

                                      )}
                                  </div>
                                  <Typography><span onClick={(e) => { e.stopPropagation(); userRedirect(element.userId)}}>{element.name}</span>
                                    <span className={classes.chatTimeslot}>
                                      {
                                        //@ts-ignore
                                        this.props?.lastMegs?.length !== 0 && (
                                          //@ts-ignore
                                          this.props?.lastMegs.map((user: any, msgIndex: number) => {
                                            if (user?.userId == element?.userId && user?.identity == this.state.myEmailId) {
                                              return (

                                                <span key={msgIndex}>
                                                  <em>You:</em>
                                                  <em
                                                    key={element + index + element + index + index + msgIndex} id={user?.identity + 'lastMsg'}>
                                                    {user?.lastMsg === null ? <><MediaIcon className={classes.mediaIcon} />Media</> : <span className={classes.longMessage}>{user?.lastMsg}</span>}
                                                  </em>

                                                </span>


                                              )
                                            } else if (user?.userId == element?.userId && user?.identity == element?.email) {
                                              return (

                                                <span key={msgIndex}>
                                                  <em
                                                    key={element + index + element + index + index + msgIndex} id={user?.identity + 'lastMsg'}>
                                                    {user?.lastMsg === null ? <><MediaIcon className={classes.mediaIcon} />Media</> : <span className={classes.longMessage}>{user?.lastMsg}</span>}
                                                  </em>
                                                  {user?.getUnConsumedIndex > 0 && <FiberManualRecordIcon style={{color:'#888b87',fontSize:'4px', marginLeft: '2px'}}/>}
                                                  <Badge badgeContent={user?.getUnConsumedIndex} color="secondary" id={user?.identity + "badge"} key={user?.identity} className={classes.badge} >
                                                  </Badge>
                                                </span>


                                              )
                                            }
                                          })

                                        )}
                                      <h6 id={element.email} style={{ display: 'none' }}>Typing...</h6>
                                    </span>
                                  </Typography>
                                </div>
                                <Menu>
                                  <MenuButton className={classes.buttonMore} onClick={(e) => e.stopPropagation()}>
                                    <MoreHorizIcon />
                                  </MenuButton>
                                  <MenuList className={classes.menuListData} style={{ width: "240px" }}
                                  >
                                    <span className={classes.triangle}></span>
                                    <MenuItem key={index + element + index + element + index}
                                      onClick={(e) => e.stopPropagation() }
                                      onSelect={() => {
                                        //@ts-ignore
                                        this.props.history.push({
                                          pathname: '/user/' + element.userId,
                                          state: { detail: element.userId }
                                        })
                                      }}
                                    > <h4 key={element}><img src="https://img.icons8.com/ios/50/000000/user--v3.png" /> View profile</h4>
                                    </MenuItem>
                                    <MenuItem key={index + element + element + element} onClick={(e) => e.stopPropagation() } onSelect={() => this.deleteChat(element.userId, index)
                                    }><h4 key={element + index}><img src="https://img.icons8.com/ios/50/000000/delete-message.png" />Delete chat</h4></MenuItem>
                                    <MenuItem key={element + index + index} onClick={(e) => e.stopPropagation() } onSelect={() => this.handleBlockUser(element.userId)} >
                                      <h4 key={element + index + element}><img src="https://img.icons8.com/ios/50/000000/unfriend-male.png" />Block</h4></MenuItem>
                                  </MenuList>

                                </Menu>
                              </div>
                            </AccordionSummary >
                            <AccordionDetails id={'conversation' + index}>
                              {(this.state.expanded === index || this.state.getConversation === index) && (
                                <CoversationWeb navigation={''} id={'converstation'} location={''} documentId={index}
                                  //@ts-ignore
                                  addClient={this.props.addClient && this.props.addClient}
                                  //@ts-ignore
                                  list={this.props.list && this.props.list}
                                  callChatUserJoinFunc={this.state.callChatUserJoinFunc} accountId={this.state.myloginId}
                                  receiveId={element.userId}
                                  userName={element.name}
                                  UserEmai={element.email}
                                  UserProfile={element.profile}
                                  expanded={this.state.expanded}
                                  //@ts-ignore
                                  myInfo={this.props?.myInfo}
                                  //@ts-ignore
                                  removeBadge={this.props?.removeBadge}
                                />
                              )}

                            </AccordionDetails>
                          </Accordion>
                        ) : (
                          <div className={classes.accordionUser} key={index + element + index}>
                            <div className={classes.userInfo}>
                              <Button
                                style={{ textTransform: 'initial' }}
                                onClick={() => { //@ts-ignore
                                  this.props.history.push({
                                    pathname: '/chat',
                                    state: {
                                      receiveId: element.userId,
                                      accountId: this.state.myloginId
                                    }
                                  })
                                  if (this.props.callcomponentDidMountFromChat !== undefined) {
                                    this.props?.callcomponentDidMountFromChat(element.userId, this.state.myloginId)
                                  }
                                  // location.reload()
                                }
                                }
                              >
                                <div className={classes.userActivate}>
                                  <Avatar alt={element?.name} src={element.profile && element.profile} />
                                  {
                                    //@ts-ignore
                                    this.props?.activeUser?.length !== 0 && (
                                      //@ts-ignore
                                      this.props?.activeUser.map((user: any, activeIndex: number) => {
                                        if (user?.identity == element?.email && user?.is_online == true) {
                                          return (
                                            <span className={classes.imgWrap} key={activeIndex}><FiberManualRecordIcon style={{ color: 'green', fontSize: '15px' }} /></span>

                                          )
                                        }
                                      })

                                    )}
                                  <h6 id={element.email} style={{ display: 'none' }} key={index + element + index}>Typing...</h6>
                                </div>

                                <Typography
                                  key={element + index + element + index}>{element?.name}
                                  <span className={classes.chatTimeslot}>
                                    {
                                      //@ts-ignore
                                      this.props?.lastMegs?.length !== 0 && (
                                        //@ts-ignore
                                        this.props?.lastMegs.map((user: any, msgIndex: number) => {
                                          if (user?.userId == element?.userId && user?.identity == this.state.myEmailId) {
                                            return (
                                              <>
                                                <span>
                                                  <em>You:</em>
                                                  <em id={user?.identity + 'lastMsg'} >
                                                    {user?.lastMsg === null ? <><MediaIcon className={classes.mediaIcon} />Media</> : <span className={classes.longMessage}>{user?.lastMsg}</span>}
                                                    <FiberManualRecordIcon style={{color:'#888b87',fontSize:'4px', marginLeft: "6px"}}/>
                                                  </em>
                                                  {/* <em>{new Date(user?.dateUpdated.toISOString())?.getHours()+'h'}</em> */}
                                                  <em>{(user?.dateUpdated != '' && user?.dateUpdated != null && user?.dateUpdated != undefined) && (moment(user?.dateUpdated?.toISOString())?.fromNow())}</em>

                                                </span>

                                                {user?.lastConsumedMessageIndex == user?.allMsgIndex && (
                                                  <Avatar alt={element?.name} src={element.profile && element.profile} id={element.email + 'useImg'} key={index + element + index + element} />)
                                                }
                                              </>
                                            )
                                          } else if (user?.userId == element?.userId && user?.identity == element?.email) {
                                            return (

                                              <span>
                                                <em
                                                  key={element + index + element + index + index + msgIndex} id={user?.identity + 'lastMsg'}>
                                                  {user?.lastMsg === null ? <><MediaIcon className={classes.mediaIcon} />Media</> : <span className={classes.longMessage}>{user?.lastMsg}</span>}
                                                </em>
                                                {user?.getUnConsumedIndex > 0 && <FiberManualRecordIcon style={{color:'#888b87',fontSize:'4px', marginLeft: '2px'}}/>}
                                                <Badge badgeContent={user?.getUnConsumedIndex} color="secondary" id={user?.identity + "badge"} key={user?.identity} className={classes.badge}>
                                                </Badge>
                                              </span>


                                            )
                                          }
                                        })

                                      )}
                                  </span>
                                </Typography>


                              </Button>

                            </div>
                          </div>
                        )
                    )
                  }
                  )
                ) : (
                  this.state.loadChat ? (<CircularProgress className={classes.loader} />) : (<h4>No active chats. Say hi to friend</h4>)
                )
              }
            </div>
          </Scrollbar>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SidebarStyles = {
  sidebarCustomScroll: {
    height: "calc(100vh - 118px) !important",
  },
  chatCustomScroll: {
    height: "300px !important",
  },
  customScroll: {
    "& .ScrollbarsCustom-Track": {
      width: "4px !important",
      background: "transparent !important"
    }
  },
  userActivate: {
    position: "relative"
  },
  imgWrap: {
    position: "absolute",
    bottom: "2px",
    right: "12px",
    background: "#ffffff",
    borderRadius: "50%",
    height: "13px",
    width: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "1px 1px 3px rgb(0 0 0 / 20%)",
    zIndex: "1",
  },
  sideBarWrapper: {
    paddingTop: "15px", 
    maxWidth: "25%",
    height: "100%",
    position:'sticky',
    top:'0',
    flex: "0 0 25%",
  },
  sideBar: {
    padding: "10px 5px 10px 15px",
    borderRadius: "10px",
    boxShadow: "1px 1px 3px rgb(0 0 0 / 20%)",
    backgroundColor: "#ffffff",
    fontFamily: "'Poppins', sans-serif",
    overflow: "hidden",
    "& h2": {
      fontSize: "18px",
      fontWeight: "600",
      margin: "0",
      fontFamily: "'Poppins', sans-serif",
      color: "#000105",
      marginTop: "7px",
      position: "absolute",
      zIndex: "1",
      top: "0",
      left: "8px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    }
  },
  
  textArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  searchBar: {
    margin: "10px",
    width: "100%",
    position: "relative",
    "& .MuiInputBase-root": {
      backgroundColor: "#ebecec",
      color: "#6e7370",
      fontSize: "14px",
      borderRadius: "30px",
      margin: "0",
      width: "100%",
      cursor: "pointer",
    },
    "& .MuiInputBase-input": {
      padding: "0 15px",
      height: "40px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    }
  },
  toUser: {
    margin: "10px",
    width: "100%",
    position: "relative",
    "& .MuiInputBase-root": {
      backgroundColor: "transparent",
      color: "#000307",
      fontSize: "16px",
      fontWeight: "400",
      margin: "0",
      width: "100%",
      cursor: "pointer",
    },
    "& .MuiInputBase-input": {
      padding: "0 5px",
      height: "25px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    }
  },
  newchatSearch: {
    padding: "0 10px",
  },
  newchatUser: {
    padding: "10px 0",
    borderBottom: "1px solid #dcdcd7",
    "& h3": {
      color: "#000105",
      fontSize: "14px",
      fontWeight: "600",
      margin: "0",
      textAlign: "left",
      paddingBottom: "10px"
    },
    "& .MuiAvatar-root": {
      width: "33px !important",
      height: "33px !important",
    },
    "& .MuiTypography-root": {
      fontWeight: "500 !important",
      color: "#272129 !important"
    }
  },
  sidebarTitle: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "7px",
    paddingBottom: "10px",
    position: "relative",
    "& .MuiAccordion-root": {
      textAlign: "right",
      display: "inline-block",
      boxShadow: "none",
      margin: "0",
      width: "100%",
    },
    "& .MuiAccordionSummary-root": {
      width: "100px",
      marginLeft: "auto",
      padding: "0",
      minHeight: "auto",
    },
    "& .MuiAccordionSummary-content": {
      display: "inline-block",
      margin: "0",
    },
    "& .MuiAccordionDetails-root": {
      padding: "0",
      display: "block",
    },
    "& .MuiIconButton-root": {
      padding: "7px",
    },
    "& .MuiCollapse-root": {
      borderRadius: "5px",
      overflow: "hidden",
    },
    "& .MuiCollapse-wrapper": {
      marginTop: "10px",
      border: "1px solid #dcdcd7",
      borderRadius: "5px",
      overflow: "hidden",
    }
  },
  newchatHead: {
    background: "#deedf4",
    padding: "15px 15px 5px",
    position: "relative",
    "& h3": {
      margin: "0",
      color: "#000307",
      fontSize: "16px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
    },
    "& .MuiIconButton-root": {
      position: "absolute",
      top: "5px",
      right: "5px",
    }
  },
  newchatTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonNew: {
    border: "1px solid #1f7fb6",
    backgroundColor: "#e4e5e4",
    height: "40px",
    color: "#1f7fb6",
    fontSize: "16px",
    fontWeight: "700",
    borderRadius: "50px",
    boxShadow: "none !important",
    display: "inline-flex",
    alignItems: "center",
    "& .MuiButton-label": {
      textTransform: "none",
    },
    "& svg": {
      marginLeft: "5px",
      height: "23px"
    }
  },
  
  menuListData: {
    whiteSpace: "pre-wrap",
    width: "346px",
    backgroundColor: "#fffffe",
    borderRadius: "10px",
    position: "relative",
    marginTop: "20px",
    cursor: "pointer",
    "& button": {
      width: "100%",
      padding: "5px 15px",
      cursor: "pointer",
    },
    "& div": {
      backgroundColor: "#fffffe !important",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      }
    },
    "& h4": {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      color: "#565a57",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      textTransform: "none",
      flexWrap: "wrap",
      margin: "0",
      width: "100%",
      "& img": {
        width: "24px",
        marginRight: "12px",
      },
      "& svg": {
        width: "24px",
        marginRight: "12px",
      }
    },
  },
  triangle: {
    position: "absolute",
    width:"16px",
    height:"16px",
    borderBottom: "1px solid transparent",
    borderRight: "1px solid transparent",
    borderTop:"1px solid rgba(0,0,0,0.25)",
    borderLeft:"1px solid rgba(0,0,0,0.25)",
    transform:"rotate(45deg)",
    backgroundColor:"#fffffe",
    top: "-9px",
    left: "20px",
  },
  buttonMore: {
    minWidth: "42px",
    border: "none",
    height: "42px",
    backgroundColor: "#ebecec",
    color: "#1f7fb6",
    borderRadius: "5px",
    "@media(max-width: 1199px)": {
      width: "30px",
      minWidth: "30px",
      height: "30px",
      "& .MuiSvgIcon-root":{
        width: "13px",
        height: "13px",
      }
    },
  },
  accordionUser: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
    "& .MuiButton-label": {
      textAlign: "left",
      display: "flex",
    },
    "& .MuiAvatar-root": {
      width: "56px",
      height: "56px",
      backgroundColor: "#bcbeba",
      borderRadius: "50%",
      marginRight: "10px",
      "@media(max-width: 1199px)": {
        width: "40px",
        height: "40px",
      }
    },
    "& .MuiTypography-root": {
      textTransform: "none",
      fontSize: "14px",
      color: "#000307",
      fontWeight: "700",
      textAlign: "left",
      width: "100%",
    },
    "& .MuiButton-text": {
      width: "100%",
      justifyContent: "flex-start",
    }
  },
  chatTimeslot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 -3px",
    "& em": {
      fontSize: "13px",
      color: "#888b87",
      fontWeight: "500",
      position: "relative",
      fontStyle: "normal",
      padding: "0 3px",
      display: "inline-flex",
      alignItems: "center",
    },
    "& span": {
      display: "inline-block",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      width: "16px",
      height: "16px",
      fontSize: "9px",
      lineHeight: "2",
      textAlign: "center"
    }
  },
  chatAccordion: {
    textAlign: 'center',
    paddingTop: "8px",
    "& .MuiAccordionSummary-content": {
      margin: "0",
      "&.Mui-expanded": {
        "& .MuiIconButton-root": {
          backgroundColor: "#cddee9"
        },
        '& button':{
          backgroundColor:'#cddee9',
        },
      }
    },
    "& .MuiAccordionSummary-root": {
      padding: "7px",
      minHeight: "initial",
      "&.Mui-expanded": {
        backgroundColor: "#deedf4",
      }
    },
    "& .MuiPaper-root": {
      boxShadow: "none",
      borderRadius: "5px",
      overflow: "hidden",
      "&.Mui-expanded": {
        margin: "0"
      }
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: "0 10px",
      "@media(max-width: 1199px)": {
        padding: "0",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "24px",
        color: "#1f7fb6"
      }
    },
    "& .MuiCollapse-root": {
      border: "1px solid #dcdcd7",
      borderRadius: "0 0 5px 5px",
    },
    "& .MuiAccordionDetails-root": {
      paddingBottom: "60px",
      position: "relative",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      padding: "11px 15px",
      boxSizing: "border-box",
      backgroundColor: "#ebecec",
      color: "#6e7370",
      fontSize: "14px",
      borderRadius: "30px",
      margin: "0px",
      width: "100%",
      cursor: "pointer",
      height: "40px",
      overflow: "hidden"
    },
    "& .MuiInputBase-root": {
      background: "transparent",
      padding: "0",
      "&:before": {
        display: "none"
      },
      "&:after": {
        display: "none"
      }
    },
    "& .MuiGrid-item": {
      maxHeight: "280px",
      paddingBottom: "10px !important",
      '& label':{
        '& .MuiIconButton-label':{
          '& .MuiSvgIcon-root':{
            color:'#aab0b1 !important',
          },
        },
      },
    },
    "& .MuiContainer-root": {
      padding: "0",
    }
  },
  loader: {
    marginTop: '65%',
    color: '#0388c9',
    zIndex: 99,
  },
  mediaIcon: {
    color:'#888b87',
    fontSize:'15px',
    marginRight: "2px"
  },
  longMessage: {
    maxWidth: '7rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  badge: {
    marginLeft: '15px',
  }
} as any;
// Customizable Area End
const mapDispatchToProps = (dispatch:any) => {
  return{
  addFriends: (friendList:any) => {
  dispatch(GET_FRIENDSLIST(friendList));
  },
  addClient:(client:any)=>{
    dispatch(GET_CLIENT(client))
  },
  addLastMsg:(msg:any)=>{
    dispatch(GET_LASTMESG(msg))
   },
   addChatUsers:(chatUser:any)=>{
     dispatch(GET_CHAT_USERLIST(chatUser))
   },
   addInvitedChannels:(channel:any)=>{
    dispatch(GET_INVITED_CHANNELS(channel))
   },
   updateLastMsg:(data:any,func?:any)=>{
     dispatch(UPDATE_LAST_MSG(data,func))
   },
   addNewUser:(msg:any)=>{
     dispatch(UPDATED_USR_LAST_MSG(msg))
   },
   useLessCall:(msg:any)=>{
    dispatch(USELESS_CALL(msg))
   },
  removeBadge:(receipent:any)=>{
    dispatch(REMOVE_BADGE(receipent))
  },
  deleteUserchat:(user:any,func:any)=>{
    dispatch(UPDATE_CHAT_LIST(user,func))
  },
  deleteLastMesg:(user:any,func:any)=>{
    dispatch(UPDATE_CHAT_LAST_MEG_LIST(user,func))
  },
  userLogout: () => {
    dispatch(USER_LOGOUT())
  },  
}
 }
 const mapStateToProps = (state:any) => {
  //replace Reducer name with state.'Your Reducer name' and .property
  
       
       
        return {
          list: state.takeAction.clientData[0],
          activeUser:state.takeAction.userData,
          chatUserList:state.takeAction.chatUserList,
          lastMegs:state.takeAction.lastMegs,
          myInfo: state.takeAction.UserInfo,
          useLessArr:state.takeAction.useLessArr[0]
        };
        
      };
//@ts-ignore
const SidebarWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
export default withStyles(SidebarStyles)(SidebarWithRouter);
// export default  (withRouter(Navbar as any) as any)
