import React from "react";

import SinglePostController, {
  Props} from "./SinglePostController.web";
import Navbar from "./Navbar.web"
import Sidebar from './Sidebar.web'
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem as ReachMenuItem
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FriendsWeb from "../../TargetedFeed/src/Friends.web";
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import { Send } from "@material-ui/icons";
import FavoriteIcon from '@material-ui/icons/Favorite';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from "@material-ui/icons/Close";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ReportIcon from '@material-ui/icons/Report';
//@ts-ignore
import { USER_LOGOUT } from "../../../web/src/allActions.js";
import Cookies from "js-cookie";
import ConfirmDeleteDialog from './ConfirmDeleteDialog.web'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
class SinglePost extends SinglePostController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props

    const {
      reportDialog,
      confirmReportDialog,
      reportDialogMessage,
      reportReasons,
      reportSubReasons,
      postReportData
    } = this.state

    const handleConfirmReport = (reason: string, subReasons?: any, reportReasonId = 0) => {
      if (subReasons?.length > 0) {
        this.setState({
          ...this.state,
          reportSubReasons: subReasons,
        })
      } else {
        this.setState({
          ...this.state,
          confirmReportDialog: true,
          reportDialog: false,
          reportDialogMessage: reason,
          postReportData: {
            ...postReportData,
            report_reason_id: reportReasonId
          }
        })
      }
    }

    return (
      // Customizable Area Start
      <>

        <Navbar
          //@ts-ignore
          callComponentDidMountFromSingle={this.callComponentDidMountFromSingle} />
        <div className={classes.container}>
          <div style={{ display: 'flex', justifyContent: "space-between", }}>
            <Sidebar
              //@ts-ignore
              FromHome={"FromHome"} />
            <div style={{ flex: "1 1 100%" }}>
              <div className={classes.bottomBoxBg}>
                {this.state.myAllPost.length !== 0 ? (this.state.myAllPost.map((element: any, index: any) => {
                  const { attributes: { animation_url, image_url, is_current_owner } } = element
                  return (
                    <Card className={classes.postCard} key={index}>
                      <CardHeader
                        avatar={
                          //@ts-ignore
                          <Avatar src={element.attributes.account.image ? element.attributes.account.image : "https://www.studyheights.com/assets/img/Universitylogo/default.png"} aria-label="recipe">
                          </Avatar>
                        }
                        action={
                          <Menu>
                            <MenuButton>
                              <MoreHorizIcon />
                            </MenuButton>
                            <MenuList className={classes.menuListData} style={{ width: "200px" }}>
                              <span className={classes.triangle}></span>
                              {/* <Button onClick={() => this.setState({ ...this.state, reportDialog: true, postReportData: { ...postReportData, post_id: element.attributes?.id } })}><h4><ReportIcon />Report Post</h4></Button> */}
                              {Cookies.get('Login_Id') != element.attributes.account.id ?
                                  <ReachMenuItem onSelect={() => this.setState({ ...this.state, reportDialog: true, postReportData: { ...postReportData, post_id: element.attributes.id } })}>
                                    <h4><ReportIcon />Report Post</h4>
                                  </ReachMenuItem>
                                  :
                                  <>
                                    <ReachMenuItem onSelect={() => this.setState({ editPostId: element.attributes.id })}>
                                      <h4><EditOutlinedIcon />Edit</h4>
                                    </ReachMenuItem>
                                    <ReachMenuItem onSelect={() => this.handleDeleteDialogToogle(element.attributes.id)}>
                                      <h4><DeleteOutlineOutlinedIcon />Delete</h4>
                                    </ReachMenuItem>
                                  </>}
                            </MenuList>
                          </Menu>
                        }
                        title={element.attributes.account.full_name}
                        subheader={element.attributes.time}
                      />
                      <CardContent>
                        <div>
                          {this.state.editPostId === element.attributes.id ? (
                              <form className={classes.discrtxt} onSubmit={(e) => e.preventDefault()}>
                                <div style={{ display: 'flex' }} onClick={(event) => event?.stopPropagation()}>
                                  <TextField
                                    id="editBio"
                                    fullWidth
                                    variant="standard"
                                    autoComplete="off"
                                    placeholder="Write something here..."
                                    autoFocus={this.state.editPostId === element.attributes.id}
                                    defaultValue={element.attributes.description}
                                  />
                                  <Button
                                    className={classes.btnSubmit}
                                    type='submit'
                                    onClick={() => this.updatePost(element.attributes.id)}>
                                    Save
                                  </Button>
                                </div>
                              </form>
                            ) : (
                              <p>{element.attributes.description || ''}</p>
                            )}
                          {element.attributes.nft_name && <span>{element.attributes.nft_name}</span>}
                          {animation_url ? <>
                            {this.postTypeCheck(animation_url) === "audio" &&
                              <audio style={{ width: '100%', objectFit: 'inherit' }} controls>
                                <source src={animation_url} type="audio/mp3" />
                                <source src={animation_url} type="audio/wav" />
                                Your browser does not support the audio element.
                              </audio>}
                            {this.postTypeCheck(animation_url) === "video" &&
                              <video style={{ width: '100%', height: '530px', objectFit: 'inherit' }} controls>
                                <source src={animation_url} type="video/mp4" />
                                <source src={animation_url} type="video/ogg" />
                                <source src={animation_url} type="video/webm" />
                                Your browser does not support the video element.
                              </video>}
                            {this.postTypeCheck(animation_url) === "iframe" &&
                              <iframe style={{ width: '100%', height: '530px', objectFit: 'inherit' }} src={animation_url} />}
                            {this.postTypeCheck(animation_url) === "3dObject" &&
                              <img
                                style={{ width: '100%', height: '530px', objectFit: 'inherit' }}
                                src={image_url} />}
                          </> :
                            image_url &&
                            <img
                              style={{ width: '100%', height: '530px', objectFit: 'inherit' }}
                              src={image_url} />}
                          <div className={classes.postCardInfo}>
                            <div>
                              <FavoriteOutlinedIcon />
                              <h3>
                                {//@ts-ignore
                                  element.attributes.likes_count}
                              </h3>
                            </div>
                            <p>
                              {//@ts-ignore
                                element.attributes.comments_count} comments
                            </p>
                            {image_url && <h3 className={`${classes.paidCardText} ${!is_current_owner && classes.soldNFT}`}><img src="https://img.icons8.com/ios/24/000000/price-tag--v1.png" />
                              {`Paid $ ${element?.attributes?.usd_amount || 0}(${element?.attributes?.eth_amount || 0} ETH)`}
                            </h3>}
                          </div>
                        </div>
                      </CardContent>
                      <div className={classes.likeCommentShare}>
                        <IconButton className={classes.txtHover} aria-label="add to favorites" onClick={() => this.likePost(//@ts-ignore
                          element.attributes.id, element.attributes.is_liked)}>
                          <FavoriteBorderOutlinedIcon style={
                            //@ts-ignore
                            element.attributes.is_liked ?
                              { color: '#0388c9' } : { color: 'grey' }} id="likePost" />
                          <p> {
                            element.attributes.is_liked ?
                              'Unlike' : 'Like'}</p>
                        </IconButton>
                        <IconButton className={classes.txtHover} aria-label="comment">
                          <ModeCommentOutlinedIcon /><p>Comment</p>
                        </IconButton>
                      </div>
                      <div className={classes.commentBlockWrapper}>
                        <FormControl className={classes.selectComment}>
                          <InputLabel></InputLabel>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            autoWidth
                            defaultValue={10}
                          >
                            <MenuItem disabled value={30}>
                              <em>Comment filter</em>
                            </MenuItem>
                            <MenuItem value={10} onClick={() => { this.getCommentData(element.attributes.id, 'most_relevant') }}>Most relevant comments</MenuItem>
                            <MenuItem value={21} onClick={() => { this.getCommentData(element.attributes.id, 'oldest') }}>Old comments</MenuItem>
                            <MenuItem value={22} onClick={() => { this.getCommentData(element.attributes.id, 'newest') }}>New comments</MenuItem>
                          </Select>
                        </FormControl>
                        <div className={classes.commentBlock}>
                          <Avatar aria-label="recipe" src={this.state.profileImage ? this.state.profileImage : "https://www.studyheights.com/assets/img/Universitylogo/default.png"}>
                          </Avatar>
                          <TextField
                            className={classes.commentBar}
                            placeholder="Write a comment"
                            id="comentBox"
                          />
                          <IconButton
                            style={HomeStyles.sendButton} onClick={() => this.createComment(element.attributes.id, index)}>
                            <Send style={HomeStyles.sendIcon} />
                          </IconButton>
                        </div>
                        {this.state.allComments.length !== 0 && (
                          this.state.allComments.map((comment: any, commentIndex: any) => {
                            if (commentIndex < this.state.commentCount) {

                              return (
                                <div className={classes.commentBlock} key={commentIndex}>
                                  <Avatar aria-label="recipe" src={comment.attributes.account.image}>
                                  </Avatar>
                                  <div className={classes.commentWrap}>
                                    <p>
                                      <span>{comment.attributes.account.full_name}</span>
                                      {this.state.editCommentId === comment.attributes.id ?
                                        <TextField
                                          id="editCommentBox"
                                          fullWidth
                                          variant="standard"
                                          autoComplete="off"
                                          placeholder="Write a comment"
                                          autoFocus={this.state.editCommentId === comment.attributes.id}
                                          defaultValue={comment.attributes.comment}
                                          onClick={(e) => e.stopPropagation()}
                                        />
                                        : comment.attributes.deleted ? 'This comment was deleted' : comment.attributes.comment}
                                    </p>
                                    <ul className={classes.listComment}>
                                      <li>
                                        <Button onClick={() => this.likeOrDislikeComment(comment.attributes.id, comment.attributes.is_liked)}>
                                          {comment.attributes.is_liked ? (<div style={{ color: '#1f7fb6' }}>Dislike</div>) : 'Like'}
                                        </Button>
                                      </li>
                                      <li>
                                        <Button onClick={() => {
                                          if (this.state.showReplyInbox !== comment.attributes.id) {
                                            this.setState({ showReplyInbox: comment.attributes.id, allRepliedComments: [] })

                                            this.getRepliedCommentData(comment.attributes.id)
                                          }
                                        }}>
                                          Reply
                                        </Button>
                                      </li>
                                      <li style={{ color: '#1f7fb6' }}><FavoriteIcon style={{ fontSize: 'larger' }} /> ({comment.attributes.likes_count})&nbsp;</li>
                                      <li>{comment.attributes.time}</li>
                                      {Cookies.get('Login_Id') == comment.attributes.account_id && !comment.attributes.deleted && (
                                        <>
                                          <li>
                                            {this.state.editCommentId === comment.attributes.id ?
                                              <Button onClick={() => this.editComment(element.attributes.id)}>
                                                Save
                                              </Button>
                                              : <Button onClick={() => this.setState({ editCommentId: comment.attributes.id })}>
                                                Edit
                                              </Button>}
                                          </li>
                                          <li>
                                            <Button onClick={() => this.handleDeleteCommentToogle(comment.attributes.id)}>
                                              Delete
                                            </Button>
                                          </li></>)}
                                    </ul>

                                    {this.state.showReplyInbox === comment.attributes.id ? (
                                      <div className={classes.replyCommentBlock}>

                                        <div className={classes.commentBlock}>
                                          {this.state.allRepliedComments.length !== 0 && (
                                            this.state.allRepliedComments.map((replyComment: any, replyIndex: any) => {
                                              return (
                                                <>
                                                  <Avatar aria-label="recipe" src={replyComment.attributes.account.image}>
                                                  </Avatar>
                                                  <div className={classes.commentWrap}>
                                                    <p>
                                                      <span>{replyComment.attributes.account.full_name}</span>
                                                      {replyComment.attributes.comment}
                                                    </p>
                                                    <ul className={classes.listComment}>
                                                      <li>
                                                        <Button onClick={() => this.likeOrDislikeComment(replyComment.attributes.id, replyComment.attributes.is_liked)}>
                                                          {replyComment.attributes.is_liked ? (<div style={{ color: '#1f7fb6' }}>Dislike</div>) : 'Like'}
                                                        </Button>
                                                      </li>
                                                      <li>
                                                        <Button >
                                                          Reply
                                                        </Button>
                                                      </li>
                                                      <li style={{ color: '#1f7fb6' }}>{replyComment.attributes.likes_count} &nbsp;<FavoriteIcon style={{ fontSize: 'larger' }} /></li>
                                                      <li>{replyComment.attributes.time}</li>
                                                    </ul>
                                                  </div></>
                                              )
                                            })
                                          )}
                                          <Avatar aria-label="recipe" src={this.state.profileImage ? this.state.profileImage : "https://www.studyheights.com/assets/img/Universitylogo/default.png"}>
                                          </Avatar>
                                          <TextField
                                            className={classes.commentBar}
                                            placeholder="Write a comment"
                                            id={"comentReplyBox" + index + commentIndex}
                                          />
                                          <IconButton
                                            style={HomeStyles.sendButton} onClick={() => this.createReplyComment(element.attributes.id, index, commentIndex, comment.attributes.id)}>
                                            <Send style={HomeStyles.sendIcon} />
                                          </IconButton>
                                        </div>
                                      </div>
                                    ) : ('')}
                                  </div>
                                </div>
                              )
                            }
                          })
                        )}
                        {(this.state.allComments.length !== 0 && this.state.allComments.length > 10) && (

                          <div className={classes.paginationBlock}>
                            <Button onClick={() => {
                              if (this.state.commentCount !== this.state.allComments.length) {
                                this.setState({ commentCount: this.state.allComments.length })
                              }
                              else {
                                this.setState({ commentCount: 10 })
                              }
                            }}>{this.state.commentCount !== this.state.allComments.length ? "See more comments" : "See less comments"}</Button>
                            <div className={classes.paginationBlockWrapper}>
                              <span>{this.state.commentCount}</span> of <span>{this.state.allComments.length}</span>
                            </div>
                          </div>)
                        }
                      </div>
                    </Card>
                  )
                })) : (
                  this.state.loading ? <CircularProgress className={classes.loader} /> :
                    <h4>No Post Found</h4>)}
              </div>
            </div>
            <FriendsWeb
              //@ts-ignore
              pageState={this.state.pageState}
            />

          </div>
        </div>
        <ConfirmDeleteDialog
          //@ts-ignore
          title={"comment"}
          isOpen={this.state.deleteCommentDialog}
          handleCancelDelete={this.handleDeleteCommentToogle}
          handleConfirmDelete={this.deleteComment}
        />
        <ConfirmDeleteDialog
          //@ts-ignore
          isOpen={this.state.deleteDialog}
          handleCancelDelete={this.handleDeleteDialogToogle}
          handleConfirmDelete={this.deletePost}
        />
        <Dialog
          open={reportDialog}
          onClose={() => this.setState({
            ...this.state,
            reportDialog: false,
            reportSubReasons: [],
            postReportData: {
              post_id: 0,
              report_reason_id: 0
            }
          })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.deactivateDialog}
        >
          <DialogTitle className={classes.titleDialog} id="alert-dialog-title">
            <span className={classes.subreportTitle}>
              {reportSubReasons?.length > 0 &&
                <IconButton onClick={() => this.setState({
                  ...this.state,
                  reportSubReasons: [],
                  postReportData: {
                    post_id: 0,
                    report_reason_id: 0
                  }
                })}>
                  <ArrowBackRoundedIcon />
                </IconButton>}
              Report
            </span>
            <IconButton onClick={() => this.setState({
              ...this.state,
              reportDialog: false,
              reportSubReasons: [],
              postReportData: {
                post_id: 0,
                report_reason_id: 0
              }
            })}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.txtDialog}>
            <h2>Report post</h2>
            <p>
              Help us understand what is wrong with this post. How would you describe it?
            </p>
            <div className={classes.btnList}>
              {reportSubReasons?.length > 0 ? reportSubReasons?.map((subReason: any) => {
                const { data: { attributes } } = subReason
                return (
                  <Button key={attributes?.id} onClick={() => handleConfirmReport(attributes?.content, attributes?.sub_reasons, attributes?.id)}>
                    {attributes?.content}
                    <IconButton>
                      <ChevronRightIcon />
                    </IconButton>
                  </Button>
                )
              }) :
                reportReasons?.map((reason: any) => {
                  const { attributes } = reason
                  return (
                    <Button key={attributes.id} onClick={() => handleConfirmReport(attributes.content, attributes?.sub_reasons, attributes?.id)}>
                      {attributes.content}
                      <IconButton>
                        <ChevronRightIcon />
                      </IconButton>
                    </Button>
                  )
                })
              }
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={confirmReportDialog}
          onClose={() => this.setState({
            ...this.state, confirmReportDialog: false, reportDialog: true, postReportData: {
              post_id: 0,
              report_reason_id: 0
            }
          })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.deactivateDialog}
        >
          <DialogTitle className={classes.titleDialog} id="alert-dialog-title">
            Please confirm your submission
            <IconButton onClick={() => this.setState({
              ...this.state, confirmReportDialog: false, reportDialog: true, postReportData: {
                post_id: 0,
                report_reason_id: 0
              }
            })}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ textAlign: "center", paddingBottom: "20px" }}>
            <Button className={classes.btnAddFriend}><ReportIcon />{reportDialogMessage}</Button>
          </DialogContent>
          <DialogActions>
            <Button className={classes.btnChat} onClick={() => this.handlePostReport()}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
const HomeStyles = {
  txtHover: {
    "&:hover": {
      background: "transparent",
      color: "#1f7fb6"
    }
  },
  MuiBoxRoot: {
    flexGrow: "unset",
  },
  container: {
    padding: "0 20px",
    overflowX: "hidden",
    height: "100%",
    backgroundColor: "#f1f2f6",
  },
  boxBg: {
    backgroundColor: "#fff",
    fontFamily: "'Poppins', sans-serif",
    height: "100%",
    margin: "20px 0",
    padding: "10px 15px",
    borderRadius: "10px",
    boxShadow: "1px 1px 3px rgb(0 0 0 / 20%)",
    "& h2": {
      fontSize: "20px",
      fontWeight: "600",
      margin: "0"
    }
  },
  selectComment: {
    width: "100%",
    "& .MuiInputBase-root": {
      maxWidth: "200px",
      width: "100%",
      marginLeft: "auto",
      margin: 0,
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      }
    }
  },
  commentBlockWrapper: {
    borderTop: "1px solid #dcdcd7",
    margin: "0 15px 0",
    paddingTop: "10px",
    "& .MuiSelect-selectMenu": {
      textAlign: "right",
      fontSize: "11px",
      color: "#404341",
      fontWeight: 700,
      "&:focus": {
        background: "transparent",
      },
      "& em": {
        opacity: "0.7"
      }
    },
    "& .MuiSelect-icon": {
      color: "#404341"
    }
  },
  sendButton: { backgroundColor: "#1f7fb6", width: "40px", padding: "10px", height: "40px", marginLeft: "10px" },
  sendIcon: { color: "#ffffff", width: "auto", height: "auto", },
  listComment: {
    margin: "0",
    marginTop: "5px",
    padding: "0",
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    color: "#404341",
    "& li": {
      fontSize: "11px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      padding: "0 5px",
      display: "inline-flex",
      alignItems: "center",
    },
    "& button": {
      margin: "0",
      padding: "0",
      textAlign: "left",
      minWidth: "auto",
      "& span": {
        fontSize: "11px",
        fontWeight: "700",
        fontFamily: "'Roboto', sans-serif",
        textTransform: "none",
      },
      "&:hover": {
        backgroundColor: "none"
      }
    }
  },
  paginationBlock: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 0 15px",
    alignItems: "center",
    "& button": {
      padding: "0",
      margin: "0",
      color: "#6e7370",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "transparent"
      }
    }
  },
  paginationBlockWrapper: {
    color: "#6e7370",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "'Roboto', sans-serif",
  },
  commentWrap: {
    maxWidth: "calc(100% - 47px)",
    flex: "0 0 calc(100% - 47px)",
    "& p": {
      backgroundColor: "#ebecec",
      borderRadius: "30px",
      padding: "5px 15px",
      color: "#6e7370",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      width: "100%",
      maxWidth: "100%",
      boxSizing: "border-box",
      margin: "0",
      "& span": {
        paddingBottom: "3px",
      }
    },
    "& span": {
      color: "#000307",
      fontSize: "11px",
      display: "block",
      paddingBottom: "0",
      margin: "0",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
    },
  },
  commentBar: {
    margin: "2px 0 2px 10px",
    position: "relative",
    maxWidth: "calc(100% - 95px)",
    flex: "0 0 calc(100% - 95px)",
    "&::before": {
      position: "absolute",
      content: "",
      height: "10px",
      width: "2px",
      backgroundColor: "#ffffff",
      left: "0px"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ebecec",
      color: "#6e7370",
      borderRadius: "30px",
      height: "38px",
      fontWeight: "500",
      padding: "0 15px",
      cursor: "pointer",
      fontSize: "14px",
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    }
  },
  commentBlock: {
    display: "flex",
    flexWrap: "wrap",
    padding: "7px 0",
    justifyContent: "space-between",
    "& .MuiAvatar-root": {
      width: "35px",
      height: "35px",
      marginTop: "5px",
    }
  },
  menuListData: {
    whiteSpace: "pre-wrap",
    width: "346px",
    backgroundColor: "#fffffe",
    borderRadius: "10px",
    position: "relative",
    marginTop: "20px",
    "& button": {
      width: "100%",
      padding: "5px 15px",
    },
    "& div": {
      backgroundColor: "#fffffe !important",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      }
    },
    "& h4": {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      color: "#565a57",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      textTransform: "none",
      flexWrap: "wrap",
      margin: "0",
      width: "100%",
      "& img": {
        width: "24px",
        marginRight: "12px",
      },
      "& svg": {
        width: "24px",
        marginRight: "12px",
      }
    },
  },
  triangle: {
    position: "absolute",
    width: "16px",
    height: "16px",
    borderBottom: "1px solid transparent",
    borderRight: "1px solid transparent",
    borderTop: "1px solid rgba(0,0,0,0.25)",
    borderLeft: "1px solid rgba(0,0,0,0.25)",
    transform: "rotate(45deg)",
    backgroundColor: "#fffffe",
    top: "-9px",
    left: "20px",
  },
  postCardInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 0",
    margin: "0 15px",
    borderBottom: "1px solid #dcdcd7",
    "& div": {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      
      color: "#6e7370",
      maxWidth: "33.33%",
      flex: "0 0 33.33%",
      "& h3": {
        margin: "0 0 0 10px",
      },
      "& svg": {
        color: "#1f7fb6"
      }
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#6e7370 !important",
      margin: "0 !important",
      padding: "0 !important",
      border: "none !important"
    },
    "& h3": {
      margin: "0",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#1f7fb6",
      maxWidth: "45%",
      flex: "0 0 45%",
      justifyContent: "flex-end",
    },
  },
  paidCardText: {
    lineHeight: "1.3",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& img": {
      width: "18px",
      marginRight: "10px",
    }
  },
  likeCommentShare: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 0",
    margin: "0 15px",
    "& button": {
      padding: "0",
      margin: "0",
      color: "#6e7370",
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      height: "20px",
      borderRadius: "0",
      "& p": {
        margin: "0 0 0 5px",
      }
    }
  },
  metaBox: {
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    padding: '12px 0',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: "'Roboto', sans-serif",
    position:'absolute',
    top:'auto',
    bottom:'100%',
  },
  postCard: {
    boxShadow: "none",
    margin: "0 auto 25px",
    borderRadius: "10px",
    maxWidth: "80%",
    "& .MuiCardHeader-root": {
      padding: "10px",
      justifyContent: "space-beetween",
      "& .MuiCardHeader-avatar": {
        marginRight: "15px",
        "& .MuiAvatar-root": {
          width: "45px",
          height: "45px",
        }
      },
      "& .MuiCardHeader-content": {
        "& .MuiCardHeader-title": {
          fontSize: "16px",
          color: "#000307",
          fontWeight: "700",
          lineHeight: "1",
          paddingBottom: "5px",
        },
        "& .MuiCardHeader-subheader": {
          fontSize: "13px",
          color: "#888b87",
          fontWeight: "500",
          lineHeight: "1",
          paddingBottom: "0",
        }
      },
      "& .MuiCardHeader-action": {
        margin: "0",
        "& button": {
          color: "#1f7fb6",
          padding: "7px",
          height: "40px",
          width: "40px",
          backgroundColor: "transparent",
          border: "none"
        }
      }
    },
    "& .MuiCardHeader-content": {
      "& .MuiCardHeader-title": {
        fontSize: "16px",
        color: "#000307",
        fontWeight: "700",
        lineHeight: "1",
        paddingBottom: "5px",
      },
      "& .MuiCardHeader-subheader": {
        fontSize: "13px",
        color: "#888b87",
        fontWeight: "500",
        lineHeight: "1",
        paddingBottom: "0",
      }
    },
    "& .MuiCardHeader-action": {
      margin: "0",
      "& button": {
        color: "#1f7fb6",
        padding: "7px",
        height: "40px",
        width: "40px",
        backgroundColor: "transparent",
        border: "none"
      }
    },
    "& .MuiCardContent-root": {
      padding: "0",
      "& p": {
        margin: "0 15px 0",
        color: "#6e7370",
        fontSize: "16px",
        fontWeight: "500",
        fontFamily: "'Roboto', sans-serif",
        paddingBottom: "5px",
        lineHeight: "1.4",
      },
      "& span": {
        borderTop: "1px solid #dcdcd7",
        color: "#565a57",
        fontSize: "16px",
        fontWeight: "700",
        display: "block",
        fontFamily: "'Roboto', sans-serif",
        margin: "0 15px 15px",
        paddingTop: "10px",
      }
    }
  },
  deactivateDialog: {
    "& .MuiPaper-root": {
      width: "100%",
    },
    "& *": {
      boxSizing: "border-box"
    },
    "&.MuiDialogContent-root": {
      padding: "8px 34px",
    }
  },
  txtDialog: {
    "& h2": {
      fontSize: "21px",
      color: "#000105",
      fontWeight: "600",
      fontFamily: "'Poppins', sans-serif",
      paddingBottom: "15px",
      margin: "0"
    },
    "& p": {
      fontSize: "15px",
      color: "#6e7370",
      fontWeight: "400",
      fontFamily: "'Roboto', sans-serif",
      margin: "0",
      paddingBottom: "20px",
      lineHeight: "1.3",
      "& span": {
        width: "100%",
        display: "block"
      },
    }
  },
  titleDialog: {
    "& h2": {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "21px",
      color: "#000105",
      fontWeight: "600",
      fontFamily: "'Poppins', sans-serif",
      padding: "0 0 10px",
      width: "100%",
      borderBottom: "2px solid #d8dddd",
      boxSizing: "border-box",
    },
    "& .MuiIconButton-root": {
      padding: "0",
      width: "35px",
      height: "35px",
    },
  },
  subreportTitle: {
    width: '120px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnList: {
    "& .MuiButton-text": {
      width: "100%",
      padding: "10px 6px",
      "& .MuiButton-label": {
        textAlign: "left",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "16px",
        color: "#000105",
        fontWeight: "600",
        fontFamily: "'Roboto', sans-serif",
        textTransform: "none",
        "& .MuiIconButton-root": {
          padding: "0",
          color: "#000105"
        }
      }
    }
  },
  btnAddFriend: {
    minWidth: "127px",
    backgroundColor: "#d8dddd",
    height: "41px",
    fontSize: "16px",
    color: "#1f7fb6",
    fontWeight: "700",
    letterSpacing: "0",
    borderRadius: "55px",
    textAlign: "center",
    textTransform: "none",
    margin: "0 4px",
    padding: "6px 20px",
    "& svg": {
      marginRight: "10px"
    }
  },
  btnChat: {
    margin: "0 4px",
    minWidth: "91px",
    backgroundColor: "#1f7fb6",
    height: "41px",
    fontSize: "16px",
    color: "#ffffff",
    fontWeight: "700",
    letterSpacing: "0",
    border: "1px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1f7fb6",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "5px",
    },
  },
  pointer: {
    cursor: "pointer"
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: 99,
    color: '#1f7fb6'
  }
} as any;
// Customizable Area End
const mapDispatchToProps = (dispatch: any) => {
  return {
    userLogout: () => {
      dispatch(USER_LOGOUT())
    }
  }
}

const mapStateToProps = (state: any) => {
  //replace Reducer name with state.'Your Reducer name' and .property
  return {
  };
};
//@ts-ignore
const HomeWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(SinglePost))
export default withStyles(HomeStyles)(HomeWithRouter)
