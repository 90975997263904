import React from "react"
import ConfirmDeleteDialogController, { Props } from "./ConfirmDeleteDialogController.web"
import { withRouter } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from "@material-ui/icons/Close"
import AnnouncementIcon from '@material-ui/icons/Announcement';

class ConfirmDeleteDialog extends ConfirmDeleteDialogController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        const { classes,isOpen, title, handleCancelDelete, handleConfirmDelete } = this.props

        return (
            // Customizable Area Start
            <Dialog
                fullWidth
                className={classes.logoutModal}
                open={isOpen}
                onClose={handleCancelDelete}
            >
                <DialogTitle className={classes.modalTitle}>
                    Delete {title || "post"}
                    <IconButton className={classes.closeBtn} onClick={handleCancelDelete}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.removeFriend}>
                    <p><AnnouncementIcon />Do you really want to delete this {title || "post"}?</p>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.disagreeBtn}
                        onClick={handleCancelDelete}>
                        Cancel
                    </Button>
                    <Button
                        className={classes.agreeBtn}
                        onClick={handleConfirmDelete}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const DeleteDialogStyles = {
    dialogContent: {
        "& p": {
            fontSize: "20px",
            color: "#6e7370",
            fontWeight: "400",
            fontFamily: "'Roboto', sans-serif",
            margin: "0",
            paddingBottom: "20px",
            lineHeight: "1.3",
            "& span": {
              width: "100%",
              display: "block"
            },
          }
    },
    logoutModal: {
        height: '500px',
        padding: '15px',
        "& .MuiDialog-paper": {
            maxWidth: "350px"
        },
        "& h2": {
            fontSize: "18px",
            color: "#000000",
            fontWeight: "500",
            textAlign: "center",
            padding: "10px",
        },
        "& .MuiButton-text": {
            minWidth: "110px"
        },
        "& .MuiDialogTitle-root": {
            padding: "5px 15px 0"
        },
        "& .MuiDialogActions-root": {
            padding: "5px 15px 15px",
            justifyContent: "space-evenly",
        }
    },
    removeFriend: {
        "& span": {
            fontSize: "16px",
            color: "#000000",
            fontFamily: "'Poppins', sans-serif",
        },
        "& ul": {
            fontSize: "16px",
            color: "#000000",
            fontFamily: "'Poppins', sans-serif",
            margin: "0",
            paddingLeft: "20px",
            paddingBottom: "20px"
        },
        "& p": {
            fontFamily: "'Poppins', sans-serif",
            margin: "0",
            textAlign: "center",
            "& svg": {
            fontSize: "40px",
            color: "#1f7fb6",
            display: "block",
            margin: "0 auto",
            }
        },
    },
    closeBtn: {
        position: "absolute",
        top: "15px",
        background: " #e5e6eb",
        right: "15px",
        padding: "5px",
    },
    disagreeBtn: {
        border: "1px solid #1f7fb6",
        backgroundColor: "#e4e5e4",
        height: "40px",
        borderRadius: "50px",
        boxShadow: "none !important",
        display: "inline-flex",
        alignItems: "center",
        "& .MuiButton-label": {
            fontSize: "16px !important",
            fontWeight: "700 !important",
            color: "#1f7fb6 !important",
            margin: "0 !important",
            textTransform: "none",
        },
        "& svg": {
            marginLeft: "5px",
            height: "23px"
        }
    },
    agreeBtn: {
        border: "1px solid #1f7fb6",
        backgroundColor: "#1f7fb6 !important",
        height: "40px",
        borderRadius: "50px",
        boxShadow: "none !important",
        display: "inline-flex",
        alignItems: "center",
        "& .MuiButton-label": {
            fontSize: "16px !important",
            fontWeight: "700 !important",
            color: "#ffffff !important",
            margin: "0 !important",
            textTransform: "none",
        },
        "& svg": {
            marginLeft: "5px",
            height: "23px"
        }
    }
} as any;
// Customizable Area End
const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}
const mapStateToProps = (state: any) => {
    return {
    }
};

//@ts-ignore
const ConfirmDeleteDialogWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteDialog))
export default withStyles(DeleteDialogStyles)(ConfirmDeleteDialogWithRouter)
