import React from "react";

// Customizable Area Start
import HomeController, {
  Props
} from "./HomeController.web";
import Navbar from "./Navbar.web"
import Sidebar from './Sidebar.web'
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import {
  Menu,
  MenuList,
  MenuButton,
  MenuPopover,
  MenuItem
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FriendsWeb from "../../TargetedFeed/src/Friends.web";
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import ReportIcon from '@material-ui/icons/Report';
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from "@material-ui/core/TextField";
import { Send } from "@material-ui/icons";
import FavoriteIcon from '@material-ui/icons/Favorite';
import { connect } from "react-redux";
import Cookies from "js-cookie";
import LoopIcon from '@material-ui/icons/Loop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from "@material-ui/icons/Close";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import ConfirmDeleteDialog from './ConfirmDeleteDialog.web'
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import LinkShareWeb from "../../LinkShare/src/LinkShare.web";
//@ts-ignore
import { GET_INDEXPOST, DELETE_COMMENT,EMPTY_INDEX_POST, EDIT_COMMENT, DELETE_POST, UPDATE_POST_DESC, USER_LOGOUT, USER_CHANGE, UPDATE_POST, GET_INDEXTEMPPOST, DELETE_tEMPPOST, GET_TEMPCOMMENT, CREATE_COMMENT, GET_CLIENT, GET_ACTIVEUSERS, DELETE_ACTIVEUSERS, DELETE_LASTMESG, DELETE_CHAT_USERLIST, GET_CHAT_USERLIST, GET_LASTMESG, SET_PROFILE } from "../../../web/src/allActions.js";
// Customizable Area End
class Home extends HomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes, profileData: {
      profileImage
    } } = this.props

    const {
      reportDialog,
      confirmReportDialog,
      reportDialogMessage,
      reportReasons,
      reportSubReasons,
      postReportData,
      stepsEnabled,
      steps,
      initialStep,
    } = this.state

    // Customizable Area Start
    const handleUserRedirect = (value: string | number, isUserName: boolean) => {
      if (isUserName) {
        this.props.history.push({
          pathname: '/' + value,
          state: { detail: value }
        })
      } else {
        this.props.history.push({
          pathname: '/user/' + value,
          state: { detail: value }
        })
      }
    }

    const postRedirect = (postId: number) => {
      this.props.history.push({
        pathname: '/post/' + postId,
        state: { detail: postId }
      })
    }

    const handleConfirmReport = (reason: string, subReasons?: any, reportReasonId = 0) => {
      if (subReasons?.length > 0) {
        this.setState({
          ...this.state,
          reportSubReasons: subReasons,
        })
      } else {
        this.setState({
          ...this.state,
          confirmReportDialog: true,
          reportDialog: false,
          reportDialogMessage: reason,
          postReportData: {
            ...postReportData,
            report_reason_id: reportReasonId
          }
        })
      }
    }
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={() => this.handleHomeTutorialsClose()}
          options={{
            hidePrev: true,
            doneLabel: "Finish",
            keyboardNavigation: true
          }}
        />
        <Navbar />
        <div className={classes.container}>
          <div style={{ display: 'flex', justifyContent: "space-between", }}>
            <Sidebar
              //@ts-ignore
              FromHome={"FromHome"} />
            <div style={{ flex: "1 1 100%" }}>
              <div className={classes.bottomBoxBg}>
                {this.state.myAllPost.length !== 0 ? (
                  this.state.myAllPost.map((element: any, index) => {
                    const elements = element
                    const { attributes: {
                      animation_url,
                      image_url,
                      is_current_owner,
                      user_name,
                      id: postId,
                      description,
                      time,
                      nft_name,
                      comments_count,
                      likes_count,
                      usd_amount,
                      eth_amount,
                      is_liked,
                      comments,
                      account: {
                        image,
                        id,
                        full_name
                      }
                    } } = elements
                    return (
                      <Card className={classes.postCard} key={index}>
                        {!!elements?.meta &&
                          <div className={classes.metaBox}>
                            <Avatar
                              sizes="10"
                              className={`${classes.pointer} ${classes.metaAvatar}`}
                              src={elements.meta?.action_by?.data?.attributes?.image || "https://www.studyheights.com/assets/img/Universitylogo/default.png"} aria-label="recipe"
                              onClick={() => {
                                const loginId = Cookies.get('Login_Id')
                                if (loginId != id) {
                                  if (!!elements.meta?.action_by?.data?.attributes?.username) {
                                    handleUserRedirect(elements.meta?.action_by?.data?.attributes?.username, true)
                                  } else {
                                    handleUserRedirect(elements.meta?.action_by?.data?.id, false)
                                  }
                                } else {
                                  this.props.history.push('/myprofile')
                                }
                              }}>
                            </Avatar>{elements.meta.message}
                          </div>}
                        <CardHeader
                          avatar={
                            <Avatar
                              className={classes.pointer}
                              src={image || "https://www.studyheights.com/assets/img/Universitylogo/default.png"} aria-label="recipe"
                              onClick={() => {
                                const loginId = Cookies.get('Login_Id')
                                if (loginId != id) {
                                  if (!!user_name) {
                                    handleUserRedirect(user_name, true)
                                  } else {
                                    handleUserRedirect(id, false)
                                  }
                                } else {
                                  this.props.history.push('/myprofile')
                                }
                              }}>
                            </Avatar>
                          }
                          action={
                            <Menu>
                              <MenuButton>
                                <MoreHorizIcon />
                              </MenuButton>
                              <MenuList className={classes.menuListData} style={{ width: "200px" }}>
                                <span className={classes.triangle}></span>
                                {Cookies.get('Login_Id') != id ?
                                  <MenuItem onSelect={() => this.setState({ ...this.state, reportDialog: true, postReportData: { ...postReportData, post_id: postId } })}>
                                    <h4><ReportIcon />Report Post</h4>
                                  </MenuItem>
                                  :
                                  <>
                                    <MenuItem onSelect={() => this.setState({ editPostId: postId, editPostDesc: description })}>
                                      <h4><EditOutlinedIcon />Edit</h4>
                                    </MenuItem>
                                    <MenuItem onSelect={() => this.handleDeleteDialogToogle(postId)}>
                                      <h4><DeleteOutlineOutlinedIcon />Delete</h4>
                                    </MenuItem>
                                  </>}
                              </MenuList>
                            </Menu>
                          }
                          title={<span
                            className={classes.pointer}
                            onClick={() => {
                              const loginId = Cookies.get('Login_Id')
                              if (loginId != id) {
                                if (!!user_name) {
                                  handleUserRedirect(user_name, true)
                                } else {
                                  handleUserRedirect(id, false)
                                }
                              } else {
                                this.props.history.push('/myprofile')
                              }
                            }}>{full_name}</span>}
                          subheader={time}
                        />
                        <CardContent>
                          <div>
                            {this.state.editPostId === postId ? (
                              <form className={classes.discrtxt} onSubmit={(e) => e.preventDefault()}>
                                <div style={{ display: 'flex' }} onClick={(event) => event?.stopPropagation()}>
                                  <TextField
                                    id="editBio"
                                    fullWidth
                                    variant="standard"
                                    autoComplete="off"
                                    placeholder="Write something here..."
                                    autoFocus={this.state.editPostId === postId}
                                    defaultValue={description}
                                  />
                                  <Button
                                    className={classes.btnSubmit}
                                    type='submit'
                                    onClick={() => this.updatePost(postId)}>
                                    Save
                                  </Button>
                                </div>
                              </form>
                            ) : (
                              <p className={classes.pointer} onClick={() => postRedirect(postId)}>{description || ''}</p>
                            )}
                            {nft_name &&
                            <span className={classes.pointer} onClick={() => postRedirect(postId)}>{nft_name || ''}</span>}
                            {animation_url ? <>
                              {this.postTypeCheck(animation_url) === "audio" &&
                                <audio
                                  className={classes.pointer}
                                  onClick={() => postRedirect(postId)} style={{ width: '100%', objectFit: 'inherit' }} controls>
                                  <source src={animation_url} type="audio/mp3" />
                                  <source src={animation_url} type="audio/wav" />
                                  Your browser does not support the audio elements.
                                </audio> }
                              {this.postTypeCheck(animation_url) === "video" &&
                                <video
                                  className={classes.pointer}
                                  onClick={() => postRedirect(postId)}
                                  style={{ width: '100%', height: '530px', objectFit: 'inherit' }} controls>
                                  <source src={animation_url} type="video/mp4" />
                                  <source src={animation_url} type="video/ogg" />
                                  <source src={animation_url} type="video/webm" />
                                  Your browser does not support the video elements.
                                </video> }
                              {this.postTypeCheck(animation_url) === "iframe" &&
                                <iframe
                                  className={classes.pointer}
                                  onClick={() => postRedirect(postId)}
                                  style={{ width: '100%', height: '530px', objectFit: 'inherit' }} src={animation_url} /> }
                              {this.postTypeCheck(animation_url) === "3dObject" &&
                                <img
                                  className={classes.pointer}
                                  onClick={() => postRedirect(postId)}
                                  style={{ width: '100%', height: '530px', objectFit: 'inherit' }}
                                  src={image_url} />}
                            </> :
                              image_url &&
                              <img
                                className={classes.pointer}
                                onClick={() => postRedirect(postId)}
                                style={{ width: '100%', height: '530px', objectFit: 'inherit' }}
                                src={image_url} />}
                            <div className={classes.postCardInfo}>
                              <div>
                                <FavoriteOutlinedIcon />
                                <h3>
                                  {likes_count}
                                </h3>
                              </div>
                              <p onClick={(e) => {
                                e.stopPropagation()
                                this.handleCommentsToggle(`post${postId}`)
                              }}>
                                {comments_count} comments
                              </p>
                              {image_url && <h3 className={`${classes.paidCardText} ${!is_current_owner && classes.soldNFT}`}>
                                <img src="https://img.icons8.com/ios/24/000000/price-tag--v1.png" />
                                {`Paid $ ${usd_amount || 0}(${eth_amount || 0} ETH)`}
                              </h3>}
                            </div>
                          </div>
                        </CardContent>
                        <div className={classes.likeCommentShare}>
                          <IconButton className={classes.txtHover} aria-label="add to favorites"
                            onClick={() => this.likePost(postId, is_liked, index)}>
                            <FavoriteBorderOutlinedIcon
                              style={is_liked ? { color: '#0388c9' } : { color: 'grey' }} id="likePost" />
                            <p>{is_liked ? 'Unlike' : 'Like'}</p>
                          </IconButton>
                          <IconButton className={classes.txtHover} aria-label="comment" onClick={() => {
                            this.handleCommentsToggle(`post${postId}`)
                          }}>
                            <ModeCommentOutlinedIcon /><p>Comment</p>
                          </IconButton>
                        </div>

                        <div className={classes.commentBlockWrapper} id={`post${postId}`} style={{ display: 'none' }}>
                          <FormControl className={classes.selectComment}>
                            <InputLabel></InputLabel>
                          </FormControl>
                          <div className={classes.commentBlock}>
                            <Avatar aria-label="recipe" src={profileImage || "https://www.studyheights.com/assets/img/Universitylogo/default.png"}>
                            </Avatar>
                            <TextField
                              className={classes.commentBar}
                              placeholder="Write a comment"
                              id={"comentBox" + index}
                            />
                            <IconButton
                              style={HomeStyles.sendButton} onClick={() => this.createComment(elements.attributes.id, index)}>
                              <Send style={HomeStyles.sendIcon} />
                            </IconButton>
                          </div>
                          {comments?.data?.length !== 0 && (
                            comments?.data?.map((comment: any, commentIndex: any) => {

                              return (
                                <div className={classes.commentBlock} key={commentIndex}>
                                  <Avatar aria-label="recipe" src={comment.attributes.account.image}>
                                  </Avatar>
                                  <div className={classes.commentWrap}>
                                    <p onClick={() => {
                                      this.props.history.push({
                                        pathname: '/post/' + elements.attributes.id,
                                        state: { detail: elements.attributes.id }
                                      })
                                    }}>
                                      <span >{comment.attributes.account.full_name}</span>
                                      {this.state.editCommentId === comment.attributes.id ?
                                        <TextField
                                          id="editCommentBox"
                                          fullWidth
                                          variant="standard"
                                          autoComplete="off"
                                          placeholder="Write a comment"
                                          autoFocus={this.state.editCommentId === comment.attributes.id}
                                          defaultValue={comment.attributes.comment}
                                          onClick={(e) => e.stopPropagation()}
                                        />
                                        : comment.attributes.deleted ? 'This comment was deleted' : comment.attributes.comment}
                                    </p>
                                    <ul className={classes.listComment} >
                                      <li>
                                        <Button onClick={() => this.likeOrDislikeComment(comment.attributes.id, comment.attributes.is_liked, index)}>
                                          {comment.attributes.is_liked ? (<div style={{ color: '#1f7fb6' }}>Dislike</div>) : 'Like'}
                                        </Button>
                                      </li>
                                      <li>
                                        <Button key={commentIndex + comment + index + commentIndex} onClick={() => {
                                          this.props.history.push({
                                            pathname: '/post/' + elements.attributes.id,
                                            state: { detail: elements.attributes.id }
                                          })
                                        }}>
                                          Reply
                                        </Button>
                                      </li>
                                      <li style={{ color: '#1f7fb6' }}><FavoriteIcon style={{ fontSize: 'larger' }} /> ({comment.attributes.likes_count})&nbsp;</li>
                                      <li>{comment.attributes.time}</li>
                                      {Cookies.get('Login_Id') == comment.attributes.account_id && !comment.attributes.deleted && (
                                        <>
                                          <li>
                                            {this.state.editCommentId === comment.attributes.id ?
                                              <Button onClick={() => this.editComment(postId)}>
                                                Save
                                              </Button>
                                              : <Button onClick={() => this.setState({ editCommentId: comment.attributes.id })}>
                                                Edit
                                              </Button>}
                                          </li>
                                          <li>
                                            <Button onClick={() => this.handleDeleteCommentToogle(comment.attributes.id)}>
                                              Delete
                                            </Button>
                                          </li></>)}
                                    </ul>
                                    {this.state.showReplyInbox === comment.attributes.id ? (
                                      <div className={classes.replyCommentBlock}>

                                        <div className={classes.commentBlock}>
                                          {this.state.allRepliedComments.length !== 0 && (
                                            this.state.allRepliedComments.map((replyComment: any, replyIndex: any) => {
                                              return (
                                                <span key={replyIndex + replyComment}>
                                                  <Avatar aria-label="recipe" src={replyComment.attributes.account.image}>
                                                  </Avatar>
                                                  <div className={classes.commentWrap} key={replyIndex + replyComment + replyIndex}>
                                                    <p key={replyIndex + replyComment + replyComment}>
                                                      <span key={replyIndex + replyComment + replyIndex + replyIndex}>{replyComment.attributes.account.full_name}</span>
                                                      {replyComment.attributes.comment}
                                                    </p>
                                                    <ul className={classes.listComment} key={replyIndex + replyComment + replyIndex + replyComment}>
                                                      <li key={replyComment}>
                                                        <Button key={replyIndex + replyComment + replyComment + replyIndex} onClick={() => this.likeOrDislikeComment(replyComment.attributes.id, replyComment.attributes.is_liked)}>
                                                          {replyComment.attributes.is_liked ? (<div style={{ color: '#1f7fb6' }}>Dislike</div>) : 'Like'}
                                                        </Button>
                                                      </li>
                                                      <li key={replyIndex + replyComment + index}>
                                                        <Button key={replyIndex + replyComment + index + replyIndex} >
                                                          Reply
                                                        </Button>
                                                      </li>
                                                      <li style={{ color: '#1f7fb6' }} key={replyIndex + replyComment + index + replyComment + index + index}>{replyComment.attributes.likes_count} &nbsp;<FavoriteIcon style={{ fontSize: 'larger' }} /></li>
                                                      <li key={replyIndex + replyComment + index + replyComment}>{replyComment.attributes.time}</li>
                                                    </ul>
                                                  </div>
                                                </span>
                                              )
                                            })
                                          )}
                                          <Avatar aria-label="recipe" src={profileImage || "https://www.studyheights.com/assets/img/Universitylogo/default.png"}>
                                          </Avatar>
                                          <TextField
                                            className={classes.commentBar}
                                            placeholder="Write a comment"
                                            id={"comentReplyBox" + index + commentIndex}
                                          />
                                          <IconButton
                                            style={HomeStyles.sendButton} onClick={() => this.createReplyComment(elements.attributes.id, index, commentIndex, comment.attributes.id)}>
                                            <Send style={HomeStyles.sendIcon} />
                                          </IconButton>
                                        </div>
                                      </div>
                                    ) : ('')}
                                  </div>
                                </div>
                              )
                            })
                          )}
                          {(comments?.data?.length !== 0 && comments?.data?.length !== undefined && comments?.data?.length !== '' && comments_count > 4) && (
                            <div className={classes.paginationBlock}>
                              <Button
                                onClick={() => {
                                  this.props.history.push({
                                    pathname: '/post/' + postId,
                                    state: { detail: postId }
                                  })
                                }}
                              >See more comments</Button>
                              <div className={classes.paginationBlockWrapper}>
                                <span>{comments?.data?.length}</span> of <span>{comments_count}</span>
                              </div>
                            </div>)
                          }
                        </div>

                      </Card>
                    )
                  })) : (
                  this.state.loading ? <CircularProgress className={classes.loader} /> :
                    <h4 className={classes.poppinsFont} style={{textAlign: "center"}}>Invite friends to showcase their NFTs</h4>)}
                {(this.state.myAllPost.length !== 0 && this.state.hideData) && (<div className={classes.btnWrap}><Button variant="outlined" id="loadMore" className={classes.btnLoadMore} onClick={() => this.getAllMyNftPost(1, 2)}><LoopIcon /> &nbsp;Load More</Button></div>)}

              </div>
            </div>
            <FriendsWeb
              //@ts-ignore
              pageState={this.state.pageState}
            />

          </div>
        </div>
        <ConfirmDeleteDialog
          //@ts-ignore
          title={"comment"}
          isOpen={this.state.deleteCommentDialog}
          handleCancelDelete={this.handleDeleteCommentToogle}
          handleConfirmDelete={this.deleteComment}
        />
        <LinkShareWeb
          //@ts-ignore
          open={this.state.shareDialog}
          url={this.state.shareLinkURL}
          text={this.state.shareLinkText}
          handleClose={this.handleShareLinkClose}
        />
        <ConfirmDeleteDialog
          //@ts-ignore
          isOpen={this.state.deleteDialog}
          handleCancelDelete={this.handleDeleteDialogToogle}
          handleConfirmDelete={this.deletePost}
        />
        <Dialog
          open={reportDialog}
          onClose={() => this.setState({
            ...this.state,
            reportDialog: false,
            reportSubReasons: [],
            postReportData: {
              post_id: 0,
              report_reason_id: 0
            }
          })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.deactivateDialog}
        >
          <DialogTitle className={classes.titleDialog} id="alert-dialog-title">
            <span className={classes.subreportTitle}>
              {reportSubReasons?.length > 0 &&
                <IconButton onClick={() => this.setState({
                  ...this.state,
                  reportSubReasons: [],
                  postReportData: {
                    post_id: 0,
                    report_reason_id: 0
                  }
                })}>
                  <ArrowBackRoundedIcon />
                </IconButton>}
              Report
            </span>
            <IconButton onClick={() => this.setState({
              ...this.state,
              reportDialog: false,
              reportSubReasons: [],
              postReportData: {
                post_id: 0,
                report_reason_id: 0
              }
            })}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.txtDialog}>
            <h2>Report post</h2>
            <p>
              Help us understand what is wrong with this post. How would you describe it?
            </p>
            <div className={classes.btnList}>
              {reportSubReasons?.length > 0 ? reportSubReasons?.map((subReason: any) => {
                const { data: { attributes } } = subReason
                return (
                  <Button key={attributes?.id} onClick={() => handleConfirmReport(attributes?.content, attributes?.sub_reasons, attributes?.id)}>
                    {attributes?.content}
                    <IconButton>
                      <ChevronRightIcon />
                    </IconButton>
                  </Button>
                )
              }) :
                reportReasons?.map((reason: any) => {
                  const { attributes } = reason
                  return (
                    <Button key={attributes.id} onClick={() => handleConfirmReport(attributes.content, attributes?.sub_reasons, attributes?.id)}>
                      {attributes.content}
                      <IconButton>
                        <ChevronRightIcon />
                      </IconButton>
                    </Button>
                  )
                })
              }
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={confirmReportDialog}
          onClose={() => this.setState({
            ...this.state, confirmReportDialog: false, reportDialog: true, postReportData: {
              post_id: 0,
              report_reason_id: 0
            }
          })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.deactivateDialog}
        >
          <DialogTitle className={classes.titleDialog} id="alert-dialog-title">
            Please confirm your submission
            <IconButton onClick={() => this.setState({
              ...this.state, confirmReportDialog: false, reportDialog: true, postReportData: {
                post_id: 0,
                report_reason_id: 0
              }
            })}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ textAlign: "center", paddingBottom: "20px" }}>
            <Button className={classes.btnAddFriend}><ReportIcon />{reportDialogMessage}</Button>
          </DialogContent>
          <DialogActions>
            <Button className={classes.btnChat} onClick={() => this.handlePostReport()}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
const HomeStyles = {
  txtHover: {
    "&:hover": {
      background: "transparent",
      color: "#1f7fb6"
    }
  },
  btnWrap: {
    width: "100%",
    textAlign: "center"
  },
  btnLoadMore: {
    margin: "0 4px",
    minWidth: "91px",
    backgroundColor: "#1f7fb6",
    height: "41px",
    fontSize: "16px",
    color: "#ffffff",
    fontWeight: "700",
    letterSpacing: "0",
    border: "1px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1f7fb6",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "5px",
    },
  },
  MuiBoxRoot: {
    flexGrow: "unset",
  },
  container: {
    padding: "0 20px",
    overflowX: "hidden",
    height: "100%",
    backgroundColor: "#f1f2f6",
  },
  boxBg: {
    backgroundColor: "#fff",
    fontFamily: "'Poppins', sans-serif",
    height: "100%",
    margin: "20px 0",
    padding: "10px 15px",
    borderRadius: "10px",
    boxShadow: "1px 1px 3px rgb(0 0 0 / 20%)",
    "& h2": {
      fontSize: "20px",
      fontWeight: "600",
      margin: "0"
    }
  },
  selectComment: {
    width: "100%",
    "& .MuiInputBase-root": {
      maxWidth: "200px",
      width: "100%",
      marginLeft: "auto",
      margin: 0,
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      }
    }
  },
  commentBlockWrapper: {
    borderTop: "1px solid #dcdcd7",
    margin: "0 15px 0",
    paddingTop: "10px",
    "& .MuiSelect-selectMenu": {
      textAlign: "right",
      fontSize: "11px",
      color: "#404341",
      fontWeight: 700,
      "&:focus": {
        background: "transparent",
      },
      "& em": {
        opacity: "0.7"
      }
    },
    "& .MuiSelect-icon": {
      color: "#404341"
    }
  },
  sendButton: { backgroundColor: "#1f7fb6", width: "40px", padding: "10px", height: "40px", marginLeft: "10px" },
  sendIcon: { color: "#ffffff", width: "auto", height: "auto", },
  listComment: {
    margin: "0",
    marginTop: "5px",
    padding: "0",
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    color: "#404341",
    "& li": {
      fontSize: "11px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      padding: "0 5px",
      display: "inline-flex",
      alignItems: "center",
    },
    "& button": {
      margin: "0",
      padding: "0",
      textAlign: "left",
      minWidth: "auto",
      "& span": {
        fontSize: "11px",
        fontWeight: "700",
        fontFamily: "'Roboto', sans-serif",
        textTransform: "none",
      },
      "&:hover": {
        backgroundColor: "none"
      }
    }
  },
  paginationBlock: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 0 15px",
    alignItems: "center",
    "& button": {
      padding: "0",
      margin: "0",
      color: "#6e7370",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "transparent"
      }
    }
  },
  paginationBlockWrapper: {
    color: "#6e7370",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "'Roboto', sans-serif",
  },
  commentWrap: {
    maxWidth: "calc(100% - 47px)",
    flex: "0 0 calc(100% - 47px)",
    "& p": {
      backgroundColor: "#ebecec",
      borderRadius: "30px",
      padding: "5px 15px",
      color: "#6e7370",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      width: "100%",
      maxWidth: "100%",
      boxSizing: "border-box",
      margin: "0",
      "& span": {
        paddingBottom: "3px",
      }
    },
    "& span": {
      color: "#000307",
      fontSize: "11px",
      display: "block",
      paddingBottom: "0",
      margin: "0",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
    },
  },
  deletedComment: {
    "& span": {
      fontStyle: "italic"
    }
  },
  commentBar: {
    margin: "2px 0 2px 10px",
    position: "relative",
    maxWidth: "calc(100% - 95px)",
    flex: "0 0 calc(100% - 95px)",
    "&::before": {
      position: "absolute",
      content: "",
      height: "10px",
      width: "2px",
      backgroundColor: "#ffffff",
      left: "0px"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ebecec",
      color: "#6e7370",
      borderRadius: "30px",
      height: "38px",
      fontWeight: "500",
      padding: "0 15px",
      cursor: "pointer",
      fontSize: "14px",
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    }
  },
  commentBlock: {
    display: "flex",
    flexWrap: "wrap",
    padding: "7px 0",
    justifyContent: "space-between",
    "& .MuiAvatar-root": {
      width: "35px",
      height: "35px",
      marginTop: "5px",
    }
  },
  menuListData: {
    whiteSpace: "pre-wrap",
    width: "346px",
    backgroundColor: "#fffffe",
    borderRadius: "10px",
    position: "relative",
    marginTop: "10px",
    "& div": {
      backgroundColor: "#fffffe !important",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      }
    },
    "& button": {
      width: "100%",
      padding: "5px 15px",
    },
    "& h4": {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      color: "#565a57",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      textTransform: "none",
      flexWrap: "wrap",
      margin: "0",
      width: "100%",
      "& img": {
        width: "24px",
        marginRight: "12px",
      },
      "& svg": {
        width: "24px",
        marginRight: "12px",
      }
    },
  },
  triangle: {
    position: "absolute",
    width: "16px",
    height: "16px",
    borderBottom: "1px solid transparent",
    borderRight: "1px solid transparent",
    borderTop: "1px solid rgba(0,0,0,0.25)",
    borderLeft: "1px solid rgba(0,0,0,0.25)",
    transform: "rotate(45deg)",
    backgroundColor: "#fffffe",
    top: "-9px",
    left: "20px",
  },
  postCardInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 0",
    margin: "0 15px",
    borderBottom: "1px solid #dcdcd7",
    "& div": {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#6e7370",
      maxWidth: "33.33%",
      flex: "0 0 33.33%",
      "& h3": {
        margin: "0 0 0 10px",
      },
      "& svg": {
        color: "#1f7fb6"
      }
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#6e7370 !important",
      margin: "0 !important",
      padding: "0 !important",
      border: "none !important"
    },
    "& h3": {
      margin: "0",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#1f7fb6",
      maxWidth: "45%",
      flex: "0 0 45%",
      justifyContent: "flex-end",
    },
  },
  soldNFT: {
    color: "#9a9a9a!important"
  },
  mainContent: {
    height: "80vh",
    width: "50%",
    margin: "20px 30px",
  },
  friends: {
    width: "220px",
    color: "#696a6e",
  },
  bottomBoxBg: {
    maxWidth: "100%",
    margin: "0 auto",
    padding: '15px',
    "& .MuiBox-root": {
      padding: 0,
    }
  },
  paidCardText: {
    lineHeight: "1.3",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& img": {
      width: "18px",
      marginRight: "10px",
    }
  },
  likeCommentShare: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 0",
    margin: "0 15px",
    "& button": {
      padding: "0",
      margin: "0",
      color: "#6e7370",
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      height: "20px",
      borderRadius: "0",
      "& p": {
        margin: "0 0 0 5px",
      }
    }
  },
    metaBox: {
      display: 'flex',
      alignItems: 'center',
      color: '#000',
      padding: '12px 0',
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: "'Roboto', sans-serif",
      position:'absolute',
      top:'auto',
      bottom:'100%',
    },
    postCard: {
      boxShadow: "none",
      marginBottom: "25px",
      borderRadius: "10px",
      maxWidth: "80%",
      margin: "45px auto 25px",  
      position:'relative',
      overflow:'visible',
      "& .MuiCardHeader-root": {
        padding: "10px",
        justifyContent: "space-between",
        "& .MuiCardHeader-avatar": {
          marginRight: "15px",
          cursor: "pointer",
          "& .MuiAvatar-root": {
            width: "45px",
            height: "45px",
          }
        },
        "& .MuiCardHeader-content": {
          "& .MuiCardHeader-title": {
            fontSize: "16px",
            color: "#000307",
            fontWeight: "700",
            lineHeight: "1",
            paddingBottom: "5px",
            cursor: "pointer",
          },
          "& .MuiCardHeader-subheader": {
            fontSize: "13px",
            color: "#888b87",
            fontWeight: "500",
            lineHeight: "1",
            paddingBottom: "0",
          }
        },
        "& .MuiCardHeader-action": {
          margin: "0",
          "& button": {
            color: "#1f7fb6",
            padding: "7px",
            height: "40px",
            width: "40px",
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }
        }
      },
      "& .MuiCardHeader-content": {
        "& .MuiCardHeader-title": {
          fontSize: "16px",
          color: "#000307",
          fontWeight: "700",
          lineHeight: "1",
          paddingBottom: "5px",
          cursor: "pointer",
        },
        "& .MuiCardHeader-subheader": {
          fontSize: "13px",
          color: "#888b87",
          fontWeight: "500",
          lineHeight: "1",
          paddingBottom: "0",
        }
      },
      "& .MuiCardHeader-action": {
        margin: "0",
        "& button": {
          color: "#1f7fb6",
          padding: "7px",
          height: "40px",
          width: "40px",
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
        }
      },
      "& .MuiCardContent-root": {
        padding: "0",
        "& p": {
          margin: "0 15px 0",
          color: "#6e7370",
          fontSize: "16px",
          fontWeight: "500",
          fontFamily: "'Roboto', sans-serif",
          paddingBottom: "5px",
          lineHeight: "1.4",
        },
        "& span": {
          borderTop: "1px solid #dcdcd7",
          color: "#565a57",
          fontSize: "16px",
          fontWeight: "700",
          display: "block",
          fontFamily: "'Roboto', sans-serif",
          margin: "0 15px 15px",
          paddingTop: "10px",
        }
      }
  },
  deactivateDialog: {
    "& .MuiPaper-root": {
      width: "100%",
    },
    "& *": {
      boxSizing: "border-box"
    },
    "&.MuiDialogContent-root": {
      padding: "8px 34px",
    }
  },
  txtDialog: {
    "& h2": {
      fontSize: "21px",
      color: "#000105",
      fontWeight: "600",
      fontFamily: "'Poppins', sans-serif",
      paddingBottom: "15px",
      margin: "0"
    },
    "& p": {
      fontSize: "15px",
      color: "#6e7370",
      fontWeight: "400",
      fontFamily: "'Roboto', sans-serif",
      margin: "0",
      paddingBottom: "20px",
      lineHeight: "1.3",
      "& span": {
        width: "100%",
        display: "block"
      },
    }
  },
  titleDialog: {
    "& h2": {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "21px",
      color: "#000105",
      fontWeight: "600",
      fontFamily: "'Poppins', sans-serif",
      padding: "0 0 10px",
      width: "100%",
      borderBottom: "2px solid #d8dddd",
      boxSizing: "border-box",
    },
    "& .MuiIconButton-root": {
      padding: "0",
      width: "35px",
      height: "35px",
    },
  },
  subreportTitle: {
    width: '120px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnList: {
    "& .MuiButton-text": {
      width: "100%",
      padding: "10px 6px",
      "& .MuiButton-label": {
        textAlign: "left",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "16px",
        color: "#000105",
        fontWeight: "600",
        fontFamily: "'Roboto', sans-serif",
        textTransform: "none",
        "& .MuiIconButton-root": {
          padding: "0",
          color: "#000105"
        }
      }
    }
  },
  btnAddFriend: {
    minWidth: "127px",
    backgroundColor: "#d8dddd",
    height: "41px",
    fontSize: "16px",
    color: "#1f7fb6",
    fontWeight: "700",
    letterSpacing: "0",
    borderRadius: "55px",
    textAlign: "center",
    textTransform: "none",
    margin: "0 4px",
    padding: "6px 20px",
    "& svg": {
      marginRight: "10px"
    }
  },
  btnChat: {
    margin: "0 4px",
    minWidth: "91px",
    backgroundColor: "#1f7fb6",
    height: "41px",
    fontSize: "16px",
    color: "#ffffff",
    fontWeight: "700",
    letterSpacing: "0",
    border: "1px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1f7fb6",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "5px",
    },
  },
  pointer: {
    cursor: "pointer"
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    color: '#0388c9',
    zIndex: 99,
  },
  poppinsFont: {
    fontFamily: "'Poppins', sans-serif",
  },
  btnSubmit: {
    border: "1px solid #1f7fb6",
    backgroundColor: "#e4e5e4",
    height: "40px",
    borderRadius: "50px",
    boxShadow: "none !important",
    display: "inline-flex",
    alignItems: "center",
    "& .MuiButton-label": {
      fontSize: "16px !important",
      fontWeight: "700 !important",
      color: "#1f7fb6 !important",
      margin: "0 !important",
      textTransform: "none",
    },
    "& svg": {
      marginLeft: "5px",
      height: "23px"
    }
  },
  discrtxt: {
    padding: "5px 10px 15px",
    "& .MuiInput-underline": {
      "&:after": {
        display: "none"
      },
      "&:before": {
        display: "none"
      }
    },
    "& .MuiFormControl-root": {
      marginRight: "10px",
    },
    "& .MuiInputBase-input": {
      height: "40px",
      padding: "9px 10px",
      border: "1px solid #cccccc",
      borderRadius: "5px",
      boxSizing: "border-box",
      fontSize: " 14px",
      fontWeight: 500,
    },
  },
  metaAvatar: {
    height: '24px',
    width: '24px',
    marginRight: '7px'
  }
} as any;
<style>

</style>
// Customizable Area End
const mapDispatchToProps = (dispatch: any) => {
  return {
    addPosts: (post: any) => {
      dispatch(GET_INDEXPOST(post));
    },
    userLogout: () => {
      dispatch(USER_LOGOUT())
    },
    userChange: () => {
      dispatch(USER_CHANGE())
    },
    updatePost: (post: any) => {
      dispatch(GET_INDEXTEMPPOST(post))
    },
    updateData: () => {
      dispatch(UPDATE_POST())
    },
    deletePost: () => {
      dispatch(DELETE_tEMPPOST())
    },
    addTempComment: (comment: any) => {
      dispatch(GET_TEMPCOMMENT(comment))
    },
    createComment: (postId: any) => {
      dispatch(CREATE_COMMENT(postId))
    },
    addClient: (client: any) => {
      dispatch(GET_CLIENT(client))
    },
    addActiveUser: (user: any) => {
      dispatch(GET_ACTIVEUSERS(user))
    },
    deleteActiveUser: () => {
      dispatch(DELETE_ACTIVEUSERS())
    },
    addLastMsg: (msg: any) => {
      dispatch(GET_LASTMESG(msg))
    },
    addChatUsers: (chatUser: any) => {
      dispatch(GET_CHAT_USERLIST(chatUser))
    },
    deleteLastMsg: () => {
      dispatch(DELETE_LASTMESG())
    },
    deleteChatUsers: () => {
      dispatch(DELETE_CHAT_USERLIST())
    },
    deleteParticularPost: (id: string | number) => {
      dispatch(DELETE_POST(id))
    },
    updatePostDesc: (postDetails: { id: number, description: string }) => {
      dispatch(UPDATE_POST_DESC(postDetails))
    },
    setProfile: (profileData: any) => {
      dispatch(SET_PROFILE(profileData))
    },
    editComment: (commentDetails: {
      postId: number,
      commentId: number,
      comment: string
    }) => {
      dispatch(EDIT_COMMENT(commentDetails))
    },
    deleteComment: (commentDetails: {
      postId: number,
      commentId: number,
    }) => {
      dispatch(DELETE_COMMENT(commentDetails))
    },
    emptyIndexPost: () => {
      dispatch(EMPTY_INDEX_POST())
    } 
  }
}

const mapStateToProps = (state: any) => {
  //replace Reducer name with state.'Your Reducer name' and .property

  return {
    list: state.takeAction.allIndexPost,
    metaPost: state.takeAction.metaPost,
    activeUser: state.takeAction.userData,
    UserInfo: state.takeAction.UserInfo,
    chatUserList: state.takeAction.chatUserList,
    lastMegs: state.takeAction.lastMegs,
    listClient: state.takeAction.clientData[0],
    profileData: state.takeAction.profileData
  };
  // this.setState({ myAllPost: state.takeAction.allIndexPost})


};
//@ts-ignore
const HomeWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))
//@ts-ignore
export default withStyles(HomeStyles)(HomeWithRouter)
