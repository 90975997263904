import { toast } from 'react-toastify';
const initialState = {
  friendsList: [],
  allIndexPost: [],
  UserInfo: [],
  tempPost: [],
  tempComment: [],
  allSelectedImg: [],
  clientData: [],
  userData: [],
  userEmails: [],
  lastMegs: [],
  chatUserList: [],
  newInvitedChannels: [],
  useLessArr: [],
  userIdDelete: [],
  userIdDeleteLST: [],
  ownPost: [],
  ownNFTs: [],
  previouslyOwnedNFTs: [],
  profileData: {
    location: '',
    information: '',
    emailId: '',
    countryCode: '',
    phoneNumber: '',
    dateOfBirth: '',
    profileImage: '',
    fullName: '',
    firstName: '',
    lastName: '',
    userName: '',
    country: '',
    state: '',
    city: '',
    countryCode: '',
    userIdResetPassword: ''
  },
  usersFriends: [],
  blockedUsers: [],
  notificationsList: [],
  notificationsConfigurations: {
    emailAcceptReq: false,
    emailPostReq: false,
    emailReceiveReq: false,
    pushAcceptReq: false,
    pushPostReq: false,
    pushReceiveReq: false,
    smsReceiveReq: false,
    smsAcceptReq: false,
    smsPostReq: false
  }
};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FRIENDSLIST':
      return {
        ...state,
        friendsList: [action.payload]
      };
    case 'GET_INDEXPOST':
      return {
        ...state,
        allIndexPost: [...state.allIndexPost, action.payload]
      };
    case 'USER_LOGOUT':
      return {
        ...state,
        clientData: [],
        userData: [],
        userEmails: [],
        lastMegs: [],
        chatUserList: [],
        newInvitedChannels: [],
        useLessArr: [],
        userIdDelete: [],
        userIdDeleteLST: [],
        UserInfo: [],
        friendsList: [],
        allIndexPost: [],
        ownPost: [],
        ownNFTs: [],
        previouslyOwnedNFTs: [],
        profileData: {
          location: '',
          information: '',
          emailId: '',
          countryCode: '',
          phoneNumber: '',
          dateOfBirth: '',
          profileImage: '',
          fullName: '',
          firstName: '',
          lastName: '',
          userName: '',
          country: '',
          state: '',
          city: '',
          countryCode: '',
          userIdResetPassword: ''
        },
        usersFriends: [],
        blockedUsers: [],
        notificationsList: [],
        notificationsConfigurations: {
          emailAcceptReq: false,
          emailPostReq: false,
          emailReceiveReq: false,
          pushAcceptReq: false,
          pushPostReq: false,
          pushReceiveReq: false,
          smsReceiveReq: false,
          smsAcceptReq: false,
          smsPostReq: false
        }
      };
    case 'GET_USER':
      return {
        ...state,
        UserInfo: [action.payload]
      };
    case 'USER_CHANGE':
      return {
        ...state,
        UserInfo: []
      };
    case 'GET_INDEXTEMPPOST':
      return {
        ...state,
        tempPost: [...state.tempPost, action.payload]
      };
    case 'UPDATE_POST':
      state.allIndexPost.forEach((element, index) => {
        if (
          state.tempPost[0] &&
          element.attributes.id === state.tempPost[0].attributes.id
        ) {
          state.allIndexPost[index] = state.tempPost[0];
        } else if (
          state.tempPost[1] &&
          element.attributes.id === state.tempPost[1].attributes.id
        ) {
          state.allIndexPost[index] = state.tempPost[1];
        } else if (
          state.tempPost[2] &&
          element.attributes.id === state.tempPost[2].attributes.id
        ) {
          state.allIndexPost[index] = state.tempPost[2];
        } else if (
          state.tempPost[3] &&
          element.attributes.id === state.tempPost[3].attributes.id
        ) {
          state.allIndexPost[index] = state.tempPost[3];
        } else if (
          state.tempPost[4] &&
          element.attributes.id === state.tempPost[4].attributes.id
        ) {
          state.allIndexPost[index] = state.tempPost[4];
        }
      });
      return state;
    case 'DELETE_tEMPPOST':
      return {
        ...state,
        tempPost: []
      };
    case 'DELETE_FRIENDLIST':
      return {
        ...state,
        friendsList: []
      };
    case 'DELETE_POST': {
      state.allIndexPost.forEach((element, index) => {
        if (action.payload == element.attributes.id) {
          state.allIndexPost.splice(index, 1);
        }
      });
      return state;
    }
    case 'UPDATE_POST_DESC': {
      state.allIndexPost.forEach((element, index) => {
        if (action.payload.id == element.attributes.id) {
          state.allIndexPost[index].attributes.description =
            action.payload.description;
        }
      });
      return state;
    }
    case 'LIKE_POST':
      state.allIndexPost.forEach((element, index) => {
        if (
          action.payload == element.attributes.id &&
          state.allIndexPost[index].attributes.is_liked == false
        ) {
          state.allIndexPost[index].attributes.is_liked = true;
          state.allIndexPost[index].attributes.likes_count =
            state.allIndexPost[index].attributes.likes_count + 1;
        } else if (
          action.payload == element.attributes.id &&
          state.allIndexPost[index].attributes.is_liked == true
        ) {
          state.allIndexPost[index].attributes.is_liked = false;
          state.allIndexPost[index].attributes.likes_count =
            state.allIndexPost[index].attributes.likes_count - 1;
        }
      });
      return state;
    case 'LIKE_POSTCOMMENT':
      state.allIndexPost.forEach((element, index) => {
        if (action.payload.postId == element.attributes.id) {
          element.attributes.comments.data.forEach((comment, comIndex) => {
            if (
              action.payload.commentId == comment.attributes.id &&
              (state.allIndexPost[index].attributes.comments.data[comIndex]
                .attributes.is_liked == false ||
                state.allIndexPost[index].attributes.comments.data[comIndex]
                  .attributes.is_liked == null)
            ) {
              state.allIndexPost[index].attributes.comments.data[
                comIndex
              ].attributes.is_liked = true;
              state.allIndexPost[index].attributes.comments.data[
                comIndex
              ].attributes.likes_count =
                state.allIndexPost[index].attributes.comments.data[comIndex]
                  .attributes.likes_count + 1;
            } else if (
              action.payload.commentId == comment.attributes.id &&
              state.allIndexPost[index].attributes.comments.data[comIndex]
                .attributes.is_liked == true
            ) {
              state.allIndexPost[index].attributes.comments.data[
                comIndex
              ].attributes.is_liked = false;
              state.allIndexPost[index].attributes.comments.data[
                comIndex
              ].attributes.likes_count =
                state.allIndexPost[index].attributes.comments.data[comIndex]
                  .attributes.likes_count - 1;
            }
          });
        }
      });
      return state;
    case 'CREATE_COMMENT':
      state.allIndexPost.forEach((element, index) => {
        if (action.payload == element.attributes.id && state.tempComment[0]) {
          if (
            state.allIndexPost[index].attributes.comments.data === undefined
          ) {
            const data = { data: [state.tempComment[0]] };
            state.allIndexPost[index].attributes.comments = data;
            state.allIndexPost[index].attributes.comments_count =
              state.allIndexPost[index].attributes.comments_count + 1;
          } else {
            state.allIndexPost[index].attributes.comments.data.unshift(
              state.tempComment[0]
            );
            state.allIndexPost[index].attributes.comments_count =
              state.allIndexPost[index].attributes.comments_count + 1;
            if (state.allIndexPost[index].attributes.comments.data.length > 4) {
              state.allIndexPost[index].attributes.comments.data.pop();
            }
          }
        }
      });
      return state;
    case 'EDIT_COMMENT':
      state.allIndexPost.map((element, index) => {
        if (action.payload.postId === element.attributes.id) {
          state.allIndexPost[index].attributes.comments.data.map(
            (comment, commentIndex) => {
              if (comment.attributes.id == action.payload.commentId) {
                state.allIndexPost[index].attributes.comments.data[
                  commentIndex
                ].attributes.comment = action.payload.comment;
              }
            }
          );
        }
      });
      return state;
    case 'DELETE_COMMENT':
      state.allIndexPost.map((element, index) => {
        if (action.payload.postId === element.attributes.id) {
          state.allIndexPost[index].attributes.comments.data.map(
            (comment, commentIndex) => {
              if (comment.attributes.id == action.payload.commentId) {
                state.allIndexPost[index].attributes.comments.data[
                  commentIndex
                ].attributes.deleted = true;
              }
            }
          );
        }
      });
      return state;
    case 'GET_TEMPCOMMENT':
      return {
        ...state,
        tempComment: [action.payload]
      };
    case 'GET_IMAGES':
      return {
        ...state,
        allSelectedImg: [action.payload]
      };
    case 'GET_CLIENT':
      return {
        ...state,
        clientData: [action.payload]
      };
    case 'GET_ACTIVEUSERS':
      return {
        ...state,
        userData: [...state.userData, action.payload]
      };
    case 'GET_USERSID':
      return {
        ...state,
        userEmails: [...state.userEmails, action.payload]
      };
    case 'DELETE_ACTIVEUSERS':
      return {
        ...state,
        userData: []
      };
    case 'DELETE_USERID':
      return {
        ...state,
        userEmails: []
      };
    case 'GET_LASTMESG':
      return {
        ...state,
        lastMegs: [...state.lastMegs, action.payload]
      };
    case 'DELETE_LASTMESG':
      return {
        ...state,
        lastMegs: []
      };
    case 'GET_CHAT_USERLIST':
      return {
        ...state,
        chatUserList: [...state.chatUserList, action.payload]
      };
    case 'DELETE_CHAT_USERLIST':
      return {
        ...state,
        chatUserList: []
      };
    case 'GET_INVITED_CHANNELS':
      if (action.payload && state.chatUserList != undefined) {
        state.chatUserList.unshift(action.payload);
      }
      return state;
    case 'UPDATE_LAST_MSG':
      state.lastMegs.forEach((element, index) => {
        if (element.userId == action.payload.msg.userId) {
          state.lastMegs[index] = action.payload.msg;
          //action.payload.func(action.payload.msg)
        }
      });
      return {
        ...state,
        useLessArr: [action.payload.msg]
      };
    case 'UPDATED_USR_LAST_MSG':
      if (
        action.payload &&
        state.lastMegs != undefined &&
        !state.lastMegs.some(e => e.userId === action.payload.userId)
      ) {
        state.lastMegs.unshift(action.payload);
      }
      return state;
    case 'USELESS_CALL':
      return {
        ...state,
        useLessArr: [action.payload]
      };
    case 'REMOVE_BADGE':
      state.lastMegs.forEach((element, index) => {
        if (element.userId == action.payload) {
          state.lastMegs[index].getUnConsumedIndex = 0;
          //action.payload.func(action.payload.msg)
        }
      });
      return {
        ...state,
        useLessArr: [action.payload]
      };
    case 'UPDATE_CHAT_LIST':
      state.chatUserList.forEach((element, index) => {
        if (element.userId == action.payload.user) {
          state.chatUserList.splice(index, 1);
          action.payload.func(action.payload.user);
          //action.payload.func(action.payload.msg)
        }
      });
      return {
        ...state,
        userIdDelete: [action.payload]
      };
    case 'UPDATE_CHAT_LAST_MEG_LIST':
      state.lastMegs.forEach((element, index) => {
        if (element.userId == action.payload.user) {
          state.lastMegs.splice(index, 1);
          action.payload.func(action.payload.user);
          //action.payload.func(action.payload.msg)
        }
      });
      return {
        ...state,
        userIdDeleteLST: [action.payload]
      };
    case 'DELETE_CHATS':
      return {
        ...state,
        clientData: [],
        userData: [],
        userEmails: [],
        lastMegs: [],
        chatUserList: [],
        newInvitedChannels: [],
        useLessArr: [],
        userIdDelete: [],
        userIdDeleteLST: []
      };
    case 'SET_OWN_POSTS':
      return {
        ...state,
        ownPost: action.payload
      };
    case 'EDIT_OWN_POST':
      state.ownPost.forEach((element, index) => {
        if (action.payload.id === element.attributes.id) {
          state.ownPost[index].attributes.description =
            action.payload.description;
        }
      });
      return state;
    case 'DELETE_OWN_POST':
      state.ownPost.forEach((element, index) => {
        if (action.payload == element.attributes.id) {
          state.ownPost.splice(index, 1);
        }
      });
      state.ownNFTs?.length > 0 &&
        state.ownNFTs.forEach((element, index) => {
          if (action.payload == element.attributes.post.id) {
            state.ownNFTs.splice(index, 1);
          }
        });
      return state;
    case 'SET_OWN_NFTS':
      return {
        ...state,
        ownNFTs: action.payload
      };
    case 'SET_PREVIOUS_NFTS':
      return {
        ...state,
        previouslyOwnedNFTs: action.payload
      };
    case 'SET_PROFILE':
      return {
        ...state,
        profileData: { ...state.profileData, ...action.payload }
      };
    case 'SET_FRIENDS':
      return {
        ...state,
        usersFriends: action.payload
      };
    case 'UPDATE_FRIENDS':
      switch (action.payload.action) {
        case 'UNFOLLOW':
          state.usersFriends.forEach((element, index) => {
            if (element.id == action.payload.id) {
              state.usersFriends[index].attributes.follow = !state.usersFriends[
                index
              ].attributes.follow;
            }
          });
          return state;
        case 'UNFRIEND':
          state.usersFriends.forEach((element, index) => {
            if (element.id == action.payload.id) {
              state.usersFriends.splice(index, 1);
            }
          });
          return state;
        case 'BLOCK':
          state.usersFriends.forEach((element, index) => {
            if (element.attributes.receipient_id == action.payload.id) {
              state.usersFriends.splice(index, 1);
            }
          });
          return state;
      }
    case 'SET_BLOCKED_USERS':
      return {
        ...state,
        blockedUsers: action.payload
      };
    case 'UNBLOCK_USER':
      state.blockedUsers.forEach((element, index) => {
        if (action.payload == element.attributes.account_id) {
          state.blockedUsers.splice(index, 1);
        }
      });
      return state;
    case 'SET_NOTIFICATIONS': {
      return {
        ...state,
        notificationsList: action.payload
      };
    }
    case 'SET_NOTIFICATIONS_CONFIGURATIONS':
      return {
        ...state,
        notificationsConfigurations: {
          ...state.notificationsConfigurations,
          ...action.payload
        }
      };
    case 'EMPTY_INDEX_POST':
      return {
        ...state,
        allIndexPost: []
      }
    default:
      return state;
  }
};
