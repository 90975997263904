import React from "react";

// Customizable Area Start
import TrendingController, {
  Props} from "./TrendingController.web";
import Navbar from "./Navbar.web"
import Sidebar from './Sidebar.web'
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';
import ConfirmDeleteDialog from './ConfirmDeleteDialog.web'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FriendsWeb from "../../TargetedFeed/src/Friends.web";
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from "@material-ui/core/TextField";
import { Send } from "@material-ui/icons";
import FavoriteIcon from '@material-ui/icons/Favorite';
import { connect } from "react-redux";
import LoopIcon from '@material-ui/icons/Loop';
import Cookies from "js-cookie";
import CircularProgress from '@material-ui/core/CircularProgress';
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
import ReportIcon from '@material-ui/icons/Report';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from "@material-ui/icons/Close";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//@ts-ignore
import { GET_INDEXPOST, DELETE_POST,EMPTY_INDEX_POST,DELETE_COMMENT,EDIT_COMMENT,UPDATE_POST_DESC,USER_LOGOUT, LIKE_POST, LIKE_POSTCOMMENT, GET_TEMPCOMMENT, CREATE_COMMENT } from "../../../web/src/allActions.js";
import { Steps } from "intro.js-react";
// Customizable Area End
class Trending extends TrendingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props
    const {
      reportDialog,
      confirmReportDialog,
      reportDialogMessage,
      reportReasons,
      reportSubReasons,
      postReportData,
      stepsEnabled,
      steps,
      initialStep
    } = this.state

    const handleConfirmReport = (reason: string, subReasons?: any, reportReasonId=0) => {
      if (subReasons?.length > 0) {
        this.setState({
          ...this.state,
          reportSubReasons: subReasons,
        })
      } else {
        this.setState({
          ...this.state,
          confirmReportDialog: true,
          reportDialog: false,
          reportDialogMessage: reason,
          postReportData: {
            ...postReportData,
            report_reason_id: reportReasonId
          }
        })
      }
    }

    const postRedirect = (postId: number) => {
      this.props.history.push({
        pathname: '/post/' + postId,
        state: { detail: postId }
      })
    }

    const handleUserRedirect = (element: any) => {
      const id = Cookies.get('Login_Id')
      if (id != element.attributes.account.id) {
        if (element.attributes?.account?.user_name) {
          //@ts-ignore
          this.props.history.push({
            pathname: '/' + element.attributes?.account?.user_name,
            state: { detail: element.attributes?.account?.user_name }
          })
        } else {
          //@ts-ignore
          this.props.history.push({
            pathname: '/user/' + element.attributes?.account?.id,
            state: { detail: element.attributes?.account?.id }
          })
        }
      } else {
        //@ts-ignore
        this.props.history.push('/myprofile')
      }

    }

    return (
      // Customizable Area Start
      <>
        <Steps
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={this.handleUpdateTutorials}
            options={{
              hidePrev: true,
              doneLabel: "Finish",
              keyboardNavigation: true
            }}
        />
        <Navbar />
        <div className={classes.container}>
          <div style={{ display: 'flex', justifyContent: "space-between", }}>
            <Sidebar
              //@ts-ignore
              FromHome={"FromHome"} />
            <div style={{ flex: "1 1 100%"}}>
              <div className={classes.bottomBoxBg}>
                {this.state.myAllPost.length > 0 ? (this.state.myAllPost.map((element: any, index) => {
                  const { attributes: { animation_url, image_url, is_current_owner } } = element
                  return (
                    <Card className={classes.postCard} key={index}>
                      <CardHeader
                        avatar={
                          //@ts-ignore
                          <Avatar
                            className={classes.pointer}
                            onClick={() => handleUserRedirect(element)}
                            src={element.attributes.account.image ? element.attributes.account.image : "https://www.studyheights.com/assets/img/Universitylogo/default.png"} aria-label="recipe">
                          </Avatar>
                        }
                        action={
                          <Menu>
                            <MenuButton>
                              <MoreHorizIcon />
                            </MenuButton>
                            <MenuList className={classes.menuListData} style={{width: "200px"}}>
                            <span className={classes.triangle}></span>
                            {Cookies.get('Login_Id') != element.attributes.account.id ?
                                <MenuItem onSelect={() => this.setState({ ...this.state, reportDialog: true, postReportData: { ...postReportData, post_id: element.attributes?.id } })}><h4><ReportIcon />Report Post</h4></MenuItem>
                                :
                                <>
                                  <MenuItem onSelect={() => this.setState({ editPostId: element.attributes.id, editPostDesc: element.attributes.description })}>
                                    <h4><EditOutlinedIcon />Edit</h4>
                                  </MenuItem>
                                  <MenuItem onSelect={() => this.handleDeleteDialogToogle(element?.attributes?.id)}>
                                    <h4><DeleteOutlineOutlinedIcon />Delete</h4>
                                  </MenuItem>
                                </>}
                            </MenuList>
                          </Menu>
                        }
                        title={<span className={classes.pointer} onClick={() => handleUserRedirect(element)}>{element.attributes.account.full_name}</span>}
                        subheader={element.attributes.time}
                      />
                      <CardContent>
                        <div>
                        {this.state.editPostId === element.attributes.id ? (
                            <form className={classes.discrtxt} onSubmit={(e) => e.preventDefault()}>
                              <div style={{ display: 'flex' }} onClick={(event) => event?.stopPropagation()}>
                                <TextField
                                  id="editBio"
                                  fullWidth
                                  variant="standard"
                                  autoComplete="off"
                                  placeholder="Write something here..."
                                  autoFocus={this.state.editPostId === element.attributes.id}
                                  defaultValue={element.attributes.description}
                                />
                                <Button
                                  className={classes.btnSubmit}
                                  type='submit'
                                  onClick={() => this.updatePost(element.attributes.id)}>
                                  Save
                                </Button>
                              </div>
                            </form>
                          ) : (
                            <p className={classes.pointer} onClick={() => postRedirect(element.attributes.id)}>{element?.attributes?.description}</p>
                          )}
                          {element.attributes.nft_name && <span
                            onClick={() => postRedirect(element.attributes.id)}
                            className={`${classes.pointer} ${index === 0 ? "tutorial-nft-title" : ""}`}>{element.attributes.nft_name}</span>}
                            {animation_url ? <>
                            {this.postTypeCheck(animation_url) === "audio" &&
                              <audio
                                className={classes.pointer}
                                onClick={() => postRedirect(element.attributes.id)}
                                style={{ width: '100%', objectFit: 'inherit' }} controls>
                                <source src={animation_url} type="audio/mp3" />
                                <source src={animation_url} type="audio/wav" />
                                Your browser does not support the audio element.
                              </audio> }
                            {this.postTypeCheck(animation_url) === "video" &&
                              <video
                                className={classes.pointer}
                                onClick={() => postRedirect(element.attributes.id)}
                                style={{ width: '100%', height: '530px', objectFit: 'inherit' }} controls>
                                <source src={animation_url} type="video/mp4" />
                                <source src={animation_url} type="video/ogg" />
                                <source src={animation_url} type="video/webm" />
                                Your browser does not support the video element.
                              </video>}
                            {this.postTypeCheck(animation_url) === "iframe" &&
                              <iframe
                                className={classes.pointer}
                                onClick={() => postRedirect(element.attributes.id)}
                                style={{ width: '100%', height: '530px', objectFit: 'inherit' }} src={animation_url} />}
                            {this.postTypeCheck(animation_url) === "3dObject" &&
                              <img
                                className={classes.pointer}
                                onClick={() => postRedirect(element.attributes.id)}
                                style={{ width: '100%', height: '530px', objectFit: 'inherit' }}
                                src={image_url} />}
                          </> :
                          image_url &&
                          <img
                            className={classes.pointer}
                            onClick={() => postRedirect(element.attributes.id)}
                            style={{ width: '100%', height: '530px', objectFit: 'inherit' }}
                            src={image_url} />}
                          <div className={classes.postCardInfo}>
                            <div className={index === 0 ? "tutorial-like" : ""}>
                              <FavoriteOutlinedIcon />
                              <h3>
                                {//@ts-ignore
                                  element.attributes.likes_count}
                              </h3>
                            </div>
                            <p className={index === 0 ? "tutorial-comment" : ""} onClick={(e) => {
                                e.stopPropagation()
                                this.handleCommentsToggle(`trending-post${element.attributes.id}`)
                              }}>
                              {//@ts-ignore
                                element.attributes.comments_count} comments
                            </p>
                            {image_url && <h3 className={`${classes.paidCardText} ${!is_current_owner && classes.soldNFT} ${index === 0 ? "tutorial-nft-price" : ""}`}><img src="https://img.icons8.com/ios/24/000000/price-tag--v1.png" />
                                {`Paid $ ${element?.attributes?.usd_amount || 0}(${element?.attributes?.eth_amount || 0} ETH)`}
                            </h3>}
                          </div>
                        </div>
                      </CardContent>
                      <div className={classes.likeCommentShare}>
                        <IconButton className={classes.txtHover} aria-label="add to favorites" onClick={() => this.likePost(//@ts-ignore
                          element.attributes.id, element.attributes.is_liked)}>
                          <FavoriteBorderOutlinedIcon style={
                            //@ts-ignore
                            element.attributes.is_liked ?
                              { color: '#0388c9' } : { color: 'grey' }} id="likePost" />
                          <p> {
                            element.attributes.is_liked ?
                              'Unlike' : 'Like'}</p>
                        </IconButton>
                        <IconButton className={classes.txtHover} aria-label="comment" onClick={() => {
                          this.handleCommentsToggle(`trending-post${element.attributes.id}`)
                        }}
                        >
                          <ModeCommentOutlinedIcon /><p>Comment</p>
                        </IconButton>
                      </div>

                      <div className={classes.commentBlockWrapper} id={`trending-post${element.attributes.id}`} style={{ display: 'none' }}>
                        <FormControl className={classes.selectComment}>
                          <InputLabel></InputLabel>
                          {/* <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            autoWidth
                            defaultValue={10}
                          >
                            <MenuItem disabled value={30}>
                              <em>Comment filter</em>
                            </MenuItem>
                            <MenuItem value={10} onClick={()=>{this.getCommentData(element.attributes.id,'most_relevant')}}>Most relevant comments</MenuItem>
                            <MenuItem value={21} onClick={()=>{this.getCommentData(element.attributes.id,'oldest')}}>Old comments</MenuItem>
                            <MenuItem value={22} onClick={()=>{this.getCommentData(element.attributes.id,'newest')}}>New comments</MenuItem>
                          </Select> */}
                        </FormControl>
                        <div className={classes.commentBlock}>
                          <Avatar aria-label="recipe" src={this.state.profileImage ? this.state.profileImage : "https://www.studyheights.com/assets/img/Universitylogo/default.png"}>
                          </Avatar>
                          <TextField
                            className={classes.commentBar}
                            placeholder="Write a comment"
                            id={"comentBox" + index}
                          />
                          <IconButton
                            style={HomeStyles.sendButton} onClick={() => this.createComment(element.attributes.id, index)}>
                            <Send style={HomeStyles.sendIcon} />
                          </IconButton>
                        </div>
                        {element?.attributes?.comments?.data?.length !== 0 && (
                          element?.attributes?.comments?.data?.map((comment: any, commentIndex: any) => {
                            return (
                              <div className={classes.commentBlock} key={commentIndex}>
                                <Avatar aria-label="recipe" src={comment?.attributes?.account?.image}>
                                </Avatar>
                                <div className={classes.commentWrap} key={commentIndex + comment + commentIndex}>
                                  <p
                                    onClick={() => {
                                      //@ts-ignore
                                      this.props.history.push({
                                        pathname: '/post/' + element.attributes.id,
                                        state: { detail: element.attributes.id }
                                      })
                                    }}>
                                    <span>{comment?.attributes?.account?.full_name}</span>
                                    {this.state.editCommentId === comment.attributes.id ?
                                        <TextField
                                          id="editCommentBox"
                                          fullWidth
                                          variant="standard"
                                          autoComplete="off"
                                          placeholder="Write a comment"
                                          autoFocus={this.state.editCommentId === comment.attributes.id}
                                          defaultValue={comment.attributes.comment}
                                          onClick={(e) => e.stopPropagation()}
                                        />
                                        : comment.attributes.deleted ? 'This comment was deleted' : comment.attributes.comment}
                                  </p>
                                  <ul className={classes.listComment}>
                                    <li>
                                      <Button key={commentIndex + comment + comment + commentIndex} onClick={() => this.likeOrDislikeComment(comment.attributes.id, comment.attributes.is_liked)}>
                                        {comment.attributes.is_liked ? (<div style={{ color: '#1f7fb6' }}>Dislike</div>) : 'Like'}
                                      </Button>
                                    </li>
                                    <li>
                                      <Button key={commentIndex + comment + index + commentIndex} onClick={() => {
                                        //@ts-ignore
                                        this.props.history.push({
                                          pathname: '/post/' + element.attributes.id,
                                          state: { detail: element.attributes.id }
                                        })
                                      }}>
                                        Reply
                                      </Button>
                                    </li>
                                    <li style={{color:'#1f7fb6'}}><FavoriteIcon style={{fontSize:'larger'}}/> ({comment.attributes.likes_count})&nbsp;</li>
                                    <li>{comment.attributes.time}</li>
                                    {Cookies.get('Login_Id') == comment.attributes.account_id && !comment.attributes.deleted && (
                                        <>
                                          <li>
                                            {this.state.editCommentId === comment.attributes.id ?
                                              <Button onClick={() => this.editComment(element.attributes.id)}>
                                                Save
                                              </Button>
                                              : <Button onClick={() => this.setState({ editCommentId: comment.attributes.id })}>
                                                Edit
                                              </Button>}
                                          </li>
                                          <li>
                                            <Button onClick={() => this.handleDeleteCommentToogle(comment.attributes.id)}>
                                              Delete
                                            </Button>
                                          </li></>)}
                                  </ul>

                                  {this.state.showReplyInbox === comment.attributes.id ? (
                                    <div className={classes.replyCommentBlock}>

                                      <div className={classes.commentBlock}>
                                        {this.state.allRepliedComments.length !== 0 && (
                                          this.state.allRepliedComments.map((replyComment: any, replyIndex: any) => {
                                            return (
                                              <span key={replyIndex + replyComment}>
                                                <Avatar aria-label="recipe" src={replyComment?.attributes?.account?.image}>
                                                </Avatar>
                                                <div className={classes.commentWrap}>
                                                  <p>
                                                    <span>{replyComment?.attributes?.account?.full_name}</span>
                                                    {replyComment.attributes.comment}
                                                  </p>
                                                  <ul className={classes.listComment}>
                                                    <li>
                                                      <Button onClick={() => this.likeOrDislikeComment(replyComment.attributes.id, replyComment.attributes.is_liked)}>
                                                        {replyComment.attributes.is_liked ? (<div style={{ color: '#1f7fb6' }}>Dislike</div>) : 'Like'}
                                                      </Button>
                                                    </li>
                                                    <li>
                                                      <Button key={replyIndex + replyComment + index + replyIndex} >
                                                        Reply
                                                      </Button>
                                                    </li>
                                                    <li style={{color: '#1f7fb6' }}>{replyComment.attributes.likes_count} &nbsp;<FavoriteIcon style={{ fontSize: 'larger' }} /></li>
                                                    <li>{replyComment.attributes.time}</li>
                                                  </ul>
                                                </div>
                                              </span>
                                            )
                                          })
                                        )}
                                        <Avatar aria-label="recipe" src={this.state.profileImage ? this.state.profileImage : "https://www.studyheights.com/assets/img/Universitylogo/default.png"}>
                                        </Avatar>
                                        <TextField
                                          className={classes.commentBar}
                                          placeholder="Write a comment"
                                          id={"comentReplyBox" + index + commentIndex}
                                        />
                                        <IconButton
                                          style={HomeStyles.sendButton} onClick={() => this.createReplyComment(element.attributes.id, index, commentIndex, comment.attributes.id)}>
                                          <Send style={HomeStyles.sendIcon} />
                                        </IconButton>
                                      </div>
                                    </div>
                                  ) : ('')}
                                </div>
                              </div>
                            )
                          })
                        )}
                        {(element?.attributes?.comments?.data?.length !== 0 && element?.attributes?.comments?.data?.length !== undefined && element?.attributes?.comments?.data?.length !== '' && element.attributes.comments_count > 4 && element?.attributes?.comments?.data?.length === 4) && (
                          <div className={classes.paginationBlock}>
                            <Button
                              onClick={() => {
                                //@ts-ignore
                                this.props.history.push({
                                  pathname: '/post/' + element.attributes.id,
                                  state: { detail: element.attributes.id }
                                })
                              }}
                            >See more comments</Button>
                            <div className={classes.paginationBlockWrapper}>
                              <span>{element?.attributes?.comments?.data?.length}</span> of <span>{element.attributes.comments_count}</span>
                            </div>
                          </div>)
                        }
                      </div>

                    </Card>
                  )
                })) : (
                  this.state.loading ? <CircularProgress className={classes.loader} /> :
                    <h4>No Post Found</h4>)}
                {(this.state.myAllPost.length !== 0 && this.state.hideData) && (<div style={{textAlign: "center"}}><Button variant="outlined" className={classes.btnLoadMore} id="loadMore" onClick={() => this.getAllMyNftPost(1, 2)}><LoopIcon /> &nbsp;Load More</Button></div>)}

              </div>
            </div>
            <FriendsWeb
              //@ts-ignore
              pageState={this.state.pageState}
            />

          </div>
        </div>
        <ConfirmDeleteDialog
          //@ts-ignore
          title={"comment"}
          isOpen={this.state.deleteCommentDialog}
          handleCancelDelete={this.handleDeleteCommentToogle}
          handleConfirmDelete={this.deleteComment}
        />
        <Dialog
          open={reportDialog}
          onClose={() => this.setState({
            ...this.state,
            reportDialog: false,
            reportSubReasons: [],
            postReportData: {
              post_id: 0,
              report_reason_id: 0
            }
          })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.deactivateDialog}
        >
          <DialogTitle className={classes.titleDialog} id="alert-dialog-title">
            <span className={classes.subreportTitle}>
              {reportSubReasons?.length > 0 &&
                <IconButton onClick={() => this.setState({
                  ...this.state,
                  reportSubReasons: [],
                  postReportData: {
                    post_id: 0,
                    report_reason_id: 0
                  }
                })}>
                  <ArrowBackRoundedIcon />
                </IconButton>}
              Report
            </span>
            <IconButton onClick={() => this.setState({
              ...this.state,
              reportDialog: false,
              reportSubReasons: [],
              postReportData: {
                post_id: 0,
                report_reason_id: 0
              }
            })}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.txtDialog}>
            <h2>Report post</h2>
            <p>
              Help us understand what is wrong with this post. How would you describe it?
            </p>
            <div className={classes.btnList}>
              {reportSubReasons?.length > 0 ? reportSubReasons?.map((subReason: any) => {
                const { data: { attributes } } = subReason
                return (
                  <Button key={attributes?.id} onClick={() => handleConfirmReport(attributes?.content, attributes?.sub_reasons, attributes?.id)}>
                    {attributes?.content}
                    <IconButton>
                      <ChevronRightIcon />
                    </IconButton>
                  </Button>
                )
              }) :
                reportReasons?.map((reason: any) => {
                  const { attributes } = reason
                  return (
                    <Button key={attributes.id} onClick={() => handleConfirmReport(attributes.content, attributes?.sub_reasons, attributes?.id)}>
                      {attributes.content}
                      <IconButton>
                        <ChevronRightIcon />
                      </IconButton>
                    </Button>
                  )
                })
              }
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={confirmReportDialog}
          onClose={() => this.setState({
            ...this.state, confirmReportDialog: false, reportDialog: true, postReportData: {
              post_id: 0,
              report_reason_id: 0
            }
          })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.deactivateDialog}
        >
          <DialogTitle className={classes.titleDialog} id="alert-dialog-title">
            Please confirm your submission
            <IconButton onClick={() => this.setState({
              ...this.state, confirmReportDialog: false, reportDialog: true, postReportData: {
                post_id: 0,
                report_reason_id: 0
              }
            })}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ textAlign: "center", paddingBottom: "20px" }}>
            <Button className={classes.btnAddFriend}><ReportIcon />{reportDialogMessage}</Button>
          </DialogContent>
          <DialogActions>
            <Button className={classes.btnChat} onClick={() => this.handlePostReport()}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <ConfirmDeleteDialog
          //@ts-ignore
          isOpen={this.state.deleteDialog}
          handleCancelDelete={this.handleDeleteDialogToogle}
          handleConfirmDelete={this.deletePost}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
const HomeStyles = {
  btnAddFriend: {
    minWidth: "127px",
    backgroundColor: "#d8dddd",
    height: "41px",
    fontSize: "16px",
    color: "#1f7fb6",
    fontWeight: "700",
    letterSpacing: "0",
    borderRadius: "55px",
    textAlign: "center",
    textTransform: "none",
    margin: "0 4px",
    padding: "6px 20px",
    "& svg": {
      marginRight: "10px"
    }
  },
  btnLoadMore: {
    margin: "0 4px",
    minWidth: "91px",
    backgroundColor: "#1f7fb6",
    height: "41px",
    fontSize: "16px",
    color: "#ffffff",
    fontWeight: "700",
    letterSpacing: "0",
    border: "1px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1f7fb6",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "5px",
    },
  },
  textField: {
    padding: "0 0 15px",
    width: "100%",
    fontSize: "14px",
    color: "#000000",
    "& .MuiSelect-selectMenu": {
      border: "1px solid #c4c4c4",
      borderRadius: "5px",
      padding: "10px 14px !important",
    },
    "& .MuiInputBase-root": {
      fontSize: "14px",
      color: "#565a57",
      fontWeight: "400",
      "&::before": {
        display: "none",
      }
    },
    ".MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
      color: "#565a57",
      fontWeight: "400",
    }
  },
  btnChat: {
    margin: "0 4px",
    minWidth: "91px",
    backgroundColor: "#1f7fb6",
    height: "41px",
    fontSize: "16px",
    color: "#ffffff",
    fontWeight: "700",
    letterSpacing: "0",
    border: "1px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1f7fb6",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "5px",
    },
  },
  btnList: {
    "& .MuiButton-text": {
      width: "100%",
      padding: "10px 6px",
      "& .MuiButton-label": {
        textAlign: "left",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "16px",
        color: "#000105",
        fontWeight: "600",
        fontFamily: "'Roboto', sans-serif",
        textTransform: "none",
        "& .MuiIconButton-root": {
          padding: "0",
          color: "#000105"
        }
      }
    }
  },
  deactivateDialog: {
    "& .MuiPaper-root": {
      width: "100%",
    },
    "& *": {
      boxSizing: "border-box"
    },
    "&.MuiDialogContent-root": {
      padding: "8px 34px",
    }
  },
  txtDialog: {
    "& h2": {
      fontSize: "21px",
      color: "#000105",
      fontWeight: "600",
      fontFamily: "'Poppins', sans-serif",
      paddingBottom: "15px",
      margin: "0"
    },
    "& p": {
      fontSize: "15px",
      color: "#6e7370",
      fontWeight: "400",
      fontFamily: "'Roboto', sans-serif",
      margin: "0",
      paddingBottom: "20px",
      lineHeight: "1.3",
      "& span": {
        width: "100%",
        display: "block"
      },
    }
  },
  titleDialog: {
    "& h2": {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "21px",
      color: "#000105",
      fontWeight: "600",
      fontFamily: "'Poppins', sans-serif",
      padding: "0 0 10px",
      width: "100%",
      borderBottom: "2px solid #d8dddd",
      boxSizing: "border-box",
    },
    "& .MuiIconButton-root": {
      padding: "0",
      width: "35px",
      height: "35px",
    },
  },
  subreportTitle: {
    width: '120px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  txtHover: {
    "&:hover": {
      background: "transparent",
      color: "#1f7fb6"
    }
  },
  MuiBoxRoot: {
    flexGrow: "unset",
  },
  container: {
    padding: "0 20px",
    overflowX: "hidden",
    height: "100%",
    backgroundColor: "#f1f2f6",
  },
  boxBg: {
    backgroundColor: "#fff",
    fontFamily: "'Poppins', sans-serif",
    height: "100%",
    margin: "20px 0",
    padding: "10px 15px",
    borderRadius: "10px",
    boxShadow: "1px 1px 3px rgb(0 0 0 / 20%)",
    "& h2": {
      fontSize: "20px",
      fontWeight: "600",
      margin: "0"
    }
  },
  selectComment: {
    width: "100%",
    "& .MuiInputBase-root": {
      maxWidth: "200px",
      width: "100%",
      marginLeft: "auto",
      margin: 0,
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      }
    }
  },
  commentBlockWrapper: {
    borderTop: "1px solid #dcdcd7",
    margin: "0 15px 0",
    paddingTop: "10px",
    "& .MuiSelect-selectMenu": {
      textAlign: "right",
      fontSize: "11px",
      color: "#404341",
      fontWeight: 700,
      "&:focus": {
        background: "transparent",
      },
      "& em": {
        opacity: "0.7"
      }
    },
    "& .MuiSelect-icon": {
      color: "#404341"
    }
  },
  sendButton: { backgroundColor: "#1f7fb6", width: "40px", padding: "10px", height: "40px", marginLeft: "10px" },
  sendIcon: { color: "#ffffff", width: "auto", height: "auto", },
  listComment: {
    margin: "0",
    marginTop: "5px",
    padding: "0",
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    color: "#404341",
    "& li": {
      fontSize: "11px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      padding: "0 5px",
      display: "inline-flex",
      alignItems: "center",
    },
    "& button": {
      margin: "0",
      padding: "0",
      textAlign: "left",
      minWidth: "auto",
      "& span": {
        fontSize: "11px",
        fontWeight: "700",
        fontFamily: "'Roboto', sans-serif",
        textTransform: "none",
      },
      "&:hover": {
        backgroundColor: "none"
      }
    }
  },
  paginationBlock: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 0 15px",
    alignItems: "center",
    "& button": {
      padding: "0",
      margin: "0",
      color: "#6e7370",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "transparent"
      }
    }
  },
  paginationBlockWrapper: {
    color: "#6e7370",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "'Roboto', sans-serif",
  },
  commentWrap: {
    maxWidth: "calc(100% - 47px)",
    flex: "0 0 calc(100% - 47px)",
    "& p": {
      backgroundColor: "#ebecec",
      borderRadius: "30px",
      padding: "5px 15px",
      color: "#6e7370",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      width: "100%",
      maxWidth: "100%",
      boxSizing: "border-box",
      margin: "0",
      "& span": {
        paddingBottom: "3px",
      }
    },
    "& span": {
      color: "#000307",
      fontSize: "11px",
      display: "block",
      paddingBottom: "0",
      margin: "0",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
    },
  },
  commentBar: {
    margin: "2px 0 2px 10px",
    position: "relative",
    maxWidth: "calc(100% - 95px)",
    flex: "0 0 calc(100% - 95px)",
    "&::before": {
      position: "absolute",
      content: "",
      height: "10px",
      width: "2px",
      backgroundColor: "#ffffff",
      left: "0px"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ebecec",
      color: "#6e7370",
      borderRadius: "30px",
      height: "38px",
      fontWeight: "500",
      padding: "0 15px",
      cursor: "pointer",
      fontSize: "14px",
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    }
  },
  commentBlock: {
    display: "flex",
    flexWrap: "wrap",
    padding: "7px 0",
    justifyContent: "space-between",
    "& .MuiAvatar-root": {
      width: "35px",
      height: "35px",
      marginTop: "5px",
    }
  },
  menuListData: {
    whiteSpace: "pre-wrap",
    width: "346px",
    backgroundColor: "#fffffe",
    borderRadius: "10px",
    position: "relative",
    marginTop: "20px",
    "& div": {
      backgroundColor: "#fffffe !important",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      }
    },
    "& button": {
      width: "100%",
      padding: "5px 15px",
      cursor: "pointer",
    },
    "& h4": {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      color: "#565a57",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      textTransform: "none",
      flexWrap: "wrap",
      margin: "0",
      width: "100%",
      "& img": {
        width: "24px",
        marginRight: "12px",
      },
      "& svg": {
        width: "24px",
        marginRight: "12px",
      }
    },
  },
  triangle: {
    position: "absolute",
    width:"16px",
    height:"16px",
    borderBottom: "1px solid transparent",
    borderRight: "1px solid transparent",
    borderTop:"1px solid rgba(0,0,0,0.25)",
    borderLeft:"1px solid rgba(0,0,0,0.25)",
    transform:"rotate(45deg)",
    backgroundColor:"#fffffe",
    top: "-9px",
    left: "20px",
  },
  postCardInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 0",
    margin: "0 15px",
    borderBottom: "1px solid #dcdcd7",
    "& div": {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#6e7370",
      maxWidth: "33.33%",
      flex: "0 0 33.33%",
      "& h3": {
        margin: "0 0 0 10px",
      },
      "& svg": {
        color: "#1f7fb6"
      }
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#6e7370 !important",
      margin: "0 !important",
      padding: "0 !important",
      border: "none !important"
    },
    "& h3": {
      margin: "0",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      color: "#1f7fb6",
      maxWidth: "45%",
      flex: "0 0 45%",
      justifyContent: "flex-end",
    },
  },
  soldNFT: {
    color: "#9a9a9a!important"
  },
  mainContent: {
    height: "80vh",
    width: "50%",
    margin: "20px 30px",
  },
  friends: {
    width: "220px",
    color: "#696a6e",
  },
  bottomBoxBg: {
    maxWidth: "100%",
    margin: "0 auto",
    padding: '15px',
    "& .MuiBox-root": {
      padding: 0,
    }
  },
  paidCardText: {
    lineHeight: "1.3",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& img": {
      width: "18px",
      marginRight: "10px",
    }
  },
  likeCommentShare: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 0",
    margin: "0 15px",
    "& button": {
      padding: "0",
      margin: "0",
      color: "#6e7370",
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "'Roboto', sans-serif",
      height: "20px",
      borderRadius: "0",
      "& p": {
        margin: "0 0 0 5px",
      }
    }
  },
  postCard: {
    boxShadow: "none",
    borderRadius: "10px",
    maxWidth: "80%",
    margin: "0 auto 25px",
    "& .MuiCardHeader-root": {
      padding: "10px",
      justifyContent: "space-beetween",
      "& .MuiCardHeader-avatar": {
        marginRight: "15px",
        cursor: "pointer",
        "& .MuiAvatar-root": {
          width: "45px",
          height: "45px",
        }
      },
      "& .MuiCardHeader-content": {
        "& .MuiCardHeader-title": {
          fontSize: "16px",
          color: "#000307",
          fontWeight: "700",
          lineHeight: "1",
          paddingBottom: "5px",
          cursor: "pointer",
        },
        "& .MuiCardHeader-subheader": {
          fontSize: "13px",
          color: "#888b87",
          fontWeight: "500",
          lineHeight: "1",
          paddingBottom: "0",
        }
      },
      "& .MuiCardHeader-action": {
        margin: "0",
        "& button": {
          color: "#1f7fb6",
          padding: "7px",
          height: "40px",
          width: "40px",
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer"
        }
      }
    },
    "& .MuiCardContent-root": {
      padding: "0",
      "& p": {
        margin: "0 15px 0",
        color: "#6e7370",
        fontSize: "16px",
        fontWeight: "500",
        fontFamily: "'Roboto', sans-serif",
        paddingBottom: "5px",
        lineHeight: "1.4",
      },
      "& span": {
        borderTop: "1px solid #dcdcd7",
        color: "#565a57",
        fontSize: "16px",
        fontWeight: "700",
        display: "block",
        fontFamily: "'Roboto', sans-serif",
        margin: "0 15px 15px",
        paddingTop: "10px",
      }
    }
  },
  pointer: {
    cursor: "pointer"
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: 99,
    color: '#1f7fb6'
  },
  btnSubmit: {
    border: "1px solid #1f7fb6",
    backgroundColor: "#e4e5e4",
    height: "40px",
    borderRadius: "50px",
    boxShadow: "none !important",
    display: "inline-flex",
    alignItems: "center",
    "& .MuiButton-label": {
      fontSize: "16px !important",
      fontWeight: "700 !important",
      color: "#1f7fb6 !important",
      margin: "0 !important",
      textTransform: "none",
    },
    "& svg": {
      marginLeft: "5px",
      height: "23px"
    }
  },
  discrtxt: {
    padding: "5px 10px 15px",
    "& .MuiInput-underline": {
      "&:after": {
        display: "none"
      },
      "&:before": {
        display: "none"
      }
    },
    "& .MuiFormControl-root": {
      marginRight: "10px",
    },
    "& .MuiInputBase-input": {
      height: "40px",
      padding: "9px 10px",
      border: "1px solid #cccccc",
      borderRadius: "5px",
      boxSizing: "border-box",
      fontSize:" 14px",
      fontWeight: 500,
    },
  },
} as any;
// Customizable Area End
const mapDispatchToProps = (dispatch: any) => {
  return {
    addPosts: (post: any) => {
      dispatch(GET_INDEXPOST(post));
    },
    userLogout: () => {
      dispatch(USER_LOGOUT())
    },
    likePost: (postId: any) => {
      dispatch(LIKE_POST(postId))
    },
    likeComment: (postId: any, commentId: any) => {
      dispatch(LIKE_POSTCOMMENT(postId, commentId))
    },
    addTempComment: (comment: any) => {
      dispatch(GET_TEMPCOMMENT(comment))
    },
    createComment: (postId: any) => {
      dispatch(CREATE_COMMENT(postId))
    },
    deleteParticularPost: (id: string | number) => {
      dispatch(DELETE_POST(id))
    },
    updatePostDesc: (postDetails: { id: number, description: string }) => {
      dispatch(UPDATE_POST_DESC(postDetails))
    },
    editComment: (commentDetails: {
      postId: number,
      commentId: number,
      comment: string
    }) => {
      dispatch(EDIT_COMMENT(commentDetails))
    },
    deleteComment: (commentDetails: {
      postId: number,
      commentId: number,
    }) => {
      dispatch(DELETE_COMMENT(commentDetails))
    },
    emptyIndexPost: () => {
      dispatch(EMPTY_INDEX_POST())
    }
  }
}

const mapStateToProps = (state: any) => {
  //replace Reducer name with state.'Your Reducer name' and .property

  return {
    list: state.takeAction.allIndexPost,
  };
  // this.setState({ myAllPost: state.takeAction.allIndexPost})


};
//@ts-ignore
const HomeWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Trending))
export default withStyles(HomeStyles)(HomeWithRouter)
