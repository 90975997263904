import ConversationController, {
  Props
} from "./ConversationController.web";


// Customizable Area Start
import React from "react";
import {
  AppBar,
  Backdrop,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  List,
  TextField,
  Toolbar,
  Typography,
  ListItem
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import Cookies from "js-cookie";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Scrollbar } from "react-scrollbars-custom";
// Customizable Area End
export default class ConversationWeb extends ConversationController {
  constructor (props: Props) {
    super(props);
    // Customizable Area Start
    //this.scrollDiv = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    //@ts-ignore
    const { classes } = this.props;
    const { loading, text, messages, channel } = this.state;
    // const { location } = this.props;
    // const { state } = location || {};
    // const { email, room } = state || {};
    // const { message, email } = this.props;
    let messageOwner = Cookies.get("Login_Email") || "";
    let isOwnMessage = false;
    if (messageOwner) {
      isOwnMessage = true
    }
    let count=-1;
    const handleKeyDown = (event: any,id:any) => {
      if (event.key === 'Enter') {
        this.sendMessage(event, id)
        const divdata=document.getElementById('chatBottomView'+id)
        
        //@ts-ignore
        document.getElementById('chatBottomView'+id).scrollIntoView();
      }
    }
    return (

      <Container>
        <Backdrop open={loading} style={{ zIndex: 99999 }}>
          <CircularProgress style={{ color: "white" }} />
        </Backdrop>
        <CssBaseline />
        <Grid container direction="column" style={styles.mainGrid}>
          <Grid item style={styles.gridItemChatList} >
            <List dense={true}>
              {messages.length !== 0 ? (
                messages.map((message: any, index: any) => {

                  if( message.state.type==="media") {
                                  
                                      return(
                                        <ListItem
                                        key={index + message.author + 1}
                                        //@ts-ignore
                                        style={styles.listItem(message.state.author)}>
                                         <div>
                                        <img 
                                        //@ts-ignore
                                        alt="&nbsp;&nbsp;Loading..."  key={index+index} style={{height:'60px',width:'100px'}} id={"msgImG"+index}/>
                                        
                                        </div>
                                        <div key={index + message.author + message + index}
                                        //@ts-ignore
                                        style={styles.timestamp}>
                                        {new Date(message.dateCreated.toISOString()).toLocaleString()}
                                      </div>
                                        </ListItem>)
                                   }else{
                                     return(
                                    <ListItem
                                    key={index + message.author + 1}
                                    //@ts-ignore
                                    style={styles.listItem(message.state.author)}>
                                    <div key={index + message.author + 2 + index}
                                      style={styles.container(message.state.author)}>
                                    {message.body}
                                    </div>
                                     <div key={index + message.author + message + index}
                                     //@ts-ignore
                                     style={styles.timestamp}>
                                     {new Date(message.dateCreated.toISOString()).toLocaleString()}
                                   </div>
                                 </ListItem>)
                                  
                                   }
                                   
                                
                 })) : ( 
                   this.state.loadMessages?  
                 <div  style={{textAlign:'center'}}> <CircularProgress/></div>: <h4 style={{color:'gray',textAlign:'center'}}>You don't have any messages yet!</h4>)
              
               }
              <div id={"chatBottomView"+this.props.documentId}></div>
            </List>
          </Grid>
          <Grid item style={styles.gridItemMessage}>
          <div>
                      {this.state.tempImgUrl !==''&&this.state.tempImgUrl.urlArr.map((element:any,imgIndex:number) => {
                      return(
                      <>
                      <IconButton style={{position:'absolute',backgroundColor:'white'}} key={imgIndex} id={this.state.tempImgUrl.fileName[imgIndex]} onClick={()=>{this.setImages(this.state.tempImgUrl.fileName[imgIndex])}}>
                      <CloseIcon style={{position:'absolute'}} key={imgIndex+element}/>
                      </IconButton>
                      <img src={element} alt="" width={'10%'} key={imgIndex+element+imgIndex} id={this.state.tempImgUrl.fileName[imgIndex]+'img'}/>
                      </>)
                      })}
                      <h5 id={
                       //@ts-ignore
                       this.props?.UserEmai+'chat'
                      } style={{display:'none'}} >Typing...</h5>
                      </div>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item
                //@ts-ignore
                style={styles.textFieldContainer}>
                <div>
                <input
                  accept="image/*"
                  id={"icon-button-file"+this.props.documentId}
                  type="file"
                  name="profilePic"
                  multiple
                  style={{ display: "none" }}
                  onChange={(event) => this.fileUploadHandler(event,this.props.documentId)}
                />
                 <label htmlFor={"icon-button-file"+this.props.documentId}>
                <IconButton
                  aria-label="upload picture"
                  component="span">
                  <AddPhotoAlternateOutlinedIcon style={{ color: "#1f7fb6" }} />
                </IconButton>
                </label> 
                </div>
                <TextField
                      id={"sendChatMessageBtn"+ this.props.documentId}
                      // style={styles.textField}
                      variant="outlined"
                      placeholder="Message"
                      onChange={(event) =>{
                        this.setState({ text: event.target.value })
                        if(this.state?.channel !== '' && this.state?.channel !== null && this.state?.channel !== undefined ){
                        this.state?.channel?.typing()}
                      }}
                      onKeyDown={(event:any)=>handleKeyDown(event,this.props.documentId)}
                    />
                <IconButton
                  style={styles.sendButton}
                 onClick={(event:any)=>this.sendMessage(event,this.props.documentId)}
                // disabled={!channel || !text}
                >
                  <Send style={styles.sendIcon} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
const styles = {
  listItem: (isOwnMessage: any) => ({
    flexDirection: "column",
    alignItems: isOwnMessage === Cookies.get("Login_Email") ? "flex-end" : "flex-start",
    padding: "0",
    paddingTop: "10px",
  }),
  container: (isOwnMessage: any) => ({
    maxWidth: "90%",
    borderRadius: "12px",
    padding: "10px 15px",
    lineHeight: "1.3",
    fontSize: "15px",
    backgroundColor: isOwnMessage=== Cookies.get("Login_Email") ? "#1f7fb6" : "#d8dddd",
    color: isOwnMessage=== Cookies.get("Login_Email") ? "#ffffff" : "#000105",
    fontFamily: "'Roboto', sans-serif",
    flexRedirection: "column-reverse",
    overflowWrap: "break-word" as "break-word"
  }),
  author: { fontSize: 10, color: "gray" },
  timestamp: { fontSize: 8, color: "#888b87", textAlign: "right", paddingTop: 4 },
  textField: {
    backgroundColor: "#ebecec",
    color: "#6e7370",
    fontSize: "14px",
    borderRadius: "30px",
    margin: "0",
    width: "100%",
    cursor: "pointer",
  },
  textFieldContainer: {
    left: "0",
    bottom: "0",
    right: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding:"0 10px",
    position: "absolute"
  },
  gridItemChatList: { overflow: "auto" },
  sendButton: { backgroundColor: "#1f7fb6", width: "40px", padding: "10px", height: "40px", marginLeft: "10px" },
  sendIcon: { color: "#ffffff", width: "auto", height: "auto", },
  mainGrid: { borderWidth: 1, padding: "0" },
  gridItemMessage: { padding: "0 2px" }
};