// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Settings2 from "../../blocks/Settings2/src/Settings2";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import PushNotifications from "../../blocks/PushNotifications/src/Pushnotifications";
import SearchHistory from "../../blocks/SearchHistory/src/SearchHistory";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Followers from "../../blocks/Followers/src/Followers";
import ApiIntegration8 from "../../blocks/ApiIntegration8/src/ApiIntegration8";
import CfVerifyCurrentOwnership3 from "../../blocks/CfVerifyCurrentOwnership3/src/CfVerifyCurrentOwnership3";
import AdvancedSearch from "../../blocks/AdvancedSearch/src/AdvancedSearch";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import BlockUsers7 from "../../blocks/BlockUsers7/src/BlockUsers7";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import TwofactorAuthentication from "../../blocks/TwofactorAuthentication/src/TwofactorAuthentication";
import LinkShare from "../../blocks/LinkShare/src/LinkShare";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import PrivacySettings from "../../blocks/PrivacySettings/src/PrivacySettings";
import Comments from "../../blocks/Comments/src/Comments";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import SingleSignonsso from "../../blocks/SingleSignonsso/src/SingleSignonsso";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import CfVerifySignature3 from "../../blocks/CfVerifySignature3/src/CfVerifySignature3";
import Trending from "../../blocks/Trending/src/Trending";
import LikeAPost from "../../blocks/LikeAPost/src/LikeAPost";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import CfOpenseaIntegration3 from "../../blocks/CfOpenseaIntegration3/src/CfOpenseaIntegration3";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import TargetedFeed from "../../blocks/TargetedFeed/src/TargetedFeed";
import Chat9 from "../../blocks/Chat9/src/Chat9";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import ContentFlag from "../../blocks/ContentFlag/src/ContentFlag";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import Captcha from "../../blocks/Captcha/src/Captcha";
import Analytics3 from "../../blocks/Analytics3/src/Analytics3";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import TwilioIntegration2 from "../../blocks/TwilioIntegration2/src/TwilioIntegration2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CfPullAssetsFromOpenseaneedCheck2 from "../../blocks/CfPullAssetsFromOpenseaneedCheck2/src/CfPullAssetsFromOpenseaneedCheck2";
import AddFriends from "../../blocks/AddFriends/src/AddFriends";
import CfMetamask from "../../blocks/CfMetamask/src/CfMetamask";
import CfDapp from "../../blocks/CfDapp/src/CfDapp";



// Customizable Area Start
import { Switch } from "react-router-dom";
import EmailAccountRegistrationSignUp from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import CreateProfile from "../../blocks/email-account-registration/src/CreateProfile.web";
import VerificationCode from "../../blocks/email-account-registration/src/VerificationCode.web";
import SettingUpProfilePic from "../../blocks/email-account-registration/src/SettingUpProfilePic.web";
import EmailAccountLogin from "../../blocks/email-account-login/src/EmailAccountLogin.web";
import ComingSoon from "../../blocks/email-account-login/src/ComingSoon.web";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import PasswordRecovery from "../../blocks/forgot-password/src/PasswordRecovery.web";
import ForgotPasswordConfirmation from "../../blocks/forgot-password/src/ForgotPasswordConfirmation.web"
import ForgotPasswordOTPWeb from "../../blocks/forgot-password/src/ForgotPasswordOTP.web"
import MyProfile from "../../blocks/user-profile-basic/src/MyProfile.web";
import notFoundPage from "../../blocks/user-profile-basic/src/404.web";
import InternalServerErrorPage from "../../blocks/user-profile-basic/src/500.web";
import SearchResult from "../../blocks/user-profile-basic/src/SearchResult.web";
import chatOneToOne from "../../blocks/user-profile-basic/src/chatOneToOne.web";
import postSeparate from "../../blocks/user-profile-basic/src/postSeparate.web";
import userSetting from "../../blocks/user-profile-basic/src/userAccountSetting.web";
import LogoImage from '../../blocks/image_Background.png';
import HomeWeb from "../../blocks/TargetedFeed/src/Home.web";
import TrendingWeb from "../../blocks/TargetedFeed/src/Trending.web";
import SinglePost from "../../blocks/TargetedFeed/src/SinglePost.web";
import User from "../../blocks/TargetedFeed/src/User.web";
import ValidateOtp from "../../blocks/otp-input-confirmation/src/ValidateInputOtp"
import AddFriendsProfile from "../../blocks/AddFriends/src/AddFriendsProfile.web";
import Conversation from "../../blocks/Chat9/src/Conversation.web"

import { onMessageListener } from "../../web/src/firebaseInit";
import Notifications from "../../web/src/Notifications";
import ReactNotificationComponent from "../../web/src/ReactNotification";
// Customizable Area End


const routeMap = {
CfMetamask:{
 component:CfMetamask,
path:"/CfMetamask"},
CfDapp:{
 component:CfDapp,
path:"/CfDapp"},

Settings2:{
 component:Settings2,
path:"/Settings2"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
PushNotifications:{
 component:PushNotifications,
path:"/PushNotifications"},
SearchHistory:{
 component:SearchHistory,
path:"/SearchHistory"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Followers:{
 component:Followers,
path:"/Followers"},
ApiIntegration8:{
 component:ApiIntegration8,
path:"/ApiIntegration8"},
CfVerifyCurrentOwnership3:{
 component:CfVerifyCurrentOwnership3,
path:"/CfVerifyCurrentOwnership3"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
BlockUsers7:{
 component:BlockUsers7,
path:"/BlockUsers7"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
TwofactorAuthentication:{
 component:TwofactorAuthentication,
path:"/TwofactorAuthentication"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
PrivacySettings:{
 component:PrivacySettings,
path:"/PrivacySettings"},
Comments:{
 component:Comments,
path:"/Comments"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
SingleSignonsso:{
 component:SingleSignonsso,
path:"/SingleSignonsso"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
CfVerifySignature3:{
 component:CfVerifySignature3,
path:"/CfVerifySignature3"},
Trending:{
 component:Trending,
path:"/Trending"},
LikeAPost:{
 component:LikeAPost,
path:"/LikeAPost"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
CfOpenseaIntegration3:{
 component:CfOpenseaIntegration3,
path:"/CfOpenseaIntegration3"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
TargetedFeed:{
 component:TargetedFeed,
path:"/TargetedFeed"},
Chat9:{
 component:Chat9,
path:"/Chat9"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
ContentFlag:{
 component:ContentFlag,
path:"/ContentFlag"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},
Captcha:{
 component:Captcha,
path:"/Captcha"},
Analytics3:{
 component:Analytics3,
path:"/Analytics3"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
TwilioIntegration2:{
 component:TwilioIntegration2,
path:"/TwilioIntegration2"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
CfPullAssetsFromOpenseaneedCheck2:{
 component:CfPullAssetsFromOpenseaneedCheck2,
path:"/CfPullAssetsFromOpenseaneedCheck2"},
AddFriends:{
 component:AddFriends,
path:"/AddFriends"},

  // Home: {
  //   component: HomeScreen,
  //   path: '/',
  //   exact: true
  // },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: InternalServerErrorPage,
    path: "*/AlertWeb",
    exact: true
  },
    // Customizable Area Start
    EmailAccountRegistrationSignUp: {
      component: EmailAccountRegistrationSignUp,
      path: "/signUp",
    },
    CreateProfile: {
      component: CreateProfile,
      path: "/profile",
    },
    SettingUpProfilePic: {
      component: SettingUpProfilePic,
      path: "/settingProfilePic",
    },
    EmailAccountLogin: {
      component: EmailAccountLogin,
      path: "/login"
  },
  ComingSoon: {
      component: ComingSoon,
      path: "/coming-soon"
  },
    VerificationCode: {
      component: VerificationCode,
      path: "/verification"
    },
    ForgotPasswordWeb: {
      component:  ForgotPasswordWeb,
      path: "/forgotPass"
  },
     ForgotPasswordConfirmation: {
      component:  ForgotPasswordConfirmation,
      path: "/ForgotPasswordConfirmation"
  },
      ForgotPasswordEmailConfirmation: {
      component:  ForgotPasswordOTPWeb,
      path: "/ForgotPasswordOTPWeb"
  },
       ValidateOtp: {
      component:  ValidateOtp,
      path: "/ValidateOtp"
    },
    ResetPassword: {
      component:  ResetPassword,
      path: "/resetPassword"
    },
    PasswordRecovery: {
      component:  PasswordRecovery,
      path: "/passwordRecovery"
    },
    MyProfile: {
      component:  MyProfile,
      path: "/myprofile",
    },
    SearchResult: {
      component:  SearchResult,
      path: "/searchresult"
    },
    chatOneToOne: {
      component:  chatOneToOne,
      path: "/chat",
    },
    // postSeparate: {
    //   component:  postSeparate,
    //   path: "/post"
    // },
    HomeWeb: {
      component: HomeWeb,
      path: '/',
      exact: true
    },
    TrendingWeb: {
      component: TrendingWeb,
      path: '/trendingpost',
      exact: true
    },
    SinglePost: {
      component: SinglePost,
      path: '/post/:id',
      exact: true
    },
    User: {
      component: User,
      path: '/users'
    },
    AddFriendsProfile:{
      component: AddFriendsProfile,
      path: '/user/:id'
    },
    userSetting:{
      component: userSetting,
      path: '/userSetting',
      exact: true,
    },
    notFoundPage: {
      component: notFoundPage,
      path: "/404",
      exact: true,
    },
    profileByUsername:{
      component: AddFriendsProfile,
      path: '/:username',
      exact: true,
    }
  // Customizable Area End
};

// const firebaseAPI = firebase.initializeApp({
//   apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
//   authDomain: "rnmasterapp-c11e9.firebaseapp.com",
//   databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
//   projectId: "rnmasterapp-c11e9",
//   storageBucket: "rnmasterapp-c11e9.appspot.com",
//   messagingSenderId: "649592030497",
//   appId: "1:649592030497:web:7728bee3f2baef208daa60",
//   measurementId: "G-FYBCF3Z2W3"
// });
var sectionStyle = {
   backgroundImage: `url(${LogoImage})`
};



class App extends Component {

  constructor(props){
    super(props)
    this.state={
      show:false,
      notification:{title:'',body:''}
    }
  }
  
 
  render() {
   
    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');
    //bx_block_notifications/notifications
    onMessageListener()
    .then((payload) => {
      // this.setState({})
      this.setState({
        show:true,
        notification:{
        title: payload.notification.title,
        body: payload.notification.body,
        }
      });
      console.log(payload);
    })
    .catch((err) => console.log(err));
    return (
       <div style={sectionStyle}>
        {this.state.show ? (
        <ReactNotificationComponent
          title={this.state.notification.title}
          body={this.state.notification.body}
        />
      ) : (
        <></>
      )}
      <Notifications />
      <View style={{ height: '100vh', width: '100vw' }}>
        {/*<TopNav />*/}
        <Switch>
        {WebRoutesGenerator({ routeMap })}
        </Switch>
        <ModalContainer />
        </View>
          </div>
    );
  }
}
export default App;