import React from "react";

// Customizable Area Start
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Formik, Form } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactSelect from 'react-select'
import {bgImage} from "./assets";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {withRouter} from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import CreateProfileController, { configJSON, Props } from "./CreateProfileController.web";

 class CreateProfile extends CreateProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    const { classes } = this.props
    return (
      <>
        <div className={classes.mainWrapper}>
          <div className={classes.container}>
            <div className={classes.leftBlock} style={{}}>
              <h1 className={classes.title}>deegee</h1>
              <h2 className={classes.welcomeTitle}>Welcome!</h2>
              <span style={{ width: "100px", border: "2px solid #fff", display: "block", margin: "20px auto 30px auto" }}></span>
              <p>Social networking for NFT collectors. Showcase your <br></br>NFT's to the world around you.</p>
            </div>
            <div className={classes.rightBlock}>

              <Formik
                enableReinitialize={true}
                initialValues={{
                  firstName: this.responseFirstName,
                  lastName: this.responseLastName,
                  countryCode: "",
                  phone: "",
                  dateOfbirth: "",
                  email: this.responseEmail,
                  country: "",
                  state: "",
                  city: "",
                  bio: "",
                }}
                validationSchema={this.ContinueSchema}
                onSubmit={(values): any => this.doContinue(values)}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  handleBlur,
                }) => (
                  //@ts-ignore
                  <Form className={classes.registerFrm}>
                    <h2 className={classes.formHead1} style={{ margin: "0 0 20px 0" }}>Profile</h2>
                    <p className={classes.formHead}>Tell us about you</p>
                    <p className={classes.formTxt}>Please enter following information</p>
                    <div className={classes.mainFrmCard}>
                      <div className={classes.formGrp}>
                        <div className={classes.formCol} >
                          <label className={classes.formLabel}>First Name*</label>
                          <TextField
                            id="firstName"

                            className={classes.textField}
                            InputProps={{
                              className: classes.input,
                            }}
                            placeholder="First Name"
                            variant="outlined"
                            name="firstName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            helperText={errors.firstName}
                            error={touched.firstName && errors.firstName ? true : false}
                            disabled={this.state.submitBtn}
                          />
                        </div>
                        <div className={classes.formCol + " " + classes.ml}>
                          <label className={classes.formLabel}>Last Name*</label>
                          <TextField
                            id="lastName"

                            className={classes.textField}
                            InputProps={{
                              className: classes.input,
                            }}
                            placeholder="Last Name"
                            variant="outlined"
                            name="lastName"
                            onChange={handleChange}
                            value={values.lastName}
                            error={touched.lastName && errors.lastName ? true : false}
                            onBlur={handleBlur}
                            helperText={errors.lastName}
                            disabled={this.state.submitBtn}
                          />
                        </div>
                      </div>
                      <div className={classes.formGrp}>
                        <div className={classes.formCol} >
                          <label className={classes.formLabel}>Email*</label>
                          <TextField
                            id="email"

                            className={classes.textField}
                            InputProps={{
                              className: classes.input,
                            }}
                            variant="outlined"
                            name="email"
                            placeholder="Enter email"
                            onChange={handleChange}
                            value={values.email}
                            error={touched.email && errors.email ? true : false}
                            onBlur={handleBlur}
                            helperText={errors.email}
                            disabled={this.state.submitBtn}
                          />
                        </div>
                      </div>
                      <div className={classes.formGrp}>
                        <div className={`${classes.formCol} ${classes.selectField}`} >
                          <label className={classes.formLabel}>Phone number</label>
                          <div className={classes.phoneDetail}>
                            <FormControl
                              error={touched.countryCode && errors.countryCode ? true : false}
                            >
                              <ReactSelect
                                value={configJSON.countryCodes.find((option: any) => option.dial_code === values.countryCode) || ''}
                                onChange={(e: any) => setFieldValue("countryCode", e.dial_code)}
                                options={configJSON.countryCodes}
                                isSearchable
                                placeholder="Country code"
                                getOptionLabel={(country: any) => `(${country.dial_code}) ${country.name}`}
                                getOptionValue={(country: any) => country.dial_code}
                              />
                            </FormControl>
                            <TextField
                              id="phone"
                              className={classes.textField}
                              InputProps={{
                                className: classes.input,
                              }}
                              variant="outlined"
                              name="phone"
                              placeholder="Phone number"
                              onChange={handleChange}
                              value={values.phone}
                              error={touched.phone && errors.phone ? true : false}
                              helperText={errors.phone}
                              onBlur={handleBlur} />
                          </div>
                        </div>
                      </div>
                      <div className={classes.formGrp}>
                        <div className={classes.formCol + " " + classes.selectField}>
                          <label className={classes.formLabel}>Date Of birth</label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              InputProps={{
                                className: classes.input,
                                readOnly: true
                              }}
                              style={{ width: "100%" }}
                              value={values.dateOfbirth}
                              placeholder="DD/MM/YYYY"
                              id="dateOfbirth"
                              name="dateOfbirth"
                              maxDate={new Date()}
                              onChange={() => { }}
                              format="dd-MM-yyyy"
                              onAccept={(date) => setFieldValue("dateOfbirth", date)}
                              error={false}
                              helperText={null}
                              inputProps={{ readOnly: true }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                      <div className={classes.formGrp} >
                        <div className={classes.formCol + " " + classes.selectField} >
                          <label className={classes.formLabel}>Country</label>
                          <FormControl
                            error={touched.country && errors.country ? true : false}
                            style={{ width: "100%" }}
                          >
                            <ReactSelect
                              value={this.state.countryData.find((option: any) => option.iso2 === values.country) || ''}
                              onChange={(e: any) => this.getStatesByCountryNameApi(e, setFieldValue)}
                              options={this.state.countryData}
                              isSearchable
                              placeholder="Select a country"
                              getOptionLabel={(country: any) => country.name}
                              getOptionValue={(country: any) => country.iso2}
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className={classes.formGrp} >
                        <div className={classes.formCol + " " + classes.selectField} >
                          <label className={classes.formLabel}>State
                          </label>
                          <FormControl
                            error={touched.country && errors.country ? true : false}
                            style={{ width: "100%" }}
                          >
                            <ReactSelect
                              value={this.state.stateData.find((option: any) => option.iso2 === values.state) || ''}
                              onChange={(e: any) => this.getCitiesByStateNameApi(e, setFieldValue)}
                              options={this.state.stateData}
                              isSearchable
                              placeholder="Select a state"
                              getOptionLabel={(state: any) => state.name}
                              getOptionValue={(state: any) => state.iso2}
                            />
                          </FormControl>
                        </div>
                        <div className={classes.formCol + " " + classes.ml + " " + classes.selectField}>
                          <label className={classes.formLabel}>City
                          </label>
                          <FormControl
                            error={touched.country && errors.country ? true : false}
                            style={{ width: "100%" }}
                          >
                            <ReactSelect
                              value={this.state.cityData.find((option: any) => option.name === values.city) || ''}
                              onChange={(e: any) => setFieldValue("city", e.name)}
                              options={this.state.cityData}
                              isSearchable
                              placeholder="Select a city"
                              getOptionLabel={(city: any) => city.name}
                              getOptionValue={(city: any) => city.name}
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className={classes.formCol} >
                        <label className={classes.formLabel}>Bio
                        </label>
                        <TextField
                          style={{ width: "100%" }}
                          id="bio"
                          //label={this.labelBio}
                          multiline
                          rows={3}
                          variant="outlined"
                          error={touched.bio && errors.bio ? true : false}
                          name="bio"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          helperText={errors.bio}
                          placeholder="Profile bio"
                        />
                      </div>
                      <div className={classes.formCol} >
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "#1882AD", color: "white", width: "100%", height: "50px", marginTop: "25px", padding: "10px 30px", textTransform: "none", fontSize: "16px", fontFamily: "'Poppins', sans-serif" }}
                          type="submit"
                          onClick={(e) => {
                            handleSubmit();
                          }}
                          disabled={isSubmitting || this.state.continueLoder}
                        >
                          {this.state.continueLoder ? <CircularProgress size={30} style={{ color: '#ffffff' }} /> : "Continue"}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const CreateProfileStyles = {
  mainWrapper: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    fontFamily: "'Poppins', sans-serif",
    overflowY:"auto",
    backgroundColor:"#1882AD",
    padding:"30px 0",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    background:"url("+bgImage+")",
  },
  phoneDetail: {
    display: "flex",
    alignItems: "center",
    "& >.MuiFormControl-root": {
      color: "#a6a7af",
      fontSize: "15px",
      fontWeight: 500,
      borderRadius: "7px",
      backgroundColor:" #ffffff !important",
      marginRight: "10px",
      minWidth: "160px",
      "& .MuiInput-root": {
        padding: "2px 0",
      },
      "&.MuiTextField-root": {
        marginRight: "0",
      }
    }
  },
  buttonOutline:
  {
    marginTop: "45px",
    color: "#fff",
    border: "1px solid white",
    padding:"0 25px",
    height: "44px",
    cursor: "pointer",
    textTransform:" none",
  },
  MuiTypographyBody1:{
    "& .MuiTypography-root":{
    fontSize: "14px",
    fontWeight: "400",
    color: "#9E9F9E",
    }
  },
  container: {
    display: "flex",
    width: "100%",
    margin: "0 10px",
    justifyContent: "center",
    maxWidth:"1280px",

    "@media (max-width: 767px)": {
      flexDirection: "column",
      justifyContent: "flex-start",
      
    },
    "@media (max-width: 1024px)": {
      width: "100%",
      padding: "10px"
    },
   
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "20px",
    }
  },
  title:
  {
    fontSize: "72px",
    fontWeight: "500",
    margin:"0",
    "@media (max-width: 767px)": {
      fontSize: "42px",
    }
  },
  welcomeTitle:
  {
    fontSize: "52px",
    fontWeight: "500",
    margin:"20px 0 0 0",
    "@media (max-width: 767px)": {
      fontSize: "36px",
    }
  },
  leftBlock:
  {
    textAlign: "center", color: "#fff" ,
    width:"50%",
    paddingTop:"50px",
    "@media (max-width: 767px)": {
      width:"100%",
    }
  },
  rightBlock: {
    width:"50%",
    height: "100%",
    "@media (max-width: 767px)": {
      display: "flex",
      justifyContent: "center",
      paddingLeft: "0",
      margin: "30px 0",
      width:"100%",
    },
  },
  registerFrm: {
    backgroundColor: "white",
    padding: "20px 40px",
    margin: "20px auto",
    borderRadius: "20px",
    width:"80%",
    color:"#4D4E4C",
    maxWidth:"450px",
    "@media (max-width: 767px)": {
      padding: "30px",
      width: "100%",
      margin: "7px auto",
    }
  },

  formHead1:{
    fontSize:"26px",
    fontWeight:"600",
    margin:"0",
  },
  formHead:{
    fontSize:"18px",
    fontWeight:"500",
    margin:"0",
  },
  formTxt:{
    fontSize:"14px",
    color: "#9E9F9E",
    margin: "0 0 10px 0"
  },
  mainFrmCard:
  {
    display: "flex",
    borderRadius: "20px",
    flexWrap: "wrap",
  },
  recaptchaBtn: {
    display: "flex", justifyContent: "center", alignItems: "center", width: "100%", margin: "15px 0",
  },
  textField: {
    width: "100%",
  },
  selectField:{
    borderRadius: "7px !important",
    "& .MuiInput-root":
    {
      fontWeight: 500,
      fontSize: 15,
      color: "#a6a7af",
      borderRadius: 7,
      border:"1px solid rgba(0, 0, 0, 0.23)",
      padding:"2px 0"
    },
    "& .MuiInput-root:before":
    {
      borderBottom: "0 !important",
    },
    "& .MuiInput-root:after":
    {
      borderBottom: "0 !important",
    },
    "& .MuiInput-root:placeholder":
    {
      color: "#000000",
      opacity: 1,
    }

  },
  input: {
    borderRadius: "7px !important",
    margin: "5px 0 5px 0",
    backgroundColor: "#fff !important",
    cursor: "text",
    "& input":
    {
      padding: "10px 17px",
      fontWeight: 500,
      fontSize: 15,
      color: "#a6a7af",
      backgroundColor: "#fff !important",
      borderRadius: 7,
    },
    "& textarea":
    {
      padding: "10px 17px",
      fontWeight: 500,
      fontSize: 7,
      color: "#a6a7af",
    },
    "&::before":
    {
      borderBottom: "0 !important",
    },
    "&::after":
    {
      borderBottom: "0 !important",
    },
    "&::hover":
    {
      borderBottom: "0 !important",
    },
    "&::focus":
    {
      borderBottom: "0 !important",
    },
    "&::placeholder":
    {
      color: "#a6a7af",
      opacity: 1,
    }
  },
  formGrp:
  {
    display: "flex",
    marginTop: "10px",
    width: "100%",
    "& .MuiSelect-select": {
      paddingLeft:"10px"
    },
    "@media (max-width: 767px)": {
      flexDirection: "column",
      flexWrap: "wrap",
    },
  },
  formLabel:
  {
    fontSize:"16px",
    fontWeight:"500",
    marginBottom: "10px",
    whiteSpace: "nowrap",
    "@media (max-width: 1024px)": {
      fontSize: 12,
    },
  },
  formCol:
  {
    width: "100%",
    marginBottom: "10px",
  },
  ml: {
    marginLeft: "15px",
    "@media (max-width: 767px)": {
      marginLeft: "0",
    },
  }
} as any;

//@ts-ignore
const emailCreateProfileWithRouter =withRouter(CreateProfile )
export default withStyles(CreateProfileStyles)(emailCreateProfileWithRouter)