import { BlockComponent } from "../../../framework/src/BlockComponent";

export interface Props {
    open: boolean
    handleClose: any
    url: string
    text?: string
}

export interface S {
    // Customizable Area Start
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class LinkShareController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        this.state = {
            // Customizable Area Start
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End

    }
    // Customizable Area Start
    // Customizable Area End

}