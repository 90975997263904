Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Chat9";
exports.labelBodyText = "Chat9 Body";

exports.btnExampleTitle = "CLICK ME";
exports.chatAuthTokenEndPoint = "bx_block_chat/tokens";
exports.ContentType = "application/json";
exports.httpPostMethod = "POST";
exports.httpGetMethod="GET"
exports.friendsListEndPoints="bx_block_conn/connections/friend_lists"
// Customizable Area End