import React from "react";

// Customizable Area Start
import TextField from "@material-ui/core/TextField";
//@ts-ignore
import { ReactComponent as LogoTitle } from '../../logo-title.svg';
import { withRouter } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";

import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Formik, Form } from "formik";
// Customizable Area End

import ValidateInputOtpController, { Props } from "./ValidateInputOtpController";

class ValidateInputOtp extends ValidateInputOtpController {
  constructor (props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    //@ts-ignore
    const { classes } = this.props
    return (
      <div >
        <Card style={{
          flex: 1,
          padding: 16,
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "200px",
          marginTop: "200px",
          width: '545.76px',
          height: '494.5px',
          backgroundColor: "#fff"
        }}>
          <LogoTitle />
          <CardContent>
            <Typography variant="h5" component="div">
              Forgot Password
            </Typography>
            <Typography style={{}}>
              Please enter your deegee login email and we will send you instructions.
            </Typography>
          </CardContent>
          <CardActions>
            <Formik
              initialValues={{
                otp: "",
              }}
              validationSchema={this.SubmitSchema}
              onSubmit={(values): any => this.submitEmailOtp(values)}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
                handleBlur,
              }) => (
                //@ts-ignore
                <Form style={{ textAlign: "center" }}>
                  <TextField
                    id="otp"
                    variant="outlined"
                    name="otp"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.otp}
                    helperText={errors.otp}
                    error={
                      touched.otp && errors.otp ? true : false
                    }
                    inputProps={{ maxLength: 4, minLength: 4 }}
                    style={{ marginTop: "50px", justifyContent: "center", display: "flex" }}
                    className={classes.inputTexts}
                  />
                  <br />
                  <Button style={{ textTransform: 'capitalize' }} onClick={() => this.ResendOTP()}>Have not received? Resend</Button>
                  <br />
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={(e) => {
                      handleSubmit();
                    }}
                    style={{ background: "#1f7fb6" }} className={classes.cssButton}
                  >
                    Submit Otp
                  </Button>
                </Form>
              )}
            </Formik>
          </CardActions>
        </Card>
      </div>
    );
  }
}

const useStyles = {
  cssButton: {
    height: '50px',
    width: "470px",
    justifyContent: "center",
    display: "flex"
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    width: '545.76px',
    height: '494.5px',
    background: '#fffffe!important',
  },
  texHeaderStyle: {
    width: '328px',
    height: '58px',
    fontFamily: 'Poppins',
    fontSize: '38.7826px',
    fontWeight: 600,
    lineHeight: '58px',
  },
  textStyle: {
    width: '426px',
    height: '30px',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
    marginTop: '70px',
  },
  inputText: {
    height: '50px',
    width: "470px",
    borderRadius: '10px',
    border: 'dashed 1.5px #d9d9d9',
    background: 'rgba(255,255,255,0.87)',
  }
} as any;


//@ts-ignore
const validateInputOtp = withRouter(ValidateInputOtp)
export default withStyles(useStyles)(validateInputOtp)