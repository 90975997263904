import React from "react";

// Customizable Area Start
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Formik, Form, FormikProps } from "formik";
import BorderAllIcon from "@material-ui/icons/BorderAll";
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import moment from "moment";
import "date-fns";
import SearchResultController, { Props } from "./SearchResultController.web";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { getWeb3ReactContext } from "@web3-react/core";
import Avatar from "@material-ui/core/Avatar";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import "@reach/menu-button/styles.css";
import NavbarWeb from "../../TargetedFeed/src/Navbar.web";
import { toast } from "react-toastify";
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from "js-cookie";
// Customizable Area End

class SearchResult extends SearchResultController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 
  render() {
    //@ts-ignore
    const { classes } = this.props;
    //     const openAnchor = Boolean(this.state.anchorEl);
    function TabPanel(props: any) {
      const { children, value, index, ...other } = props;

      return (
        <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>
      );
    }

    const handleRedirect = (value: string | number,isUserName: boolean ) => {
      if (isUserName) {
        //@ts-ignore
        this.props.history.push({
          pathname: '/'+value,
          state: { detail: value}
        })
      } else {
        //@ts-ignore
        this.props.history.push({
          pathname: '/user/'+ value,
          state: { detail: value}
        })
      }
    }

    const handlePostRedirect = (value: string) => {
      //@ts-ignore
      this.props.history.push({
        pathname: '/post/'+ value,
        state: { detail: value}
      })
    }

    return (
      <>
        <NavbarWeb
          //@ts-ignore
          getSearching={this.getSearching}
          makeEmptyUserAndPost={this.makeEmptyUserAndPost}
          //@ts-ignore
        showNotification={'showNotification'} 
        />
        <div className={classes.container}>
          <Box className={classes.tabWrapper}>
            <div className={classes.leftTabWrapper}>
              <Tabs
                value={this.state.value}
                orientation="vertical"
                variant="scrollable"
                selectionFollowsFocus
                onChange={(event: any, newValue: number) => {
                  this.setState({
                    value: newValue,
                  });
                }}
                aria-label="Vertical tabs example"
              >
                <Tab icon={<BorderAllIcon style={this.state.allTab ? ({ backgroundColor: '#1f7fb6', color: '#ffffff' }) : ({ backgroundColor: '#ebecec', color: "#1f7fb6" })} />} label="All" onClick={() => {
                  this.setState({ allTab: true, peopleTab: false, nftsTab: false })
                }} style={this.state.allTab ? ({ backgroundColor: '#ebecec' }) : ({ backgroundColor: '' })} />
                <Tab icon={<BorderAllIcon style={this.state.nftsTab ? ({ backgroundColor: '#1f7fb6', color: '#ffffff' }) : ({ backgroundColor: '#ebecec', color: "#1f7fb6" })} />} label="NFTs" onClick={() => {
                  this.setState({ allTab: false, peopleTab: false, nftsTab: true })
                }} style={this.state.nftsTab ? ({ backgroundColor: '#ebecec' }) : ({ backgroundColor: '' })} />
                <Tab icon={<PeopleAltOutlinedIcon style={this.state.peopleTab ? ({ backgroundColor: '#1f7fb6', color: '#ffffff' }) : ({ backgroundColor: '#ebecec', color: "#1f7fb6" })} />} label="People" onClick={() => {
                  this.setState({ allTab: false, peopleTab: true, nftsTab: false })
                }} style={this.state.peopleTab ? ({ backgroundColor: '#ebecec' }) : ({ backgroundColor: '' })} />
              </Tabs>
            </div>
            <div className={classes.rightTabWrapper}>
              {//@ts-ignore
                ((this.state.allPost?.length > 0 || this.state.allUsers?.length > 0) && document.getElementById('searchNftAndUsers').value !== "") ? (
                  <TabPanel value={this.state.value} index={0}>
                    <div className={classes.tabContent}>
                      {this.state.allUsers.length !== 0 && (
                        <div className={classes.peopleTab}>
                          <h2>Peoples ({this.state.allUsers.length})</h2>
                          <div>
                            {this.state.allUsers &&
                              this.state.allUsers.map(
                                (user: any, index: number) => {
                                  if (index <= this.state.pendingCount) {
                                    return (
                                      <div
                                        className={classes.accordionUser}
                                        key={index}
                                      >
                                        <div
                                          className={classes.userInfo}
                                        >
                                          <Avatar
                                            alt={user.full_name}
                                            src={user.image ? user.image : "../assets/user_img.png"}
                                          />
                                          <Button style={{ textTransform: 'initial' }} onClick={() => {
                                            if (Cookies.get('Login_Id') != user.id) {
                                              if (user.user_name) handleRedirect(user.user_name, true)
                                              else handleRedirect(user.id, false)
                                            }
                                          }}>
                                            <Typography key={index + index + index}>
                                              {user.full_name}
                                              <span>
                                                {user.no_common_friends} friend(s) in common
                                              </span>
                                            </Typography>
                                          </Button>
                                        </div>
                                        {!user.is_blocked && Cookies.get('Login_Id') != user.id &&
                                          (!user.status ? (<Button onClick={() => this.sendRequest(user.id, index)} className={classes.btnAddFriend} disabled={user.status === null && false} id={'addFriendBtn' + index} ><PersonAddOutlinedIcon />Add friend</Button>) : (user.status === 'pending' ? (<Button onClick={() => this.sendRequest(user.id, index)} className={classes.btnAddFriend} disabled={user.status === 'pending' ? true : false} ><PersonAddOutlinedIcon />Add friend</Button>) : (<Button disabled={true} className={classes.btnAddFriend} >Friend</Button>)))
                                        }
                                      </div>
                                    );
                                  }
                                }
                              )}
                          </div>
                          <Button type="submit" style={useStyles.buttonUpload} onClick={() => {
                            this.setState({ allTab: false, peopleTab: true, nftsTab: false, value: 2 })
                          }
                          }>
                            View all
                          </Button>
                        </div>
                      )}

                      {this.state.allPost.length !== 0 &&
                        this.state.allPost.map((post: any, index: number) => {
                          if (index <= this.state.pendingCo) {
                            return (
                              <div className={classes.NFTsWrapper} key={post?.id}
                                onClick={() => {
                                  handlePostRedirect(post?.id)
                                }}
                              >
                                <div
                                  className={classes.NFTCard}
                                >
                                  <div
                                    className={classes.accordionUser}
                                    style={{ borderBottom: "1px solid #dcdcd7" }}
                                  >
                                    <div
                                      className={classes.userInfo}
                                    >
                                      <Button style={{ textTransform: 'initial' }} onClick={(event) => {
                                        event.stopPropagation()
                                        if (Cookies.get('Login_Id') != post.user_id) {
                                          if (post.user_name) handleRedirect(post.user_name, true)
                                          else handleRedirect(post.user_id, false)
                                        } else {
                                          //@ts-ignore
                                          this.props.history.push('/myprofile')
                                        }
                                      }}>
                                        <Avatar
                                          alt="Jessica Jones"
                                          src={post.image ? post.image : "../assets/user_img.png"}
                                        />
                                        <Typography
                                          style={{ fontSize: "16px" }}
                                        >
                                          {post.name}
                                        </Typography>
                                      </Button>
                                    </div>
                                  </div>
                                  <span
                                    style={useStyles.workInfo}
                                  >
                                    {post.nft_name}
                                  </span>
                                  <div className={classes.bottomCard}>
                                    <div className={classes.leftBottom}>
                                      <ul
                                        className={classes.NFTcardList}
                                      >
                                        <li>
                                          {post.time}
                                        </li>
                                        {post.image_url && <li
                                          style={{ color: "#1f7fb6" }}
                                        >
                                          {`Paid $ ${post?.usd_amount || 0}(${post?.eth_amount || 0} ETH)`}
                                        </li>}
                                      </ul>
                                      <p>
                                        {post.description}
                                      </p>
                                    </div>
                                    {post.image_url && <div className={classes.RightBottom}>
                                      <img src={post.image_url} alt="post" />
                                    </div>} 
                                  </div>
                                  <div className={classes.likeCommentShare}>
                                    <IconButton>
                                      <FavoriteIcon style={{ color: "#1f7fb6" }} />
                                      <p>
                                        {post.likes_count}
                                      </p>
                                    </IconButton>
                                    <IconButton aria-label="comment">
                                      <p>
                                        {post.comments_count} Comment(s)
                                      </p>
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </TabPanel>
                ) : (
                  (this.state.resultNoFound === "" ? (
                    <CircularProgress style={{ display: 'block', margin: 'auto', marginTop: '40px', color: '#0388c9' }} />
                  ) : (
                    <h3 style={{ textAlign: 'center', fontFamily: 'Poppins' }}>{this.state.resultNoFound}</h3>
                  ))
                )}

              <TabPanel value={this.state.value} index={1}>
                {/* <div className={classes.NFTsWrapper}> */}

                {this.state.allPost?.length > 0 &&
                  this.state.allPost?.map((post: any, index: number) => {
                    return (
                      <div className={classes.NFTsWrapper} key={post?.id || index}
                        onClick={() => {
                          if (post?.id) handlePostRedirect(post.id)
                        }}>
                        <div
                          className={classes.NFTCard}
                        >
                          <div
                            className={classes.accordionUser}
                            style={{ borderBottom: "1px solid #dcdcd7" }}
                          >
                            <div
                              className={classes.userInfo}
                            >
                              <Button style={{ textTransform: 'initial' }} onClick={(event) => {
                                event?.stopPropagation()
                                if (Cookies.get('Login_Id') != post.user_id) {
                                  if (post?.user_name) handleRedirect(post.user_name, true)
                                  else handleRedirect(post.user_id, false)
                                } else {
                                  //@ts-ignore
                                  this.props.history.push('/myprofile')
                                }
                              }} >
                                <Avatar
                                  alt="Jessica Jones"
                                  src={post.image ? post.image : "../assets/user_img.png"}
                                />
                                <Typography style={{ fontSize: "16px" }}>
                                  {post.name}
                                </Typography>
                              </Button>
                            </div>
                          </div>
                          <span
                            style={useStyles.workInfo}
                          >
                            {post.nft_name}
                          </span>
                          <div className={classes.bottomCard}>
                            <div className={classes.leftBottom}>
                              <ul
                                className={classes.NFTcardList}
                              >
                                <li
                                >
                                  {post.time}
                                </li>
                                {post.image_url && <li
                                  style={{ color: "#1f7fb6" }}
                                >
                                  {`Paid $ ${post?.usd_amount || 0}(${post?.eth_amount || 0} ETH)`}
                                </li>}
                              </ul>
                              <p>
                                {post.description}
                              </p>
                            </div>
                            {post.image_url && <div
                              className={classes.RightBottom}
                            >
                              <img
                                src={post.image_url}
                                alt="post"
                              />
                            </div>}
                          </div>
                          <div
                            className={classes.likeCommentShare}
                          >
                            <IconButton
                            >
                              <FavoriteIcon
                                style={{ color: "#1f7fb6" }}
                              />
                              <p
                              >
                                {post.likes_count}
                              </p>
                            </IconButton>
                            <IconButton
                              aria-label="comment"
                            >
                              <p
                              >
                                {post.comments_count} Comment(s)
                              </p>
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {/* </div> */}
              </TabPanel>
              <TabPanel value={this.state.value} index={2}>
                {this.state.allUsers?.length > 0 && (
                  <div className={classes.peopleTab}>
                    <h2>Peoples ({this.state.allUsers.length})</h2>
                    <div>
                      {this.state.allUsers &&
                        this.state.allUsers.map(
                          (user: any, index: number) => {
                            return (
                              <div
                                className={classes.accordionUser}
                                key={user?.id || index}
                              >
                                <div
                                  className={classes.userInfo}
                                >
                                  <Avatar
                                    alt="Jessica Jones"
                                    src={user.image ? user.image : "../assets/user_img.png"}
                                  />
                                  <Button style={{ textTransform: 'initial' }} onClick={() => {
                                    if (Cookies.get('Login_Id') != user.id) {
                                      if (user?.user_name) handleRedirect(user.user_name, true)
                                      else handleRedirect(user.id, false)
                                    }
                                  }} >
                                    <Typography key={index + index + index}>
                                      {user.full_name}
                                      <span>
                                        {user.no_common_friends} friend(s) in common
                                      </span>
                                    </Typography>
                                  </Button>
                                </div>
                                {!user.is_blocked && Cookies.get('Login_Id') != user.id &&
                                  (!user.status ?
                                    <Button
                                      onClick={() => this.sendRequest(user.id, index)}
                                      className={classes.btnAddFriend}
                                      disabled={user.status === null && false}
                                      id={'addFriendBtn' + index} >
                                        <PersonAddOutlinedIcon />
                                        Add friend
                                    </Button>
                                    :(user.status === 'pending' ? (<Button onClick={() => this.sendRequest(user.id, index)} className={classes.btnAddFriend} disabled={user.status === 'pending' ? true : false} ><PersonAddOutlinedIcon />Add friend</Button>)
                                  :(<Button disabled={true} className={classes.btnAddFriend} >Friend</Button>)))
                                }
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                )}
              </TabPanel>
            </div>
          </Box>
        </div>
      </>
    );
  }
}
// Customizable Area Start
const useStyles = {
  container: {
    padding: "0 20px",
    overflowX: "hidden",
    height: "100%",
    backgroundColor: "#f1f2f6",
  },
  tabWrapper: {
    display: "flex",
    paddingTop: "24px",
  },
  leftTabWrapper: {
    flex: "0 0 347px",
    maxWidth: "347px",
    backgroundColor: "#fffffe",
    borderRadius: "10px",
    minHeight: "calc(100vh - 115px)",
    height: "100%",
    position: "sticky",
    top: "24px",
    "& .MuiTabs-indicator" :{
      display: "none"
    },
    "& .MuiTab-labelIcon": {
      width: "100%",
      opacity: "1",
      maxWidth: "100%",
      borderRadius: "5px",
      "& .MuiTab-wrapper": {
        flexDirection: "row",
        justifyContent: "flex-start",
        fontSize: "16px",
        color: "#000105",
        fontWeight: "700",
        textTransform: "none",
        fontFamily: "'Poppins', sans-serif",
        margin: "0",
        "& svg": {
          margin: "0",
          width: "55px",
          height: "55px",
          padding: "8px",
          boxSizing: "border-box",
          backgroundColor: "#1f7fb6",
          color: "#ffffff",
          borderRadius: "50%",
          marginRight: "13px",
        },
      },
    },
  },
  rightTabWrapper: {
    flex: "0 0 calc(100% - 347px)",
    maxWidth: "calc(100% - 347px)",
    padding: "0 15px",
  },
  peopleTab: {
    maxWidth: "635px",
    backgroundColor: "#fffffe",
    borderRadius: "5px",
    padding: "22px",
    margin: "0 auto 25px",
    "& h2 ": {
      fontSize: "21px",
      color: "#000105",
      fontWeight: "600",
      fontFamily: "'Poppins', sans-serif",
      margin: "0",
      paddingBottom: "20px",
      borderBottom: "2px solid #d8dddd",
    },
  },
  accordionUser: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    boxSizing: "border-box",
    padding: "13px 0",
    borderBottom: "2px solid #dcdcd7",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "& .MuiButton-text": {
      width: "100%",
    },
    "& .MuiButton-label": {
      textAlign: "left",
      display: "flex",
      justifyContent: "flex-start"
    },
    "& .MuiAvatar-root": {
      width: "56px",
      height: "56px",
      backgroundColor: "#bcbeba",
      borderRadius: "50%",
      marginRight: "10px",
      "@media(max-width: 1199px)": {
        width: "40px",
        height: "40px",
      }
    },
    "& .MuiTypography-root": {
      textTransform: "none",
      fontSize: "14px",
      color: "#000307",
      fontWeight: "700",
      lineHeight: "1",
      fontFamily: "'Roboto', sans-serif",
      "& span": {
        display: "block",
        fontSize: "10px",
        color: "#505452",
        fontWeight: "500",
        fontFamily: "'Roboto', sans-serif",
        marginTop: "10px",
      },
    },
  },
  btnAddFriend: {
    minWidth: "127px",
    backgroundColor: "#d8dddd",
    height: "41px",
    fontSize: "16px",
    color: "#1f7fb6",
    fontWeight: "700",
    letterSpacing: "0",
    border: "1px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    textTransform: "none",
    margin: "0 4px",
    "& svg": {
      width: "17px",
      marginRight: "7px"
    }
  },
  buttonUpload: {
    display: "block",
    background: "#d8dddd",
    border: "2px solid #1f7fb6",
    borderRadius: "5px",
    textAlign: "center",
    color: "#1f7fb6",
    fontWeight: 700,
    width: "100%",
    marginTop: "15px",
  },
  NFTsWrapper: {
    maxWidth: "679px",
    margin: "0 auto",
  },
  NFTCard: {
    backgroundColor: "#fffffe",
    borderRadius: "5px",
    padding: "7px 22px 20px",
    marginBottom: "18px",
    cursor: "pointer",
  },
  workInfo: {
    fontSize: "16px",
    color: "#000307",
    fontWeight: "700",
    lineHeight: "1",
    fontFamily: "'Roboto', sans-serif",
    display: "block",
    margin: "5px 0 10px",
  },
  NFTcardList: {
    margin: "0",
    padding: "0",
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    "& li": {
      position: "relative",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "13px",
      color: "#888b87",
      paddingRight: "20px",
      "&:last-child": {
        paddingRight: "0",
      },
    },
    "li:after": {
      position: "absolute",
      content: "",
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      background: "#888b87",
    },
  },
  bottomCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftBottom: {
    maxWidth: "calc(100% - 100px)",
    flex: "0 0 calc(100% - 100px)",
    "& p": {
      fontFamily: "'Roboto', sans-serif",
      fontSize: "14px",
      color: "#6e7370",
      lineHeight: "1.5",
      margin: "0",
      paddingTop: "5px",
    },
  },
  RightBottom: {
    maxWidth: "90px",
    flex: "0 0 90px",
    height: "90px",
    borderRadius: "10px",
    overflow: "hidden",
    marginLeft: "10px",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  likeCommentShare: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 0 0",
    "& button": {
      padding: "0",
      margin: "0",
      color: "#6e7370",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "'Roboto', sans-serif",
      height: "20px",
      borderRadius: "0",
      "& p": {
        margin: "0 0 0 5px",
      },
    },
  },
} as any;
// Customizable Area End
//@ts-ignore
const searchResult = withRouter(SearchResult);
export default withStyles(useStyles)(searchResult);

const convertBirthDate = (
  value: string,
  separator: string,
  formik: FormikProps<any>
) => {
  if (value === "") {
    formik.setFieldValue("dateOfbirth", "");
  } else {
    const today = new Date();
    if (!moment(value).isAfter(today)) {
      const isValidDate = moment(value, "MM-DD-YYYY", true).isValid();
      const isValidDateWithUnderscore = moment(
        value.replace(/_/g, ""),
        "MM-DD-YY",
        true
      ).isValid();
      if (isValidDate || isValidDateWithUnderscore) {
        let date: any = value;
        const dateParts = date && date.split(separator);
        const withoutUnderScore =
          dateParts && dateParts[2] && dateParts[2].replace(/_/g, "");
        if (withoutUnderScore && withoutUnderScore.length === 2) {
          date = date.slice(0, date.length - 4);
          if (withoutUnderScore < 50) {
            date = date + "20" + withoutUnderScore;
          } else {
            date = date + "19" + withoutUnderScore;
          }
          setBirthDate(date, formik);
        } else {
          setBirthDate(date, formik);
        }
      }
    } else {
      formik.setFieldValue("dateOfbirth", "");
      toast.error("Birth Date can not be in future",{position: toast.POSITION.BOTTOM_RIGHT});
      return false;
    }
    return true;
  }
};
const setBirthDate = (date: any, formik: FormikProps<any>) => {
  formik.setFieldValue("dateOfbirth", date.toString());
};
