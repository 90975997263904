import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import Cookies from "js-cookie";
const Chat = require("twilio-chat");
import { Client as ConversationsClient } from "@twilio/conversations";
import React from "react";

// import { Client, Conversation, Participant, Message, User } from '@twilio/conversations';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  receiveId:any;
  accountId:any;
  callChatUserJoinFunc:any;
  userName: any;
  documentId: any;
  addClient:any
  list:any
  UserEmai:any
  expanded:any
  removeBadge:any
  UserProfile:any
  myInfo:any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  chatAuthToken: string;
  loading: any;
  messages: any;
  channel: any;
  text: any;
  allFriendList: any;
  mediaUrl:any;
  selectedProfileImage: any;
  urlImg:any
  mediaMesg:[];
  imageFile:any;
  checkRoomId:any;
  loadMessages:boolean;
  joinClient:boolean;
  tempImgUrl:any;
  setDeletingImg:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ConversationController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  ChatAuthTokenApiCallId: any;
  Chat_Token: string;
  Token: string;
  allFirendsApiId: any;
  counterState:number=-1;
  // scrollDiv: React.RefObject<unknown>;
  // Customizable Area End

  constructor (props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      chatAuthToken: "",
      loading: false,
      messages: [],
      channel: "",
      text: "",
      allFriendList: [],
      selectedProfileImage: false,
      mediaUrl:'',
      urlImg:'',
      mediaMesg:[],
      checkRoomId:'',
      imageFile:'',
      loadMessages:true,
      joinClient:false,
      tempImgUrl:'',
      setDeletingImg:''
      // Customizable Area End
    };
    
    // this.scrollDiv = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area Start
  componentDidMount = async () => {
    if (Cookies.get('Login_Token')) {
     // await this.generateChatAuthToken()
     
     
      //@ts-ignore
      if (this.props?.accountId && this.props?.receiveId) {
        //this.setState({mediaMesg: []});
       let roomId=""
       if (Number(this.props?.accountId) > Number(this.props?.receiveId)) {
                   roomId = this.props?.receiveId+'_'+this.props?.accountId 
       } else {
         roomId = this.props.accountId+'_'+this.props?.receiveId 
       }
       
  //@ts-ignore
  if(this.props.list){
    
    //@ts-ignore
    const client = this.props.list
    //const channels =  await client.getChannelByUniqueName(roomId);
    let channels =''  
    try {
      channels=await client.getChannelByUniqueName(roomId);
      //@ts-ignore
      const memb=await channels.getMembers()
      if(memb.length ===1){
        try {
          
        //@ts-ignore
        await channels.invite(this.props?.UserEmai)
        } catch (error) {
          console.log(error);
        
        }
      }
     this.setState({ channel: channels});

    await this.joinChannel(channels);  
     
     //@ts-ignore
     const messages = await channels.getMessages();
    
      messages.items.forEach((element:any,index:number) => {
       
         if (element.state.type==="media") {
         // this.getImgUrl(element.state.media)
          
         element.state.media.getContentTemporaryUrl().then(function(url:any) {
           //@ts-ignore
           document.getElementById('msgImG'+index)?.setAttribute("src", url) 
         });
         }
         
      });
      
     this.setState({ messages: messages.items || [] });
     if(messages.items.length===0){
      this.setState({loadMessages:false})
    }
     // @ts-ignore
     await channels?.setAllMessagesConsumed()
     //@ts-ignore
     this.props?.removeBadge(this.props?.receiveId)
     this.scrollToBottom();

    
    } catch (error) {
      this.setState({ messages:[] ,loadMessages:false});
      const channel = await client.createChannel({
        uniqueName: roomId,
        friendlyName: roomId,
      });
      await this.joinChannel(channel);
      this.setState({ channel: channel});
      await channel.invite(this.props?.UserEmai)   
      const obj=[{profile:this.props?.UserProfile,name:this.props?.userName,userId:this.props?.receiveId,email:this.props?.UserEmai},{
        //@ts-ignore
        profile:this.props?.myInfo[0]?.image,name:this.props?.myInfo[0]?.fullName,userId:this.props?.accountId,email:Cookies.get("Login_Email")}]
      await channel.updateAttributes({obj})  
    }
     
  }else{
   await this.createClientComponent()
   //let check=0;
   //@ts-ignore
   const client = this.props.list
   let channels =''  
  try {
    channels=await client.getChannelByUniqueName(roomId);
    
   this.setState({ channel: channels});
   
    //@ts-ignore
    const memb=await channels.getMembers()
    if(memb.length ===1){
      try {
        
      //@ts-ignore
      await channels.invite(this.props?.UserEmai)
      } catch (error) {
        console.log(error);
      
      }
    }
  await this.joinChannel(channels);  
   
   //@ts-ignore
   const messages = await channels.getMessages();
   //this.setState({allChannels:[...this.state.allChannels,channels]})
  
  // this.setState({ channel: channels});
    messages.items.forEach((element:any,index:number) => {
     
       if (element.state.type==="media") {
       // this.getImgUrl(element.state.media)
        
       element.state.media.getContentTemporaryUrl().then(function(url:any) {
         //@ts-ignore
         document.getElementById('msgImG'+index)?.setAttribute("src", url) 
       });
       }
       
    });
    
   this.setState({ messages: messages.items || [] });
   if(messages.items.length===0){
    this.setState({loadMessages:false})
  }
   // @ts-ignore
   await channels?.setAllMessagesConsumed()
     //@ts-ignore
     this.props?.removeBadge(this.props?.receiveId)
   this.scrollToBottom();
  } catch (error) {
    
    
    
    this.setState({ messages:[] ,loadMessages:false});
    const channel = await client.createChannel({
      uniqueName: roomId,
      friendlyName: roomId,
    });
    await this.joinChannel(channel);
    this.setState({ channel: channel});
    //this.setState({allChannels:[...this.state.allChannels,channel]})
    const obj=[{profile:this.props?.UserProfile,name:this.props?.userName,userId:this.props?.receiveId,email:this.props?.UserEmai},{
      //@ts-ignore
      profile:this.props?.myInfo[0]?.image,name:this.props?.myInfo[0]?.fullName,userId:this.props?.accountId,email:Cookies.get("Login_Email")}]
    await channel.updateAttributes({obj})  
 
  }
 
  }

      }
    }
  };


  createClientComponent = async (roomId?:any) => {  
    
    
    let token = "";

    // this.setState({ loading: true });
    try {
      token = Cookies.get("Chat_Token")||await this.getToken();
    } catch {
      throw new Error("unable to get token, please reload this page");
    }

    const client = await Chat.Client.create(token);
    //@ts-ignore
    if(this.props.addClient){
      //@ts-ignore
      this.props.addClient(client)
    }
    
  }
   //@ts-ignore
   componentWillUnmount=()=>{
    //@ts-ignore
    this.state.channel=''
  
  
  }
  // //@ts-ignore
  // componentWillUpdate=async()=>{
  //   if(this.state?.channel !== '' && this.state?.channel !== undefined && this.props?.expanded !=='')
  //   await this.state?.channel.setAllMessagesConsumed()
  // }
  //@ts-ignore
  CheckUserChatRoom = async (room:any) => {
    const token  = this.getToken()
    const client = await Chat.Client.create(token); 
    try {
      const channel = await client.getChannelByUniqueName(room);
      
      await this.joinChannel(channel);
      this.setState({ channel, loading: false });
    } catch (e) {
      try {
        const channel = await client.createChannel({
          uniqueName: room,
          friendlyName: room,
        });
        await this.joinChannel(channel);
        this.setState({ channel, loading: false });
      } catch (e) {
        console.error("unable to create channel",e);
      }
    }
  
  }
   joinChannel = async (channel:any) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }
    channel.on("messageAdded", this.handleMessageAdded);
  };
  
  
  componentWillUpdate=()=>{
  
  this.render()
  }

  handleMessageAdded = async (message:any) => {
    
    
    const { messages } = this.state;
   
    
    this.setState(
      {
        messages: !!messages ? [...messages, message] : [message],
      },
      this.scrollToBottom
    );
    
    if(this.state.channel !='' && this.props?.expanded !==''){
     // @ts-ignore
     await this.state?.channel?.setAllMessagesConsumed()}
    if (message.state.type==="media") {
      // this.getImgUrl(element.state.media)
       
      message.state.media.getContentTemporaryUrl().then(function(url:any) {
        //@ts-ignore
        document.getElementById('msgImG'+messages.length)?.setAttribute("src", url) 
      });
      }
  };
  scrollToBottom = async () => {
    //@ts-ignore
      document.getElementById("chatBottomView"+this.props.documentId)?.scrollIntoView();
    }
  fileUploadHandler = async (e:  React.FormEvent<EventTarget>,id:any) => {
        
    let target = e.target as HTMLInputElement;
    let files = target.files as any;
  
     if(files.length !==0 && files.length < 11){
        
      let name=''
      let urlArr=[];
      let fileName=[];
      for (const key in files) {
      
        if(typeof files[key] === 'object' && files[key].name){
          let url=URL.createObjectURL(files[key])
          urlArr.push(url)
          fileName.push(files[key].name)
          name=files[key].name+" "+name
        }
      }
    let allImg={
      urlArr,
      fileName
    }
    //@ts-ignore
   // document.getElementById("sendOne2OneMessageBtn").value=name
    this.setState({imageFile:files,tempImgUrl:allImg})
    }else{
      if(files.length > 10){
        toast.info("Maximum 10 files are allowed.")
      }
      
    }
    //@ts-ignore
    document.getElementById("sendChatMessageBtn" + id)?.focus()
  };
  setImages=(idOfImg:any)=>{

    for (const key in this.state.imageFile) {
        
      if(this.state.imageFile[key].name === idOfImg){
        
         //@ts-ignore
         document.getElementById(idOfImg)?.setAttribute('style','display:none;')
         //@ts-ignore
         document.getElementById(idOfImg+'img')?.setAttribute('style','display:none;')
         
         this.setState({setDeletingImg:[...this.state.setDeletingImg,this.state.imageFile[key].name]})
        }
    }
    document.getElementById("sendOne2OneMessageBtn")?.focus()
   }

  sendImage =async  (acceptedFiles:any) => {
    
    const { text, channel } = this.state;
    try {
      
      
      
      for (const key in acceptedFiles) {
        
        if(typeof acceptedFiles[key] === 'object' && acceptedFiles[key].name && acceptedFiles[key].name !==this.state.setDeletingImg[0] && acceptedFiles[key].name !==this.state.setDeletingImg[1] &&acceptedFiles[key].name !==this.state.setDeletingImg[2] && acceptedFiles[key].name !==this.state.setDeletingImg[3]&& acceptedFiles[key].name !==this.state.setDeletingImg[4]&& acceptedFiles[key].name !==this.state.setDeletingImg[5]&& acceptedFiles[key].name !==this.state.setDeletingImg[6]&& acceptedFiles[key].name !==this.state.setDeletingImg[7]&& acceptedFiles[key].name !==this.state.setDeletingImg[8]&& acceptedFiles[key].name !==this.state.setDeletingImg[9]){
      
               channel &&channel.sendMessage({contentType:acceptedFiles[key].type,media:acceptedFiles[key]}); 
               //@ts-ignore
               document.getElementById(acceptedFiles[key].name)?.setAttribute('style','display:none;')
               //@ts-ignore
               document.getElementById(acceptedFiles[key].name+'img')?.setAttribute('style','display:none;')
        }
      }
      
       this.setState({imageFile:'',setDeletingImg:[],tempImgUrl:''})
    } catch (error) {
      console.error(error);
      
    }
    
  };


  sendMessage = (files?:any,id?:any) => {
    
    const { text, channel } = this.state;
    
    if(this.state.imageFile && text && String(text).trim()){
      this.sendImage(this.state.imageFile)
      channel && channel.sendMessage(text);
        //@ts-ignore
       const dataset = document.getElementById("sendChatMessageBtn" + id).value=""
    }else if(this.state.imageFile){
      this.sendImage(this.state.imageFile)
       //@ts-ignore
       const dataset = document.getElementById("sendChatMessageBtn" + id).value=""
    }
    else if (text && String(text).trim()) {
    
      channel && channel.sendMessage(text);
      this.setState({ text: "" });
      //@ts-ignore
      const dataset = document.getElementById("sendChatMessageBtn" + id).value=""
    }
    this.setState({ text: ""});
  };
  getToken = ()  => {
    try {
      //case:1 for chat_token
      if (this.Chat_Token !=="" && this.Chat_Token !== undefined ) {
         return this.Chat_Token
      }
      //case:2 for chat_token
      else if (this.state.chatAuthToken !=="" && this.state.chatAuthToken !== undefined) {
      return this.state.chatAuthToken
      }
        //case:3 for chat_token
       else if (Cookies.get("Chat_Token") !=="" && Cookies.get("Chat_Token") !== undefined)
      {
        return Cookies.get("Chat_Token") || ""
      } else {
        
        this.generateChatAuthToken()
         return Cookies.get("Chat_Token") || ""
      }
    } catch (e) {
      console.error("errors : ",e);
    }
     return ""
  };
  // Customizable Area End
  componentDidUpdate=()=>{
    
    
    this.render()
  }
  generateChatAuthToken() : boolean {
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ChatAuthTokenApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chatAuthTokenEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
    getFriendsList():boolean{
    const header = {
      "Content-Type":configJSON.contentTypeApiGetUserProfile,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allFirendsApiId= requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.friendsListEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId != null &&
      apiRequestCallId === this.ChatAuthTokenApiCallId
    ) {
      if (responseJson.token) {
       // toast.success("chatAuthToken success",{position: toast.POSITION.BOTTOM_RIGHT});
        this.setState({ chatAuthToken: responseJson.token })
        Cookies.set("Chat_Token", responseJson.token)
        this.Chat_Token = responseJson.token
        
      }
      if (responseJson.errors) {
        if (responseJson.errors && responseJson.errors[0]?.token) {
          toast.error(responseJson.errors[0]?.token,{position: toast.POSITION.BOTTOM_RIGHT})
          return;
        }
      }
    }
     if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId != null &&
      apiRequestCallId === this.allFirendsApiId
    ) {
       this.setState({ allFriendList: responseJson.data })
      
      }
     if (responseJson.errors) {
        
    }
    // Customizable Area End
    
  }
}
