import React from "react";

import UserController, { Props, configJSON } from "./UserController.web";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import IconButton from "@material-ui/core/IconButton";
//@ts-ignore
import ReactTable from "react-table-v6";  
import 'react-table-v6/react-table.css';

class User extends UserController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
   
   const columns = [{  
     Header: 'No',  
     accessor: 'id',
     filterable: true,
    sortable:true     
     },{  
     Header: 'FirstName',  
     accessor: 'attributes.first_name',
     filterable: true,
      sortable:false    
     },{  
      Header: 'LastName',  
     accessor: 'attributes.last_name',
     filterable: true,
     sortable:false    
      },{  
        Header: 'Email',  
        accessor: 'attributes.email',
        filterable: true,
        sortable:false        
      },{  
        Header: 'See Profile',  
        accessor: 'id',
        sortable:false,     
        Cell: (row:any) => (
          <IconButton style={{marginLeft:'40px'}}  onClick={(event:any)=>{
                    if (row.original.attributes?.user_name) {
                      //@ts-ignore
                      this.props.history.push({
                        pathname: '/'+ row.original.attributes.user_name,
                        state: { detail: row.original.attributes.user_name }
                      })
                    } else {
                      //@ts-ignore
                      this.props.history.push({
                        pathname: '/user/'+row.original.id,
                        state: { detail: row.original.id }
                      })
                    }
                }}>
                    <RemoveRedEyeIcon/>
                  </IconButton>
          
        )
      }]  
    return (
      // Customizable Area Start
      <div className={classes.userFriendList}>
        <h2>Friends</h2>
        <div className={classes.userFriendWrapper} style={{backgroundColor:'aliceblue',padding:'20px',borderRadius:'20px'}}>
        <ReactTable  
      data={this.state.allUsers}  
      columns={columns}  
      defaultPageSize = {10}  
      style={{    width: '100%'}}
      pageSizeOptions = {[10,15, 20]}  
      
     
  />  
        {/* <TableContainer component={Paper}>
                        <Table  aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              
                              <TableCell align="right">Id</TableCell>
                              <TableCell align="right">Email</TableCell>
                              <TableCell align="right">First Name</TableCell>
                              <TableCell align="right">Last Name</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
        {
            this.state.allUsers&&(
                this.state.allUsers.map((element:any,index) => {
                    return(
                        // <h2 className={classes.userFriend}>
                        //     <span onClick={()=>{
                             
                        //         //@ts-ignore
                        //         this.props.history.push({
                        //           pathname: '/user/'+element.id,
                        //           state: { detail: element.id }
                        //         })
                        //     }
                        //     }>{element.attributes.first_name+" "+element.attributes.last_name}</span>
                        // </h2>
                      
                         
                            
                              <TableRow  key={index}>
                                <TableCell align="right">{element.id}</TableCell>
                                <TableCell align="right">{element.attributes.email}</TableCell>
                                <TableCell align="right">{element.attributes.first_name}</TableCell>
                                <TableCell align="right">{element.attributes.last_name}</TableCell>
                              </TableRow>
                           
                          
                        
                    )
                })
            )
        }
        </TableBody>
        </Table>
                      </TableContainer> */}
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const UserStyles = {
  userFriendList: {
    "& h2": {
      textAlign: "center",
      color: "#ffffff",
      fontSize: "16px",
      fontFamily: "'Poppins', sans-serif",
    }
  },
  userFriendWrapper: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "700px",
    margin: "0 auto",
  },
  userFriend: {
    color: "#ffffff",
    fontSize: "14px",
    padding: "15px",
    fontFamily: "'Poppins', sans-serif",
  }

} as any;
// Customizable Area End
//@ts-ignore
const UserWithRouter = withRouter(User);
export default withStyles(UserStyles)(UserWithRouter);
// export default  (withRouter(Navbar as any) as any)
